import { memo } from "react";
// components
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  message?: string;
  answer?: string;
  place: "coupon" | "company";
};

function View({ message, answer }: Props) {
  const { t } = useTranslation();

  return (
    <Stack sx={{ minWidth: "50vw", p: 3 }} spacing={2}>
      <Stack spacing={1}>
        <Typography variant="h5">{t("message")}</Typography>
        <Box sx={{ pl: 3 }}>
          <div dangerouslySetInnerHTML={{ __html: message ?? `<i>${t("message_not_exist")}</i>` }} />
        </Box>
      </Stack>
      <Divider />
      <Stack spacing={1}>
        <Typography variant="h5">{t("answer")}</Typography>
        <Box sx={{ pl: 3 }}>
          <div dangerouslySetInnerHTML={{ __html: answer ?? `<i>${t("answer_not_exist")}</i>` }} />
        </Box>
      </Stack>
    </Stack>
  );
}

export default memo(View);
