import { memo } from "react";
import { useTranslation } from "react-i18next";
// components
import { TableBody as MuiTableBody, TableCell, TableRow } from "@mui/material";
import { Actions, ChangeOrder } from "src/components/common-components";
// types
import { Section } from "src/api/settings";

interface Props {
  sections: Section[];
  onDelete(id: number): void;
  onEdit(id: number): void;
  onOrderToDown(index: number, array: Section[]): void;
  onOrderToUp(index: number, array: Section[]): void;
}

function TableBody({ sections, onDelete, onEdit, onOrderToDown, onOrderToUp }: Props) {
  const { t } = useTranslation();

  return (
    <MuiTableBody>
      {sections.map((section, index, array) => (
        <TableRow key={section.id}>
          <TableCell>
            <ChangeOrder
              lastElementOrder={array.length}
              order={section.order}
              onOrderToDown={() => onOrderToDown(index, array)}
              onOrderToUp={() => onOrderToUp(index, array)}
            />
          </TableCell>
          <TableCell>{section.title_am ?? "-"}</TableCell>
          <TableCell>{section.title_ru ?? "-"}</TableCell>
          <TableCell>{section.title_en ?? "-"}</TableCell>
          <TableCell>{t(section.group) ?? "-"}</TableCell>
          <TableCell>
            <Actions actions={["edit", "remove"]} onEdit={() => onEdit(section.id)} onDelete={() => onDelete(section.id)} />
          </TableCell>
        </TableRow>
      ))}
    </MuiTableBody>
  );
}

export default memo(TableBody);
