import Api from "src/api";
// hooks
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// helpers
import { signIn } from "src/providers/redux/slices/user";
// components
import { Box, CircularProgress, IconButton, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormikProvider, useFormik } from "formik";
// types
import { SignInBody } from "src/api/auth";
// icons
import KeyIcon from "@mui/icons-material/Key";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { differenceInMilliseconds } from "date-fns";

export const TOKEN_KEY = "token";
export const TOKEN_EXPIRE_AT_KEY = "tokenExpiresAt";

export default function SignIn() {
  const token = localStorage.getItem(TOKEN_KEY);
  const tokenExpired = differenceInMilliseconds(new Date(localStorage.getItem(TOKEN_EXPIRE_AT_KEY) as string), new Date()) <= 0;

  if (token && !tokenExpired) return <Navigate to="/main" />;

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleSignIn = useCallback(async (body: SignInBody) => {
    setLoading(true);
    const response = await Api.auth.SignIn(body);
    setLoading(false);
    if (response?.data) {
      if (["super_admin", "admin", "manager", "operator"].includes(response.data.user.role)) {
        localStorage.setItem(TOKEN_KEY, response.data.auth.token);
        localStorage.setItem(TOKEN_EXPIRE_AT_KEY, response.data.auth.expiresAt);
        dispatch(signIn(response.data));
        if (["super_admin", "admin"].includes(response.data.user.role)) navigate("/main");
        else navigate("/personal");
      } else enqueueSnackbar("Permission denied", { variant: "error" });
    } else enqueueSnackbar(response.message, { variant: "error" });
  }, []);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: ""
    },
    onSubmit: values => handleSignIn(values),
    validateOnBlur: false,
    validateOnChange: false
  });

  const { getFieldProps, submitForm } = formik;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={submitForm}>
        <Box
          sx={{
            width: "100vw",
            height: "100vh"
          }}
        >
          <Box
            sx={{
              width: { xs: "80vw", sm: "60vw", md: "50vw", lg: "35vw" },
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              p: 2
            }}
          >
            <Stack spacing={5} alignItems="center">
              <Stack alignItems="center">
                <KeyIcon sx={{ fontSize: "50px" }} />
                <Typography sx={{ fontSize: "20px" }}>Войти</Typography>
              </Stack>
              <Stack spacing={3} sx={{ width: "100%" }}>
                <TextField fullWidth {...getFieldProps("username")} label="Введите логин" />
                <TextField
                  fullWidth
                  {...getFieldProps("password")}
                  type={showPassword ? "text" : "password"}
                  label="Введите пароль"
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => setShowPassword(state => !state)}>
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    )
                  }}
                />
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingIndicator={<CircularProgress sx={{ color: "#FFF" }} size={16} />}
                  sx={{
                    fontSize: "20px",
                    bgcolor: "primary.main",
                    color: "#FFF",
                    transition: "0.5s",
                    "&:hover": { bgcolor: "primary.main", opacity: "0.8" }
                  }}
                  onClick={submitForm}
                >
                  Войти
                </LoadingButton>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </form>
    </FormikProvider>
  );
}
