import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
// components
import { Grid, TextField, TextFieldProps } from "@mui/material";
import { Select } from "src/components/common-components/control";
// source
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { company_position_select_options, coupon_position_select_options } from "src/providers/source/positions";
import { format } from "date-fns";

interface FieldProps {
  field_key: string;
}

function Field({ field_key, ...props }: FieldProps & TextFieldProps) {
  const { t } = useTranslation();
  const { getFieldProps, errors } = useFormikContext<{ [key: string]: unknown }>();

  return (
    <TextField
      fullWidth
      {...getFieldProps(field_key)}
      label={t(field_key)}
      error={Boolean(errors[field_key])}
      helperText={errors[field_key]}
      {...props}
    />
  );
}

interface Props {
  mode: "add" | "edit";
  place: "coupon" | "company";
}

export default function UpsertForm({ mode, place }: Props) {
  const { t } = useTranslation();

  const { values, errors, setFieldValue } = useFormikContext<{ [key: string]: unknown }>();

  const selectOptions = place === "company" ? company_position_select_options : coupon_position_select_options;

  return (
    <Grid container sx={{ width: "100%" }} spacing={2}>
      <Grid item xs={6}>
        <Select field_key="type" options={selectOptions} />{" "}
      </Grid>
      {mode === "add" && (
        <Grid item xs={6}>
          <Field field_key="amount" fullWidth type="number" />
        </Grid>
      )}
      <Grid item xs={6}>
        <Field field_key="position" fullWidth type="number" />
      </Grid>
      <Grid item xs={mode === "edit" ? 12 : 6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            closeOnSelect
            inputFormat="yyyy-MM-dd"
            label={t("expire_at")}
            value={values["expire_at"]}
            onChange={(newValue: Date | null) => {
              setFieldValue("expire_at", newValue ? format(new Date(newValue), "yyyy-MM-dd").split(" ")[0] : "");
            }}
            renderInput={props => (
              <TextField {...props} error={Boolean(errors["expire_at"])} helperText={errors["expire_at"]} fullWidth required />
            )}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}
