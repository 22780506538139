import { Collapse, Grid, Typography, useMediaQuery } from "@mui/material";
import { memo } from "react";
import { ContentLanguages } from "src/types/components";
import { useTranslation } from "react-i18next";
// components
// source
import { FastDraft, FastField } from "../../fast-fields";

type Props = {
  currentLanguage: ContentLanguages;
  activeLanguage: ContentLanguages;
};

function CurrentLanguage({ activeLanguage, currentLanguage }: Props) {
  const isXlDown = useMediaQuery("(max-width:1536px)");
  const isActive = activeLanguage === currentLanguage;
  const { t } = useTranslation();

  return (
    <Collapse
      in={isXlDown ? true : isActive}
      orientation="horizontal"
      collapsedSize={200}
      sx={{ borderRadius: "20px", overflow: "hidden" }}
    >
      <Grid
        {...(!isActive && { title: "Click to open" })}
        container
        spacing={2}
        p={3}
        sx={{
          ...(!isActive && { backgroundColor: "rgba(0, 0, 0, 0.1)", opacity: 0.8, cursor: "pointer", transition: "1s" })
        }}
      >
        <Grid item xs={12}>
          <FastField field_key={`title_${currentLanguage}`} />
        </Grid>
        <Grid item xs={12}>
          <FastField field_key={`excerpt_${currentLanguage}`} />
        </Grid>
        <Grid item xs={12}>
          <FastField field_key={`meta_keywords_${currentLanguage}`} />
        </Grid>
        <Grid item xs={12}>
          <FastField field_key={`meta_description_${currentLanguage}`} />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: 12, m: 1 }}>{t(`terms_${currentLanguage}`)}</Typography>
          <FastDraft field_key={`terms_${currentLanguage}`} />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: 12, p: 1 }}>{t(`description_${currentLanguage}`)}</Typography>
          <FastDraft field_key={`description_${currentLanguage}`} />
        </Grid>
      </Grid>
    </Collapse>
  );
}

export default memo(CurrentLanguage);
