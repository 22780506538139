import Api from "src/api";
import { memo, useCallback, useEffect, useState } from "react";
// components
import { Box, Table } from "@mui/material";
import {
  TableHead,
  TableWrapper,
  WrapWithAddButton,
  WrapWithLoading,
  WrapWithNoResult,
  WrapWithPagination
} from "src/components/common-components";
import TableBody from "./TableBody";
//source
import { tableHeadCellNames } from "./source";
import { Admin, Meta, Pagination } from "src/types/api";
import { withModals, withModalsProps } from "src/components/hoc";
import { BaseModal, DeleteModal } from "src/components/modal";
import { AddUser, UpdateUser } from "./upsert";
import { useSnackbar } from "notistack";

function Users({
  mainModal,
  deleteModal,
  closeDeleteModal,
  openDeleteModal,
  closeMainModal,
  openMainModalForAdding,
  openMainModalForUpdating
}: withModalsProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [usersList, setUsersList] = useState<Admin[]>([]);
  const [usersMeta, setUsersMeta] = useState<Meta | null>(null);

  const handleDelete = useCallback(async () => {
    try {
      setDeleting(true);
      const response = await Api.users.deleteUser(deleteModal.id);
      setDeleting(false);
      if (response.status === "success") enqueueSnackbar("User successfully deleted", { variant: "success" });
      else enqueueSnackbar(response.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    } finally {
      closeDeleteModal();
      await getUsersList();
    }
  }, [deleteModal]);

  const getUsersList = useCallback(async (paginationParams?: Pagination) => {
    try {
      const response = await Api.users.getUsersList(paginationParams);
      setLoading(false);
      if (response?.data) setUsersList(response.data);
      if (response?.meta) setUsersMeta(response.meta);
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  useEffect(() => {
    getUsersList({ perPage: 50 });
  }, [getUsersList]);

  return (
    <WrapWithAddButton handleAdd={openMainModalForAdding} sx={{ width: "80%" }}>
      <TableWrapper>
        <WrapWithLoading loading={loading}>
          <WrapWithNoResult length={usersList.length}>
            <WrapWithPagination pagination_meta={usersMeta?.pagination} onPageChange={page => getUsersList({ page })}>
              <Table>
                <TableHead cellNames={tableHeadCellNames} />
                <TableBody users={usersList} onEdit={openMainModalForUpdating} onDelete={openDeleteModal} />
              </Table>
            </WrapWithPagination>
          </WrapWithNoResult>
        </WrapWithLoading>
      </TableWrapper>
      <BaseModal open={mainModal?.open} onClose={closeMainModal} disableBackdropClick>
        <Box sx={{ width: "80vw" }}>
          {(() => {
            switch (mainModal?.for) {
              case "edit":
                return mainModal?.id && <UpdateUser id={mainModal.id} refetch={getUsersList} closeModal={closeMainModal} />;
              case "add":
                return <AddUser refetch={getUsersList} closeModal={closeMainModal} />;
            }
          })()}
        </Box>
      </BaseModal>
      <DeleteModal open={Boolean(deleteModal?.open)} onClose={closeDeleteModal} onDelete={handleDelete} loading={deleting} />
    </WrapWithAddButton>
  );
}

export default withModals(memo(Users));
