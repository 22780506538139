import { memo } from "react";
// components
import Banners from "./banners";
import { TransactionsFilter } from "./transactions";
import { SubscribersFilter } from "./subscribers";
import { CompaniesFilter } from "./companies";
import { CouponsFilter } from "./coupons";
import { ReviewsFilter } from "./company-reviews";

export type FilterTopic = "transactions" | "subscribers" | "banners" | "companies" | "coupons" | "reviews";

type Props = {
  topic: FilterTopic;
};

function Filter({ topic }: Props) {
  switch (topic) {
    case "banners":
      return <Banners />;
    case "transactions":
      return <TransactionsFilter />;
    case "subscribers":
      return <SubscribersFilter />;
    case "companies":
      return <CompaniesFilter />;
    case "coupons":
      return <CouponsFilter />;
    case "reviews":
      return <ReviewsFilter />;
    default:
      return <p>no filter content</p>;
  }
}

export default memo(Filter);
