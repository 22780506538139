import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "src/api";
import { useSnackbar } from "notistack";
// components
import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import { FormikErrors, FormikProvider, useFormik } from "formik";
import { Editor } from "src/components/common-components";
import Field from "./field";
// types
import { ContentLanguages } from "src/types/components";
import { TextFromRequest, UpdateTextBody } from "src/api/settings";

interface Props {
  derived_values: TextFromRequest;
}

interface UpdateTerms {
  title_am: string;
  title_ru: string;
  title_en: string;
  content_am: string;
  content_ru: string;
  content_en: string;
}

function UpdateForm({ derived_values }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [updating, setUpdating] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState<ContentLanguages>("am");

  const handleUpdateText = useCallback(async (values: UpdateTextBody) => {
    try {
      setUpdating(true);
      const response = await Api.settings.texts.updateText(values);
      if (response.data) enqueueSnackbar(t("success"), { variant: "success" });
      else {
        setErrors(response.errors as FormikErrors<UpdateTerms>);
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setUpdating(false);
    }
  }, []);

  const formik = useFormik<UpdateTerms>({
    initialValues: {
      title_am: derived_values.title_am ?? "",
      title_ru: derived_values.title_ru ?? "",
      title_en: derived_values.title_en ?? "",
      content_am: derived_values.content_am ?? "",
      content_ru: derived_values.content_ru ?? "",
      content_en: derived_values.content_en ?? ""
    },
    onSubmit: values => {
      handleUpdateText({ ...values, key: derived_values.key });
    }
  });

  const { handleSubmit, setErrors } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Field field_key="title_am" />
          </Grid>
          <Grid item xs={4}>
            <Field field_key="title_ru" />
          </Grid>
          <Grid item xs={4}>
            <Field field_key="title_en" />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid
                item
                sx={{ transition: "0.5s" }}
                xs={12}
                xl={activeLanguage === "am" ? 8 : 2}
                onClick={() => setActiveLanguage("am")}
              >
                <Editor field_key="content_am" />
              </Grid>
              <Grid
                item
                sx={{ transition: "0.5s" }}
                xs={12}
                xl={activeLanguage === "ru" ? 8 : 2}
                onClick={() => setActiveLanguage("ru")}
              >
                <Editor field_key="content_ru" />
              </Grid>
              <Grid
                item
                sx={{ transition: "0.5s" }}
                xs={12}
                xl={activeLanguage === "en" ? 8 : 2}
                onClick={() => setActiveLanguage("en")}
              >
                <Editor field_key="content_en" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton loading={updating} type="submit">
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </FormikProvider>
    </form>
  );
}

export default memo(UpdateForm);
