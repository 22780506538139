import Api from "src/api";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
// components
import { Table } from "@mui/material";
import { TableHead, TableWrapper, WrapWithAddButton, WrapWithLoading, WrapWithNoResult } from "src/components/common-components";
import { TableBody } from "./table";
import { DeleteModal } from "src/components/modal";
// source
import { Blog as BlogType } from "src/api/blog";
import { tableHeadCellNames } from "./source";
import { withModals, withModalsProps } from "src/components/hoc";
import { useNavigate } from "react-router-dom";

function Blog({ deleteModal, openDeleteModal, closeDeleteModal }: withModalsProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [blogList, setBlogList] = useState<BlogType[]>([]);

  const getBlogList = useCallback(async () => {
    try {
      const response = await Api.blog.getList();
      if (response.data) {
        setLoading(false);
        setBlogList(response.data);
      } else enqueueSnackbar(response.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const handleDelete = useCallback(async () => {
    if (deleteModal?.id) {
      try {
        setDeleting(true);
        const response = await Api.blog.delete(deleteModal.id);
        setDeleting(false);
        if (response.status === "success") {
          enqueueSnackbar(t("blog_deleted", { id: deleteModal.id }), { variant: "success" });
        } else enqueueSnackbar(response.message, { variant: "error" });
      } catch (error) {
        throw new Error(error as string);
      } finally {
        closeDeleteModal();
        await getBlogList();
      }
    }
  }, [deleteModal]);

  useEffect(() => {
    getBlogList();
  }, [getBlogList]);

  return (
    <WrapWithAddButton sx={{ width: "80%" }} handleAdd={() => navigate(`/blog/upsert/add`)}>
      <WrapWithLoading loading={loading}>
        <WrapWithNoResult length={blogList.length}>
          <TableWrapper>
            <Table>
              <TableHead cellNames={tableHeadCellNames} />
              <TableBody blogs={blogList} onEdit={id => navigate(`/blog/upsert/${id}`)} onDelete={openDeleteModal} />
            </Table>
          </TableWrapper>
        </WrapWithNoResult>
      </WrapWithLoading>
      <DeleteModal open={deleteModal?.open} onClose={closeDeleteModal} onDelete={handleDelete} loading={deleting} />
    </WrapWithAddButton>
  );
}

export default withModals(Blog);
