import Api from "src/api";
// hooks
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
// components
import { Box, Table } from "@mui/material";
import { TableHead, TableWrapper, WrapWithLoading, WrapWithAddButton, WrapWithNoResult } from "src/components/common-components";
import { useCallback, useEffect, useState } from "react";
import { PlanType } from "src/api/plans";
import { BaseModal, DeleteModal } from "src/components/modal";
import { TableBody, tableHeadCellNames } from "./common";
import { AddCouponPlan, UpdateCouponPlan } from "./common/coupon";
import { withModals, withModalsProps } from "src/components/hoc";

function Coupon({
  mainModal,
  deleteModal,
  openDeleteModal,
  closeDeleteModal,
  openMainModalForAdding,
  openMainModalForUpdating,
  closeMainModal
}: withModalsProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);

  const [membershipPlans, setMembershipPlans] = useState<PlanType[]>([]);

  const getMembershipPlans = useCallback(async () => {
    try {
      const response = await Api.plans.getCouponPlans();
      if (response?.data) {
        setMembershipPlans(response.data);
        setLoading(false);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const handleDelete = useCallback(async () => {
    if (deleteModal?.id) {
      try {
        setDeleting(true);
        const response = await Api.plans.deleteCouponPlan(deleteModal.id);
        setDeleting(false);
        if (response.status === "success") {
          enqueueSnackbar(t("coupon_plan_deleted", { number: deleteModal.id }), { variant: "success" });
        } else enqueueSnackbar(response.message, { variant: "error" });
      } catch (error) {
        throw new Error(error as string);
      } finally {
        closeDeleteModal();
        await getMembershipPlans();
      }
    }
  }, [deleteModal]);

  useEffect(() => {
    getMembershipPlans();
  }, []);

  return (
    <WrapWithAddButton handleAdd={openMainModalForAdding} sx={{ width: "80%" }}>
      <TableWrapper>
        <WrapWithLoading loading={loading}>
          <WrapWithNoResult length={membershipPlans.length}>
            <Table>
              <TableHead cellNames={tableHeadCellNames} />
              <TableBody plans={membershipPlans} onDelete={openDeleteModal} onEdit={openMainModalForUpdating} />
            </Table>
          </WrapWithNoResult>
        </WrapWithLoading>
        <BaseModal open={Boolean(mainModal?.open)} onClose={closeMainModal} disableBackdropClick>
          <Box sx={{ width: "70vw" }}>
            {(() => {
              switch (mainModal?.for) {
                case "add":
                  return <AddCouponPlan refetch={getMembershipPlans} closeModal={closeMainModal} />;
                case "edit":
                  return <UpdateCouponPlan refetch={getMembershipPlans} closeModal={closeMainModal} planId={mainModal?.id} />;
              }
            })()}
          </Box>
        </BaseModal>
        <DeleteModal open={Boolean(deleteModal?.open)} onClose={closeDeleteModal} onDelete={handleDelete} loading={deleting} />
      </TableWrapper>
    </WrapWithAddButton>
  );
}

export default withModals(Coupon);
