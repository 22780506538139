import { memo } from "react";
// components
import { TableBody as MuiTableBody, TableCell, TableRow } from "@mui/material";
import { Actions } from "src/components/common-components";
// types
import { Region } from "src/api/settings";

interface Props {
  regions: Region[];
  onEdit(id: number): void;
  onDelete(id: number): void;
}

function TableBody({ regions, onDelete, onEdit }: Props) {
  return (
    <MuiTableBody>
      {regions.map(region => (
        <TableRow key={region.id}>
          <TableCell>{region.title_am ?? "-"}</TableCell>
          <TableCell>{region.title_ru ?? "-"}</TableCell>
          <TableCell>{region.title_en ?? "-"}</TableCell>
          <TableCell>{region.latitude ?? "-"}</TableCell>
          <TableCell>{region.longitude ?? "-"}</TableCell>
          {/* handle images cell */}
          <TableCell>
            <Actions actions={["edit", "remove"]} onEdit={() => onEdit(region.id)} onDelete={() => onDelete(region.id)} />
          </TableCell>
        </TableRow>
      ))}
    </MuiTableBody>
  );
}

export default memo(TableBody);
