import { createContext } from "react";

interface SubscriberCouponsStoreContextProps {
  subscriberId: number | "";
  closeModal: () => void;
}

export const SubscriberCouponsStoreContext = createContext<SubscriberCouponsStoreContextProps>({
  subscriberId: "",
  closeModal: () => alert("fn is empty")
});

export const { Provider: SubscriberCouponsStoreContextProvider } = SubscriberCouponsStoreContext;
