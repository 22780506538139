import { memo } from "react";
import { FormikProvider, useFormik, useFormikContext } from "formik";
// components
import { FieldsWrapper, Submit } from "src/components/common-components";
// source
import { UpdateCoupon } from "../../source";
import UpsertForm, { CreatePrice } from "./upsert-form";

interface Props {
  onClose(): void;
}

function AddPrice({ onClose }: Props) {
  const {
    setFieldValue,
    values: { prices }
  } = useFormikContext<UpdateCoupon>();

  const formik = useFormik<CreatePrice>({
    initialValues: {
      id: null,
      description_am: "",
      description_ru: "",
      description_en: "",
      title_am: "",
      title_en: "",
      title_ru: "",
      price: "",
      discounted_price: "",
      coupon_price: "",
      discount: "",
      available: ""
    },
    onSubmit(values) {
      setFieldValue("prices", [...prices, values]);
      onClose();
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false
  });

  const { handleSubmit } = formik;

  return (
    <FieldsWrapper>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <UpsertForm />
          <Submit title="add" loading={false} />
        </form>
      </FormikProvider>
    </FieldsWrapper>
  );
}

export default memo(AddPrice);
