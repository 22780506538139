// hooks
import { Grid } from "@mui/material";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormikProvider, useFormik } from "formik";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
// components
import { memo, useContext } from "react";
import { ResetForm, SubmitForm } from "../common";
import { Select } from "../../control";
import Field from "../common/Field";
// context
import { FilterContext } from "src/providers/context";

export interface CompaniesFilterProps {
  name?: string;
  status: string;
}

function Companies() {
  const { onFilter, filtering } = useContext(FilterContext);
  const { search } = useLocation();

  const status = queryString.parse(search)?.status as string;

  const formik = useFormik<CompaniesFilterProps>({
    initialValues: { name: "", status: status ?? "" },
    onSubmit(values) {
      const filteredValues = Object.fromEntries(Object.entries(values).filter(value => Boolean(value[1]) && value));
      onFilter(filteredValues);
    }
  });

  const { handleSubmit, resetForm } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <Grid container alignItems="center" rowGap={2} pt={2}>
          <Grid item xs={6}>
            <Grid container columnSpacing={2}>
              <Grid item xs={6}>
                <Field field_key="name" />
              </Grid>
              <Grid item xs={6}>
                <Select
                  field_key="status"
                  options={[
                    { label: "blocked", value: "blocked" },
                    { label: "deactivated", value: "deactivated" },
                    { label: "active", value: "active" },
                    { label: "in_moderation", value: "in_moderation" }
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container columnSpacing={2} justifyContent="flex-end">
              <Grid item>
                <SubmitForm loading={filtering} />
              </Grid>
              <Grid item>
                <ResetForm onClick={resetForm} loading={filtering} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormikProvider>
    </form>
  );
}

export default memo(Companies);
