// hooks
import { Grid } from "@mui/material";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormikProvider, useFormik } from "formik";
// components
import { memo, useContext } from "react";
import { ResetForm, SubmitForm } from "../common";
import Field from "../common/Field";
import { ReactPhoneField } from "../../control";
// context
import { FilterContext } from "src/providers/context";

export interface SubscribersFilterProps {
  name: string;
  email: string;
  phone: string;
}

function Subscribers() {
  const { onFilter, filtering } = useContext(FilterContext);

  const formik = useFormik<SubscribersFilterProps>({
    initialValues: {
      name: "",
      email: "",
      phone: ""
    },
    onSubmit(values) {
      const filteredValues = Object.fromEntries(Object.entries(values).filter(value => Boolean(value[1]) && value));
      onFilter(filteredValues);
    }
  });

  const { handleSubmit, resetForm } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <Grid container alignItems="center" justifyContent="flex-end" columnSpacing={2} rowGap={2} pt={2}>
          <Grid item xs={3}>
            <ReactPhoneField field_key="phone" />
          </Grid>
          <Grid item xs={3}>
            <Field field_key="name" />
          </Grid>
          <Grid item xs={3}>
            <Field field_key="email" />
          </Grid>
          <Grid item xs={3}>
            <Grid container columnSpacing={2} justifyContent="flex-end">
              <Grid item xs={6}>
                <SubmitForm loading={filtering} />
              </Grid>
              <Grid item xs={6}>
                <ResetForm onClick={resetForm} loading={filtering} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormikProvider>
    </form>
  );
}

export default memo(Subscribers);
