import Api from "src/api";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
// components
import { Box, CircularProgress, IconButton, Paper, TextField } from "@mui/material";
import { openUniversalImage } from "src/providers/lib";
// icons
import Cancel from "@mui/icons-material/Cancel";
// source
import { FileType } from "../upload-modal";
// icons
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { CouponMediaContext } from "src/providers/context";

export type MediaResource = {
  id: number | null;
  url?: string;
  file?: File | Blob | string;
  type: FileType;
  alt: string | null;
  order?: number;
};

type Props = {
  data: MediaResource;
};

export default function MediaBox({ data }: Props) {
  const { setMediasCache } = useContext(CouponMediaContext);

  const [deleting, setDeleting] = useState(false);
  const [updatingMediaAlt, setUpdatingMediaAlt] = useState(false);
  const [mediaAlt, setMediaAlt] = useState(data.alt ?? "");
  const [mediaOrder, setMediaOrder] = useState(data.order ?? "");

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { id } = useParams();

  const handleDelete = useCallback(async () => {
    try {
      if (data.id) {
        setDeleting(true);
        const response = await Api.coupons.deleteMedia(data.id);
        if (response.status === "success") {
          enqueueSnackbar(t("success"), { variant: "success" });
          if (setMediasCache) setMediasCache(array => array.filter(media => media.id !== data.id));
        } else enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setDeleting(false);
    }
  }, [data.id, setMediasCache]);

  const handleEdit = useCallback(async () => {
    try {
      if (id && data.id && mediaAlt) {
        setUpdatingMediaAlt(true);
        const response = await Api.coupons.updateMediaAlt(id, data.id, mediaAlt);
        if (!response.data) enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setUpdatingMediaAlt(false);
    }
  }, [id, mediaAlt, data.id]);

  const handleOrderEdit = useCallback(async () => {
    try {
      if (id && data.id) {
        const response = await Api.coupons.updateMediaOrder(id, data.id, mediaOrder);
        if (!response.data) enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, [id, mediaOrder, data.id]);

  return (
    <Paper elevation={8} sx={{ position: "relative" }}>
      {(() => {
        switch (data.type) {
          case "image":
            return (
              <Box sx={{ p: 1 }}>
                <Box sx={{ overflow: "hidden", height: "220px", position: "relative" }}>
                  <img src={openUniversalImage(data?.file ?? "")} width="100%" alt="image" />
                </Box>
                <TextField
                  fullWidth
                  label={t("alternative")}
                  size="small"
                  value={mediaAlt}
                  onChange={e => setMediaAlt(e.target.value)}
                  onBlur={handleEdit}
                  InputProps={{ endAdornment: updatingMediaAlt ? <CircularProgress size="20px" /> : null }}
                />
                <TextField
                  sx={{ mt: 1 }}
                  fullWidth
                  type="number"
                  label={t("order")}
                  size="small"
                  value={mediaOrder}
                  onChange={e => setMediaOrder(e.target.value)}
                  onBlur={handleOrderEdit}
                />
              </Box>
            );
          case "video":
            return (
              <Box sx={{ height: "100%", position: "relative" }}>
                <video src={openUniversalImage(data?.file ?? "")} width="100%" height="100%" style={{ objectFit: "cover" }} />
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    borderRadius: "100%",
                    overflow: "hidden"
                  }}
                >
                  <a
                    href={typeof data.file === "string" ? data.file : URL.createObjectURL(data.file as File)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <PlayCircleIcon sx={{ fontSize: 100, cursor: "pointer", color: "common.white" }} />
                  </a>
                </Box>
              </Box>
            );
          case "link":
            return (
              <a href={data.url as string} target="_blank" rel="noreferrer">
                <Box sx={{ width: "100%", height: "100%" }} className="flex-center">
                  <InsertLinkIcon />
                </Box>
              </a>
            );
          default:
            return null;
        }
      })()}
      <IconButton
        sx={{ zIndex: "999", background: "#fff", boxShadow: 3, position: "absolute", top: 15, right: 15 }}
        onClick={handleDelete}
      >
        {deleting ? <CircularProgress size="20px" /> : <Cancel sx={{ color: "error.main" }} />}
      </IconButton>
    </Paper>
  );
}
