import { Box, Table } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Api from "src/api";
import { CategoryFollower } from "src/api/followers";
import { TableHead, TableWrapper, WrapWithLoading, WrapWithNoResult } from "src/components/common-components";
//source
import { categoryFollowersTableHeadCellNames } from "./source";
import { CategoryFollowersTableBody } from "./table";

export default function Followers() {
  const [loading, setLoading] = useState(true);
  const [followersList, setFollowersList] = useState<CategoryFollower[]>([]);

  const getList = useCallback(async () => {
    try {
      const response = await Api.followers.getCategoryFollowers();
      setLoading(false);
      if (response?.data) {
        setFollowersList(response.data);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
      <Box sx={{ width: { xs: "80%" } }}>
        <TableWrapper withGutter>
          <WrapWithLoading loading={loading}>
            <WrapWithNoResult length={followersList.length}>
              <Table>
                <TableHead cellNames={categoryFollowersTableHeadCellNames} />
                <CategoryFollowersTableBody followers={followersList} />
              </Table>
            </WrapWithNoResult>
          </WrapWithLoading>
        </TableWrapper>
      </Box>
    </Box>
  );
}
