import { createContext } from "react";
import { ActionCommons } from ".";

const onEdit = () => alert("onEdit fn is empty");
const onDelete = () => alert("onDelete fn is empty");
const onAddBalance = () => alert("onAddBalance fn is empty");
const refetchTable = () => alert("refetchTable fn is empty");

export const CompanyActionsContext = createContext<ActionCommons>({
  onEdit,
  onDelete,
  refetchTable,
  onAddBalance
});

export const { Provider: CompanyActionsContextProvider } = CompanyActionsContext;
