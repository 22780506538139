import { Paper, Stack } from "@mui/material";
// source
import { memo, ReactNode } from "react";

type Props = {
  children: ReactNode | ReactNode[];
};

function Wrapper({ children }: Props) {
  return (
    <Paper elevation={7} sx={{ width: "100%", minHeight: 365, px: 1.5 }}>
      <Stack spacing={2} sx={{ pt: 4 }}>
        {children}
      </Stack>
    </Paper>
  );
}

export default memo(Wrapper);
