import Api from "src/api";
import { memo, useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
// components
import { Grid, Skeleton, Stack, TextField, TextFieldProps } from "@mui/material";
import UploadImage from "./upload-image";
// source
import { FormikErrors, FormikProvider, useFormik, useFormikContext } from "formik";
import { UpdateUserPersonalInformation, UserPersonalInformation } from "src/api/user";
import { FieldsWrapper, Select, Submit } from "src/components/common-components";
import { createFormDataWithImage } from "src/providers/lib";
import { genderOptions } from "src/providers/source";
import { useDispatch } from "react-redux";
import { update } from "src/providers/redux/slices";

interface FieldProps {
  field_key: keyof UpdateUserPersonalInformation;
}

const Field = memo(function Field({ field_key, ...props }: FieldProps & TextFieldProps) {
  const { t } = useTranslation();
  const { getFieldProps, errors } = useFormikContext<UpdateUserPersonalInformation>();

  return (
    <TextField
      fullWidth
      {...getFieldProps(field_key)}
      label={t(field_key)}
      error={Boolean(errors[field_key])}
      helperText={errors[field_key]}
      {...props}
    />
  );
});

function ChangePersonalInformation() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [user, setUser] = useState<UserPersonalInformation | null>(null);

  const { t } = useTranslation();

  const getMe = useCallback(async () => {
    const response = await Api.user.getMe();
    if (response.data) {
      setUser(response.data);
      setLoading(false);
    } else enqueueSnackbar(response.message, { variant: "error" });
  }, []);

  const updateMe = useCallback(async (values: FormData) => {
    try {
      setUpdating(true);
      const response = await Api.user.updateMe(values);
      if (response.data) {
        enqueueSnackbar(t("success"), { variant: "success" });
        getMe();
        dispatch(update({ userUpdatableContent: response.data }));
      } else {
        setErrors(response.errors as FormikErrors<UpdateUserPersonalInformation>);
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setUpdating(false);
    }
  }, []);

  const formik = useFormik<UpdateUserPersonalInformation>({
    initialValues: {
      avatar: user?.avatar ?? "",
      first_name: user?.first_name ?? "",
      last_name: user?.last_name ?? "",
      email: user?.email ?? "",
      gender: "female"
    },
    onSubmit: values => {
      const formData = createFormDataWithImage(values);
      formData.append("_method", "PUT");
      updateMe(formData);
    },
    enableReinitialize: true
  });

  useEffect(() => {
    getMe();
  }, [getMe]);

  const { handleSubmit, setErrors } = formik;

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <FieldsWrapper title="change_personal_information">
            <div>
              <Grid container spacing={5}>
                <Grid item xs={12} lg={6}>
                  {loading ? <Skeleton height="100%" /> : <UploadImage field_key="avatar" />}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Stack justifyContent="space-between" spacing={3.2}>
                    {loading ? (
                      <>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </>
                    ) : (
                      <>
                        <Field field_key="first_name" />
                        <Field field_key="last_name" />
                        <Field field_key="email" />
                        <Select field_key="gender" options={genderOptions} />
                      </>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </div>
            <Submit title="save" loading={updating} />
          </FieldsWrapper>
        </FormikProvider>
      </form>
    </div>
  );
}

export default memo(ChangePersonalInformation);
