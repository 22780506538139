import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
// components
import { FastField, FastFieldProps } from "formik";
// source
import { UpdateCoupon } from "../source";

interface Props {
  field_key: keyof UpdateCoupon;
}

export default function FastCheckbox({ field_key }: Props) {
  const { t } = useTranslation();

  return (
    <Box>
      <FastField name={field_key}>
        {({ field, form }: FastFieldProps) => (
          <FormControlLabel
            sx={{ ml: 0 }}
            label={t(field_key)}
            control={
              <Checkbox
                checked={Boolean(form.values[field_key])}
                onChange={e => form.setFieldValue(field_key, e.target.checked ? 1 : 0)}
              />
            }
            {...field}
          />
        )}
      </FastField>
    </Box>
  );
}
