import ApiSlice from "src/api/slice";
// types
import { Blog as BlogType } from "./types";

export default class Blog extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin/blog`;
  static defaultAuth = true;

  static getList = () => this.request<BlogType[]>();
  static getSingle = (id: string) => this.request<BlogType>(`/${id}`);
  static update = (id: string, body: FormData) => this.request<BlogType>(`/${id}`, "POST", body);
  static create = (body: FormData) => this.request<BlogType>(``, "POST", body);
  static delete = (id: number) => this.request(`/${id}`, "DELETE");
}

export * from "./types";
