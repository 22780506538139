import { CreatePrice } from "../prices/upsert/upsert-form";

export const couponTypeOptions = [
  { label: "coupon", value: "coupon" },
  { label: "certificate", value: "certificate" }
];
export interface UpdateCoupon {
  thumbnail_alt: string;
  slug: string;
  type: "coupon" | "certificate";
  thumbnail: string | Blob;
  categories: number[];
  price?: number;
  discount?: number;
  description_am: string;
  description_ru: string;
  description_en: string;
  excerpt_am: string;
  excerpt_ru: string;
  excerpt_en: string;
  meta_description_am: string;
  meta_description_ru: string;
  meta_description_en: string;
  meta_keywords_am: string;
  meta_keywords_ru: string;
  meta_keywords_en: string;
  terms_am: string;
  terms_ru: string;
  terms_en: string;
  title_am: string;
  title_ru: string;
  title_en: string;
  prices: CreatePrice[];
  expire_at: string;
  addresses: number[];
  discount_equal: 1 | 0 | boolean;
  sold_count_enabled: 1 | 0 | boolean;
  is_draft?: 1 | 0 | boolean;
  coupon_price: string;
  page_title: string;
}
