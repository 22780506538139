// hooks/helpers
import { memo, useContext } from "react";
//components
import { Box, Grid } from "@mui/material";
import Card from "./card";
// source
import { DashboardContext } from "src/providers/context";
// icons
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import HandshakeIcon from "@mui/icons-material/Handshake";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";

function ApplicationInformationalCards() {
  const { data } = useContext(DashboardContext);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6} xl={4}>
          <Card count={data?.partners[0]?.count} path="/partners" title="partners" Icon={HandshakeIcon} iconColor="#ebb287" />
        </Grid>
        <Grid item xs={6} xl={4}>
          <Card count={data?.coupons[0]?.count} path="/coupons" title="stocks" Icon={LocalActivityIcon} iconColor="#f44336" />
        </Grid>
        <Grid item xs={6} xl={4}>
          <Card count={data?.sold_coupons} path="/coupons" title="sold_coupons" Icon={PointOfSaleIcon} iconColor="#636c77" />
        </Grid>
        <Grid item xs={6} xl={6}>
          <Card
            count={Number(data?.sold_coupons_amount)}
            path=""
            title="sold_coupons_amount"
            Icon={AttachMoneyIcon}
            iconColor="#64b06c"
          />
        </Grid>
        <Grid item xs={6} xl={6}>
          <Card
            count={data?.subscribers}
            path="/subscribers"
            title="subscribers"
            Icon={SupervisorAccountIcon}
            iconColor="#bad4f8"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(ApplicationInformationalCards);
