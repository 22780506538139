import { memo, PropsWithChildren } from "react";
// components
import { Box, IconButton, Modal, Paper } from "@mui/material";
// types
import { BaseModalProps } from "src/types/components";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import { ModalCloseProvider } from "src/providers/context/ModalCloseProvider";

function Base({
  open,
  children,
  disableBackdropClick = false,
  disablePaperPadding = false,
  cancelButtonColor = "rgba(0, 0, 0, 0.54)",
  onClose
}: PropsWithChildren<BaseModalProps>) {
  const { t } = useTranslation();

  if (!open) return null;

  return (
    <Modal open={open} {...(!disableBackdropClick && { onClose })}>
      <Paper
        sx={{
          ...(!disablePaperPadding && { p: 1 }),
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        <Box
          sx={{
            maxHeight: "90vh",
            maxWidth: "90vw",
            overflow: "auto"
          }}
        >
          <ModalCloseProvider value={{ closeModal: onClose }}> {children}</ModalCloseProvider>
        </Box>
        <IconButton title={t("close")} sx={{ position: "absolute", right: "0px", top: "0px" }} onClick={onClose}>
          <CancelIcon sx={{ color: cancelButtonColor }} />
        </IconButton>
      </Paper>
    </Modal>
  );
}

export default memo(Base);
