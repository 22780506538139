// components
import { Box, Divider, List, Stack } from "@mui/material";
import { ListItem, Logout, NestedList, NestedListItem } from "./appBarItems";
import { Link } from "react-router-dom";
// icons
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import RememberMeIcon from "@mui/icons-material/RememberMe";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import HomeIcon from "@mui/icons-material/Home";
import PaidIcon from "@mui/icons-material/Paid";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import GroupIcon from "@mui/icons-material/Group";
import HandshakeIcon from "@mui/icons-material/Handshake";
import PercentIcon from "@mui/icons-material/Percent";
import CommentIcon from "@mui/icons-material/Comment";
import AddCommentIcon from "@mui/icons-material/AddComment";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import TurnSlightRightIcon from "@mui/icons-material/TurnSlightRight";
import MapIcon from "@mui/icons-material/Map";
import BusinessIcon from "@mui/icons-material/Business";
import WorkIcon from "@mui/icons-material/Work";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import BarChartIcon from "@mui/icons-material/BarChart";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import QuizIcon from "@mui/icons-material/Quiz";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

function AppBar() {
  return (
    <Stack sx={{ height: "100%", bgcolor: "primary.main" }}>
      <Link to="/main">
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", height: "63px" }}>
          <img src="/images/main_logo.png" alt="main_logo" style={{ width: "208px", height: "32px" }} />
        </Box>
      </Link>
      <Divider />
      <List disablePadding sx={{ pt: 2, px: 2 }}>
        <ListItem permission_key="statistic" to="/main" title="main" Icon={HomeIcon} />
        <ListItem permission_key="statistic" to="/coupons" title="stocks" Icon={PercentIcon} />
        <ListItem permission_key="subscribers" to="/subscribers" title="subscribers" Icon={GroupIcon} />
        <ListItem permission_key="company" to="/partners" title="partners" Icon={HandshakeIcon} />
        <ListItem permission_key="user_transactions" to="/transactions" title="transactions" Icon={PaidIcon} />
        <ListItem permission_key="coupons_comments" to="/coupon-feedbacks" title="coupon_feedbacks" Icon={CommentIcon} />
        <ListItem permission_key="company_review" to="/company-feedbacks" title="company_feedbacks" Icon={CommentIcon} />
        <ListItem
          permission_key="company_questions"
          to="/company-questions"
          title="company_questions"
          Icon={QuestionAnswerIcon}
        />
        <ListItem permission_key="contact_form" to="/feedback-forms" title="feedback_forms" Icon={AddCommentIcon} />
        <NestedList permission_keys={["category_followers", "company_followers"]} title="followers" Icon={FollowTheSignsIcon}>
          <NestedListItem
            permission_key="category_followers"
            to="/followers/categories"
            title="category_followers"
            Icon={AdminPanelSettingsIcon}
          />
          <NestedListItem
            permission_key="company_followers"
            to="/followers/companies"
            title="company_followers"
            Icon={BusinessIcon}
          />
        </NestedList>
        <ListItem permission_key="statistic" to="/statistics" title="statistics" Icon={BarChartIcon} />
        <ListItem permission_key="users" to="/users" title="users" Icon={AdminPanelSettingsIcon} />
        <ListItem permission_key="finance" to="/finances" title="finances" Icon={AccountBalanceIcon} />
        <NestedList
          permission_keys={[
            "banners",
            "navigation",
            "sections",
            "common",
            "business_types",
            "categories",
            "regions",
            "blog",
            "faq"
          ]}
          title="settings"
          Icon={SettingsSuggestIcon}
        >
          <NestedListItem permission_key="banners" to="/settings/banners" title="banners" Icon={SubtitlesIcon} />
          <NestedListItem permission_key="navigation" to="/settings/navigation" title="navigation" Icon={TurnSlightRightIcon} />
          <NestedListItem permission_key="sections" to="/settings/sections" title="sections" Icon={AddToQueueIcon} />
          <NestedListItem permission_key="common" to="/settings/texts" title="texts" Icon={TextSnippetIcon} />
          <NestedListItem permission_key="business_types" to="/settings/business_types" title="business_types" Icon={WorkIcon} />
          <NestedListItem
            permission_key="categories"
            to="/settings/categories"
            title="categories"
            Icon={FormatListBulletedIcon}
          />
          <NestedListItem permission_key="regions" to="/settings/regions" title="regions" Icon={MapIcon} />
          <NestedListItem permission_key="blog" to="/settings/blog" title="blog" Icon={NewspaperIcon} />
          <NestedListItem permission_key="faq" to="/settings/faq" title="faq" Icon={QuizIcon} />
        </NestedList>
        <NestedList permission_keys={["plans_membership", "plans_coupons"]} title="plans" Icon={JoinInnerIcon}>
          <NestedListItem permission_key="plans_membership" to="/plans/member" title="member_plans" Icon={RememberMeIcon} />
          <NestedListItem permission_key="plans_coupons" to="/plans/coupon" title="coupon_plans" Icon={ConfirmationNumberIcon} />
        </NestedList>
        <NestedList permission_keys={["common"]} title="my_profile" Icon={AccountBoxIcon}>
          <NestedListItem permission_key="common" to="/personal" title="main_settings" Icon={ManageAccountsIcon} />
        </NestedList>
        <Logout />
      </List>
    </Stack>
  );
}

export default AppBar;
