// react
import { memo, useContext, useMemo, useEffect } from "react";
import { FormikProvider, useFormik } from "formik";
// components
import { Grid } from "@mui/material";
import { Select } from "../../control";
// source
import { FilterContext } from "src/providers/context";
import { BannersContext } from "src/providers/context/BannersContext";
import { BannerPosition } from "src/api/settings";

export type BannerFilterOptions = { filter_by: "" | BannerPosition };

function BannersFilter() {
  const { onFilter } = useContext(FilterContext);
  const { positionTypes } = useContext(BannersContext);

  const formik = useFormik<BannerFilterOptions>({
    initialValues: { filter_by: "" },
    onSubmit(values) {
      onFilter(values);
    }
  });

  const { values } = formik;

  const positionTypesForSelect = useMemo(() => positionTypes.map(type => ({ label: type, value: type })), [positionTypes]);

  useEffect(() => {
    onFilter(values);
  }, [values]);

  return (
    <Grid container py={2} justifyContent="flex-end">
      <FormikProvider value={formik}>
        <Grid item xs={2}>
          <Select field_key="filter_by" options={[{ label: "all", value: "all" }, ...positionTypesForSelect]} />
        </Grid>
      </FormikProvider>
    </Grid>
  );
}

export default memo(BannersFilter);
