import ApiSlice from "../slice";
// types
import { Admin, Auth as AuthT } from "src/types/api";
import { ChangePasswordReqBodyTypes } from "./types";

type SignIn = {
  user: Admin;
  auth: AuthT;
};

export type SignInBody = {
  username: string;
  password: string;
};

export default class Auth extends ApiSlice {
  static SignIn = (body: SignInBody) => this.request<SignIn>("/session", "POST", body);
  static changePassword = (body: ChangePasswordReqBodyTypes) => this.request("/password/change", "POST", { ...body });
  // send token with sign out request
  // static SignOut = () => this.request("/session/destroy", "DELETE");
}

export * from "./types";
