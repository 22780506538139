import { Box, FormHelperText } from "@mui/material";
import { useFormikContext } from "formik";
import { memo, useEffect } from "react";
import { CreateNavigation } from "src/api/settings";
// source
import { withUpload } from "src/components/hoc/client-intervention";
import { withUploadProps } from "src/components/hoc/client-intervention/withUpload";
import { openUniversalImage } from "src/providers/lib";

function UploadImage({ image }: withUploadProps) {
  const { values, errors, setFieldValue } = useFormikContext<CreateNavigation>();

  useEffect(() => {
    if (!image.first_render) {
      setFieldValue("image", image.file);
    }
  }, [image]);

  return (
    <Box
      sx={{
        ...(errors.image && { border: "1.5px solid", borderColor: "error.main", p: 1, overflow: "hidden" })
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "250px"
        }}
      >
        <img
          src={values.image ? openUniversalImage(values.image) : image.url}
          width={"100%"}
          height={"100%"}
          style={{ objectFit: "contain" }}
        />
      </Box>
      {errors.image && <FormHelperText sx={{ color: "error.main" }}>{errors.image}</FormHelperText>}
    </Box>
  );
}

export default withUpload(memo(UploadImage));
