import { memo } from "react";
import { useTranslation } from "react-i18next";
// components
import { TextField, TextFieldProps } from "@mui/material";

interface FieldProps {
  field_key: string;
}

function Field({ field_key, ...props }: FieldProps & TextFieldProps) {
  const { t } = useTranslation();

  return <TextField fullWidth label={t(field_key)} {...props} />;
}

export default memo(Field);
