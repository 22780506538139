import { memo } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
// components
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";

export type CustomSelectProps = { label: string; value: string | number; disabled?: boolean; labelAdditionalValue?: string };
interface Props extends SelectProps {
  field_key: string;
  options: CustomSelectProps[];
}

function CustomSelect({ field_key, options, ...props }: Props) {
  const { t } = useTranslation();
  const { getFieldProps, errors } = useFormikContext<{ [key: string]: unknown }>();

  return (
    <FormControl fullWidth error={Boolean(errors[field_key])}>
      <InputLabel id={`create_user_${field_key}_label`}>{t(field_key)}</InputLabel>
      <Select
        labelId={`create_user_${field_key}_label`}
        id={`create_user_${field_key}`}
        {...getFieldProps(field_key)}
        label={t(field_key)}
        {...props}
      >
        {!props.multiple && (
          <MenuItem value="">
            <em>none</em>
          </MenuItem>
        )}
        {options.map(({ label, value, disabled, labelAdditionalValue }) => (
          <MenuItem disabled={Boolean(disabled)} key={`${field_key}_${value}`} value={value}>
            {t(label, { additional: labelAdditionalValue ?? "" })}
          </MenuItem>
        ))}
      </Select>
      {Boolean(errors[field_key]) && <FormHelperText>{errors[field_key]}</FormHelperText>}
    </FormControl>
  );
}

export default memo(CustomSelect);
