//hooks/helpers
import { ComponentType, useState } from "react";
import { SubscriberConfirmParams } from "src/providers/context";
// types
import { ActionTypes } from "../common-components";

type MainModal = { id?: number; open: boolean; for: ActionTypes | "add"; additionalInfo?: unknown };
type DeleteModal = { id: number; open: boolean };

export interface withModalsProps {
  mainModal: MainModal;
  deleteModal: DeleteModal;
  openMainModalForAdding(): void;
  openMainModalForUpdating(id: number): void;
  openMainModalForStatusChange(id: number): void;
  openMainModalForConfirm(params: SubscriberConfirmParams): void;
  closeMainModal(): void;
  openDeleteModal(id: number): void;
  closeDeleteModal(): void;
  openMainModalForAddBalance(id: number): void;
  openMainModalForMakeTop(id: number): void;
}

function withModals<T>(Component: ComponentType<T>) {
  return function WrappedComponent(
    props: Omit<
      T,
      | "mainModal"
      | "deleteModal"
      | "openMainModalForAdding"
      | "openMainModalForUpdating"
      | "closeMainModal"
      | "openDeleteModal"
      | "closeDeleteModal"
      | "openMainModalForAddBalance"
      | "openMainModalForConfirm"
      | "openMainModalForStatusChange"
      | "openMainModalForMakeTop"
    >
  ) {
    const [mainModal, setMainModal] = useState<MainModal | null>(null);
    const [deleteModal, setDeleteModal] = useState<DeleteModal | null>(null);

    const openMainModalForAdding = () => setMainModal({ open: true, for: "add" });
    const openMainModalForAddBalance = (id: number) => setMainModal({ id, open: true, for: "add_balance" });
    const openMainModalForConfirm = ({ id, email, phone }: SubscriberConfirmParams) =>
      setMainModal({ id, open: true, for: "confirm", additionalInfo: { phone, email } });
    const openMainModalForUpdating = (id: number) => setMainModal({ id, open: true, for: "edit" });
    const openMainModalForStatusChange = (id: number) => setMainModal({ id, open: true, for: "change_status" });
    const closeMainModal = () => setMainModal(null);
    const openDeleteModal = (id: number) => setDeleteModal({ id, open: true });
    const closeDeleteModal = () => setDeleteModal(null);
    const openMainModalForMakeTop = (id: number) => setMainModal({ id, open: true, for: "make_top" });

    return (
      <Component
        {...(props as T)}
        mainModal={mainModal}
        deleteModal={deleteModal}
        openMainModalForAdding={openMainModalForAdding}
        openMainModalForUpdating={openMainModalForUpdating}
        openMainModalForAddBalance={openMainModalForAddBalance}
        openMainModalForConfirm={openMainModalForConfirm}
        openMainModalForStatusChange={openMainModalForStatusChange}
        closeMainModal={closeMainModal}
        openDeleteModal={openDeleteModal}
        closeDeleteModal={closeDeleteModal}
        openMainModalForMakeTop={openMainModalForMakeTop}
      />
    );
  };
}

export default withModals;
