import { memo, useCallback } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
// source
import { AddMedia } from "../..";
import { Button, Grid, TextField } from "@mui/material";

interface Props {
  url: string;
  index: number;
}

function LinkField({ url, index }: Props) {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<AddMedia>();

  const handleDelete = useCallback(() => {
    setFieldValue(
      "links_preview",
      [...values.links_preview].filter((_, i) => i !== index)
    );
  }, [index, values.links_preview]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <TextField
            fullWidth
            value={url}
            onChange={e => {
              setFieldValue(
                "links_preview",
                values.links_preview.map((value, i) => (i === index ? e.target.value : value))
              );
            }}
            sx={{ "& input": { py: 1 } }}
          />
        </Grid>
        <Grid item xs={2}>
          <Button fullWidth onClick={handleDelete}>
            {t("remove")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(LinkField);
