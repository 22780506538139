import { BannerPosition } from "src/api/settings";

export function checkIsPOOS(value: BannerPosition | "") {
  return value.includes("offer_section") || value.includes("partners_section");
}

export function checkIsPS(value: BannerPosition | "") {
  return value.includes("partners_section");
}

export function checkIsOS(value: BannerPosition | "") {
  return value.includes("offer_section");
}
