import { memo } from "react";
import { useFormikContext } from "formik";
// components
import { Box, Chip, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps, Typography } from "@mui/material";
// source
import { useTranslation } from "react-i18next";

export type CustomSelectProps = {
  label: string | number;
  value: string | number;
  price: string | number;
  disabled?: boolean;
};

interface Props extends SelectProps {
  field_key: string;
  options: CustomSelectProps[];
}

function CustomSelect({ field_key, options, ...props }: Props) {
  const { t } = useTranslation();
  const { getFieldProps, errors } = useFormikContext<{ [key: string]: unknown }>();

  return (
    <FormControl fullWidth error={Boolean(errors[field_key])}>
      <InputLabel
        sx={{ maxWidth: "75%" }}
        size={props?.size === "small" ? "small" : "normal"}
        id={`create_user_${field_key}_label`}
      >
        {t(field_key)}
      </InputLabel>
      <Select
        id={`create_user_${field_key}`}
        {...getFieldProps(field_key)}
        labelId={`create_user_${field_key}_label`}
        label={t(field_key)}
        MenuProps={{ sx: { height: 300 } }}
        sx={{ "& .selected-plan": { display: "none" } }}
        {...props}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {options.map(({ label, value, price, disabled }) => (
          <MenuItem
            disabled={Boolean(disabled)}
            key={`${field_key}_${value}`}
            value={value}
            sx={{ fontSize: { xs: 13, xl: 16 }, position: "relative" }}
          >
            <Box
              sx={{
                width: "100%",
                "&:hover": { mr: { md: 0.5 } }
              }}
            >
              <Typography sx={{ fontSize: { xs: 12, xl: 16 } }}>{label}</Typography>
              <Box
                className="selected-plan"
                sx={{
                  position: "absolute",
                  height: "92%",
                  overflow: "hidden",
                  width: "auto",
                  right: 0,
                  top: "50%",
                  transform: "translate(0, -50%)",
                  transition: "0.4s",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "2px"
                }}
              >
                <Chip label={price + " " + t("dram")} />
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Select>
      {Boolean(errors[field_key]) && <FormHelperText>{errors[field_key]}</FormHelperText>}
    </FormControl>
  );
}

export default memo(CustomSelect);
