import Api from "src/api";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { FormikErrors, FormikProvider, useFormik } from "formik";
import { useTranslation } from "react-i18next";
// components
import { Box, Divider, Grid, Typography } from "@mui/material";
import Content from "./content";
import ConstantFields from "./constant-fields";
import Photos from "./photos";
import { WrapWithLoading } from "src/components/common-components";
import ContentWrapper from "./common/ContentWrapper";
import Prices from "./prices";
// source
import { Coupon } from "src/api/coupons";
import { CouponProvider } from "src/providers/context";
import { UpdateCoupon } from "./source";
import { ParentCategory, CompanyAddress, CouponAddress } from "src/api/independent";
import Actions from "./actions";
import { createFormDataForUpdate } from "src/providers/lib/coupon";
import Information from "./information";
import { format } from "date-fns";

export default function UpsertCoupon() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<ParentCategory[]>([]);
  const [updating, setUpdating] = useState(false);
  const [companyAddresses, setCompanyAddresses] = useState<CompanyAddress[]>([]);
  const [couponAddresses, setCouponAddresses] = useState<CouponAddress[]>([]);
  const [coupon, setCoupon] = useState<Coupon | null>(null);

  const getCouponWithAdditionalInfo = useCallback(async () => {
    if (id) {
      try {
        const [categories, coupon, addresses] = await Promise.all([
          Api.independent.getCategories(),
          Api.coupons.getSingle(id),
          Api.independent.getCouponAddresses(id)
        ]);
        if (categories.data) setCategories(categories.data);
        else enqueueSnackbar(categories.message, { variant: "error" });
        if (addresses.data) setCouponAddresses(addresses.data);
        else enqueueSnackbar(addresses.message, { variant: "error" });
        if (coupon.data) {
          const response = await Api.independent.getCompanyAddresses(coupon.data.company_id);
          if (response.data) setCompanyAddresses(response.data);
          else setCompanyAddresses([]);
          setCoupon(coupon.data);
        } else {
          enqueueSnackbar(coupon.message, { variant: "error" });
          navigate(-1);
        }
        setLoading(false);
      } catch (error) {
        throw new Error(error as string);
      }
    }
  }, []);

  const content = useMemo(
    () => ({
      content_am: coupon?.content.find(lng => lng.language === "am"),
      content_ru: coupon?.content.find(lng => lng.language === "ru"),
      content_en: coupon?.content.find(lng => lng.language === "en")
    }),
    [coupon]
  );

  const updateCoupon = useCallback(async (body: FormData, is_save_as_draft: boolean) => {
    if (id) {
      try {
        setUpdating(true);
        const response = await Api.coupons.update(id, body);

        if (response.data) {
          enqueueSnackbar(t("coupon_updated"), { variant: "success" });
          window.location.reload();
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
          setErrors(response.errors as FormikErrors<UpdateCoupon>);
        }
      } catch (error) {
        throw new Error(error as string);
      } finally {
        setUpdating(false);
      }
    }
  }, []);

  const formik = useFormik<UpdateCoupon>({
    initialValues: {
      thumbnail_alt: coupon?.thumbnail_alt ?? "",
      coupon_price: coupon?.coupon_price ?? "",
      page_title: coupon?.page_title ?? "",
      slug: coupon?.slug ?? "",
      sold_count_enabled: coupon?.sold_count_enabled ?? 1,
      addresses: couponAddresses.map(address => address.address_id),
      thumbnail: coupon?.thumbnail ?? "",
      categories: coupon?.categories.map(category => Number(category.id)) ?? [],
      type: coupon?.type ?? "coupon",
      price: coupon?.price ?? 0,
      discount: coupon?.discount ?? 0,
      description_am: content.content_am?.description ?? "",
      description_ru: content.content_ru?.description ?? "",
      description_en: content.content_en?.description ?? "",
      excerpt_am: content.content_am?.excerpt ?? "",
      excerpt_ru: content.content_ru?.excerpt ?? "",
      excerpt_en: content.content_en?.excerpt ?? "",
      meta_description_am: content.content_am?.meta_description ?? "",
      meta_description_ru: content.content_ru?.meta_description ?? "",
      meta_description_en: content.content_en?.meta_description ?? "",
      meta_keywords_am: content.content_am?.meta_keywords ?? "",
      meta_keywords_ru: content.content_ru?.meta_keywords ?? "",
      meta_keywords_en: content.content_en?.meta_keywords ?? "",
      terms_am: content.content_am?.terms ?? "",
      terms_ru: content.content_ru?.terms ?? "",
      terms_en: content.content_en?.terms ?? "",
      title_am: content.content_am?.title ?? "",
      title_ru: content.content_ru?.title ?? "",
      title_en: content.content_en?.title ?? "",
      discount_equal: coupon?.discount_equal ?? 0,
      is_draft: 0,
      prices:
        coupon?.prices.map(
          ({
            id,
            description_am,
            description_en,
            description_ru,
            title_am,
            title_en,
            title_ru,
            discount,
            price,
            discounted_price,
            coupon_price,
            available
          }) => ({
            id,
            description_am,
            description_en,
            description_ru,
            title_am,
            title_en,
            title_ru,
            discount,
            price,
            discounted_price,
            coupon_price,
            available
          })
        ) ?? [],
      expire_at: coupon?.expire_at ? format(new Date(coupon.expire_at), "yyyy-MM-dd") : format(new Date(), "yyyy-MM-dd")
    },
    onSubmit: values => {
      const formData = createFormDataForUpdate(values, true);
      updateCoupon(formData, Boolean(values.is_draft));
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    enableReinitialize: true
  });

  useEffect(() => {
    getCouponWithAdditionalInfo();
  }, [getCouponWithAdditionalInfo]);

  const { handleSubmit, setErrors } = formik;

  if (!loading && !coupon) return <Navigate to="/coupons" />;

  return (
    <CouponProvider value={{ categories, companyAddresses }}>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <Box className="flex-center">
            <Box sx={{ width: "90%", my: 4 }}>
              <WrapWithLoading loading={loading}>
                <Grid container sx={{ py: 2, px: 1 }} justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Typography variant="h5">{t("update_coupon")}</Typography>
                  </Grid>
                  <Grid item>
                    <Actions loading={updating} />
                  </Grid>
                </Grid>
                <Grid container rowGap={5}>
                  <Grid item xs={12}>
                    <ContentWrapper>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <ConstantFields />
                        </Grid>
                        <Grid item xs={12} className="flex-center">
                          <Divider sx={{ width: "90%" }} />
                        </Grid>
                        <Grid item xs={12}>
                          <Content />
                        </Grid>
                      </Grid>
                    </ContentWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <ContentWrapper>
                      <Photos
                        medias={
                          coupon?.images.map(media => ({
                            file: media.url,
                            type: media.type,
                            alt: media.alt,
                            order: media.order,
                            id: media.id
                          })) ?? []
                        }
                      />
                    </ContentWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <ContentWrapper>
                      <Prices />
                    </ContentWrapper>
                  </Grid>
                </Grid>
                <Box sx={{ py: 2, px: 1 }}>
                  <Actions loading={updating} />
                </Box>
              </WrapWithLoading>
            </Box>
          </Box>
        </form>
      </FormikProvider>
      <Information information={coupon?.information} />
    </CouponProvider>
  );
}
