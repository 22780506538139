import Api from "src/api";
import { useCallback, useEffect, useState } from "react";
// components
import { Box, Stack } from "@mui/material";
import { DashboardProvider } from "src/providers/context";
import { ApplicationInformationalCards } from "./cards";
import Warnings from "./warnings";
// source
import { Dashboard as DashboardType } from "src/api/dashboard";

export default function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [dashboard, setDashboard] = useState<DashboardType | null>(null);

  const getDashboardInformation = useCallback(async () => {
    try {
      const response = await Api.dashboard.getDashboard();
      if (response.data) {
        setLoading(false);
        setDashboard(response.data);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  useEffect(() => {
    getDashboardInformation();
  }, [getDashboardInformation]);

  return (
    <Box className="flex-center">
      <Stack mt={3} spacing={10} sx={{ width: "90%" }}>
        <Box>
          <Stack spacing={2}>
            <DashboardProvider value={{ data: dashboard, loading: loading }}>
              <Warnings />
              <ApplicationInformationalCards />
            </DashboardProvider>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
