export type UniversalImageType = string | File | Blob;

export function openUniversalImage(image: UniversalImageType) {
  if (typeof image === "string") return image;
  return URL.createObjectURL(image);
}

export function createFormDataWithImage<T extends { [key: string]: unknown }>(values: T, PUT?: boolean) {
  const formData = new FormData();
  Object.entries(values).forEach(([key, value]) => {
    if (value) {
      if (key === "data") formData.append(key, JSON.stringify(value));
      else
        formData.append(
          key,
          !["image", "icon", "thumbnail", "avatar", "mobile_image"].includes(key) ? String(value) : (value as Blob)
        );
    }
  });
  if (PUT) formData.append("_method", "PUT");
  return formData;
}

export function formatNumber(n: number | string | bigint) {
  return Intl.NumberFormat().format(Number(n));
}

export const LEFT_SIDE_SPACE_RGX = /^\s/g;

export function trimStringLeftSide(value: string) {
  return value.replace(/^\s+/g, "");
}

export * from "./sections";
export * from "./api";
