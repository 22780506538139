import { Stack, Typography } from "@mui/material";
import { memo, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  title?: string;
  additional?: string | number;
}

function FieldsWrapper({ title, additional, children }: PropsWithChildren<Props>) {
  const { t } = useTranslation();

  return (
    <Stack spacing={{ xs: 3 }} px={{ xs: 5 }} py={{ xs: 2 }}>
      <Typography variant="h5" mb={{ xs: 2 }}>
        {t(title ?? "", { additional })}
      </Typography>
      {children}
    </Stack>
  );
}

export default memo(FieldsWrapper);
