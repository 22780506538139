import { useCallback, useEffect, useState } from "react";
import { FormikErrors, FormikProvider, useFormik } from "formik";
// components
import { Stack } from "@mui/material";
import WrapForms from "../common/wrap-forms";
import UpsertForm from "../form";
import { Submit } from "src/components/common-components/control";
import { format } from "date-fns";
// source
import { PositionInRequestBody, UpdatePosition } from "src/types/api/positions";
import Api from "src/api";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

type Props = {
  closeAddModal: () => void;
  refetch: () => void;
  id: number;
  place: "coupon" | "company";
  companyId: number;
};

export default function EditPosition({ closeAddModal, place, id, companyId, refetch }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [position, setPosition] = useState<PositionInRequestBody | null>(null);
  const [loading, setLoading] = useState(false);

  const getPosition = useCallback(async () => {
    try {
      setLoading(true);
      const response = await Api[place === "coupon" ? "coupons" : "companies"].getSinglePosition(id);
      if (response.data) {
        setPosition(response.data);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, [companyId, id, place]);

  const updatePosition = useCallback(
    async (values: UpdatePosition) => {
      try {
        setLoading(true);
        const response = await Api[place === "coupon" ? "coupons" : "companies"].updatePosition(id, values);
        if (response.data) {
          enqueueSnackbar(t("success"), { variant: "success" });
          closeAddModal();
          refetch();
        } else {
          setErrors(response.errors as FormikErrors<UpdatePosition>);
          enqueueSnackbar(response.message, { variant: "error" });
        }
      } catch (error) {
        throw new Error(error as string);
      } finally {
        setLoading(false);
      }
    },
    [id, place]
  );

  const formik = useFormik<UpdatePosition>({
    initialValues: {
      type: position?.type ?? "",
      expire_at: position?.expire_at ? format(new Date(position.expire_at), "yyyy-MM-dd") : "",
      position: position?.position ?? ""
    },
    onSubmit: values => {
      updatePosition(values);
    },
    enableReinitialize: true
  });

  useEffect(() => {
    getPosition();
  }, [getPosition]);

  const { handleSubmit, setErrors } = formik;

  return (
    <WrapForms title="edit_position">
      <form onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Stack spacing={2}>
            <UpsertForm mode="edit" place={place} />
            <Submit title="update" loading={loading} />
          </Stack>
        </FormikProvider>
      </form>
    </WrapForms>
  );
}
