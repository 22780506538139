import { Box, Button } from "@mui/material";
import { ChangeEvent, memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onUpload(files: FileList): void;
  inputFileType?: "image" | "video";
  title?: string;
}

function UploadButton({ onUpload, inputFileType, title }: Props) {
  const { t } = useTranslation();
  const [images, setImages] = useState<FileList>();

  const handleChange = useCallback(({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    if (currentTarget.files === null) return;
    setImages(currentTarget.files);
  }, []);

  useEffect(() => {
    if (images) {
      onUpload(images);
    }
  }, [images]);

  return (
    <Box>
      <Button component="label">
        <input
          hidden
          type="file"
          accept={`${inputFileType === "video" ? "video" : "image"}/*`}
          multiple
          onChange={handleChange}
        />
        {t(title ?? "upload_images")}
      </Button>
    </Box>
  );
}

export default memo(UploadButton);
