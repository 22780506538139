import ApiSlice from "../slice";
import { generateQueryParamsForFilter, generateQueryParamsForPagination } from "src/providers/lib";
import { Coupon as CouponType, CouponMedia, PurchasedCouponsSub, Status } from "./types";
// slice
import { Pagination } from "src/types/api";
import { CouponsFilterProps } from "src/components/common-components/filter/coupons";
import { CouponPositionInRequestBody } from "src/types/api/coupons";
import { AddPosition, PositionInRequestBody, UpdatePosition } from "src/types/api/positions";

export default class Coupons extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin/coupons`;
  static defaultAuth = true;

  static getList = (params: { paginationParams?: Pagination; filterParams?: CouponsFilterProps | null }) =>
    this.request<CouponType[]>(
      `${generateQueryParamsForFilter<CouponsFilterProps>(params?.filterParams ?? null)}${generateQueryParamsForPagination(
        params.paginationParams,
        Boolean(params?.filterParams)
      )}`
    );
  static getSingle = (id: string) => this.request<CouponType>(`/${id}`);

  static getPurchased = (id: number, params?: Pagination) =>
    this.request<PurchasedCouponsSub[]>(`/${id}/purchased${generateQueryParamsForPagination(params)}`);
  static update = (id: string, body: FormData) => this.request<CouponType>(`/${id}`, "POST", body);
  static delete = (id: number) => this.request(`/${id}`, "DELETE");
  static updateStatus = (id: number, status: Status) => this.request(`/${id}/status`, "POST", { status });

  static makeTop = (id: number, top_index: number) => this.request<CouponType>(`/${id}/top`, "POST", { top_index });
  static makeHot = (id: number) => this.request<CouponType>(`/${id}/hot`, "POST", { top_index: id });
  static cancelTop = (id: number) => this.request(`/${id}/top`, "DELETE");
  static cancelHot = (id: number) => this.request(`/${id}/hot`, "DELETE");

  static getStatuses = () => this.request<Status[]>(`/statuses`);

  static getPosition = (id: number, paginationParams?: Pagination) =>
    this.request<CouponPositionInRequestBody[]>(
      `/${id}/position${generateQueryParamsForPagination({ ...paginationParams, perPage: 10 }, false)}`
    );
  static getSinglePosition = (positionId: number) => this.request<PositionInRequestBody>(`/position/${positionId}`);
  static createPosition = (id: number, values: AddPosition) => this.request(`/${id}/position`, "POST", values);
  static updatePosition = (id: number, values: UpdatePosition) =>
    this.request(`/position/${id}`, "POST", { ...values, _method: "PUT" });
  static deletePosition = (id: number) => this.request(`/position/${id}`, "DELETE");

  static uploadMedia = (id: string, body: FormData) => this.request<CouponMedia[]>(`/${id}/media`, "POST", body);
  static updateMediaAlt = (couponId: string, id: number, alt: string) => this.request(`/media/alt/${id}`, "POST", { alt });
  static updateMediaOrder = (couponId: string, id: number, order: number | string) =>
    this.request(`/media/order/${id}`, "POST", { order });
  static deleteMedia = (mediaId: number) => this.request(`/media/${mediaId}`, "DELETE");

  static deletePrice = (priceId: number) => this.request(`/price/${priceId}`, "DELETE");
}

export * from "./types";
