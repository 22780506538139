import { useState } from "react";
// components
import { Avatar, Box, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { CompanyQuestion } from "src/api/comments";
import View from "src/components/common-components/review/view";
import { BaseModal } from "src/components/modal";
import { Actions } from "../../../components/common-components/actions";
import { DependModeRedirector } from "src/components/common-components/wrappers";

interface Props {
  questions: CompanyQuestion[];
  onDelete(id: number): void;
}

export default function TableBodyC({ questions, onDelete }: Props) {
  const [openView, setOpenView] = useState<{
    isOpen: boolean;
    currentMessage?: string;
    currentAnswer?: string;
  }>({
    isOpen: false
  });

  const handleOpenModalForView = (currentMessage: string, currentAnswer?: string) =>
    setOpenView({ isOpen: true, currentMessage, currentAnswer });

  const handleCloseModalForView = () => setOpenView({ isOpen: false });

  return (
    <TableBody>
      {questions.map(question => (
        <TableRow key={question.id}>
          <TableCell>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Avatar src={question.user.avatar ?? ""} />
              <Typography sx={{ ml: 1 }}>{question.user.first_name}</Typography>
            </Box>
          </TableCell>
          <TableCell>
            <DependModeRedirector to={`company/${question.company.id}/main`} target="_blank">
              {question.company.name}
            </DependModeRedirector>
          </TableCell>
          <TableCell>
            <Typography sx={{ ml: 1 }}>{question.message ?? "-"}</Typography>
          </TableCell>
          <TableCell>
            <Actions
              actions={["remove", "view"]}
              onDelete={() => onDelete(question.id)}
              onView={() => handleOpenModalForView(question.message, question.answer)}
            />
          </TableCell>
        </TableRow>
      ))}
      <BaseModal open={openView.isOpen} onClose={handleCloseModalForView}>
        <View message={openView.currentMessage} answer={openView.currentAnswer} place="company" />
      </BaseModal>
    </TableBody>
  );
}
