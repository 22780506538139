// components
import { Box, Drawer, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  information?: string | null;
};

export default function Information({ information }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 200,
        right: 0,
        transition: "0.7s"
      }}
    >
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box
          sx={{ cursor: "default", display: "flex", transform: " translate(35px, 34.7px) rotate(-90deg)", overflow: "hidden" }}
        >
          <Typography
            onClick={handleOpen}
            variant="body1"
            component="span"
            sx={{
              letterSpacing: 1,
              color: "#FFF",
              p: 1.5,
              bgcolor: "primary.main"
            }}
          >
            Information
          </Typography>
        </Box>
        {information && (
          <Drawer anchor="right" open={open} onClose={handleClose}>
            <Box sx={{ px: 5, py: 3, width: "33vw", maxHeight: "100vh", overflow: "auto" }}>
              <Stack spacing={3}>
                <Typography sx={{ textAlign: "center" }} variant="h4">
                  {t("coupon_information")}
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: information }} />
              </Stack>
            </Box>
          </Drawer>
        )}
      </Box>
    </Box>
  );
}
