import { memo } from "react";
import { useTranslation } from "react-i18next";
// components
import { TableBody as MuiTableBody, TableCell, TableRow } from "@mui/material";
import { Actions, DateCell, EmailCell, UserCell } from "src/components/common-components";
// types
import { Admin } from "src/types/api";

interface Props {
  users: Admin[];
  onEdit(id: number): void;
  onDelete(id: number): void;
}

function TableBody({ users, onDelete, onEdit }: Props) {
  const { t } = useTranslation();

  return (
    <MuiTableBody>
      {users.map(user => (
        <TableRow key={user.id}>
          <UserCell label={user.full_name} />
          <EmailCell email={user.email} />
          <TableCell>{user.phone ?? "-"}</TableCell>
          <TableCell>{t(user.position ?? "-")}</TableCell>
          <DateCell date={user.created_at} />
          <TableCell>
            <Actions actions={["edit", "remove"]} onEdit={() => onEdit(user.id)} onDelete={() => onDelete(user.id)} />
          </TableCell>
        </TableRow>
      ))}
    </MuiTableBody>
  );
}

export default memo(TableBody);
