import Api from "src/api";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
// components
import { Box, Table } from "@mui/material";
import {
  TableHead,
  TableWrapper,
  WrapWithFilter,
  WrapWithLoading,
  WrapWithNoResult,
  WrapWithPagination
} from "src/components/common-components";
import TableBody from "./table";
//source
import { tableHeadCellNames } from "./source";
import { Meta, Pagination } from "src/types/api";
import { Coupon, Status } from "src/api/coupons";
import { CouponsContextProvider } from "src/providers/context";
import { CouponsFilterProps } from "src/components/common-components/filter/coupons";

function Coupons() {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [filtering, setFiltering] = useState(false);
  const [couponsList, setCouponsList] = useState<Coupon[]>([]);
  const [statusList, setStatusList] = useState<Status[]>([]);
  const [couponsMeta, setCouponsMeta] = useState<Meta | null>(null);
  const [filterValues, setFilterValues] = useState<CouponsFilterProps | null>(null);

  const getCoupons = useCallback(
    async (paginationParams: Pagination = { perPage: 20 }, filterParams?: CouponsFilterProps | null) => {
      try {
        setLoading(true);
        const [couponsList, statuses] = await Promise.all([
          Api.coupons.getList({
            paginationParams,
            filterParams: filterParams
          }),
          Api.coupons.getStatuses()
        ]);
        setLoading(false);
        if (couponsList?.data) setCouponsList(couponsList.data);
        else enqueueSnackbar(couponsList.message, { variant: "error" });
        if (statuses.data) setStatusList(statuses.data);
        else enqueueSnackbar(statuses.message, { variant: "error" });
        if (couponsList?.meta) setCouponsMeta(couponsList.meta);
      } catch (error) {
        throw new Error(error as string);
      }
    },
    []
  );

  const filter = useCallback(async (filterParams: CouponsFilterProps) => {
    try {
      setFiltering(true);
      const response = await Api.coupons.getList({ filterParams, paginationParams: { perPage: 20 } });
      if (response) {
        if (response.data) setCouponsList(response.data);
        if (response.meta) setCouponsMeta(response.meta);
        if (Object.keys(filterParams).length) setFilterValues(filterParams);
      }
      setFiltering(false);
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  useEffect(() => {
    getCoupons();
  }, [getCoupons]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
      <Box sx={{ width: { xs: "90%" } }}>
        <CouponsContextProvider
          value={{
            refetchTable: () => {
              if (filterValues) filter(filterValues);
              else getCoupons(couponsMeta?.pagination);
            },
            statusList
          }}
        >
          <WrapWithFilter filter_topic="coupons" onFilter={filter} filter_height={100} filtering={filtering}>
            <TableWrapper>
              <WrapWithPagination
                pagination_meta={couponsMeta?.pagination}
                onPageChange={page => getCoupons({ page, perPage: 20 }, filterValues)}
              >
                <WrapWithLoading loading={loading}>
                  <WrapWithNoResult length={couponsList.length}>
                    <Table>
                      <TableHead cellNames={tableHeadCellNames} />
                      <TableBody coupons={couponsList} />
                    </Table>
                  </WrapWithNoResult>
                </WrapWithLoading>
              </WrapWithPagination>
            </TableWrapper>
          </WrapWithFilter>
        </CouponsContextProvider>
      </Box>
    </Box>
  );
}

export default Coupons;
