//components
import { Box, Divider, Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
// source
import { Subscriber } from "src/api/subscribers";

interface Props {
  subscriber: Subscriber;
}

interface WithDescriptionProps {
  title: string;
  translate?: boolean;
}

const FieldWithDescription = ({ title, translate = false, children }: PropsWithChildren<WithDescriptionProps>) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={1}>
      <Typography sx={{ fontSize: { xs: 16, xl: 18 }, fontWeight: 600 }}>{translate ? t(title) : title}</Typography>
      <Box sx={{ pl: 2 }}>{children}</Box>
    </Stack>
  );
};

export default function MoreInfo({ subscriber }: Props) {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: "40vw" }}>
      <Stack px={5} py={3} spacing={2}>
        <FieldWithDescription title="EasyPay">
          <Typography>
            {t("account")} : {subscriber.easypay_account}
          </Typography>
        </FieldWithDescription>
        <Divider />
        <FieldWithDescription title="Telcell">
          <Typography>
            {t("account")} : {subscriber.telcell_account}
          </Typography>
        </FieldWithDescription>
        <Divider />
        <FieldWithDescription title="offers" translate>
          <Typography>
            {t("amount")} : {subscriber.coupons_amount}
          </Typography>
        </FieldWithDescription>
        <Divider />
        <FieldWithDescription title="personal_info" translate>
          <Typography>
            {t("gender")} : {t(subscriber.gender)}
          </Typography>
        </FieldWithDescription>
      </Stack>
    </Box>
  );
}
