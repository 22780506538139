import { CouponPositionType } from "src/types/api/coupons";
import { CompaniesPositionType } from "src/types/api/companies";

export const coupon_position_select_options: { label: CouponPositionType; value: CouponPositionType }[] = [
  { label: "home", value: "home" },
  { label: "home_section", value: "home_section" },
  { label: "hot", value: "hot" },
  { label: "premium", value: "premium" },
  { label: "top", value: "top" },
  { label: "premium_category", value: "premium_category" },
  { label: "top_category", value: "top_category" }
];
export const company_position_select_options: { label: CompaniesPositionType; value: CompaniesPositionType }[] = [
  { label: "home", value: "home" },
  { label: "premium", value: "premium" },
  { label: "top", value: "top" },
  { label: "premium_business_type", value: "premium_business_type" },
  { label: "top_business_type", value: "top_business_type" }
];
