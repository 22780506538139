import Api from "src/api";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
// components
import { Box, Table } from "@mui/material";
import {
  TableHead,
  TableWrapper,
  WrapWithAddButton,
  WrapWithFilter,
  WrapWithLoading,
  WrapWithNoResult,
  WrapWithPagination
} from "src/components/common-components";
import { TableBody } from "./table";
import { AddBalance, AddSubscriber, Confirm } from "./actions";
import { BaseModal, DeleteModal } from "src/components/modal";
//source
import { withModals, withModalsProps } from "src/components/hoc";
import { Subscriber } from "src/api/subscribers";
import { tableHeadCellNames } from "./source";
import { ContactTypes, Meta, Pagination } from "src/types/api";
import { SubscribersFilterProps } from "src/components/common-components/filter/subscribers";
import { SubscriberActionsContextProvider } from "src/providers/context";

function Subscribers({
  mainModal,
  openMainModalForUpdating,
  openMainModalForAddBalance,
  openMainModalForConfirm,
  openMainModalForAdding,
  closeMainModal,
  deleteModal,
  openDeleteModal,
  closeDeleteModal
}: withModalsProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [filtering, setFiltering] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [subscribers, setSubscribers] = useState<Subscriber[]>([]);
  const [subscribersMeta, setSubscribersMeta] = useState<Meta | null>(null);
  const [filterValues, setFilterValues] = useState<SubscribersFilterProps | null>(null);

  const [phoneFromFilter, setPhoneFromFilter] = useState("");

  const getSubscribersList = useCallback(async (paginationParams?: Pagination, filter_body?: SubscribersFilterProps | null) => {
    try {
      const response = await Api.subscribers.getSubscribersList({ filter_body, paginationParams });
      setLoading(false);
      if (response.data) setSubscribers(response.data);
      if (response.meta) setSubscribersMeta(response.meta);
      else enqueueSnackbar(response.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const filter = useCallback(async (filter_body: SubscribersFilterProps) => {
    try {
      setFiltering(true);
      const response = await Api.subscribers.getSubscribersList({ filter_body, paginationParams: { perPage: 20 } });
      setFiltering(false);
      if (response) {
        if (response.data) {
          setSubscribers(response.data);
          if (!response.data.length && Boolean(filter_body.phone)) {
            setPhoneFromFilter(filter_body.phone);
            openMainModalForAdding();
          }
        }
        if (response.meta) setSubscribersMeta(response.meta);
        if (Object.keys(filter_body).length) setFilterValues(filter_body);
        else setFilterValues(null);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const handleDelete = useCallback(async () => {
    if (deleteModal?.id) {
      try {
        setDeleting(true);
        const response = await Api.subscribers.removeSubscriber(deleteModal.id);
        setDeleting(false);
        if (response.status === "success") {
          enqueueSnackbar(t("subscriber_deleted", { number: deleteModal.id }), { variant: "success" });
        } else enqueueSnackbar(response.message, { variant: "error" });
      } catch (error) {
        throw new Error(error as string);
      } finally {
        closeDeleteModal();
        refetchTable();
      }
    }
  }, [deleteModal]);

  const refetchTable = useCallback(() => {
    if (filterValues) filter(filterValues);
    else getSubscribersList();
  }, [filterValues, filter, getSubscribersList]);

  useEffect(() => {
    getSubscribersList({ perPage: 20 });
  }, [getSubscribersList]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 5 }}>
      <Box sx={{ width: { xs: "90%" } }}>
        <WrapWithFilter filter_topic="subscribers" onFilter={filter} filtering={filtering} filter_height={80}>
          <WrapWithAddButton handleAdd={openMainModalForAdding} width="100%">
            <TableWrapper>
              <WrapWithLoading loading={loading}>
                <WrapWithNoResult length={subscribers.length}>
                  <WrapWithPagination
                    pagination_meta={subscribersMeta?.pagination}
                    onPageChange={page => getSubscribersList({ perPage: 20, page: page }, filterValues)}
                  >
                    <Table>
                      <TableHead cellNames={tableHeadCellNames} />
                      <SubscriberActionsContextProvider
                        value={{
                          onEdit: openMainModalForUpdating,
                          onDelete: openDeleteModal,
                          onAddBalance: openMainModalForAddBalance,
                          onConfirm: openMainModalForConfirm,
                          refetchTable: refetchTable
                        }}
                      >
                        <TableBody subscribers={subscribers} />
                      </SubscriberActionsContextProvider>
                    </Table>
                  </WrapWithPagination>
                </WrapWithNoResult>
              </WrapWithLoading>
            </TableWrapper>
          </WrapWithAddButton>
        </WrapWithFilter>
      </Box>
      <BaseModal open={mainModal?.open} onClose={closeMainModal} disableBackdropClick>
        {(() => {
          switch (mainModal?.for) {
            case "add":
              return <AddSubscriber phoneFromFilter={phoneFromFilter} refetch={refetchTable} closeModal={closeMainModal} />;
            case "add_balance":
              return mainModal?.id && <AddBalance id={mainModal.id} refetch={refetchTable} closeModal={closeMainModal} />;
            case "confirm":
              return (
                mainModal?.id && (
                  <Confirm
                    id={mainModal.id}
                    refetch={refetchTable}
                    closeModal={closeMainModal}
                    contacts={mainModal?.additionalInfo as ContactTypes}
                  />
                )
              );
          }
        })()}
      </BaseModal>
      <DeleteModal open={deleteModal?.open} onClose={closeDeleteModal} onDelete={handleDelete} loading={deleting} />
    </Box>
  );
}

export default withModals(Subscribers);
