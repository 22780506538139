import { useNavigate, useParams } from "react-router-dom";
import { Box, IconButton, Stack, TextField, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Submit } from "src/components/common-components";
// icon
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { FormikErrors, FormikProvider, useFormik, useFormikContext } from "formik";
import Api from "src/api";
import { ChangePasswordReqBodyTypes } from "src/api/auth";
import { useSnackbar } from "notistack";

type FormikTypes = Omit<ChangePasswordReqBodyTypes, "token">;
interface PasswordFieldProps {
  field_key: keyof FormikTypes;
}

const PasswordField = memo(function PasswordField({ field_key }: PasswordFieldProps) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const { getFieldProps, errors } = useFormikContext<FormikTypes>();

  return (
    <TextField
      fullWidth
      label={t(field_key)}
      type={showPassword ? "text" : "password"}
      {...getFieldProps(field_key)}
      error={Boolean(errors[field_key])}
      helperText={errors[field_key]}
      InputProps={{
        endAdornment: (
          <IconButton onClick={() => setShowPassword(state => !state)}>
            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        )
      }}
    />
  );
});

export default function ResetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { token } = useParams();

  const handlePasswordConfirm = useCallback(async (body: ChangePasswordReqBodyTypes) => {
    try {
      setLoading(true);
      const response = await Api.auth.changePassword(body);
      if (response.status === "success") {
        navigate("/");
        enqueueSnackbar(t("password_changed"), { variant: "success" });
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        if (response.errors) setErrors(response.errors as FormikErrors<FormikTypes>);
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, []);

  const formik = useFormik<FormikTypes>({
    initialValues: {
      password: "",
      password_confirmation: ""
    },
    onSubmit(values) {
      if (token) {
        handlePasswordConfirm({ token, ...values });
      }
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
    }
  }, []);

  const { handleSubmit, setErrors } = formik;

  return (
    <Box sx={{ minWidth: "100vh", overflow: "hidden" }}>
      <form onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Box sx={{ width: "100vw", height: "100vh" }}>
            <Box
              sx={{
                width: { xs: "80vw", sm: "60vw", md: "50vw", lg: "35vw" },
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                p: 2
              }}
            >
              <Stack spacing={5} alignItems="center">
                <Stack alignItems="center">
                  <VerifiedUserIcon sx={{ fontSize: 50 }} />
                  <Typography sx={{ fontSize: "20px" }}>{t("confirm_account")}</Typography>
                </Stack>
                <Stack spacing={3} sx={{ width: "100%" }}>
                  <PasswordField field_key="password" />
                  <PasswordField field_key="password_confirmation" />
                  <Submit title="confirm" loading={loading} />
                </Stack>
              </Stack>
            </Box>
          </Box>
        </FormikProvider>
      </form>
    </Box>
  );
}
