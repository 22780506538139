import { PropsWithChildren } from "react";
// components
import { Box, Stack } from "@mui/material";
import { Filter } from "./filter";
// types
import { FilterTopic } from "./filter/Filter";
import { FilterContextProvider } from "src/providers/context";

interface Props {
  filter_topic: FilterTopic;
  filter_height: number;
  onFilter(params: object): void;
  filtering: boolean;
}

export default function WrapWithFilter({ filter_topic, onFilter, filtering, filter_height, children }: PropsWithChildren<Props>) {
  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={2}>
        <Box sx={{ height: filter_height, transition: "0.5s", overflow: "hidden" }}>
          <FilterContextProvider value={{ onFilter, filtering }}>
            <Filter topic={filter_topic} />
          </FilterContextProvider>
        </Box>
        <Box>{children}</Box>
      </Stack>
    </Box>
  );
}
