import { createContext } from "react";

interface ModalCloseContextProps {
  closeModal(): void;
}

export const ModalCloseContext = createContext<ModalCloseContextProps>({
  closeModal: () => null
});

export const { Provider: ModalCloseProvider } = ModalCloseContext;
