import ApiSlice from "src/api/slice";
// types
import { BusinessType, CreateBusinessType } from "./types";

export default class BusinessTypes extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin/business_types`;
  static defaultAuth = true;

  static getList = () => this.request<BusinessType[]>();
  static getSingle = (id: number) => this.request<BusinessType>(`/${id}`);
  static update = (id: number, body: CreateBusinessType) =>
    this.request<BusinessType>(`/${id}`, "POST", { ...body, _method: "PUT" });
  static create = (body: CreateBusinessType) => this.request<BusinessType>(``, "POST", body);
  static delete = (id: number) => this.request(`/${id}`, "DELETE");
}
