import { memo } from "react";
// components
import { TableBody as MuiTableBody, TableCell, TableRow } from "@mui/material";
import { Actions, ImageCell } from "src/components/common-components";
// types
import { Blog } from "src/api/blog";

interface Props {
  blogs: Blog[];
  onEdit(id: number): void;
  onDelete(id: number): void;
}

function TableBody({ blogs, onDelete, onEdit }: Props) {
  return (
    <MuiTableBody>
      {blogs.map(blog => (
        <TableRow key={blog.id}>
          <TableCell>{blog.title ?? "-"}</TableCell>
          <ImageCell image={blog.thumbnail} alt="blog_image" />
          <TableCell>{blog.content_am ?? "-"}</TableCell>
          <TableCell>{blog.views ?? "-"}</TableCell>
          {/* handle images cell */}
          <TableCell>
            <Actions actions={["edit", "remove"]} onEdit={() => onEdit(blog.id)} onDelete={() => onDelete(blog.id)} />
          </TableCell>
        </TableRow>
      ))}
    </MuiTableBody>
  );
}

export default memo(TableBody);
