import ApiSlice from "../slice";
// types
import { Pagination } from "src/types/api";
import { ContactForm as ContactFormType } from "./types";
// source
import { generateQueryParamsForPagination } from "src/providers/lib";

export default class ContactForm extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin/contact-form`;
  static defaultAuth = true;

  static getList = (paginationParams?: Pagination) =>
    this.request<ContactFormType[]>(generateQueryParamsForPagination(paginationParams));
  static getSingle = (id: number) => this.request<ContactFormType>(`/${id}`);
  static delete = (id: number) => this.request(`/${id}`, "DELETE");
}

export * from "./types";
