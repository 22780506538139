import Api from "src/api";
// hooks
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
// components
import { FieldsWrapper, Submit, WrapWithLoading } from "src/components/common-components";
import UpsertForm from "./upsert-form";
import { UpdateModalProps } from "src/types/components";
// source
import { FormikErrors, FormikProvider, useFormik } from "formik";
import { CreateFAQ, FAQTypes } from "src/api/settings";

export default function Update({ id, closeModal, refetch }: UpdateModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [faq, setFaq] = useState<FAQTypes | null>(null);

  const getFaq = useCallback(async () => {
    try {
      const response = await Api.settings.faq.getSingle(id);
      if (response.data) {
        setFaq(response.data);
        setLoading(false);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        closeModal();
        refetch();
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, [id]);

  useEffect(() => {
    getFaq();
  }, [getFaq]);

  const createFaq = useCallback(
    async (values: CreateFAQ) => {
      try {
        setUpdating(true);
        const response = await Api.settings.faq.update(id, values);
        if (response.data) {
          closeModal();
          refetch();
          enqueueSnackbar(t("faq_updated", { id }), { variant: "success" });
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
          if (response.errors) setErrors(response.errors as FormikErrors<CreateFAQ>);
        }
      } catch (error) {
        throw new Error(error as string);
      } finally {
        setUpdating(false);
      }
    },
    [id]
  );

  const formik = useFormik<CreateFAQ>({
    initialValues: {
      title_am: faq?.title_am ?? "",
      title_ru: faq?.title_ru ?? "",
      title_en: faq?.title_en ?? "",
      content_am: faq?.content_am ?? "",
      content_en: faq?.content_en ?? "",
      content_ru: faq?.content_ru ?? ""
    },
    onSubmit(values) {
      createFaq(values);
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    enableReinitialize: true
  });

  const { handleSubmit, setErrors } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <FieldsWrapper>
          <WrapWithLoading loading={loading}>
            <UpsertForm />
            <Submit title="update" loading={updating} />
          </WrapWithLoading>
        </FieldsWrapper>
      </FormikProvider>
    </form>
  );
}
