import { Box, Grid } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { memo, useCallback, useContext, useMemo, useState } from "react";
import Api from "src/api";
import { Status } from "src/api/coupons";
// components
import { FieldsWrapper, Select, Submit } from "src/components/common-components";
import { CouponsContext } from "src/providers/context";

interface Props {
  id: number;
  currentStatus: Status;
  closeModal(): void;
}

function ChangeStatus({ id, currentStatus, closeModal }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { statusList, refetchTable } = useContext(CouponsContext);
  const [updating, setUpdating] = useState(false);

  const updateStatus = useCallback(async (status: Status) => {
    try {
      setUpdating(true);
      const response = await Api.coupons.updateStatus(id, status);
      if (response.data) {
        closeModal();
        refetchTable({ perPage: 20 });
      } else enqueueSnackbar(response.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setUpdating(false);
    }
  }, []);

  const formik = useFormik<{ status: Status | "" }>({
    initialValues: {
      status: currentStatus ?? ""
    },
    onSubmit: value => {
      if (value.status) updateStatus(value.status);
    }
  });

  const statusesForSelect = useMemo(() => statusList.map(status => ({ label: status, value: status })), [statusList]);

  const { handleSubmit } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <FieldsWrapper title="change_status">
          <Box>
            <Grid container px={2} spacing={2}>
              <Grid item xs={12}>
                <Select field_key="status" options={statusesForSelect} />
              </Grid>
              <Grid item xs={12}>
                <Submit title="change" loading={updating} />
              </Grid>
            </Grid>
          </Box>
        </FieldsWrapper>
      </FormikProvider>
    </form>
  );
}

export default memo(ChangeStatus);
