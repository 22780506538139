import Auth from "./auth";
import Filter from "./filter";
import Plans from "./plans";
import ApiSlice from "./slice";
import Transactions from "./transactions";
import Followers from "./followers";
import Users from "./admin";
import Comments from "./comments";
import Subscribers from "./subscribers";
import Companies from "./companies";
import Settings from "./settings";
import Independent from "./independent";
import ContactForm from "./contact-form";
import Dashboard from "./dashboard";
import Statistics from "./statistics";
import Coupons from "./coupons";
import UserCoupons from "./user-coupons";
import Blog from "./blog";
import Finances from "./finances";
import User from "./user";

export default class Api extends ApiSlice {
  static auth = Auth;
  static plans = Plans;
  static transactions = Transactions;
  static filter = Filter;
  static users = Users;
  static followers = Followers;
  static comments = Comments;
  static subscribers = Subscribers;
  static companies = Companies;
  static userCoupons = UserCoupons;
  static settings = Settings;
  static independent = Independent;
  static contactForm = ContactForm;
  static dashboard = Dashboard;
  static statistics = Statistics;
  static coupons = Coupons;
  static blog = Blog;
  static finances = Finances;
  static user = User;
}
