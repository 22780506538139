import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFormikContext } from "formik";
// components
import { Grid } from "@mui/material";
import { Submit } from "src/components/common-components";
import { LoadingButton } from "@mui/lab";
// source
import { UpdateCoupon } from "../source";

function Actions({ loading }: { loading: boolean }) {
  const { setFieldValue } = useFormikContext<UpdateCoupon>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid container columnSpacing={2} justifyContent="flex-end">
      <Grid item>
        <LoadingButton loading={loading} variant="contained" onClick={() => navigate(-1)}>
          {t("cancel")}
        </LoadingButton>
      </Grid>
      <Grid item>
        <Submit onClick={() => setFieldValue("is_draft", 1)} title="save_as_draft" loading={loading} variant="contained" />
      </Grid>
      <Grid item>
        <Submit onClick={() => setFieldValue("is_draft", 0)} title="save" loading={loading} variant="contained" />
      </Grid>
    </Grid>
  );
}

export default memo(Actions);
