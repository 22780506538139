import { memo } from "react";
import { useFormikContext } from "formik";
// components
import { Grid, Stack } from "@mui/material";
import UploadBtn from "../common/upload-btn";
import { AddMedia } from "..";
import VideoBox from "./video-box";

function UploadImages() {
  const { values, setFieldValue } = useFormikContext<AddMedia>();

  const handleUploadImage = (fileList: FileList) => {
    setFieldValue("videos_preview", [...values.videos_preview, ...Array.from(fileList)]);
  };

  return (
    <Stack alignItems="flex-end" spacing={2}>
      <UploadBtn onUpload={handleUploadImage} title="upload_video" inputFileType="video" />
      <div style={{ width: "100%" }}>
        <Grid container spacing={2} py={2}>
          {values.videos_preview.map((media, index) => (
            <Grid item xs={6} lg={3} xl={2} key={`video-preview-${index}`}>
              <VideoBox src={media} index={index} />
            </Grid>
          ))}
        </Grid>
      </div>
    </Stack>
  );
}

export default memo(UploadImages);
