import Api from "src/api";
import { memo, useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
// components
import { Box, Paper, Table } from "@mui/material";
import { TableHead, WrapWithLoading, WrapWithNoResult, WrapWithPagination } from "src/components/common-components";
import { couponsTableHeadCellNames } from "./source";
// components
import { TableBody } from "./table";
import { PurchasedCouponsSub } from "src/api/coupons";
import { Meta, Pagination } from "src/types/api";

interface Props {
  id: number;
  onClose(): void;
}

function Coupon({ id, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState<PurchasedCouponsSub[]>([]);
  const [meta, setMeta] = useState<Meta | null>(null);

  const getUsersThosePurchaseCoupon = useCallback(
    async (paginationParams?: Pagination) => {
      try {
        const response = await Api.coupons.getPurchased(id, { ...paginationParams, perPage: 10 });
        if (response.data) {
          setCoupons(response.data);
          if (response.meta) setMeta(response.meta);
        } else {
          onClose();
          enqueueSnackbar(response.message, { variant: "error" });
        }
        setLoading(false);
      } catch (error) {
        throw new Error(error as string);
      }
    },
    [id]
  );

  useEffect(() => {
    getUsersThosePurchaseCoupon({ page: 1 });
  }, [getUsersThosePurchaseCoupon]);

  return (
    <Box sx={{ px: 15, py: 2 }}>
      <Paper elevation={12} sx={{ borderRadius: "10px", overflow: "hidden" }}>
        <WrapWithPagination pagination_meta={meta?.pagination} onPageChange={page => getUsersThosePurchaseCoupon({ page })}>
          <WrapWithLoading loading={loading}>
            <WrapWithNoResult length={coupons.length}>
              <Table>
                <TableHead
                  tableCellClassName="subTableCells"
                  cellNames={couponsTableHeadCellNames}
                  tableCellSx={{ bgcolor: "#5ba3c9" }}
                />
                <TableBody coupons={coupons} />
              </Table>
            </WrapWithNoResult>
          </WrapWithLoading>
        </WrapWithPagination>
      </Paper>
    </Box>
  );
}

export default memo(Coupon);
