import * as React from "react";
// components
import { styled, Box, CssBaseline, Paper } from "@mui/material";
import { Bar } from "../appBar";
import Header from "./header";

const drawerWidth = 260;

const DrawerHeader = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  backgroundColor: "#FFF",
  boxShadow: "5px 0 5px 0",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

export default function MainLayout({ children }: { children: React.ReactNode }) {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Paper
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          borderRadius: "0px",
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box"
          }
        }}
      >
        <Bar />
      </Paper>
      <Box sx={{ width: "100%", minHeight: "100vh" }}>
        <DrawerHeader>
          <Header />
        </DrawerHeader>
        {children}
      </Box>
    </Box>
  );
}
