import ApiSlice from "src/api/slice";
import { Banner, BannerPosition } from "./types";

export default class Banners extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin/banners`;
  static defaultAuth = true;

  static getList = (type?: string) => this.request<Banner[]>(type ? `?filter[type]=${type}` : "");
  static getPositionTypes = () => this.request<BannerPosition[]>("/types");
  static getSingle = (id: number) => this.request<Banner>(`/${id}`);
  static update = (id: number, body: FormData) => this.request<Banner>(`/${id}`, "POST", body);
  static create = (body: FormData) => this.request<Banner>(``, "POST", body);
  static delete = (id: number) => this.request(`/${id}`, "DELETE");
}
