// components
import { TableBody, TableCell, TableRow } from "@mui/material";
import { Actions, BalanceCell } from "src/components/common-components";
// source
import { CouponPrice } from "src/api/coupons";
// icons

interface Props {
  prices: CouponPrice[];
  onBuy: (id: number) => void;
  loading: boolean;
}

export default function TableBodyC({ prices, onBuy, loading }: Props) {
  return (
    <TableBody>
      {prices.map(price => (
        <TableRow key={`subscriber_buy_coupon_${price.id}`}>
          <TableCell>{price.title}</TableCell>
          <BalanceCell label={price.coupon_price} />
          <BalanceCell label={price.price} />
          <TableCell>{price.discount}</TableCell>
          <TableCell>{price.discounted_price}</TableCell>
          <TableCell>
            <Actions
              actions={["buy"]}
              onBuy={() => price.id !== undefined && price.id !== null && onBuy(price.id)}
              loading={loading}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}
