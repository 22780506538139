import Api from "src/api";
// hooks
import { memo, useCallback, useEffect, useState } from "react";
// components
import { TableHead, WrapWithAddButton, WrapWithLoading, WrapWithNoResult } from "src/components/common-components";
import { Box, Paper, Table } from "@mui/material";
import { BaseModal, DeleteModal } from "src/components/modal";
import { TableBody } from "./table";
import { AddContactPerson, UpdateContactPerson } from "./table/upsert";
// source
import { tableHeadCellNames } from "./source";
import { ContactPerson } from "src/api/companies";
import { withModals, withModalsProps } from "src/components/hoc";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

interface Props extends withModalsProps {
  id: number;
}

function ContactPersons({
  id,
  deleteModal,
  mainModal,
  openMainModalForUpdating,
  openMainModalForAdding,
  openDeleteModal,
  closeDeleteModal,
  closeMainModal
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [contactPersons, setContactPersons] = useState<ContactPerson[]>([]);

  const getPartnerContactPersons = useCallback(async () => {
    try {
      const response = await Api.companies.getContactPersons(id);
      if (response.data) {
        setLoading(false);
        setContactPersons(response.data);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, [id]);

  useEffect(() => {
    getPartnerContactPersons();
  }, [getPartnerContactPersons]);

  const handleDelete = useCallback(async () => {
    if (deleteModal?.id) {
      try {
        setDeleting(true);
        const response = await Api.companies.deleteContactPerson(deleteModal.id);
        setDeleting(false);
        if (response.status === "success") {
          enqueueSnackbar(t("subscriber_deleted", { number: deleteModal.id }), { variant: "success" });
        } else enqueueSnackbar(response.message, { variant: "error" });
      } catch (error) {
        throw new Error(error as string);
      } finally {
        closeDeleteModal();
        await getPartnerContactPersons();
      }
    }
  }, [deleteModal]);

  return (
    <Box sx={{ py: 2 }}>
      <WrapWithAddButton sx={{ width: "85%" }} title="contact_persons" handleAdd={openMainModalForAdding} isSubTable>
        <Paper elevation={12} sx={{ borderRadius: "10px", overflow: "hidden" }}>
          <WrapWithLoading loading={loading}>
            <WrapWithNoResult length={contactPersons.length}>
              <Table>
                <TableHead tableCellSx={{ bgcolor: "#5ba3c9", fontSize: 12, py: 1.5, px: 1 }} cellNames={tableHeadCellNames} />
                <TableBody contactPersons={contactPersons} onDelete={openDeleteModal} onEdit={openMainModalForUpdating} />
              </Table>
            </WrapWithNoResult>
          </WrapWithLoading>
        </Paper>
      </WrapWithAddButton>
      <BaseModal open={mainModal?.open} onClose={closeMainModal}>
        <Box sx={{ maxWidth: "35vw", py: 2 }}>
          {(() => {
            switch (mainModal?.for) {
              case "add":
                return <AddContactPerson partnerId={id} refetch={getPartnerContactPersons} closeModal={closeMainModal} />;
              case "edit":
                return (
                  mainModal.id && (
                    <UpdateContactPerson
                      partnerId={id}
                      id={mainModal.id}
                      refetch={getPartnerContactPersons}
                      closeModal={closeMainModal}
                    />
                  )
                );
            }
          })()}
        </Box>
      </BaseModal>
      <DeleteModal open={deleteModal?.open} onClose={closeDeleteModal} onDelete={handleDelete} loading={deleting} />
    </Box>
  );
}

export default withModals(memo(ContactPersons));
