import { memo } from "react";
import { Typography, Box, Grid, Avatar } from "@mui/material";
// source
import { useSelector } from "react-redux";
import { getUserFullInformation } from "src/providers/redux/slices";
import { Link } from "react-router-dom";
import SwitchLanguages from "./switch-languages";

function Header() {
  const user = useSelector(getUserFullInformation);

  if (!user) return null;

  return (
    <Box sx={{ mr: 2, p: 1 }}>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item>
          <Link to="/personal" style={{ textDecoration: "none" }}>
            <Grid container title="open_personal_page" alignItems="center" columnSpacing={2}>
              <Grid item>
                <Avatar sx={{ cursor: "pointer" }}>{user.first_name.slice(0, 1) + user.last_name.slice(0, 1)}</Avatar>
              </Grid>
              <Grid item>
                <Typography sx={{ cursor: "pointer", color: "black" }}>{user.full_name}</Typography>
              </Grid>
            </Grid>
          </Link>
        </Grid>
        <Grid item>
          <SwitchLanguages />
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(Header);
