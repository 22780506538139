import ApiSlice from "../slice";
import { CompaniesPlanFieldTypes, CouponPlanFieldTypes, PlanType, PlanTypeWithoutId } from "./types";

export default class Plans extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin/plans`;
  static defaultAuth = true;

  static getMembershipPlans = () => this.request<PlanType[]>(`/membership`);
  static getCouponPlans = () => this.request<PlanType[]>(`/coupons`);

  static getSingleMembershipPlan = (id: number) => this.request<PlanType>(`/membership/${id}`);
  static getSingleCouponPlan = (id: number) => this.request<PlanType>(`/coupons/${id}`);

  static addMembershipPlan = (body: CompaniesPlanFieldTypes) => {
    const values = { ...body, data: JSON.stringify({ positions: body.positions }) };
    delete values["positions"];
    return this.request<PlanTypeWithoutId>(`/membership`, "POST", { ...values });
  };
  static addCouponPlan = (body: CouponPlanFieldTypes) => {
    const values = { ...body, data: JSON.stringify({ positions: body.positions }) };
    delete values["positions"];
    return this.request<PlanTypeWithoutId>(`/coupons`, "POST", { ...values });
  };

  static updateMembershipPlan = (id: number, body: CompaniesPlanFieldTypes) => {
    const values = { ...body, data: JSON.stringify({ positions: body.positions }) };
    delete values["positions"];
    return this.request<PlanType>(`/membership/${id}`, "PUT", { ...values });
  };
  static updateCouponPlan = (id: number, body: CouponPlanFieldTypes) => {
    const values = { ...body, data: JSON.stringify({ positions: body.positions }) };
    delete values["positions"];
    return this.request<PlanType>(`/coupons/${id}`, "PUT", { ...values });
  };

  static deleteMembershipPlan = (id: number) => this.request(`/membership/${id}`, "DELETE");
  static deleteCouponPlan = (id: number) => this.request(`/coupons/${id}`, "DELETE");
}

export * from "./types";
