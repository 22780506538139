import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <Box sx={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}>
      <Stack alignItems="center">
        <Typography variant="h1" sx={{ fontSize: { xs: "20px", lg: "40px" }, textAlign: "center" }}>
          Page not found
        </Typography>
        <Link to="/">Go back to home</Link>
      </Stack>
    </Box>
  );
}
