import { Table as MuiTable } from "@mui/material";
import { positionsTableHeadConfig } from "src/providers/source";
import { CompanyPositionInRequestBody } from "src/types/api/companies";
import { CouponPositionInRequestBody } from "src/types/api/coupons";
import { TableHead, TableWrapper } from "../../custom";
import TableBody from "./TableBody";

interface PositionsTableProps {
  positions: CouponPositionInRequestBody[] | CompanyPositionInRequestBody[];
  place: "coupon" | "company";
  companyId: number;
  refetch: () => void;
}

export default function Table({ positions, place, companyId, refetch }: PositionsTableProps) {
  return (
    <TableWrapper withGutter>
      <MuiTable>
        <TableHead cellNames={positionsTableHeadConfig} />
        <TableBody positions={positions} place={place} companyId={companyId} refetch={refetch} />
      </MuiTable>
    </TableWrapper>
  );
}
