import { memo } from "react";
import { useTranslation } from "react-i18next";
// components
import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";

export type CustomSelectProps = { label: string; value: string | number; disabled?: boolean; labelAdditionalValue?: string };
interface Props extends SelectProps {
  options: CustomSelectProps[];
  field_key: string;
}

function CustomSelect({ field_key, options, ...props }: Props) {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <InputLabel id={`create_user_${field_key}_label`}>{t(field_key)}</InputLabel>
      <Select labelId={`create_user_${field_key}_label`} id={`create_user_${field_key}`} label={t(field_key)} {...props}>
        {options.map(({ label, value, disabled, labelAdditionalValue }) => (
          <MenuItem disabled={Boolean(disabled)} key={`${field_key}_${value}`} value={value}>
            {t(label, { additional: labelAdditionalValue ?? "" })}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default memo(CustomSelect);
