import { useCallback, useEffect, useState } from "react";
import Api from "src/api";
// components
import { Box, Table } from "@mui/material";
import { withModals, withModalsProps } from "src/components/hoc";
import { TableHead, TableWrapper, WrapWithFilter, WrapWithLoading, WrapWithNoResult } from "src/components/common-components";
//source
import { tableHeadCellNames } from "./source";
import { DeleteModal } from "../../components/modal";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { CompanyFeedback } from "src/api/comments";
import { TableBody } from "./table";
import { ReviewFilterProps } from "src/components/common-components/filter/company-reviews/Reviews";

function Comments({ deleteModal, openDeleteModal, closeDeleteModal }: withModalsProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [filtering, setFiltering] = useState(false);
  const [commentsLis, setCommentsLis] = useState<CompanyFeedback[]>([]);
  const [deleting, setDeleting] = useState(false);

  const handleDelete = useCallback(async () => {
    if (deleteModal?.id) {
      try {
        setDeleting(true);
        const response = await Api.comments.companyReviewDelete(deleteModal.id);
        setDeleting(false);
        if (response.status === "success") {
          enqueueSnackbar(t("comment_deleted", { id: deleteModal.id }), { variant: "success" });
        } else enqueueSnackbar(response.message, { variant: "error" });
      } catch (error) {
        throw new Error(error as string);
      } finally {
        closeDeleteModal();
        await getList();
      }
    }
  }, [deleteModal]);

  const getList = useCallback(async () => {
    try {
      const response = await Api.comments.getCompanyReviewList({ paginationParams: { perPage: 100 } });
      setLoading(false);
      if (response?.data) {
        setCommentsLis(response.data);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const filter = useCallback(async (filterParams: ReviewFilterProps) => {
    try {
      setFiltering(true);
      const response = await Api.comments.getCompanyReviewList({ filterParams, paginationParams: { perPage: 100 } });
      if (response) {
        if (response.data) setCommentsLis(response.data);
      }
      setFiltering(false);
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
      <Box sx={{ width: { xs: "80%" } }}>
        <WrapWithFilter filter_topic="reviews" onFilter={filter} filtering={filtering} filter_height={80}>
          <TableWrapper withGutter>
            <WrapWithLoading loading={loading}>
              <WrapWithNoResult length={commentsLis.length}>
                <Table>
                  <TableHead cellNames={tableHeadCellNames} />
                  <TableBody comments={commentsLis} onDelete={openDeleteModal} />
                </Table>
              </WrapWithNoResult>
            </WrapWithLoading>
            <DeleteModal
              open={Boolean(deleteModal?.open)}
              onClose={closeDeleteModal}
              onDelete={handleDelete}
              loading={deleting}
            />
          </TableWrapper>
        </WrapWithFilter>
      </Box>
    </Box>
  );
}

export default withModals(Comments);
