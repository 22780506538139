import { memo } from "react";
import { useFormikContext } from "formik";
// components
import { Grid, Stack } from "@mui/material";
import UploadBtn from "../common/upload-btn";
import ImageBox from "./image-box";
// source
import { AddMedia } from "..";

function UploadImages() {
  const { values, setFieldValue } = useFormikContext<AddMedia>();

  const handleUploadImage = (fileList: FileList) => {
    setFieldValue("images_preview", [...values.images_preview, ...Array.from(fileList)]);
  };

  return (
    <Stack alignItems="flex-end" spacing={2}>
      <UploadBtn onUpload={handleUploadImage} />
      <div style={{ width: "100%" }}>
        <Grid container spacing={2} py={2}>
          {values.images_preview.map((image, index) => (
            <Grid item xs={6} lg={3} xl={2} key={`image-preview-${index}`}>
              <ImageBox src={image} index={index} />
            </Grid>
          ))}
        </Grid>
      </div>
    </Stack>
  );
}

export default memo(UploadImages);
