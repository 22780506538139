import Api from "src/api";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { memo, useCallback, useState } from "react";
// components
import { Autocomplete as MuiAutocomplete, TextField, TextFieldProps } from "@mui/material";
// source
import { FilterCommons, FilterPathConfig } from "src/api/filter";
import { LEFT_SIDE_SPACE_RGX, trimStringLeftSide } from "src/providers/lib";

type FieldProps = {
  field_key: string;
  result_key?: keyof FilterCommons;
  filterPathConfig: FilterPathConfig;
  textFieldProps?: TextFieldProps;
  alt?: string;
};

function Autocomplete({ field_key, filterPathConfig, textFieldProps, result_key, alt }: FieldProps) {
  const { setFieldValue } = useFormikContext();
  const [options, setOptions] = useState<{ label?: string; id?: number | string }[]>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const getOptions = useCallback(
    async (name: string) => {
      if (name && name.length > 3) {
        try {
          setLoading(true);
          const response = await Api.filter.filter(filterPathConfig, { [field_key]: name });
          setLoading(false);
          if (response.data) {
            setOptions(
              response.data.map(result => ({
                id: result[result_key ?? "id"] ?? "",
                label: result?.full_name ?? result?.name ?? result?.title ?? ""
              }))
            );
          }
        } catch (error) {
          throw new Error(error as string);
        }
      }
    },
    [field_key, filterPathConfig, result_key]
  );

  const handleChange = useCallback(
    (newValue: string | null) => {
      if (newValue) {
        if (newValue.match(LEFT_SIDE_SPACE_RGX)) {
          const trimmedValue = trimStringLeftSide(newValue);
          if (trimmedValue) getOptions(trimmedValue);
        } else getOptions(newValue);
      } else setFieldValue(field_key, "");
    },
    [getOptions, field_key]
  );

  return (
    <MuiAutocomplete
      filterOptions={options => options}
      loading={loading}
      disablePortal
      options={options}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(e, newValue) => setFieldValue(field_key, newValue?.id)}
      onInputChange={(e, newValue) => handleChange(newValue)}
      renderInput={props => <TextField {...props} label={t(alt ?? field_key)} fullWidth {...textFieldProps} />}
    />
  );
}

export default memo(Autocomplete);
