import { CouponType } from "src/api/settings";

export const tableHeadCellNames = [
  { id: 0, name_key: "order" },
  { id: 1, name_key: "title_am" },
  { id: 2, name_key: "title_ru" },
  { id: 4, name_key: "title_en" },
  { id: 5, name_key: "group" },
  { id: 6, name_key: "action" }
];

export const perPageOptions = [
  { label: "9", value: 9 },
  { label: "12", value: 12 },
  { label: "15", value: 15 }
];

export const showBannerOptions = [
  { label: "no_show", value: "" },
  { label: "in_left", value: "left_side" },
  { label: "in_right", value: "right_side" }
];

export const couponTypeOptions: { label: CouponType; value: CouponType }[] = [
  { label: "hot", value: "hot" },
  { label: "premium", value: "premium" },
  { label: "top", value: "top" },
  { label: "home", value: "home" },
  { label: "home_section", value: "home_section" }
];
