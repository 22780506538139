import { generateQueryParamsForFilter, generateQueryParamsForPagination } from "src/providers/lib";
import ApiSlice from "../slice";
// types
import {
  Company,
  CompanyAddBalance,
  ContactPerson,
  ContactPersonForForm,
  MakeTopFormBody,
  SignIn,
  CompanyChangePassword
} from "./types";
import { Pagination } from "src/types/api";
import { CompaniesFilterProps } from "src/components/common-components/filter/companies";
import { CompanyPositionInRequestBody } from "src/types/api/companies";
import { AddPosition, PositionInRequestBody, UpdatePosition } from "src/types/api/positions";

export default class Companies extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin`;
  static defaultAuth = true;

  static getList = (params?: { filterParams?: CompaniesFilterProps; paginationParams?: Pagination }) =>
    this.request<Company[]>(
      `/companies${generateQueryParamsForFilter(params?.filterParams ?? null)}${generateQueryParamsForPagination(
        params?.paginationParams,
        Boolean(params?.filterParams)
      )}`
    );

  static getSingle = (id: string) => this.request<Company>(`/companies/${id}`);

  static addBalance = (id: number, body: CompanyAddBalance) => this.request<Company>(`/company/${id}/balance`, "POST", body);
  static changePassword = (id: number, body: CompanyChangePassword) =>
    this.request<Company>(`/company/${id}/change-password`, "POST", body);

  static delete = (id: number) => this.request(`/companies/${id}`, "DELETE");
  static approve = (id: number) => this.request(`/company/${id}/approve`, "POST");
  static block = (id: number) => this.request(`/company/${id}/block`, "POST");
  static signIn = (id: number) => this.request<SignIn>(`/company/${id}/login`, "POST");

  static getContactPersons = (id: number) => this.request<ContactPerson[]>(`/company/${id}/contacts`);
  static deleteContactPerson = (id: number) => this.request(`/company/contacts/${id}`, "DELETE");
  static addContactPerson = (id: number, body: ContactPersonForForm) =>
    this.request<ContactPerson>(`/company/${id}/contacts`, "POST", { ...body });
  static getContactPerson = (id: number) => this.request<ContactPerson>(`/company/contacts/${id}`);
  static updateContactPerson = (id: number, body: ContactPersonForForm) =>
    this.request<ContactPerson>(`/company/contacts/${id}`, "PUT", { ...body });

  static getPosition = (id: number, paginationParams?: Pagination) =>
    this.request<CompanyPositionInRequestBody[]>(
      `/company/${id}/position${generateQueryParamsForPagination({ ...paginationParams, perPage: 10 }, false)}`
    );
  static getSinglePosition = (positionId: number) => this.request<PositionInRequestBody>(`/company/position/${positionId}`);
  static createPosition = (id: number, values: AddPosition) => this.request(`/company/${id}/position`, "POST", values);
  static updatePosition = (id: number, values: UpdatePosition) =>
    this.request(`/company/position/${id}`, "POST", { ...values, _method: "PUT" });
  static deletePosition = (id: number) => this.request(`/company/position/${id}`, "DELETE");

  static makeTop = (id: number, values: MakeTopFormBody) => this.request<Company>(`/company/${id}/top`, "POST", values);
  static cancelTop = (id: number) => this.request<Company>(`/company/${id}/top`, "DELETE");

  static assignManager = (companyId: number, managerId: number) =>
    this.request(`/company/${companyId}/assign`, "POST", { manager_id: managerId });

  static assignMemberPlan = (companyId: number, plan_id: number) =>
    this.request(`/company/${companyId}/plan/membership`, "POST", { plan_id });
  static assignCouponPlan = (companyId: number, plan_id: number) =>
    this.request(`/company/${companyId}/plan/coupon`, "POST", { plan_id });
}

export * from "./types";
