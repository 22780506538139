// components
import { Grid, Paper } from "@mui/material";
import ChangePassword from "./change-password";
import ChangePersonalInformation from "./change-personal-information";

export default function PersonalPage() {
  return (
    <div>
      <Grid container p={5} spacing={5}>
        <Grid item xs={7} sx={{ height: "auto" }}>
          <Paper sx={{ p: 2, height: "100%" }}>
            <ChangePersonalInformation />
          </Paper>
        </Grid>
        <Grid item xs={5} sx={{ height: "auto" }}>
          <Paper sx={{ p: 2, height: "100%" }}>
            <ChangePassword />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
