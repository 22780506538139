import Api from "src/api";
import { useCallback, useEffect, useState } from "react";
// source
import { PlanType } from "src/api/plans";
import { FormikErrors, FormikProvider, useFormik } from "formik";
import UpsertForm from "../upsert-form";
import { useSnackbar } from "notistack";

type Props = {
  company_id: number;
};

function MemberPlans({ company_id }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [membershipPlans, setMembershipPlans] = useState<PlanType[]>([]);

  const getMembershipPlans = useCallback(async () => {
    try {
      const response = await Api.plans.getMembershipPlans();
      if (response?.data) {
        setMembershipPlans(response.data);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const assignMemberPlan = useCallback(async (id: string | number) => {
    try {
      setLoading(true);
      const response = await Api.companies.assignMemberPlan(company_id, +id);
      if (response?.status === "success") {
        enqueueSnackbar("member_plan_assigned", { variant: "success" });
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        setErrors(response.errors as FormikErrors<{ plan_id: number | string }>);
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, []);

  const formik = useFormik({
    initialValues: { plan_id: "" },
    onSubmit: values => {
      assignMemberPlan(values.plan_id);
    }
  });

  const { handleSubmit, setErrors } = formik;

  useEffect(() => {
    getMembershipPlans();
  }, [getMembershipPlans]);

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <UpsertForm plans={membershipPlans} loading={loading} />
      </FormikProvider>
    </form>
  );
}
export default MemberPlans;
