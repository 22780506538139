import { memo } from "react";
// components
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Box, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props extends LoadingButtonProps {
  title: string;
}

function SubmitBtn({ title, ...props }: Props) {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: "100%", display: "flex", height: "100%", justifyContent: "flex-end" }}>
      <LoadingButton fullWidth type="submit" {...props} loadingIndicator={<CircularProgress size={16} sx={{ color: "#FFF" }} />}>
        {t(title)}
      </LoadingButton>
    </Box>
  );
}

export default memo(SubmitBtn);
