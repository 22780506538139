import Api from "src/api";
import { useCallback, useEffect, useState } from "react";
import { Banner, CreateBanner } from "src/api/settings";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
// components
import { FieldsWrapper, Submit, WrapWithLoading } from "src/components/common-components";
import UpsertForm from "./upsert-form";
// source
import { FormikErrors, FormikProvider, useFormik } from "formik";
import { UpdateModalProps } from "src/types/components";
import { createFormDataWithImage } from "src/providers/lib";
import { checkIsPOOS } from "src/providers/lib/banners";

export default function Update({ id, closeModal, refetch }: UpdateModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [banner, setBanner] = useState<Banner | null>(null);

  const getBanner = useCallback(async () => {
    try {
      const response = await Api.settings.banners.getSingle(id);
      if (response.data) {
        setBanner({ ...response.data, ...(response.data?.data && { data: JSON.parse(response.data.data as string) }) });
        setLoading(false);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        closeModal();
        refetch();
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, [id]);

  useEffect(() => {
    getBanner();
  }, [getBanner]);

  const createBanner = useCallback(
    async (values: FormData) => {
      try {
        setUpdating(true);
        const response = await Api.settings.banners.update(id, values);
        if (response.data) {
          closeModal();
          refetch();
          enqueueSnackbar(t("banner_updated", { id }), { variant: "success" });
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
          if (response.errors) setErrors(response.errors as FormikErrors<CreateBanner>);
        }
      } catch (error) {
        throw new Error(error as string);
      } finally {
        setUpdating(false);
      }
    },
    [id]
  );

  const formik = useFormik<CreateBanner>({
    initialValues: {
      name: banner?.name ?? "",
      position: banner?.position ?? "",
      description: banner?.description ?? "",
      visible: banner?.visible ?? 0,
      external_url: banner?.external_url ?? "",
      target: banner?.target ?? "blank",
      image: banner?.url ?? banner?.image ?? "",
      mobile_image: banner?.mobile_image ?? "",
      ...(typeof banner?.data !== "string" && {
        banner_idx: banner?.data?.banner_idx ?? "",
        order: banner?.data?.order ?? "",
        categories: banner?.data?.categories ?? [],
        show_banner: banner?.data?.show_banner ?? "",
        business_types: banner?.data?.business_types ?? []
      })
    },
    onSubmit(values) {
      const compressedValues = {
        name: values.name,
        description: values.description,
        position: values.position,
        visible: values.visible,
        external_url: values.external_url,
        target: values.target,
        image: values.image,
        mobile_image: values.mobile_image,
        ...(checkIsPOOS(values.position) && {
          data: {
            banner_idx: values.banner_idx,
            order: values.order,
            categories: values.categories,
            show_banner: values.show_banner,
            business_types: values.business_types
          }
        })
      };
      const formData = createFormDataWithImage(compressedValues, true);
      createBanner(formData);
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    enableReinitialize: true
  });

  const { handleSubmit, setErrors } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <FieldsWrapper>
          <WrapWithLoading loading={loading}>
            <UpsertForm />
            <Submit title="update" loading={updating} />
          </WrapWithLoading>
        </FieldsWrapper>
      </FormikProvider>
    </form>
  );
}
