import Api from "src/api";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
// components
import { Box, Table } from "@mui/material";
import { TableHead, TableWrapper, WrapWithAddButton, WrapWithFilter, WrapWithLoading } from "src/components/common-components";
import { BaseModal, DeleteModal } from "src/components/modal";
import { AddBanner, UpdateBanner } from "./upsert";
import { TableBody } from "./table";
// source
import { Banner, BannerPosition } from "src/api/settings";
import { tableHeadCellNames } from "./source";
import { withModals, withModalsProps } from "src/components/hoc";
import { BannersProvider } from "src/providers/context/BannersContext";
import { BannerFilterOptions } from "src/components/common-components/filter/banners";

function Banners({
  mainModal,
  deleteModal,
  openDeleteModal,
  openMainModalForAdding,
  openMainModalForUpdating,
  closeDeleteModal,
  closeMainModal
}: withModalsProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [positionTypes, setPositionTypes] = useState<BannerPosition[]>([]);
  const [banners, setBanners] = useState<Banner[]>([]);

  const getBanners = useCallback(async (type?: string) => {
    try {
      const [banners, bannerPositions] = await Promise.all([
        Api.settings.banners.getList(type),
        Api.settings.banners.getPositionTypes()
      ]);
      if (banners.data) setBanners(banners.data);
      else enqueueSnackbar(banners.message, { variant: "error" });
      if (bannerPositions.data) setPositionTypes(bannerPositions.data);
      else enqueueSnackbar(bannerPositions.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDelete = useCallback(async () => {
    if (deleteModal?.id) {
      try {
        setDeleting(true);
        const response = await Api.settings.banners.delete(deleteModal.id);
        setDeleting(false);
        if (response.status === "success") {
          enqueueSnackbar(t("banner_deleted", { id: deleteModal.id }), { variant: "success" });
        } else enqueueSnackbar(response.message, { variant: "error" });
      } catch (error) {
        throw new Error(error as string);
      } finally {
        closeDeleteModal();
        await getBanners();
      }
    }
  }, [deleteModal]);

  const handleFilter = (values: BannerFilterOptions) => {
    getBanners(values.filter_by);
  };

  useEffect(() => {
    getBanners();
  }, [getBanners]);

  return (
    <WrapWithAddButton title="banners" sx={{ width: "80%" }} handleAdd={openMainModalForAdding}>
      <BannersProvider value={{ positionTypes }}>
        <WrapWithLoading loading={loading}>
          <WrapWithFilter filter_topic="banners" onFilter={handleFilter} filter_height={80} filtering={loading}>
            <TableWrapper>
              <Table>
                <TableHead cellNames={tableHeadCellNames} />
                <TableBody banners={banners} onDelete={openDeleteModal} onEdit={openMainModalForUpdating} />
              </Table>
            </TableWrapper>
          </WrapWithFilter>
        </WrapWithLoading>
        <BaseModal open={mainModal?.open} onClose={closeMainModal} disableBackdropClick>
          <Box sx={{ width: "50vw" }}>
            {(() => {
              if (mainModal?.for) {
                switch (mainModal.for) {
                  case "add":
                    return <AddBanner refetch={getBanners} closeModal={closeMainModal} />;
                  case "edit":
                    return mainModal?.id && <UpdateBanner refetch={getBanners} closeModal={closeMainModal} id={mainModal.id} />;
                  default:
                    return <p>Unknown form</p>;
                }
              }
            })()}
          </Box>
        </BaseModal>
        <DeleteModal open={deleteModal?.open} onClose={closeDeleteModal} onDelete={handleDelete} loading={deleting} />
      </BannersProvider>
    </WrapWithAddButton>
  );
}

export default withModals(Banners);
