import { memo, useMemo } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
// components
import { Box, Grid, InputAdornment, Switch, Typography } from "@mui/material";
import { Checkbox, Editor } from "src/components/common-components";
import Field from "../Field";
// source
import { CouponPlanFieldTypes } from "src/api/plans";
import { CouponPositionType } from "src/types/api/coupons";

function Fields() {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<CouponPlanFieldTypes>();

  const arrayForPositions = useMemo((): CouponPositionType[] => {
    return ["home", "premium", "top", "hot", "premium_category", "top_category"];
  }, []);

  return (
    <Box>
      <Grid container rowGap={3} columnSpacing={2}>
        <Grid item xs={12}>
          <Grid container columnSpacing={2}>
            <Grid item xs={4}>
              <Field field_key="name_am" />
            </Grid>
            <Grid item xs={4}>
              <Field field_key="name_ru" />
            </Grid>
            <Grid item xs={4}>
              <Field field_key="name_en" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container columnSpacing={2}>
            <Grid item xs={4}>
              <Editor field_key="description_am" />
            </Grid>
            <Grid item xs={4}>
              <Editor field_key="description_ru" />
            </Grid>
            <Grid item xs={4}>
              <Editor field_key="description_en" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Field
            field_key="price"
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box title={t("price_without_discount")}>{(+values.price * 100) / (100 - +values.discount)}</Box>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Field field_key="period" type="number" />
        </Grid>
        <Grid item xs={6}>
          <Field field_key="count" type="number" />
        </Grid>
        <Grid item xs={6}>
          <Field field_key="discount" type="number" />
        </Grid>
        <Grid item xs={12}>
          <Grid container columnSpacing={2}>
            {arrayForPositions.map(position => (
              <Grid item key={`position_handler_$$${position}`}>
                <Box className="flex-center">
                  <Switch
                    checked={Boolean(values?.positions?.find(vs_position => vs_position === position))}
                    onChange={e => {
                      if (e.target.checked) setFieldValue("positions", [...(values?.positions ?? []), position]);
                      else
                        setFieldValue(
                          "positions",
                          values?.positions?.filter(fi_position => fi_position !== position)
                        );
                    }}
                  />
                  <Typography>{t(position)}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Checkbox field_key="visible" />
          <Checkbox field_key="contact_us" />
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(Fields);
