import Api from "src/api";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
// hooks
import { FormikErrors, FormikProvider, useFormik } from "formik";
import { useCallback, useState } from "react";
// components
import { Stack } from "@mui/material";
import { FieldsWrapper, Submit } from "src/components/common-components";
// types
import { CompaniesPlanFieldTypes } from "src/api/plans";
import { AddModalProps } from "../source";
import Fields from "./Fields";

function AddMemberPlan({ refetch, closeModal }: AddModalProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const addPlan = useCallback(
    async (body: CompaniesPlanFieldTypes) => {
      try {
        setLoading(true);
        const response = await Api.plans.addMembershipPlan(body);
        setLoading(false);
        if (!response.error) {
          enqueueSnackbar(t("membership_plan_added", { name: response.data?.name }), { variant: "success" });
          refetch();
          closeModal();
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
          if (response.errors) setErrors(response.errors as FormikErrors<CompaniesPlanFieldTypes>);
        }
      } catch (error) {
        throw new Error(error as string);
      }
    },
    [refetch, closeModal]
  );

  const formik = useFormik<CompaniesPlanFieldTypes>({
    initialValues: {
      name_am: "",
      name_ru: "",
      name_en: "",
      description_am: "",
      description_ru: "",
      description_en: "",
      price: "",
      period: "",
      visible: false,
      contact_us: false,
      positions: [],
      full_content: 0,
      discount: ""
    },
    onSubmit(values) {
      addPlan(values);
    },
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false
  });

  const { handleSubmit, setErrors } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <Stack spacing={{ xs: 3 }} px={{ xs: 5 }} py={{ xs: 3 }}>
          <FieldsWrapper title="add_member_plan">
            <Fields />
            <Submit loading={loading} title="add" />
          </FieldsWrapper>
        </Stack>
      </FormikProvider>
    </form>
  );
}

export default AddMemberPlan;
