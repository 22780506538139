import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
// components
import { Divider, Grid, TextField, Typography } from "@mui/material";
// source
import { CreateFAQ } from "src/api/settings";
import { Editor } from "src/components/common-components";

interface FieldProps {
  field_key: keyof CreateFAQ;
}

const Field = ({ field_key }: FieldProps) => {
  const { t } = useTranslation();
  const { getFieldProps, errors } = useFormikContext<CreateFAQ>();
  return (
    <TextField
      fullWidth
      {...getFieldProps(field_key)}
      label={t(field_key)}
      error={Boolean(errors[field_key])}
      helperText={errors[field_key]}
    />
  );
};

function UpsertForm() {
  const { t } = useTranslation();
  return (
    <Grid container rowGap={2} columnSpacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">{t("title")}</Typography>
        <Divider sx={{ my: 1 }} />
      </Grid>
      <Grid item xs={6}>
        <Field field_key="title_am" />
      </Grid>
      <Grid item xs={6}>
        <Field field_key="title_ru" />
      </Grid>
      <Grid item xs={6}>
        <Field field_key="title_en" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">{t("content")}</Typography>
        <Divider sx={{ my: 1 }} />
      </Grid>
      <Grid item xs={12}>
        <Editor field_key="content_am" />
      </Grid>
      <Grid item xs={12}>
        <Editor field_key="content_ru" />
      </Grid>
      <Grid item xs={12}>
        <Editor field_key="content_en" />
      </Grid>
    </Grid>
  );
}

export default memo(UpsertForm);
