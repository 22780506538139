// hooks/helpers
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
// components
import { Grid, Typography } from "@mui/material";
import { Submit } from "src/components/common-components";
import Permissions from "./permissions";
import User from "./user";
// types
import { UserUpsertTypes } from "src/api/admin";
import { UserPermission } from "src/types/api";

interface Props {
  permissions?: UserPermission[];
}

function UpsertForm({ permissions }: Props) {
  const permissionsIds = useMemo(() => {
    if (permissions) return permissions.map(permission => permission.id);
  }, [permissions]);

  const { t } = useTranslation();

  const { setFieldValue, errors } = useFormikContext<UserUpsertTypes>();

  return (
    <Grid container spacing={3} p={4}>
      <Grid item xs={12}>
        <Typography variant="h5">{t("add_user")}</Typography>
      </Grid>
      <Grid item xs={6}>
        <User />
      </Grid>
      <Grid item xs={6} mt={-8.5}>
        <Permissions setField={setFieldValue} permissions={permissionsIds ?? []} isError={Boolean(errors["permissions"])} />
      </Grid>
      <Grid item xs={12}>
        <Submit title="add" loading={false} />
      </Grid>
    </Grid>
  );
}

export default memo(UpsertForm);
