import { Box, Button, TableBody, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Transaction } from "src/api/transactions";
import { StatusCell } from "src/components/common-components";
import { JSONValue, JsonView } from "src/components/modal";
import { format } from "date-fns";

interface Props {
  transactions: Transaction[];
}

type JSON = {
  [key: string]: JSONValue;
};

export default function TableBodyC({ transactions }: Props) {
  const [jsonModal, setJsonModal] = useState<{ open: boolean; response: JSON } | null>(null);

  const { t } = useTranslation();

  const handleJsonModalOpen = (response: string) => {
    try {
      setJsonModal({ open: true, response: JSON.parse(response) });
    } catch (err) {
      setJsonModal({ open: true, response: {} });
    }
  };

  const handleJsonModalClose = () => {
    setJsonModal(null);
  };

  const getStatusColor = (status: string) => {
    let color = "#00b9ff";

    if (status === "processed") {
      color = "#089d51";
    }
    if (status === "failed") {
      color = "#cf0404";
    }
    if (status === "canceled") {
      color = "#cbab0f";
    }

    return color;
  };

  return (
    <TableBody>
      {transactions.map(transaction => (
        <TableRow key={transaction.id}>
          <TableCell>{transaction.user.full_name}</TableCell>
          <TableCell>{transaction.company ? transaction.company.name : "-"}</TableCell>
          <TableCell>
            <strong>{transaction.amount}</strong>
          </TableCell>
          <TableCell>
            <Box
              sx={{
                textTransform: "uppercase",
                borderRadius: 1,
                fontSize: 11,
                py: 0.4,
                color: "#fff",
                background: getStatusColor(transaction.status)
              }}
            >
              {transaction.status}
            </Box>
          </TableCell>
          <TableCell>
            <Box sx={{ color: "#f17104", fontWeight: 700 }}>{transaction.method}</Box>
          </TableCell>
          <TableCell>
            <Button sx={{ py: 0.2 }} size="small" onClick={() => handleJsonModalOpen(transaction.response)}>
              {t("look")}
            </Button>
          </TableCell>
          <TableCell>{format(new Date(transaction.created_at ?? ""), "yyyy-MM-dd H:mm")}</TableCell>
        </TableRow>
      ))}
      {jsonModal && <JsonView open={jsonModal.open} data={jsonModal.response} onClose={handleJsonModalClose} />}
    </TableBody>
  );
}
