import Api from "src/api";
import { memo, useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
// components
import { Box, Table } from "@mui/material";
import { TableHead, TableWrapper, WrapWithLoading, WrapWithNoResult, WrapWithPagination } from "src/components/common-components";
import TableBody from "./TableBody";
//source
import { tableHeadCellNames } from "./source";
import { Meta, Pagination } from "src/types/api";
import { withModals, withModalsProps } from "src/components/hoc";
import { DeleteModal } from "src/components/modal";
import { ContactForm } from "src/api/contact-form";

function ContactForms({ deleteModal, closeDeleteModal, openDeleteModal }: withModalsProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [contactFormList, setContactFormList] = useState<ContactForm[]>([]);
  const [contactFormMeta, setContactFormMeta] = useState<Meta | null>(null);

  const handleDelete = useCallback(async () => {
    try {
      setDeleting(true);
      const response = await Api.contactForm.delete(deleteModal.id);
      setDeleting(false);
      if (response.status === "success")
        enqueueSnackbar(t("contact_form_deleted", { id: deleteModal.id }), { variant: "success" });
      else enqueueSnackbar(response.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    } finally {
      closeDeleteModal();
      await getContactFormsList();
    }
  }, [deleteModal]);

  const getContactFormsList = useCallback(async (paginationParams?: Pagination) => {
    try {
      const response = await Api.contactForm.getList(paginationParams);
      setLoading(false);
      if (response?.data) setContactFormList(response.data);
      if (response?.meta) setContactFormMeta(response.meta);
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  useEffect(() => {
    getContactFormsList({ perPage: 50 });
  }, [getContactFormsList]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
      <Box sx={{ width: { xs: "80%" } }}>
        <TableWrapper>
          <WrapWithLoading loading={loading}>
            <WrapWithNoResult length={contactFormList.length}>
              <WrapWithPagination
                pagination_meta={contactFormMeta?.pagination}
                onPageChange={page => getContactFormsList({ page, perPage: 50 })}
              >
                <Table>
                  <TableHead cellNames={tableHeadCellNames} />
                  <TableBody contactFormsList={contactFormList} onDelete={openDeleteModal} />
                </Table>
              </WrapWithPagination>
            </WrapWithNoResult>
          </WrapWithLoading>
          <DeleteModal open={Boolean(deleteModal?.open)} onClose={closeDeleteModal} onDelete={handleDelete} loading={deleting} />
        </TableWrapper>
      </Box>
    </Box>
  );
}

export default withModals(memo(ContactForms));
