// components
import Chart, { Props as ApexChartProps } from "react-apexcharts";
// source
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../../providers/lib";

interface Props {
  title: string;
  data: ApexAxisChartSeries;
  amounts: number[];
  categories: number[] | string[];
}

export default function ColumnChart({ title, data, categories, amounts, ...props }: Props | ApexChartProps) {
  const { t } = useTranslation();

  return (
    <Chart
      type="bar"
      series={data}
      options={{
        chart: {
          type: "bar",
          zoom: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "70%"
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return formatNumber(value);
            }
          }
        },
        fill: {
          opacity: 1
        },
        title: {
          text: t(title)
        },
        tooltip: {
          y: {
            formatter: (val, opt) => {
              if (opt.seriesIndex === 1) {
                return formatNumber(val - amounts[opt.dataPointIndex] * 0.4);
              }
              return formatNumber(val);
            }
          }
        }
      }}
      height="250"
      {...props}
    />
  );
}
