import Api from "src/api";
// hooks
import { useCallback, useState } from "react";
import { FormikErrors, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
// components
import { FieldsWrapper, Submit } from "src/components/common-components";
import UpsertForm from "./upsert-form";
// source
import { AddModalProps } from "src/types/components";
import { createFormDataWithImage } from "src/providers/lib";
import { CreateFinanceDealType } from "src/api/finances";

export default function Add({ refetch, closeModal }: AddModalProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const createFinanceDeal = useCallback(async (values: FormData) => {
    try {
      setLoading(true);
      const response = await Api.finances.create(values);
      if (response.data) {
        closeModal();
        refetch();
        enqueueSnackbar(t("finance_deal_created", { id: response.data.id }), { variant: "success" });
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        if (response.errors) setErrors(response.errors as FormikErrors<CreateFinanceDealType>);
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, []);

  const formik = useFormik<CreateFinanceDealType>({
    initialValues: {
      type: "",
      description: "",
      amount: "",
      operation: ""
    },
    onSubmit(values) {
      const formData = createFormDataWithImage<CreateFinanceDealType>(values);
      createFinanceDeal(formData);
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false
  });

  const { handleSubmit, setErrors } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <FieldsWrapper>
          <UpsertForm />
          <Submit title="add" loading={loading} />
        </FieldsWrapper>
      </FormikProvider>
    </form>
  );
}
