// components
import { TableBody as MuiTableBody } from "@mui/material";
// types
import { Subscriber } from "src/api/subscribers";
import TableRow from "./TableRow";

interface Props {
  subscribers: Subscriber[];
}

export default function TableBody({ subscribers }: Props) {
  return (
    <MuiTableBody>
      {subscribers.map(subscriber => (
        <TableRow key={`Subscriber_line_${subscriber.id}`} subscriber={subscriber} />
      ))}
    </MuiTableBody>
  );
}
