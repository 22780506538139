import Api from "src/api";
import { memo, useEffect, useState, useCallback } from "react";
// components
import { Grid } from "@mui/material";
import { DatePicker, Select } from "src/components/common-components";
// source
import { dateSelectOptions } from "./source";
import { FormikProvider, useFormik } from "formik";
import { FilterParams } from "src/api/statistics";
import moment from "moment";

export type StatisticsFetchers = "getPostedCoupons";
export type chartTypes = "line" | "pie" | "company-coupons" | "coupons_sales";

interface Props {
  makeFilter: (values: FilterParams) => void;
  chartType?: chartTypes;
}

function Filter({ makeFilter, chartType = "line" }: Props) {
  const [managers, setManagers] = useState<{ label: string; value: number }[]>([]);
  const formik = useFormik<FilterParams>({
    initialValues: {
      from: moment().startOf("year").format("MM/DD/YYYY"),
      to: moment().endOf("month").format("MM/DD/YYYY"),
      manager_id: null,
      range: "month"
    },
    onSubmit(values) {
      makeFilter(values);
    }
  });

  const { handleSubmit, values } = formik;

  const getManagers = useCallback(async () => {
    const response = await Api.users.getManagers();
    if (response.data) {
      setManagers(response.data.map(user => ({ label: ` Id:${user.id} - ${user.full_name}`, value: user.id })));
    }
  }, []);

  useEffect(() => {
    getManagers();
  }, [getManagers]);

  useEffect(() => {
    makeFilter(values);
  }, [makeFilter, values]);

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <Grid container columnSpacing={2} justifyContent="flex-end">
          <Grid item xs={4}>
            <DatePicker field_key="from" sx={{ "& input": { fontSize: 12 } }} />
          </Grid>
          <Grid item xs={4}>
            <DatePicker field_key="to" sx={{ "& input": { fontSize: 12 } }} />
          </Grid>
          {["line"].includes(chartType) && (
            <Grid item xs={4}>
              <Select
                field_key="range"
                options={dateSelectOptions}
                sx={{ fontSize: 12, "& span": { fontSize: 11 } }}
                MenuProps={{ sx: { "& li": { fontSize: 12 } } }}
              />
            </Grid>
          )}
          {["company-coupons"].includes(chartType) && (
            <Grid item xs={4}>
              <Select field_key="manager_id" options={managers} />
            </Grid>
          )}
        </Grid>
      </FormikProvider>
    </form>
  );
}

export default memo(Filter);
