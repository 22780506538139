import Api from "src/api";
// hooks/helpers
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikProvider, useFormik } from "formik";
// components
import { Button, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { Select, Submit } from "src/components/common-components";
// types
import { ContactTypes } from "src/types/api";

interface Props {
  id: number;
  closeModal(): void;
  refetch(): void;
  contacts: ContactTypes;
}

export default function Confirm({ id, closeModal, refetch, contacts }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const confirm = useCallback(async (type: ContactTypes) => {
    try {
      setLoading(true);
      const response = await Api.subscribers.confirmContact(id, type);
      if (response.data) {
        enqueueSnackbar(t("subscriber_contact_updated", { id }), { variant: "success" });
        closeModal();
        refetch();
      } else enqueueSnackbar(response.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, []);

  const formik = useFormik<{ type: ContactTypes | "" }>({
    initialValues: {
      type: ""
    },
    onSubmit(value) {
      if (value.type) {
        confirm(value.type);
      }
    }
  });

  const { handleSubmit } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <Grid container rowSpacing={4} p={2} sx={{ maxWidth: "30vw" }}>
          <Grid item xs={12}>
            <Typography variant="h5">{t("confirm_contacts")}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ width: "90%" }}>
            <Select
              field_key="type"
              options={[
                {
                  label: "email_for_select",
                  value: "email",
                  labelAdditionalValue: contacts.email,
                  disabled: Boolean(!contacts.email)
                },
                {
                  label: "phone_for_select",
                  value: "phone",
                  labelAdditionalValue: contacts.phone,
                  disabled: Boolean(!contacts.phone)
                }
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container columnSpacing={2}>
              <Grid item xs={6}>
                <Submit title={t("confirm")} loading={loading} />
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth onClick={closeModal}>
                  {t("cancel")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormikProvider>
    </form>
  );
}
