import { memo } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
// components
import { Checkbox, FormControl, FormControlLabel, FormControlLabelProps, FormHelperText } from "@mui/material";

interface Props extends Omit<FormControlLabelProps, "control" | "label"> {
  withoutLabel?: boolean;
  field_key: string;
}
// replace direction

function CustomCheckbox({ field_key, withoutLabel }: Props) {
  const { t } = useTranslation();
  const { setFieldValue, values, errors } = useFormikContext<{ [key: string]: boolean | 1 | 0 }>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(field_key, event.target.checked ? 1 : 0);
  };

  return (
    <FormControl error={Boolean(errors[field_key])}>
      <FormControlLabel
        sx={{ ml: 0 }}
        label={<div dangerouslySetInnerHTML={{ __html: t(withoutLabel ? "" : field_key) }} />}
        control={<Checkbox checked={Boolean(values[field_key])} onChange={handleChange} />}
      />
      {errors[field_key] && <FormHelperText>{errors[field_key]}</FormHelperText>}
    </FormControl>
  );
}

export default memo(CustomCheckbox);
