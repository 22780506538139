// hooks
import { FormikProvider, useFormik } from "formik";
// components
import { Grid } from "@mui/material";
import { memo, useContext } from "react";
import { Autocomplete, ResetForm, SubmitForm } from "../common";
// context
import { FilterContext } from "src/providers/context";

export interface ReviewFilterProps {
  company?: string;
}

function Coupons() {
  const { onFilter, filtering } = useContext(FilterContext);

  const formik = useFormik<ReviewFilterProps>({
    initialValues: { company: "" },
    onSubmit(values) {
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(value => Boolean(value[1] || value[1].length) && value)
      );

      onFilter(filteredValues);
    }
  });

  const { handleSubmit, resetForm } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <Grid container alignItems="center" justifyContent="space-between" rowGap={2} columnSpacing={2} pt={2}>
          <Grid item xs={8}>
            <Grid container columnSpacing={2}>
              <Grid item xs={5}>
                <Autocomplete field_key="company" alt="company_name" filterPathConfig="companies" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container columnSpacing={2} justifyContent="flex-end">
              <Grid item>
                <SubmitForm loading={filtering} />
              </Grid>
              <Grid item>
                <ResetForm onClick={resetForm} loading={filtering} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormikProvider>
    </form>
  );
}

export default memo(Coupons);
