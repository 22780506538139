import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  loading: boolean;
};

function SubmitForm({ loading }: Props) {
  const { t } = useTranslation();
  return (
    <LoadingButton
      type="submit"
      loading={loading}
      sx={{ fontSize: "16px" }}
      fullWidth
      loadingIndicator={<CircularProgress sx={{ color: "white" }} size={20} />}
    >
      {t("use")}
    </LoadingButton>
  );
}

export default memo(SubmitForm);
