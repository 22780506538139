import Api from "src/api";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
// hooks
import { FormikErrors, FormikProvider, useFormik } from "formik";
import { useCallback, useState } from "react";
// components
import Fields from "./Fields";
import { FieldsWrapper, Submit } from "src/components/common-components";
// types
import { CouponPlanFieldTypes } from "src/api/plans";
import { AddModalProps } from "../source";

function AddCouponPlan({ refetch, closeModal }: AddModalProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const addPlan = useCallback(
    async (body: CouponPlanFieldTypes) => {
      try {
        setLoading(true);
        const response = await Api.plans.addCouponPlan(body);
        setLoading(false);
        if (!response.error) {
          enqueueSnackbar(t("coupon_plan_added", { name: response.data?.name }), { variant: "success" });
          refetch();
          closeModal();
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
          if (response.errors) setErrors(response.errors as FormikErrors<CouponPlanFieldTypes>);
        }
      } catch (error) {
        throw new Error(error as string);
      }
    },
    [refetch, closeModal]
  );

  const formik = useFormik<CouponPlanFieldTypes>({
    initialValues: {
      name_am: "",
      name_ru: "",
      name_en: "",
      description_am: "",
      description_ru: "",
      description_en: "",
      price: "",
      count: "",
      period: "",
      visible: false,
      contact_us: false,
      positions: [],
      discount: ""
    },
    onSubmit(values) {
      addPlan(values);
    },
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false
  });

  const { handleSubmit, setErrors } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <FieldsWrapper title="add_coupon_plan">
          <Fields />
          <Submit loading={loading} title="add" />
        </FieldsWrapper>
      </FormikProvider>
    </form>
  );
}

export default AddCouponPlan;
