import { memo } from "react";
// components
import { TableBody as MuiTableBody, TableCell, TableRow } from "@mui/material";
import { Actions } from "src/components/common-components";
// types
import { BusinessType } from "src/api/settings";

interface Props {
  businessTypes: BusinessType[];
  onEdit(id: number): void;
  onDelete(id: number): void;
}

function TableBody({ businessTypes, onDelete, onEdit }: Props) {
  return (
    <MuiTableBody>
      {businessTypes.map(businessType => (
        <TableRow key={businessType.id}>
          <TableCell>{businessType.title_am ?? "-"}</TableCell>
          <TableCell>{businessType.title_ru ?? "-"}</TableCell>
          <TableCell>{businessType.title_en ?? "-"}</TableCell>
          {/* handle images cell */}
          <TableCell>
            <Actions
              actions={["edit", "remove"]}
              onEdit={() => onEdit(businessType.id)}
              onDelete={() => onDelete(businessType.id)}
            />
          </TableCell>
        </TableRow>
      ))}
    </MuiTableBody>
  );
}

export default memo(TableBody);
