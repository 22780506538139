import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { UpdateUserPersonalInformation } from "src/api/user";
// types
import { Admin } from "src/types/api";

interface Slice {
  user: Admin | null;
}

interface Reducers extends SliceCaseReducers<Slice> {
  signIn: CaseReducer<Slice, PayloadAction<{ user: Admin }>>;
  update: CaseReducer<Slice, PayloadAction<{ userUpdatableContent: UpdateUserPersonalInformation }>>;
  signOut: CaseReducer<Slice, PayloadAction>;
}

const initialState = {
  user: null
};

const user_slice = createSlice<Slice, Reducers>({
  name: "user",
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.user = action.payload.user;
    },
    signOut: state => {
      state.user = null;
    },
    update: (state, action) => {
      if (state.user) {
        state.user = { ...state.user, ...action.payload.userUpdatableContent };
      }
    }
  }
});

export const { signIn, signOut, update } = user_slice.actions;

export const getUserFullInformation = (state: Slice) => state.user;
export const getUserPermissions = (state: Slice) => state.user?.permissions;
export const getUserAvailablePermissionGroups = (state: Slice) =>
  Array.from(new Set(state.user?.permissions.map(permission => permission.group)));

export default user_slice.reducer;
