import { Avatar, Box, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { CompanyFollower } from "src/api/followers";

interface Props {
  followers: CompanyFollower[];
}

export default function TableBodyC({ followers }: Props) {
  return (
    <TableBody>
      {followers.map(follower => (
        <TableRow key={follower.user_id}>
          <TableCell>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", columnGap: 3 }}>
              <Avatar src={follower.user.avatar ?? ""} />
              <Typography>{follower.user.full_name}</Typography>
            </Box>
          </TableCell>
          <TableCell>{`${follower.company?.name}`}</TableCell>
          <TableCell>{follower.created_at}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}
