import { memo } from "react";
import { useTranslation } from "react-i18next";
// components
import { Box, Button, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import Base from "./Base";
import { LoadingButton } from "@mui/lab";
// types
import { BaseModalProps } from "src/types/components";

interface Props extends BaseModalProps {
  onDelete(): void;
  loading?: boolean;
}

function Delete({ open, loading = false, onDelete, onClose }: Props) {
  const { t } = useTranslation();

  return (
    <Base open={open} onClose={onClose}>
      <Stack alignItems="center" py={{ xs: 4 }} px={{ xs: 4 }} spacing={{ xs: 5 }}>
        <Typography variant="h5">{t("are_you_sure_to_delete")}</Typography>
        <Box>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <LoadingButton
                loading={loading}
                onClick={onDelete}
                loadingIndicator={<CircularProgress sx={{ color: "#FFF" }} size={16} />}
              >
                {t("yes")}
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <Button onClick={onClose}>{t("no")}</Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Base>
  );
}

export default memo(Delete);
