import { TextField, TextFieldProps } from "@mui/material";
import { useFormikContext } from "formik";
import { memo } from "react";
import { useTranslation } from "react-i18next";

interface FieldProps {
  field_key: string;
}

function Field({ field_key, ...props }: FieldProps & TextFieldProps) {
  const { t } = useTranslation();
  const { getFieldProps, errors } = useFormikContext<{ [key: string]: unknown }>();

  return (
    <TextField
      fullWidth
      {...getFieldProps(field_key)}
      label={t(field_key)}
      error={Boolean(errors[field_key])}
      helperText={errors[field_key]}
      {...props}
    />
  );
}
export default memo(Field);
