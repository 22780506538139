import { memo, PropsWithChildren } from "react";
// components
import { NoResult } from "../typography";

type Props = { length: number };

function WrapWithNoResult({ length, children }: PropsWithChildren<Props>) {
  return <>{!length ? <NoResult /> : children}</>;
}

export default memo(WrapWithNoResult);
