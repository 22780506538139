import Api from "src/api";
// hooks
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
// components
import { FieldsWrapper, Submit, WrapWithLoading } from "src/components/common-components";
import UpsertForm from "./upsert-form";
// source
import { FormikErrors, FormikProvider, useFormik } from "formik";
import { useRequest } from "src/providers/hooks";
import { Blog, CreateBlog } from "src/api/blog";
import { createFormDataWithImage } from "src/providers/lib";
import { useNavigate } from "react-router-dom";

export default function Update({ id }: { id: string }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [updating, setUpdating] = useState(false);

  const { loading, data: blog } = useRequest<Blog, { id: number }>(() => Api.blog.getSingle(id), {
    onError: () => {
      navigate(-1);
    }
  });

  const updateBlog = useCallback(
    async (values: FormData) => {
      try {
        setUpdating(true);
        const response = await Api.blog.update(id, values);
        if (response.data) {
          enqueueSnackbar(t("blog_updated", { id }), { variant: "success" });
          navigate(-1);
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
          if (response.errors) setErrors(response.errors as FormikErrors<CreateBlog>);
        }
      } catch (error) {
        throw new Error(error as string);
      } finally {
        setUpdating(false);
      }
    },
    [id]
  );

  const formik = useFormik<CreateBlog>({
    initialValues: {
      title_am: blog?.title_am ?? "",
      title_ru: blog?.title_ru ?? "",
      title_en: blog?.title_en ?? "",
      content_am: blog?.content_am ?? "",
      content_ru: blog?.content_ru ?? "",
      content_en: blog?.content_en ?? "",
      thumbnail: blog?.thumbnail ?? "",
      excerpt_am: blog?.excerpt_am ?? "",
      excerpt_ru: blog?.excerpt_ru ?? "",
      excerpt_en: blog?.excerpt_en ?? "",
      is_top: blog?.is_top ?? 0
    },
    onSubmit(values) {
      const formData = createFormDataWithImage(values, true);
      updateBlog(formData);
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    enableReinitialize: true
  });

  const { handleSubmit, setErrors } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <FieldsWrapper>
          <WrapWithLoading loading={loading}>
            <UpsertForm />
            <Submit title="update" loading={updating} />
          </WrapWithLoading>
        </FieldsWrapper>
      </FormikProvider>
    </form>
  );
}
