import { Grid, IconButton } from "@mui/material";
// icons
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { useTranslation } from "react-i18next";

type Props = {
  onOrderToUp(): void;
  onOrderToDown(): void;
  order: number;
  lastElementOrder: number;
};

export default function ChangeOrder({ onOrderToDown, onOrderToUp, order, lastElementOrder }: Props) {
  const { t } = useTranslation();
  return (
    <Grid container justifyContent="center" columnSpacing={2}>
      <Grid item>
        <IconButton disabled={order === 1} title={t("row_up")} onClick={onOrderToUp}>
          <KeyboardDoubleArrowUpIcon sx={{ fontSize: 20, color: order !== 1 ? "success.main" : "unset" }} />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton disabled={order === lastElementOrder} title={t("row_down")} onClick={onOrderToDown}>
          <KeyboardDoubleArrowDownIcon sx={{ fontSize: 20, color: order !== lastElementOrder ? "error.main" : "unset" }} />
        </IconButton>
      </Grid>
    </Grid>
  );
}
