import Api from "src/api";
import { useTranslation } from "react-i18next";
import { memo, useCallback, useState } from "react";
// components
import { ChartWrapper, Filter } from "../common";
// source
import { FilterParams } from "src/api/statistics";
import { format } from "date-fns";
import { WrapWithNoResult } from "src/components/common-components";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

function Operators() {
  const { t } = useTranslation();

  const [data, setData] = useState<{ name: string; count: string; sum: string }[]>([]);

  const getOperators = useCallback(async (values: FilterParams) => {
    try {
      const response = await Api.statistics.getOperators({
        ...values,
        from: format(new Date(values.from), "yyyy-MM-dd"),
        to: format(new Date(values.to), "yyyy-MM-dd")
      });
      if (response.data) {
        setData(response.data);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const tdStyles = {
    padding: "10px"
  };

  return (
    <ChartWrapper>
      <Filter makeFilter={getOperators} chartType="pie" />
      <WrapWithNoResult length={data.length}>
        <Typography sx={{ fontWeight: 700, px: 2 }}>Статистика по продажам операторов</Typography>
        <Box sx={{ p: 1, height: "350px", overflowY: "auto" }}>
          <table border={1} style={{ width: "100%", border: "1px solid #ccc", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={tdStyles}>{t("name")}</th>
                <th style={tdStyles}>{t("count")}</th>
                <th style={tdStyles}>{t("sum")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={`operator_${index}`}>
                  <td style={tdStyles}>{row.name}</td>
                  <td style={{ ...tdStyles, textAlign: "center" }}>
                    {row.count} {t("stat_coupons")}
                  </td>
                  <td style={{ ...tdStyles, textAlign: "center" }}>{row.sum}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </WrapWithNoResult>
    </ChartWrapper>
  );
}

export default memo(Operators);
