import Api from "src/api";
import { FormikErrors, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { UserUpsertTypes } from "src/api/admin";
// components
import UpsertForm from "../upsert-form";

interface Props {
  refetch(): void;
  closeModal(): void;
}

export default function AddUser({ refetch, closeModal }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const createUser = useCallback(async (body: UserUpsertTypes) => {
    try {
      const response = await Api.users.createUser(body);
      if (response.data) {
        enqueueSnackbar("User successfully added", { variant: "success" });
        refetch();
        closeModal();
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        if (response.errors) setErrors(response.errors as FormikErrors<UserUpsertTypes>);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const formik = useFormik<UserUpsertTypes>({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      avatar: "",
      position: "",
      permissions: [],
      gender: "male",
      role: "operator"
    },
    onSubmit(values) {
      createUser(values);
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false
  });

  const { handleSubmit, setErrors } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <UpsertForm />
      </FormikProvider>
    </form>
  );
}
