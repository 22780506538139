import { memo, useCallback, useEffect, useState } from "react";
// components
import { Box, Paper, Table } from "@mui/material";
import { TableHead, WrapWithLoading, WrapWithNoResult, WrapWithPagination } from "src/components/common-components";
import { couponsTableHeadCellNames } from "./source";
import Api from "src/api";
import { UserCouponFullInfo } from "src/api/user-coupons/types";
import { TableBody } from "./table";
import { useSnackbar } from "notistack";
import { Meta, Pagination } from "src/types/api";

interface Props {
  id: number;
  onClose(): void;
}

function Coupon({ id, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState<UserCouponFullInfo[]>([]);
  const [couponsMeta, setCouponsMeta] = useState<Meta | null>(null);

  const getUserCoupons = useCallback(
    async (paginationParams?: Pagination) => {
      try {
        const response = await Api.userCoupons.getUserPurchasedCoupons(id, paginationParams);
        if (response.data) {
          setCoupons(response.data);
        } else {
          onClose();
          enqueueSnackbar(response.message, { variant: "error" });
        }
        if (response.meta) setCouponsMeta(response.meta);
        setLoading(false);
      } catch (error) {
        throw new Error(error as string);
      }
    },
    [id]
  );

  useEffect(() => {
    getUserCoupons({ perPage: 10 });
  }, [getUserCoupons]);

  return (
    <Box sx={{ px: 15, py: 2 }}>
      <Paper elevation={12} sx={{ borderRadius: "10px", overflow: "hidden" }}>
        <WrapWithLoading loading={loading}>
          <WrapWithNoResult length={coupons.length}>
            <WrapWithPagination
              pagination_meta={couponsMeta?.pagination}
              onPageChange={page => getUserCoupons({ page, perPage: 10 })}
            >
              <Table>
                <TableHead
                  tableCellClassName="subTableCells"
                  cellNames={couponsTableHeadCellNames}
                  tableCellSx={{ bgcolor: "#5ba3c9" }}
                />
                <TableBody coupons={coupons} refetch={() => getUserCoupons({ perPage: 10 })} />
              </Table>
            </WrapWithPagination>
          </WrapWithNoResult>
        </WrapWithLoading>
      </Paper>
    </Box>
  );
}

export default memo(Coupon);
