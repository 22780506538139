export const tableHeadCellNames = [
  { id: 0, name_key: "" },
  { id: 1, name_key: "name" },
  { id: 2, name_key: "email" },
  { id: 3, name_key: "telephone" },
  { id: 4, name_key: "balance" },
  { id: 5, name_key: "more_info" },
  { id: 6, name_key: "date" },
  { id: 7, name_key: "action" }
];

export const subscriberContactOptions = [
  {
    label: "email",
    value: "email"
  },
  {
    label: "phone",
    value: "phone"
  }
];
