import { memo } from "react";
// components
import { TableBody as MuiTableBody, TableCell, TableRow } from "@mui/material";
import { Actions } from "src/components/common-components";
// types
import { Category } from "src/api/settings";
import ImageCell from "src/components/common-components/custom/table/ImageCell";

interface Props {
  categories: Category[];
  onEdit(id: number): void;
  onDelete(id: number): void;
}

function TableBody({ categories, onDelete, onEdit }: Props) {
  return (
    <MuiTableBody>
      {categories.map(category => (
        <TableRow key={category.id}>
          <TableCell>{category.title_am ?? "-"}</TableCell>
          <TableCell>{category.title_ru ?? "-"}</TableCell>
          <TableCell>{category.title_en ?? "-"}</TableCell>
          <TableCell>{category.parent_title ?? "-"}</TableCell>
          {/* handle images cell */}
          <ImageCell image={category.icon} alt="category_icon" />
          <TableCell>{category.order ?? "-"}</TableCell>
          <TableCell>
            <Actions actions={["edit", "remove"]} onEdit={() => onEdit(category.id)} onDelete={() => onDelete(category.id)} />
          </TableCell>
        </TableRow>
      ))}
    </MuiTableBody>
  );
}

export default memo(TableBody);
