import { Box, Table } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Api from "src/api";
import { Transaction } from "src/api/transactions";
import {
  TableHead,
  TableWrapper,
  WrapWithFilter,
  WrapWithLoading,
  WrapWithNoResult,
  WrapWithPagination
} from "src/components/common-components";
import { TransactionFilterProps } from "src/components/common-components/filter/transactions";
import { Meta, Pagination } from "src/types/api";
//source
import { tableHeadCellNames } from "./source";
import { TableBody } from "./table";

export default function Transactions() {
  const [loading, setLoading] = useState(true);
  const [filtering, setFiltering] = useState(false);
  const [transactionsList, setTransactionsList] = useState<Transaction[]>([]);
  const [transactionsMeta, setTransactionsMeta] = useState<Meta | null>(null);

  const getTransactionsList = useCallback(async (paginationParams?: Pagination) => {
    try {
      const response = await Api.transactions.getTransactionsList({ paginationParams });
      setLoading(false);
      if (response) {
        if (response.data) setTransactionsList(response.data);
        if (response.meta) setTransactionsMeta(response.meta);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const filter = useCallback(async (filter_body: TransactionFilterProps) => {
    try {
      setFiltering(true);
      const response = await Api.transactions.getTransactionsList({ filter_body, paginationParams: { perPage: 50 } });
      setFiltering(false);
      if (response) {
        if (response.data) setTransactionsList(response.data);
        if (response.meta) setTransactionsMeta(response.meta);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  useEffect(() => {
    getTransactionsList({ perPage: 50 });
  }, [getTransactionsList]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
      <Box sx={{ width: { xs: "80%" } }}>
        <WrapWithFilter filter_topic="transactions" onFilter={filter} filtering={filtering} filter_height={140}>
          <TableWrapper>
            <WrapWithLoading loading={loading || filtering}>
              <WrapWithNoResult length={transactionsList.length}>
                <WrapWithPagination
                  pagination_meta={transactionsMeta?.pagination}
                  onPageChange={page => getTransactionsList({ page, perPage: 50 })}
                >
                  <Table size="small">
                    <TableHead cellNames={tableHeadCellNames} />
                    <TableBody transactions={transactionsList} />
                  </Table>
                </WrapWithPagination>
              </WrapWithNoResult>
            </WrapWithLoading>
          </TableWrapper>
        </WrapWithFilter>
      </Box>
    </Box>
  );
}
