import { memo } from "react";
// components
import { Chip, TableCell } from "@mui/material";
// icons
import TodayIcon from "@mui/icons-material/Today";
import moment from "moment";

type Props = { date: string };

function DateCell({ date }: Props) {
  return (
    <TableCell>
      {
        <Chip
          sx={{ minWidth: "80%", bgcolor: "transparent" }}
          label={
            moment(new Date(date), "YYYY-MM-DD HH:mm:ss", true).isValid() ? moment(new Date(date)).format("DD-MM-YYYY") : "-"
          }
          icon={<TodayIcon />}
        />
      }
    </TableCell>
  );
}

export default memo(DateCell);
