import Api from "src/api";
import { useSnackbar } from "notistack";
import { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
// components
import { Grid, Paper, TextField, TextFieldProps } from "@mui/material";
import { LinearLoading, Select } from "src/components/common-components";
import UploadImage from "./upload-image";
import CustomCheckbox from "src/components/common-components/control/CustomCheckbox";
// source
import { CreateBanner } from "src/api/settings";
import { useTranslation } from "react-i18next";
import { BannersContext } from "src/providers/context/BannersContext";
import { checkIsOS, checkIsPS } from "src/providers/lib/banners";
import { SelectOption } from "src/types/components";

interface FieldProps {
  field_key: keyof CreateBanner;
}

/** Attention ... Read => "POOS" in code is abbreviated that means => Partners or Offers section */

const Field = ({ field_key, ...props }: FieldProps & TextFieldProps) => {
  const { t } = useTranslation();
  const { getFieldProps, errors } = useFormikContext<CreateBanner>();
  return (
    <TextField
      fullWidth
      {...getFieldProps(field_key)}
      label={t(field_key)}
      error={Boolean(errors[field_key])}
      helperText={errors[field_key]}
      {...props}
    />
  );
};

function UpsertForm() {
  const { positionTypes } = useContext(BannersContext);
  const { enqueueSnackbar } = useSnackbar();
  const { values } = useFormikContext<CreateBanner>();

  const [categories, setCategories] = useState<SelectOption[]>([]);
  const [businessTypes, setBusinessTypes] = useState<SelectOption[]>([]);

  const getCategoriesList = useCallback(async () => {
    try {
      const [categories, businessTypes] = await Promise.all([
        Api.independent.getCategories(),
        Api.independent.getBusinessTypes()
      ]);
      if (categories.data) {
        const categoriesForSelect = categories.data.map(category => ({ label: category.title, value: category.id }));
        setCategories(categoriesForSelect);
      } else enqueueSnackbar(categories.message, { variant: "error" });
      if (businessTypes.data) {
        const businessTypesForSelect = businessTypes.data.map(businessType => ({
          label: businessType.title,
          value: businessType.id
        }));
        setBusinessTypes(businessTypesForSelect);
      } else enqueueSnackbar(businessTypes.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const isCategoriesLoading = !categories.length;
  const isBusinessTypesLoading = !businessTypes.length;

  useEffect(() => {
    getCategoriesList();
  }, [getCategoriesList]);

  const isForPS = checkIsPS(values.position);
  const isForOS = checkIsOS(values.position);
  const isSideBannerForPOOS = values.position === "offer_section_sides" || values.position === "partners_section_sides";
  const isInnerBannerForPOOS = values.position === "offer_section_inner" || values.position === "partners_section_inner";
  const isBannerDividerForPOOS = values.position === "offer_section_divider" || values.position === "partners_section_divider";

  const positionTypesForSelect = useMemo(() => positionTypes.map(type => ({ label: type, value: type })), [positionTypes]);

  return (
    <Grid container rowGap={2} columnSpacing={2}>
      <Grid item xs={6} className="flex-center">
        <Paper elevation={5} sx={{ width: "100%", height: "100%", pt: 1, overflow: "hidden" }}>
          <UploadImage field_key="image" />
        </Paper>
      </Grid>
      <Grid item xs={6} className="flex-center">
        <Paper elevation={5} sx={{ width: "100%", height: "100%", pt: 1, overflow: "hidden" }}>
          <UploadImage field_key="mobile_image" />
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Select options={positionTypesForSelect} field_key="position" />
      </Grid>
      <Grid item xs={6}>
        <Select
          options={[
            { label: "in_new_tab", value: "blank" },
            { label: "in_current_tab", value: "inherit" }
          ]}
          field_key="target"
        />
      </Grid>
      {isForPS && (
        <Grid item xs={6}>
          {isBusinessTypesLoading ? <LinearLoading /> : <Select options={businessTypes} field_key="business_types" multiple />}
        </Grid>
      )}
      {isForOS && (
        <Grid item xs={6}>
          {isCategoriesLoading ? <LinearLoading /> : <Select options={categories} field_key="categories" multiple />}
        </Grid>
      )}
      {isSideBannerForPOOS && (
        <Grid item xs={6}>
          <Select options={[{ label: "in_left", value: "left_side" }]} field_key="show_banner" />
        </Grid>
      )}
      {isInnerBannerForPOOS && (
        <Grid item xs={6}>
          <Field field_key="banner_idx" />
        </Grid>
      )}
      {isBannerDividerForPOOS && (
        <Grid item xs={6}>
          <Field field_key="order" />
        </Grid>
      )}
      <Grid item xs={12}>
        <Field field_key="name" />
      </Grid>
      <Grid item xs={12}>
        <Field field_key="description" />
      </Grid>
      <Grid item xs={12}>
        <Field field_key="external_url" />
      </Grid>
      <Grid item xs={12}>
        <CustomCheckbox field_key="visible" />
      </Grid>
    </Grid>
  );
}

export default memo(UpsertForm);
