import { useContext } from "react";
import { DashboardContext } from "src/providers/context";
// components
import WarningAlert from "./warning-alert";
import { Skeleton, Stack } from "@mui/material";

export default function Warnings() {
  const { data } = useContext(DashboardContext);

  return (
    <Stack spacing={1}>
      {data ? (
        <>
          <WarningAlert
            path="/coupons?status=admin_review"
            content="coupons_admin_review_warning"
            count={data?.coupons.find(({ status }) => status === "admin_review")?.count}
          />
          <WarningAlert
            path="/coupons?status=company_review"
            content="coupons_company_review_warning"
            count={data?.coupons.find(({ status }) => status === "company_review")?.count}
          />
          <WarningAlert
            path="/partners?status=in_moderation"
            content="partners_in_moderation_warning"
            count={data?.partners.find(({ status }) => status === "in_moderation")?.count}
          />
        </>
      ) : (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      )}
    </Stack>
  );
}
