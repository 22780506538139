import ApiSlice from "../slice";
import queryString from "query-string";
// source
import {
  Categories,
  Operators,
  Managers,
  CompanyRegistrant,
  FilterParams,
  PaymentMethod,
  PostedCoupon,
  PurchasedCoupon,
  CompanySales,
  OfferSales,
  CompanyPositioning
} from "./types";

export default class Statistics extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin/statistic`;
  static defaultAuth = true;

  static getPurchasedCoupons = (filterOptions: FilterParams) =>
    this.request<PurchasedCoupon[]>(`/coupons/purchased?${queryString.stringify(filterOptions)}`);

  static getPostedCoupons = (filterOptions: FilterParams) =>
    this.request<PostedCoupon[]>(`/coupons?${queryString.stringify(filterOptions)}`);

  static getCompanyRegistrations = (filterOptions: FilterParams) =>
    this.request<CompanyRegistrant[]>(`/companies?${queryString.stringify(filterOptions)}`);

  static getSubscriberRegistrations = (filterOptions: FilterParams) =>
    this.request<CompanyRegistrant[]>(`/subscribers?${queryString.stringify(filterOptions)}`);

  static getPaymentMethods = (filterOptions: FilterParams) =>
    this.request<PaymentMethod[]>(`/payment/methods?${queryString.stringify(filterOptions)}`);

  static getCategories = (filterOptions: FilterParams) =>
    this.request<Categories[]>(`/categories?${queryString.stringify(filterOptions)}`);

  static getOperators = (filterOptions: FilterParams) =>
    this.request<Operators[]>(`/operators?${queryString.stringify(filterOptions)}`);

  static getCompanySales = (filterOptions: FilterParams) =>
    this.request<{ count: number; sum: number; data: CompanySales[] }>(
      `/company-coupons?${queryString.stringify(filterOptions)}`
    );
  static getOfferSales = (filterOptions: FilterParams) =>
    this.request<{ count: number; sum: number; data: OfferSales[] }>(`/offers?${queryString.stringify(filterOptions)}`);

  static getCompanyPositioning = (filterOptions: FilterParams) =>
    this.request<{ count: number; sum: number; data: CompanyPositioning[] }>(
      `/company-positioning?${queryString.stringify(filterOptions)}`
    );

  static getCompanyMembership = (filterOptions: FilterParams) =>
    this.request<{ count: number; sum: number; data: CompanyPositioning[] }>(
      `/company-membership?${queryString.stringify(filterOptions)}`
    );

  static getCouponPositioning = (filterOptions: FilterParams) =>
    this.request<{ count: number; sum: number; data: CompanyPositioning[] }>(
      `/coupon-positioning?${queryString.stringify(filterOptions)}`
    );

  static getManagers = (filterOptions: FilterParams) =>
    this.request<Managers[]>(`/managers?${queryString.stringify(filterOptions)}`);
}

export * from "./types";
