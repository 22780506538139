/* eslint-disable @typescript-eslint/no-unused-vars */
import Api from "src/api";
import {
  Box,
  Checkbox,
  Chip,
  Fade,
  FormControlLabel,
  Grid,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { ChangeEvent, memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TableHead, WrapWithLoading, WrapWithNoResult } from "src/components/common-components";
import { permissionsTableHeadCellNames } from "../../source";
// types
import { Permission, PermissionTitle, UserPermission } from "src/types/api";
// icon
import WarningIcon from "@mui/icons-material/Warning";

export type TablePermissionsType = { permissionName: Permission; permissions: UserPermission[] };

interface Props {
  permissions: number[];
  setField(key: string, value: number[]): void;
  isError: boolean;
}

function Permissions({ permissions: formik_permissions, setField, isError }: Props) {
  const { t } = useTranslation();

  const [permissions, setPermissions] = useState<TablePermissionsType[]>([]);
  const [unFilteredPermissions, setUnFilteredPermissions] = useState<UserPermission[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(true);

  const generatePermissionsArray = useCallback((array: UserPermission[]) => {
    const permissionGroups: Permission[] = Array.from(new Set(array.map(array => array.group)));
    return permissionGroups.map(permissionName => ({
      permissionName,
      permissions: array.filter(unSortedPermission => permissionName === unSortedPermission.group)
    }));
  }, []);

  const formik = useFormik({
    initialValues: Object.fromEntries(
      unFilteredPermissions.map(permission => {
        if (formik_permissions.length) {
          return [String(permission.id), formik_permissions.includes(permission.id)];
        } else return [String(permission.id), false];
      })
    ),
    onSubmit: () => {
      /** component submit calls on his parent */
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    enableReinitialize: true
  });

  const { values, setValues, setFieldValue } = formik;

  const getPermissions = useCallback(async () => {
    try {
      const response = await Api.users.getAdminPermissions();
      setLoading(false);
      if (response?.data) {
        const array = generatePermissionsArray(response.data);
        setPermissions(array);
        setUnFilteredPermissions(response.data);
        if (response.data.length === formik_permissions.length) setSelectAll(true);
        setLoading(false);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectAll(e.target.checked);
    setValues(Object.fromEntries(unFilteredPermissions.map(permission => [String(permission.id), e.target.checked])));
  };

  const handleChange = useCallback(
    (inputName: string, checked: boolean, title: PermissionTitle, group: Permission) => {
      setFieldValue(inputName, checked);
      const currentGroup = permissions.filter(permission => permission.permissionName === group)[0];
      if (!checked && title === "read") {
        currentGroup.permissions.forEach(permission => {
          if (permission.title !== "read") {
            setFieldValue(String(permission.id), false);
          }
        });
      }
      if (checked && title !== "read") {
        currentGroup.permissions.forEach(permission => {
          if (permission.title === "read") {
            setFieldValue(String(permission.id), true);
          }
        });
      }
    },
    [permissions]
  );

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  useEffect(() => {
    setField("permissions", [
      ...Object.entries(values)
        .filter(([_, value]) => value)
        .map(([key, _]) => Number(key))
    ]);
  }, [values]);

  return (
    <FormikProvider value={formik}>
      <WrapWithLoading loading={loading}>
        <Fade in={Boolean(Object.values(values).length)} timeout={700}>
          <Paper sx={{ width: "100%", overflowY: "auto" }}>
            <Grid container justifyContent="space-between" alignItems="center" pt={1} pb={2} px={2}>
              <Grid item>
                <Grid container columnGap={4}>
                  <Typography variant="h5">{t("permissions")}</Typography>
                  <Slide in={isError} timeout={400} direction="right">
                    <Chip
                      label={t("permissions_is_required")}
                      icon={<WarningIcon />}
                      sx={{ color: "error.main", px: 2, fontSize: "18px" }}
                    />
                  </Slide>
                </Grid>
              </Grid>
              <Grid item>
                <FormControlLabel label={t("select_all")} control={<Checkbox checked={selectAll} onChange={handleSelectAll} />} />
              </Grid>
            </Grid>
            <WrapWithNoResult length={Object.values(values).length}>
              <TableContainer sx={{ maxHeight: "63vh" }}>
                <Table stickyHeader sx={{ borderCollapse: "collapse" }}>
                  <TableHead tableCellSx={{ bgcolor: "primary.main" }} cellNames={permissionsTableHeadCellNames} />
                  <TableBody>
                    {permissions.map((permission, index) => (
                      <TableRow
                        key={`permission_array_key_${index}_row`}
                        sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        <TableCell sx={{ p: 0, border: "1px solid rgba(224, 224, 224, 1)" }}>
                          {permission.permissionName}
                        </TableCell>
                        {permission.permissions.map(currentPermission => (
                          <TableCell
                            sx={{ p: 0, border: "1px solid rgba(224, 224, 224, 1)" }}
                            key={`permission_key_${currentPermission.id}_cell`}
                          >
                            <Checkbox
                              name={String(currentPermission.id)}
                              checked={values[String(currentPermission.id)]}
                              onChange={(_, checked) =>
                                handleChange(
                                  String(currentPermission.id),
                                  checked,
                                  currentPermission.title,
                                  currentPermission.group
                                )
                              }
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </WrapWithNoResult>
          </Paper>
        </Fade>
      </WrapWithLoading>
    </FormikProvider>
  );
}

export default memo(Permissions);
