import Api from "src/api";
// hooks
import { useCallback, useState } from "react";
import { FormikErrors, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
// components
import { FieldsWrapper, Submit } from "src/components/common-components";
import UpsertForm from "./upsert-form";
// source
import { CreateBanner } from "src/api/settings";
import { AddModalProps } from "src/types/components";
import { createFormDataWithImage } from "src/providers/lib";
import { checkIsPOOS } from "src/providers/lib/banners";

export default function Add({ refetch, closeModal }: AddModalProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const createBanner = useCallback(async (values: FormData) => {
    try {
      setLoading(true);
      const response = await Api.settings.banners.create(values);
      if (response.data) {
        closeModal();
        refetch();
        enqueueSnackbar(t("banner_created", { id: response.data.id }), { variant: "success" });
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        if (response.errors) setErrors(response.errors as FormikErrors<CreateBanner>);
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, []);

  const formik = useFormik<CreateBanner>({
    initialValues: {
      name: "",
      description: "",
      position: "",
      visible: 0,
      external_url: "",
      target: "blank",
      image: "",
      mobile_image: "",
      banner_idx: "",
      order: "",
      categories: [],
      show_banner: "",
      business_types: []
    },
    onSubmit(values) {
      const compressedValues = {
        name: values.name,
        description: values.description,
        position: values.position,
        visible: values.visible,
        external_url: values.external_url,
        target: values.target,
        image: values.image,
        mobile_image: values.mobile_image,
        ...(checkIsPOOS(values.position) && {
          data: {
            banner_idx: values.banner_idx,
            order: values.order,
            categories: values.categories,
            show_banner: values.show_banner,
            business_types: values.business_types
          }
        })
      };
      const formData = createFormDataWithImage(compressedValues);
      createBanner(formData);
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false
  });

  const { handleSubmit, setErrors } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <FieldsWrapper>
          <UpsertForm />
          <Submit title="add" loading={loading} />
        </FieldsWrapper>
      </FormikProvider>
    </form>
  );
}
