import { memo } from "react";
// components
import { TableCell } from "@mui/material";
//

type Props = {
  image: string;
  alt: string;
};

function ImageCell({ image, alt }: Props) {
  return (
    <TableCell>
      <img src={image ?? "/images/fallback.jpeg"} alt={alt} width={image ? 40 : 40} style={{ objectFit: "cover" }} />
    </TableCell>
  );
}

export default memo(ImageCell);
