// react
import { ReactNode } from "react";
// components
import { Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { NotFound, SignIn } from "./pages";
import { Protected } from "./components/common-components";
// source
import routes from "./routes";

const ProtectedRouteWithLayout = ({ children }: { children: ReactNode }) => (
  <Protected>
    <MainLayout>{children}</MainLayout>
  </Protected>
);

function App() {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      {routes.map(({ path, Component, permission }, key) => (
        <Route
          key={`page_${key}`}
          path={path}
          element={(() => {
            switch (permission) {
              case "protected":
                return (
                  <ProtectedRouteWithLayout>
                    <Component />
                  </ProtectedRouteWithLayout>
                );
              case "unprotected":
                return <Component />;
              default:
                return <Component />;
            }
          })()}
        />
      ))}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
