import Api from "src/api";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikErrors, FormikProvider, useFormik, useFormikContext } from "formik";
// components
import { Button, Grid, TextField, TextFieldProps, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { Submit } from "src/components/common-components";
import { CompanyAddBalance } from "src/api/companies";

interface Props {
  id: number;
  closeModal(): void;
  refetch(): void;
}

interface FieldProps {
  field_key: keyof CompanyAddBalance;
}

function Field({ field_key, ...props }: FieldProps & TextFieldProps) {
  const { errors, getFieldProps } = useFormikContext<CompanyAddBalance>();
  const { t } = useTranslation();

  return (
    <TextField
      fullWidth
      label={t(field_key)}
      {...getFieldProps(field_key)}
      {...props}
      error={Boolean(errors[field_key])}
      helperText={errors[field_key]}
    />
  );
}

export default function AddBalance({ id, closeModal, refetch }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const handleAdd = useCallback(async (body: CompanyAddBalance) => {
    try {
      setLoading(true);
      const response = await Api.companies.addBalance(id, body);
      if (response.status === "success") {
        enqueueSnackbar(t("company_balance_updated", { id }), { variant: "success" });
        closeModal();
        refetch();
      } else {
        setErrors(response.errors as FormikErrors<CompanyAddBalance>);
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, []);

  const formik = useFormik<CompanyAddBalance>({
    initialValues: {
      message: "",
      amount: ""
    },
    onSubmit(values) {
      handleAdd(values);
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false
  });

  const { handleSubmit, setErrors } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <Grid container rowSpacing={4} p={2} sx={{ maxWidth: "30vw" }}>
          <Grid item xs={12}>
            <Typography variant="h5">{t("add_company_balance")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Field fullWidth type="number" field_key="amount" defaultValue={null} />
          </Grid>
          <Grid item xs={12}>
            <Field fullWidth field_key="message" />
          </Grid>
          <Grid item xs={12}>
            <Grid container columnSpacing={2}>
              <Grid item xs={6}>
                <Submit title={t("update")} loading={loading} />
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth onClick={closeModal}>
                  {t("cancel")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormikProvider>
    </form>
  );
}
