import { Stack } from "@mui/material";
import { FormikErrors, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "src/api";
import { Submit } from "src/components/common-components/control";
import { AddPosition as AddPositionType } from "src/types/api/positions";
import WrapForms from "../common/wrap-forms";
import UpsertForm from "../form";

type Props = {
  closeAddModal: () => void;
  id: number;
  place: "coupon" | "company";
  refetch: () => void;
};

export default function AddPosition({ closeAddModal, refetch, place, id }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const createPosition = useCallback(
    async (body: AddPositionType) => {
      try {
        setLoading(true);
        const response = await Api[place === "coupon" ? "coupons" : "companies"].createPosition(id, body);
        if (response.data) {
          closeAddModal();
          enqueueSnackbar(t("success"), { variant: "success" });
          refetch();
        } else {
          setErrors(response.errors as FormikErrors<AddPositionType>);
          enqueueSnackbar(response.message, { variant: "error" });
        }
      } catch (error) {
        throw new Error(error as string);
      } finally {
        setLoading(false);
      }
    },
    [id]
  );

  const formik = useFormik<AddPositionType>({
    initialValues: {
      type: "",
      amount: "",
      expire_at: "",
      position: ""
    },
    onSubmit: values => {
      createPosition(values);
    }
  });

  const { handleSubmit, setErrors } = formik;

  return (
    <WrapForms title="add_position">
      <form onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Stack spacing={2}>
            <UpsertForm mode="add" place={place} />
            <Submit title="add" loading={loading} />
          </Stack>
        </FormikProvider>
      </form>
    </WrapForms>
  );
}
