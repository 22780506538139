import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { Meta, ResponseModel } from "src/types/api";

interface RequestOptions {
  onError?<T>(response?: ResponseModel<T>): void;
  onSuccess?<T>(response?: ResponseModel<T>): void;
  finally?(): void;
}

export function useRequest<T, P>(request: (params?: P) => Promise<ResponseModel<T>>, options?: RequestOptions) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<T | null>(null);
  const [meta, setMeta] = useState<Meta | null>(null);

  const tryRequest = useCallback(async () => {
    try {
      const response = await request();
      if (response.data) {
        setLoading(false);
        setData(response.data);
        if (response.meta) setMeta(response.meta);
        if (options?.onSuccess) options?.onSuccess<T>(response);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        if (options?.onError) options?.onError<T>(response);
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      if (options?.finally) options?.finally();
    }
  }, []);

  useEffect(() => {
    tryRequest();
  }, [tryRequest]);

  return { loading, data, meta };
}
