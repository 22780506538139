import { memo } from "react";
// components
import { Alert } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  path: string;
  content: string;
  count?: number;
}

function WarningAlert({ path, content, count }: Props) {
  const { t } = useTranslation();

  return (
    <>
      {Boolean(count) && (
        <Link to={path} style={{ textDecoration: "none" }}>
          <Alert severity="error">{t(content, { count })}</Alert>
        </Link>
      )}
    </>
  );
}

export default memo(WarningAlert);
