import { memo } from "react";
import { useFormikContext } from "formik";
// components
import { TextField, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  field_key: string;
}

function Field({ field_key, ...props }: Props & TextFieldProps) {
  const { getFieldProps } = useFormikContext();
  const { t } = useTranslation();
  return <TextField fullWidth {...getFieldProps(field_key)} label={t(field_key)} {...props} />;
}

export default memo(Field);
