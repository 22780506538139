import { memo } from "react";
import { FormControl, FormHelperText, useTheme } from "@mui/material";
// components
import PhoneInput from "react-phone-input-2";
// source
import { useFormikContext } from "formik";
import "react-phone-input-2/lib/style.css";

interface Props {
  field_key: string;
}

const ReactPhoneField = ({ field_key }: Props) => {
  const { values, errors, setFieldValue, submitForm } = useFormikContext<{ [key: string]: unknown }>();
  const theme = useTheme();

  return (
    <FormControl fullWidth error={Boolean(errors[field_key])} sx={{ height: "100%" }}>
      <PhoneInput
        inputStyle={{
          width: "100%",
          fontSize: 16,
          height: "100%",
          lineHeight: "19px",
          padding: "16.5px 32px 16.5px 48px",
          border: "unset",
          color: errors[field_key] ? theme.palette.error.main : theme.palette.common.black
        }}
        containerStyle={{ border: "1px solid rgba(0, 0, 0, 0.23)" }}
        buttonStyle={{ border: "unset" }}
        country={"am"}
        value={values[field_key] as string}
        onChange={(phone: string) => setFieldValue(field_key, phone)}
        dropdownStyle={{ maxHeight: "100px" }}
        onKeyDown={e => {
          if (e.code === "Enter") submitForm();
        }}
      />
      {errors[field_key] && <FormHelperText>{errors[field_key]}</FormHelperText>}
    </FormControl>
  );
};

export default memo(ReactPhoneField);
