import Api from "src/api";
import { memo, useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
// components
import { FormikProvider, useFormik } from "formik";
import UpsertForm from "../upsert-form";
import { Stack } from "@mui/material";
import { FieldsWrapper, Submit, WrapWithLoading } from "src/components/common-components";
// source
import { UpdateModalProps } from "src/types/components";
import { ContactPerson, ContactPersonForForm } from "src/api/companies";

interface Props extends UpdateModalProps {
  partnerId: number;
}

function UpdateContactPerson({ id, closeModal, refetch }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [contactPerson, setContactPerson] = useState<ContactPerson | null>(null);
  const [updating, setUpdating] = useState(false);

  const getContactPerson = useCallback(async () => {
    try {
      const response = await Api.companies.getContactPerson(id);
      if (response.data) {
        setLoading(false);
        setContactPerson(response.data);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const updateContactPerson = useCallback(async (values: ContactPersonForForm) => {
    try {
      setUpdating(true);
      const response = await Api.companies.updateContactPerson(id, values);
      if (response.data) {
        closeModal();
        refetch();
        enqueueSnackbar(t("contact_person_updated", { id: response.data.id }), { variant: "success" });
      } else enqueueSnackbar(response.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setUpdating(false);
    }
  }, []);

  useEffect(() => {
    getContactPerson();
  }, [getContactPerson]);

  const formik = useFormik<ContactPersonForForm>({
    initialValues: {
      name: contactPerson?.name ?? "",
      position: contactPerson?.position ?? "",
      email: contactPerson?.email ?? "",
      phone: contactPerson?.phone ?? "",
      notes: contactPerson?.notes ?? ""
    },
    onSubmit(values) {
      updateContactPerson(values);
    },
    enableReinitialize: true
  });

  const { handleSubmit } = formik;
  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <FieldsWrapper title="edit_contact_person">
          <WrapWithLoading loading={loading}>
            <Stack spacing={3}>
              <UpsertForm />
              <Submit loading={updating} title="edit" />
            </Stack>
          </WrapWithLoading>
        </FieldsWrapper>
      </FormikProvider>
    </form>
  );
}

export default memo(UpdateContactPerson);
