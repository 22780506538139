import { memo } from "react";
// components
import { TableBody as MuiTableBody, TableCell, TableRow } from "@mui/material";
import { Actions, PayCell, StatusCell } from "src/components/common-components";
// types
import { FinanceDealType } from "src/api/finances";

interface Props {
  finances: FinanceDealType[];
  onEdit(id: number): void;
  onDelete(id: number): void;
}

function TableBody({ finances, onDelete, onEdit }: Props) {
  return (
    <MuiTableBody>
      {finances.map(deal => (
        <TableRow key={deal.id}>
          <TableCell>{deal.operation ?? "-"}</TableCell>
          <TableCell>{deal.description ?? "-"}</TableCell>
          <StatusCell label={deal.type} status={deal.type === "income"} />
          <PayCell money={deal.type === "income" ? `+ ${deal.amount}` : `- ${deal.amount}`} />
          <TableCell>
            <Actions actions={["edit", "remove"]} onEdit={() => onEdit(deal.id)} onDelete={() => onDelete(deal.id)} />
          </TableCell>
        </TableRow>
      ))}
    </MuiTableBody>
  );
}

export default memo(TableBody);
