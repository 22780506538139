// hooks
import { useTranslation } from "react-i18next";
// components
import { Box, Button, Grid, Stack, StackProps, Typography } from "@mui/material";
// types
import { PropsWithChildren } from "react";

interface Props extends StackProps {
  title?: string;
  isSubTable?: boolean;
  withGutter?: boolean;
  handleAdd(): void;
}

export default function WrapWithAddButton({
  handleAdd,
  title,
  children,
  isSubTable = false,
  ...props
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();

  return (
    <Box className="flex-center" sx={{ my: { xs: 4 } }}>
      <Stack spacing={2} alignItems="center" {...props}>
        <Box sx={{ width: "100%" }}>
          <Grid container justifyContent={title ? "space-between" : "flex-end"} alignItems="center">
            {title && (
              <Grid item>
                <Typography variant={isSubTable ? "h5" : "h6"}>{t(title)}</Typography>
              </Grid>
            )}
            <Grid item>
              <Button onClick={handleAdd} sx={{ fontSize: isSubTable ? 12 : 14 }}>
                {t("add")}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "100%" }}>{children}</Box>
      </Stack>
    </Box>
  );
}
