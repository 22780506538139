import { generateQueryParamsForPagination } from "src/providers/lib";
import { Pagination } from "src/types/api";
import ApiSlice from "../slice";
import { UserCouponFullInfo } from "./types";

export default class UserCoupons extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin`;
  static defaultAuth = true;

  static getUserPurchasedCoupons = (id: number, paginationParams?: Pagination) =>
    this.request<UserCouponFullInfo[]>(
      `/purchased/coupons?filter[user]=${id}${generateQueryParamsForPagination(paginationParams, true)}`
    );

  static sendSmsForCoupon = (couponId: number) => this.request(`/purchased/coupons/sms/${couponId}`, "POST");
  static cancelCoupon = (couponId: number) => this.request(`/purchased/coupons/cancel/${couponId}`, "POST");
}
