import Api from "src/api";
import { memo, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikProvider, useFormik } from "formik";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
// components
import { FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import UploadImages from "./upload-images";
import UploadVideos from "./upload-videos";
import AddLinks from "./add-links";
import { LoadingButton } from "@mui/lab";
// source
import { createFormDataForUpload, generateMediaFilesForMainFormData } from "src/providers/lib/coupon";
import { ModalCloseContext } from "src/providers/context/ModalCloseProvider";
import { CouponMediaContext } from "src/providers/context";

export type FileType = "image" | "video" | "link";

export interface AddMedia {
  images_preview: File[];
  videos_preview: File[];
  links_preview: string[];
  type: FileType;
}

function Upload() {
  const { setMediasCache } = useContext(CouponMediaContext);

  const { t } = useTranslation();
  const { closeModal } = useContext(ModalCloseContext);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const handleUpload = useCallback(
    async (values: FormData) => {
      if (id) {
        try {
          setLoading(true);
          const response = await Api.coupons.uploadMedia(id as string, values);
          if (response.data) {
            if (setMediasCache) {
              setMediasCache(
                response.data?.map(media => ({
                  file: media.url,
                  type: media.type,
                  alt: media.alt,
                  id: media.id
                })) ?? []
              );
            }

            closeModal();
          } else enqueueSnackbar(response.message, { variant: "error" });
        } catch (error) {
          throw new Error(error as string);
        } finally {
          setLoading(false);
        }
      }
    },
    [id, setMediasCache]
  );

  const formik = useFormik<AddMedia>({
    initialValues: {
      images_preview: [],
      videos_preview: [],
      links_preview: [],
      type: "image"
    },
    onSubmit: values => {
      const valuesForMainForm = generateMediaFilesForMainFormData(values);
      handleUpload(createFormDataForUpload(valuesForMainForm));
    }
  });

  const { handleSubmit, values, getFieldProps } = formik;

  return (
    <Stack p={4} sx={{ width: "50vw" }} component="form" onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <Stack spacing={2}>
          <Typography variant="h4">{t("select_file_type")}</Typography>
          <RadioGroup
            aria-labelledby="controlled-radio-buttons-group-for-images"
            sx={{ flexDirection: "row" }}
            {...getFieldProps("type")}
          >
            <FormControlLabel value="image" control={<Radio />} label={t("image")} />
            <FormControlLabel value="video" control={<Radio />} label={t("video")} />
            <FormControlLabel value="link" control={<Radio />} label={t("link")} />
          </RadioGroup>
        </Stack>
        {(() => {
          switch (values.type) {
            case "image":
              return <UploadImages />;
            case "video":
              return <UploadVideos />;
            case "link":
              return <AddLinks />;
            default:
              return null;
          }
        })()}
        <LoadingButton loading={loading} type="submit">
          {t("add")}
        </LoadingButton>
      </FormikProvider>
    </Stack>
  );
}

export default memo(Upload);
