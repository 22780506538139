import Api from "src/api";
import { memo, useCallback, useState } from "react";
// components
import { ChartWrapper, Filter, LinearChart } from "../common";
// source
import { FilterParams } from "src/api/statistics";
import { format } from "date-fns";
import { WrapWithNoResult } from "src/components/common-components";

interface Props {
  height?: number;
}

function PostedCouponsStatistics({ height = 250 }: Props) {
  const [chartData, setChartData] = useState<{ x: string; y: number | string }[]>([]);

  const getPurchasedCoupons = useCallback(async (values: FilterParams) => {
    try {
      const response = await Api.statistics.getPostedCoupons({
        ...values,
        from: format(new Date(values.from), "yyyy-MM-dd"),
        to: format(new Date(values.to), "yyyy-MM-dd")
      });
      if (response.data) {
        setChartData(response.data.map(coupon => ({ x: coupon.name, y: coupon.value })));
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  return (
    <ChartWrapper>
      <Filter makeFilter={getPurchasedCoupons} />
      <WrapWithNoResult length={chartData.length}>
        <LinearChart height={height} title="coupons_statistics" label="posted_coupons" data={chartData} />
      </WrapWithNoResult>
    </ChartWrapper>
  );
}

export default memo(PostedCouponsStatistics);
