import Banners from "./banners";
import BusinessTypes from "./business-types";
import Categories from "./categories";
import FAQ from "./faq";
import Navigation from "./navigation";
import Regions from "./regions";
import Sections from "./sections";
import Texts from "./texts";

export default class Settings {
  static navigation = Navigation;
  static categories = Categories;
  static regions = Regions;
  static businessTypes = BusinessTypes;
  static banners = Banners;
  static sections = Sections;
  static faq = FAQ;
  static texts = Texts;
}

export * from "./navigation/types";
export * from "./categories/types";
export * from "./regions/types";
export * from "./business-types/types";
export * from "./banners/types";
export * from "./sections/types";
export * from "./faq/types";
export * from "./texts/types";
