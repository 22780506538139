import { ButtonGroup, IconButton } from "@mui/material";
import { memo } from "react";
// icons
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { useTranslation } from "react-i18next";

type Props = {
  onCopy: () => void;
  onPaste: () => void;
  disableCopy: boolean;
  copiedContentAvailable: boolean;
};

function CopyPasteActions({ onCopy, onPaste, disableCopy, copiedContentAvailable }: Props) {
  const { t } = useTranslation();
  return (
    <ButtonGroup>
      <IconButton onClick={onCopy} disabled={disableCopy} title={t("copy")}>
        <ContentCopyIcon />
      </IconButton>
      <IconButton onClick={onPaste} disabled={!copiedContentAvailable} title={t("paste")}>
        <ContentPasteIcon />
      </IconButton>
    </ButtonGroup>
  );
}

export default memo(CopyPasteActions);
