export const tableHeadCellNames = [
  { id: 0, name_key: "" },
  { id: 1, name_key: "title" },
  { id: 2, name_key: "company_name" },
  { id: 3, name_key: "category" },
  { id: 4, name_key: "price" },
  { id: 5, name_key: "created_at" },
  { id: 6, name_key: "expire_date" },
  { id: 7, name_key: "status" },
  { id: 8, name_key: "action" }
];
