import { memo, useState } from "react";
// components
import { Grid } from "@mui/material";
import { Editor } from "src/components/common-components";
import { ContentLanguages } from "src/types/components";
// source

function Content() {
  const [activeLanguage, setActiveLanguage] = useState<ContentLanguages>("am");

  return (
    <Grid container spacing={2}>
      <Grid item sx={{ transition: "0.5s" }} xs={12} xl={activeLanguage === "am" ? 8 : 2} onClick={() => setActiveLanguage("am")}>
        <Editor className="draft-by-me" field_key="content_am" />
      </Grid>
      <Grid item sx={{ transition: "0.5s" }} xs={12} xl={activeLanguage === "ru" ? 8 : 2} onClick={() => setActiveLanguage("ru")}>
        <Editor className="draft-by-me" field_key="content_ru" />
      </Grid>
      <Grid item sx={{ transition: "0.5s" }} xs={12} xl={activeLanguage === "en" ? 8 : 2} onClick={() => setActiveLanguage("en")}>
        <Editor className="draft-by-me" field_key="content_en" />
      </Grid>
    </Grid>
  );
}

export default memo(Content);
