import { TableBody as MuiTableBody, TableRow } from "@mui/material";
import { PurchasedCouponsSub, Status } from "src/api/coupons";
import { UserCell, EmailCell, PhoneCell, BalanceCell, DateCell } from "src/components/common-components";
import StatusCell from "src/pages/coupons/table/table-row/status-cell";

interface Props {
  coupons: PurchasedCouponsSub[];
}

export default function TableBody({ coupons }: Props) {
  return (
    <MuiTableBody>
      {coupons.map(({ user, price, status, created_at }) => (
        <TableRow key={`user_${user.id}`}>
          <UserCell label={user.full_name} />
          <EmailCell email={user.email ?? ""} />
          <PhoneCell label={user.phone ?? ""} />
          <BalanceCell label={price} />
          <StatusCell status={status as Status} />
          <DateCell date={created_at} />
        </TableRow>
      ))}
    </MuiTableBody>
  );
}
