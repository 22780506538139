import { Box, Grid } from "@mui/material";
import Categories from "./categories";
import CompanyRegistration from "./company-registration";
import CouponsPosted from "./coupons-posted";
import CouponsPurchased from "./coupons-purchased";
import PaymentMethods from "./payment-methods";
import SubscriberRegistration from "./subscriber-registration";
import Operators from "./operators";
import Managers from "./managers";
import CompanySales from "./company-sales";
import OfferSales from "./offer-sales";
import CompanyPositioning from "./company-positioning";
import CompanyMembership from "./company-membership";
import CouponPositioning from "./coupon-positioning";

export default function Statistics() {
  return (
    <Box className="flex-center">
      <Box sx={{ width: "90%", my: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} xl={6}>
            <CouponsPurchased />
          </Grid>
          <Grid item xs={12} xl={6}>
            <CouponsPosted />
          </Grid>
          <Grid item xs={12} xl={6}>
            <CompanyRegistration />
          </Grid>
          <Grid item xs={12} xl={6}>
            <PaymentMethods />
          </Grid>
          <Grid item xs={12} xl={6}>
            <SubscriberRegistration />
          </Grid>
          <Grid item xs={12} xl={6}>
            <Categories />
          </Grid>
          <Grid item xs={12} xl={6}>
            <Operators />
          </Grid>
          <Grid item xs={12} xl={6}>
            <Managers />
          </Grid>
          <Grid item xs={12} xl={12}>
            <CompanySales />
          </Grid>
          <Grid item xs={12} xl={12}>
            <OfferSales />
          </Grid>
          <Grid item xs={12} xl={4}>
            <CompanyPositioning />
          </Grid>
          <Grid item xs={12} xl={4}>
            <CompanyMembership />
          </Grid>
          <Grid item xs={12} xl={4}>
            <CouponPositioning />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
