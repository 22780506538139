import { Box, Divider, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

function NoResult() {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: "100%" }}>
      <Divider />
      <Box sx={{ py: 3 }}>
        <Typography sx={{ textAlign: "center", maxWidth: "100% !important" }}>{t("no_result")}</Typography>
      </Box>
    </Box>
  );
}

export default memo(NoResult);
