import Api from "src/api";
import { memo, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
// components
import { Collapse, IconButton, TableCell, TableRow as MuiTableRow, Typography } from "@mui/material";
import { Actions, BalanceCell, DateCell, StatusCell } from "src/components/common-components";
import ContactPersons from "./contact-persons";
import { BaseModal, WrapWithConfirmationButtons } from "src/components/modal";
// types
import { Company } from "src/api/companies";
import { CompanyActionsContext } from "src/providers/context";
// icons
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AssignManager from "../actions/assign-manager";
import ChangePassword from "../actions/change-password";
import ChangePosition from "../actions/change-position";
import AssignPlan from "../actions/assign-plan";
interface Props {
  company: Company;
}

const isDevelopment = process.env.NODE_ENV === "development";

function TableRow({ company }: Props) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [openAssignManagerModal, setOpenAssignManagerModal] = useState(false);
  const [openAssignPlanModal, setOpenAssignPlanModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState<{ open: boolean; for: "approve" | "block" | "cancel_top" | null }>({
    open: false,
    for: null
  });
  const [openPositionChangeModal, setOpenPositionChangeModal] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [contactPerson, setContactPerson] = useState<{ open: boolean; id?: number }>({ open: false });
  const { onAddBalance, refetchTable } = useContext(CompanyActionsContext);

  const handleContactView = useCallback(() => {
    setContactPerson(oldState => {
      if (!oldState.open) return { open: true, id: company.id };
      else return { open: false };
    });
  }, []);

  const handleOpenConfirmationModalForApprove = () => setConfirmationModal({ open: true, for: "approve" });
  const handleOpenConfirmationModalForBlock = () => setConfirmationModal({ open: true, for: "block" });
  const handleCloseConfirmationModal = () => setConfirmationModal({ open: false, for: null });
  const handleOpenAssignManagerModal = () => setOpenAssignManagerModal(true);
  const handleCloseAssignManagerModal = () => setOpenAssignManagerModal(false);
  const handleOpenAssignPlanModal = () => setOpenAssignPlanModal(true);
  const handleCloseAssignPlanModal = () => setOpenAssignPlanModal(false);
  const handleOpenPositionChangeModal = () => setOpenPositionChangeModal(true);
  const handleOpenChangePasswordModal = () => setOpenChangePasswordModal(true);
  const handleCloseChangePasswordModal = () => setOpenChangePasswordModal(false);
  const handleClosePositionChangeModal = () => setOpenPositionChangeModal(false);

  const handleConfirmCompanyUser = useCallback(async () => {
    if (refetchTable) {
      try {
        setLoading(true);
        const response = await Api.companies.approve(company.id);
        if (response.status === "success") {
          refetchTable();
          enqueueSnackbar(t("success"), { variant: "success" });
        } else enqueueSnackbar(response.message, { variant: "error" });
      } catch (error) {
        throw new Error(error as string);
      } finally {
        setLoading(false);
        handleCloseConfirmationModal();
      }
    }
  }, [company]);

  const handleBlockCompanyUser = useCallback(async () => {
    if (refetchTable) {
      try {
        setLoading(true);
        const response = await Api.companies.block(company.id);
        if (response.status === "success") {
          refetchTable();
          enqueueSnackbar(t("success"), { variant: "success" });
        } else enqueueSnackbar(response.message, { variant: "error" });
      } catch (error) {
        throw new Error(error as string);
      } finally {
        setLoading(false);
        handleCloseConfirmationModal();
      }
    }
  }, [company]);

  const handleForceSignIn = useCallback(async () => {
    if (refetchTable) {
      try {
        const response = await Api.companies.signIn(company.id);
        if (response.data) {
          window.open(
            isDevelopment
              ? `http://localhost:4000/auth/force-login?token=${response.data.auth.token}`
              : `${process.env.REACT_APP_PUBLIC_URL}/auth/force-login?token=${response.data.auth.token}`,
            "_blank"
          );
          enqueueSnackbar(t("success"), { variant: "success" });
        } else enqueueSnackbar(response.message, { variant: "error" });
      } catch (error) {
        throw new Error(error as string);
      }
    }
  }, [company]);

  return (
    <>
      <MuiTableRow>
        <TableCell>
          <IconButton onClick={handleContactView} title={t(contactPerson.open ? "hide_contact_persons" : "view_contact_persons")}>
            {contactPerson.open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              window.open(
                isDevelopment
                  ? `http://localhost:4000/company/${company.id}/main`
                  : `${process.env.REACT_APP_PUBLIC_URL}/company/${company.id}/main`,
                "_blank"
              );
            }}
          >
            {company.name}
          </Typography>
        </TableCell>

        <BalanceCell label={company.balance} />
        <StatusCell label={company.status} status={company.status === "active"} />
        <DateCell date={company.created_at} />

        <TableCell>
          <Actions
            position="center"
            actions={[
              "sign_in",
              "add_balance",
              "change_password",
              company.status === "in_moderation" || company.status === "blocked" ? "confirm" : null,
              company.status === "active" || company.status === "in_moderation" ? "block" : null,
              "view_contact_persons",
              "view",
              "assign_manager",
              "change_position",
              "view_company_coupons",
              "assign_plan"
            ]}
            onViewContactPersons={handleContactView}
            onAddBalance={() => onAddBalance(company.id)}
            onChangePassword={handleOpenChangePasswordModal}
            onConfirm={handleOpenConfirmationModalForApprove}
            onBlock={handleOpenConfirmationModalForBlock}
            onSignIn={handleForceSignIn}
            onView={() => navigate(`/partners/${company.id}`)}
            onAssignManager={handleOpenAssignManagerModal}
            onPositionChange={handleOpenPositionChangeModal}
            onAssignPlan={handleOpenAssignPlanModal}
            onCompanyCouponsView={() => window.open(`/coupons?company=${company.id}`, "_blank")}
          />
        </TableCell>
      </MuiTableRow>
      <MuiTableRow>
        <TableCell sx={{ py: 0 }} colSpan={9}>
          <Collapse in={Boolean(contactPerson.open)} timeout="auto" unmountOnExit>
            {contactPerson.id && <ContactPersons id={contactPerson.id} />}
          </Collapse>
        </TableCell>
        <WrapWithConfirmationButtons
          onYes={() => {
            switch (confirmationModal?.for) {
              case "approve":
                handleConfirmCompanyUser();
                break;
              case "block":
                handleBlockCompanyUser();
                break;
              default:
                return;
            }
          }}
          open={confirmationModal.open}
          onClose={handleCloseConfirmationModal}
          loading={loading}
        >
          <>
            {confirmationModal.for &&
              {
                approve: <Typography>{t("are_you_sure_to_approve_user")}</Typography>,
                block: <Typography>{t("are_you_sure_to_block_user")}</Typography>,
                cancel_top: <Typography>{t("are_you_sure_to_cancel_top")}</Typography>
              }[confirmationModal.for]}
          </>
        </WrapWithConfirmationButtons>
        <BaseModal open={openPositionChangeModal} onClose={handleClosePositionChangeModal}>
          <ChangePosition id={company.id} closeModal={handleClosePositionChangeModal} />
        </BaseModal>
        <BaseModal open={openChangePasswordModal} onClose={handleCloseChangePasswordModal}>
          <ChangePassword closeModal={handleCloseChangePasswordModal} id={company.id} username={company?.username} />
        </BaseModal>
        <BaseModal open={openAssignManagerModal} onClose={handleCloseAssignManagerModal}>
          <AssignManager closeModal={handleCloseAssignManagerModal} companyId={company.id} managerId={company.manager_id} />
        </BaseModal>
        <BaseModal open={openAssignPlanModal} onClose={handleCloseAssignPlanModal}>
          <AssignPlan company_id={company.id} />
        </BaseModal>
      </MuiTableRow>
    </>
  );
}

export default memo(TableRow);
