import { memo, useCallback, useState } from "react";
// components
import { Box, TableBody as MuiTableBody, TableCell, TableRow } from "@mui/material";
import { Actions, DateCell } from "src/components/common-components";
// types
import { CouponPositionInRequestBody } from "src/types/api/coupons";
import { CompanyPositionInRequestBody } from "src/types/api/companies";
import { BaseModal, DeleteModal } from "src/components/modal";
import { withModals, withModalsProps } from "src/components/hoc";
import EditPosition from "../upsert/edit";
import Api from "src/api";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

interface Props extends withModalsProps {
  place: "coupon" | "company";
  positions: CouponPositionInRequestBody[] | CompanyPositionInRequestBody[];
  companyId: number;
  refetch: () => void;
}

function TableBody({
  positions,
  mainModal,
  closeMainModal,
  deleteModal,
  openMainModalForUpdating,
  openDeleteModal,
  closeDeleteModal,
  refetch,
  companyId,
  place
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const removePosition = useCallback(async () => {
    try {
      setLoading(true);
      const response = await Api[place === "coupon" ? "coupons" : "companies"].deletePosition(deleteModal?.id);
      if (response.status === "success") {
        enqueueSnackbar(t("success"), { variant: "success" });
        refetch();
      } else enqueueSnackbar(response.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, [deleteModal?.id]);

  return (
    <MuiTableBody>
      {positions.map(position => (
        <TableRow key={position.id}>
          <TableCell>{position.type ? t(position.type) : "-"}</TableCell>
          <DateCell date={position.expire_at} />
          <TableCell>{position.position ?? "-"}</TableCell>
          {/* handle images cell */}
          <TableCell>
            <Actions
              actions={["edit", "remove"]}
              onEdit={() => openMainModalForUpdating(position.id)}
              onDelete={() => openDeleteModal(position.id)}
            />
          </TableCell>
        </TableRow>
      ))}

      <BaseModal open={Boolean(mainModal?.open)} onClose={closeMainModal}>
        <Box>
          {(() => {
            if (mainModal?.for) {
              switch (mainModal.for) {
                case "edit":
                  return (
                    mainModal?.id && (
                      <EditPosition
                        place={place}
                        companyId={companyId}
                        refetch={() => {
                          null;
                        }}
                        closeAddModal={closeMainModal}
                        id={mainModal.id}
                      />
                    )
                  );
                default:
                  return <p>Unknown form</p>;
              }
            }
          })()}
        </Box>
      </BaseModal>
      <DeleteModal loading={loading} open={Boolean(deleteModal?.open)} onDelete={removePosition} onClose={closeDeleteModal} />
    </MuiTableBody>
  );
}

export default memo(withModals(TableBody));
