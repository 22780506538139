import { memo, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
// components
import { Box, Button, CircularProgress, Grid, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Base from "../Base";
// types
import { BaseModalProps } from "src/types/components";

interface Props extends BaseModalProps {
  onYes: () => void;
  loading?: boolean;
}

function WrapWithConfirmationButtons({ open, loading = false, children, onClose, onYes }: PropsWithChildren<Props>) {
  const { t } = useTranslation();

  return (
    <Base open={open} onClose={onClose}>
      <Stack alignItems="center" py={{ xs: 4 }} px={{ xs: 4 }} spacing={{ xs: 5 }}>
        {children}
        <Box>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <LoadingButton
                loading={loading}
                onClick={onYes}
                loadingIndicator={<CircularProgress sx={{ color: "#FFF" }} size={16} />}
              >
                {t("yes")}
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <Button onClick={onClose}>{t("no")}</Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Base>
  );
}

export default memo(WrapWithConfirmationButtons);
