import { memo } from "react";
import { useTranslation } from "react-i18next";
// components
import { Chip, TableCell } from "@mui/material";

type Props = {
  status: boolean;
  label: string;
};

function StatusCell({ label }: Props) {
  const { t } = useTranslation();
  let color = "#00b9ff";

  if (label === "processed") {
    color = "#089d51";
  }
  if (label === "failed") {
    color = "#cf0404";
  }
  if (label === "canceled") {
    color = "#cbab0f";
  }

  return (
    <TableCell>
      <Chip label={t(label)} sx={{ p: 0.1, background: color, color: "#fff" }} />
    </TableCell>
  );
}

export default memo(StatusCell);
