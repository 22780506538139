import ApiSlice from "src/api/slice";
// types
import { Text, TextFromRequest, UpdateTextBody } from "./types";

export default class Texts extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin/texts`;
  static defaultAuth = true;

  static updateText = (values: UpdateTextBody) => this.request<Text>(``, "POST", values);
  static getList = () => this.request<TextFromRequest[]>(``);
}
