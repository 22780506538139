import Api from "src/api";
// hooks
import { useCallback, useState } from "react";
import { FormikErrors, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
// components
import { FieldsWrapper, Submit } from "src/components/common-components";
import UpsertForm from "./upsert-form";
// source
import { CreateBusinessType } from "src/api/settings";
import { AddModalProps } from "src/types/components";

export default function Add({ refetch, closeModal }: AddModalProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const createBusinessType = useCallback(async (values: CreateBusinessType) => {
    try {
      setLoading(true);
      const response = await Api.settings.businessTypes.create(values);
      if (response.data) {
        closeModal();
        refetch();
        enqueueSnackbar(t("business_type_created", { id: response.data.id }), { variant: "success" });
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        if (response.errors) setErrors(response.errors as FormikErrors<CreateBusinessType>);
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, []);

  const formik = useFormik<CreateBusinessType>({
    initialValues: {
      title_am: "",
      title_ru: "",
      title_en: ""
    },
    onSubmit(values) {
      createBusinessType(values);
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false
  });

  const { handleSubmit, setErrors } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <FieldsWrapper>
          <UpsertForm />
          <Submit title="add" loading={loading} />
        </FieldsWrapper>
      </FormikProvider>
    </form>
  );
}
