import Api from "src/api";
import { memo, useCallback, useState } from "react";
// components
import { ChartWrapper, ColumnChart, Filter } from "../common";
// source
import { FilterParams } from "src/api/statistics";
import { format } from "date-fns";
import { WrapWithNoResult } from "src/components/common-components";

function PurchasedCouponsStatistics() {
  const [chartSeries, setChartSeries] = useState<ApexAxisChartSeries>([
    { name: "amount", data: [] },
    { name: "count", data: [] }
  ]);
  const [chartCategories, setChartCategories] = useState<string[]>([]);

  const getPurchasedCoupons = useCallback(async (values: FilterParams) => {
    try {
      const response = await Api.statistics.getPurchasedCoupons({
        ...values,
        from: format(new Date(values.from), "yyyy-MM-dd"),
        to: format(new Date(values.to), "yyyy-MM-dd")
      });
      if (response.data) {
        setChartCategories(response.data.map(coupon => coupon.name));
        setChartSeries(prevState =>
          prevState.map(couponSeries => ({
            name: couponSeries.name,
            data: response.data
              ? response.data.map(coupon => {
                  if (couponSeries.name === "amount") return coupon[couponSeries.name];
                  return coupon[couponSeries.name as "count"] + coupon.amount * 0.4;
                })
              : []
          }))
        );
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  return (
    <ChartWrapper>
      <Filter makeFilter={getPurchasedCoupons} />
      <WrapWithNoResult length={chartSeries.length || chartCategories.length}>
        <ColumnChart
          title="purchased_coupons_statistics"
          data={chartSeries}
          categories={chartCategories}
          amounts={chartSeries[0].data}
        />
      </WrapWithNoResult>
    </ChartWrapper>
  );
}

export default memo(PurchasedCouponsStatistics);
