import Api from "src/api";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { useCallback, useState } from "react";
// components
import { Box, Button, CardActions, CardContent, Paper, Stack, Typography } from "@mui/material";
import { DeleteModal } from "src/components/modal";
// source
import { UpdateCoupon } from "../../source";
import { CreatePrice } from "../upsert/upsert-form";

interface Props {
  index: number;
  price: CreatePrice;
  onEdit(id: number): void;
}

export default function Card({ price, index, onEdit }: Props) {
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const isPriceExistOnBackEnd = price.id !== undefined && price.id !== null;

  const {
    values: { prices },
    setFieldValue
  } = useFormikContext<UpdateCoupon>();
  const { t } = useTranslation();

  const handleOpenDeleteModal = () => setDeleteModal(true);
  const handleCloseDeleteModal = () => setDeleteModal(false);

  const handleRemove = useCallback(() => {
    setFieldValue(
      "prices",
      prices.filter((_, i) => i !== index)
    );
    handleCloseDeleteModal();
  }, [prices, index]);

  const handleRemoveWithAjax = useCallback(async () => {
    if (price.id !== undefined && price.id !== null) {
      try {
        setDeleting(true);
        const response = await Api.coupons.deletePrice(price.id);
        if (response.status === "success") {
          enqueueSnackbar(t("success"), { variant: "success" });
          handleCloseDeleteModal();
          handleRemove();
        } else enqueueSnackbar(response.message, { variant: "error" });
      } catch (error) {
        throw new Error(error as string);
      } finally {
        setDeleting(false);
      }
    }
  }, [price, handleRemove]);

  return (
    <Paper elevation={5} sx={{ p: 1, height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <CardContent sx={{ height: "inherit" }}>
        <Stack spacing={3} justifyContent="space-between" sx={{ height: "100%" }}>
          <Box>
            <Stack spacing={1}>
              <Typography variant="h6">{price.title_am}</Typography>
              <Typography variant="body1">{price.description_am}</Typography>
            </Stack>
          </Box>
          <Box>
            <Stack>
              <Typography variant="caption">
                {t("price")} : {price.price}
              </Typography>
              <Typography variant="caption">
                {t("discount")} : {price.discount}
              </Typography>
              <Typography variant="caption">
                {t("coupon_price")} : {price.coupon_price}
              </Typography>
              <Typography variant="caption">
                {t("discounted_price")} : {price.discounted_price}
              </Typography>
              <Typography variant="caption">
                {t("available")} : {price.available}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button size="small" onClick={() => onEdit(index)}>
          {t("edit")}
        </Button>
        <Button
          onClick={handleOpenDeleteModal}
          size="small"
          sx={{
            textTransform: "none",
            backgroundColor: "error.main",
            color: "#FFF",
            border: "1px solid",
            borderColor: "error.main",
            transition: "0.5s",
            "&:hover": { backgroundColor: "#FFF", color: "error.main" }
          }}
        >
          {t("remove")}
        </Button>
      </CardActions>
      <DeleteModal
        open={deleteModal}
        onClose={handleCloseDeleteModal}
        onDelete={() => {
          if (isPriceExistOnBackEnd) handleRemoveWithAjax();
          else handleRemove();
        }}
        loading={deleting}
      />
    </Paper>
  );
}
