import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// locales
import main_page_rs_resources from "src/locales/rs/main.json";
import main_page_en_resources from "src/locales/en/main.json";
import main_page_hy_resources from "src/locales/hy/main.json";

const resources = {
  en: {
    translation: main_page_en_resources
  },
  rs: {
    translation: main_page_rs_resources
  },
  hy: {
    translation: main_page_hy_resources
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "rs",
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
