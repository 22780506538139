import Api from "src/api";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
// components
import { TableBody as MuiTableBody, TableCell, TableRow, Typography } from "@mui/material";
import { BalanceCell, CellWithImage, DateCell } from "src/components/common-components";
import ActionsGroup from "src/components/common-components/actions/ActionsGroup";
import StatusCell from "src/pages/coupons/table/table-row/status-cell";
// source
import { UserCouponFullInfo } from "src/api/user-coupons/types";
import { WrapWithConfirmationButtons } from "src/components/modal";
import { Status } from "src/api/coupons";
interface Props {
  coupons: UserCouponFullInfo[];
  refetch: () => void;
}

const isDevelopment = process.env.NODE_ENV === "development";

export default function TableBody({ coupons, refetch }: Props) {
  const [openConfirmationModal, setOpenConfirmationModal] = useState<{
    id: number | null;
    open: boolean;
    for: "sms" | "cancel" | "";
  }>({
    id: null,
    open: false,
    for: ""
  });
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenConfirmationModalForSms = (id: number) => setOpenConfirmationModal({ id, open: true, for: "sms" });
  const handleOpenConfirmationModalForCancel = (id: number) => setOpenConfirmationModal({ id, open: true, for: "cancel" });
  const handleCloseConfirmationModal = () => setOpenConfirmationModal({ id: null, open: false, for: "" });

  const sendSms = useCallback(async (id: number) => {
    try {
      setLoading(true);
      const response = await Api.userCoupons.sendSmsForCoupon(id);
      if (response.status === "success") {
        enqueueSnackbar(t("success"), { variant: "success" });
        handleCloseConfirmationModal();
        refetch();
      } else enqueueSnackbar(response.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, []);

  const cancel = useCallback(async (id: number) => {
    try {
      setLoading(true);
      const response = await Api.userCoupons.cancelCoupon(id);
      if (response.status === "success") {
        enqueueSnackbar(t("success"), { variant: "success" });
        handleCloseConfirmationModal();
        refetch();
      } else enqueueSnackbar(response.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <MuiTableBody sx={{ "& td": { p: 0.5 } }}>
      {coupons.map(userCoupon => (
        <TableRow key={userCoupon.id}>
          <CellWithImage text={userCoupon.coupon?.title} image={userCoupon.coupon?.thumbnail} />
          <BalanceCell label={userCoupon.price ?? ""} />
          <StatusCell status={userCoupon.status as Status} />
          <DateCell date={userCoupon.created_at} />
          <TableCell>
            <ActionsGroup
              actions={["view", "send_sms", userCoupon.pdf ? "download" : null, userCoupon.status === "active" ? "cancel" : null]}
              onView={() =>
                window.open(
                  isDevelopment
                    ? `http://localhost:4000/${userCoupon.coupon?.slug}`
                    : `${process.env.REACT_APP_PUBLIC_URL}/${userCoupon.coupon.slug}`,
                  "_blank"
                )
              }
              onDownload={() => window.open(userCoupon.pdf, "_blank")}
              onSmsSend={() => handleOpenConfirmationModalForSms(userCoupon.id)}
              onCancel={() => handleOpenConfirmationModalForCancel(userCoupon.id)}
            />
          </TableCell>
        </TableRow>
      ))}
      <WrapWithConfirmationButtons
        open={openConfirmationModal.open}
        onClose={handleCloseConfirmationModal}
        onYes={
          openConfirmationModal.for === "sms"
            ? () => sendSms(openConfirmationModal.id as number)
            : () => cancel(openConfirmationModal.id as number)
        }
        loading={loading}
      >
        <Typography>
          {openConfirmationModal.for === "sms" ? t("are_you_want_to_send_sms") : t("are_you_sure_to_cancel")}
        </Typography>
      </WrapWithConfirmationButtons>
    </MuiTableBody>
  );
}
