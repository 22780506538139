import Api from "src/api";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
// components
import { Box, Table } from "@mui/material";
import {
  TableHead,
  TableWrapper,
  WrapWithFilter,
  WrapWithLoading,
  WrapWithNoResult,
  WrapWithPagination
} from "src/components/common-components";
import { BaseModal, DeleteModal } from "src/components/modal";
import { TableBody } from "./table";
//source
import { withModals, withModalsProps } from "src/components/hoc";
import { tableHeadCellNames } from "./source";
import { Company } from "src/api/companies";
import { CompanyActionsContextProvider } from "src/providers/context";
import { AddBalance } from "./actions";
import { Meta, Pagination } from "src/types/api";
import { CompaniesFilterProps } from "src/components/common-components/filter/companies";

function Companies({
  mainModal,
  closeMainModal,
  deleteModal,
  openDeleteModal,
  openMainModalForAddBalance,
  closeDeleteModal
}: withModalsProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { search } = useLocation();

  const status = queryString.parse(search)?.status as string;

  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filtering, setFiltering] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [companiesMeta, setCompaniesMeta] = useState<Meta | null>(null);
  const [filterValues, setFilterValues] = useState<CompaniesFilterProps | null>(null);

  const getCompaniesList = useCallback(async (paginationParams: Pagination = { perPage: 20 }) => {
    try {
      const response = await Api.companies.getList({ paginationParams, ...(status && { filterParams: { status } }) });
      setLoading(false);
      if (response.data) setCompanies(response.data);
      if (response.meta) setCompaniesMeta(response.meta);
      else enqueueSnackbar(response.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const handleDelete = useCallback(async () => {
    if (deleteModal?.id) {
      try {
        setDeleting(true);
        const response = await Api.subscribers.removeSubscriber(deleteModal.id);
        setDeleting(false);
        if (response.status === "success") {
          enqueueSnackbar(t("subscriber_deleted", { number: deleteModal.id }), { variant: "success" });
        } else enqueueSnackbar(response.message, { variant: "error" });
      } catch (error) {
        throw new Error(error as string);
      } finally {
        closeDeleteModal();
        await getCompaniesList({ perPage: 20 });
      }
    }
  }, [deleteModal]);

  const filter = useCallback(async (filterParams: CompaniesFilterProps) => {
    try {
      setFiltering(true);
      const response = await Api.companies.getList({ filterParams, paginationParams: { perPage: 20 } });
      setFiltering(false);
      if (response) {
        if (response.data) setCompanies(response.data);
        if (response.meta) setCompaniesMeta(response.meta);
        if (Object.keys(filterParams).length) setFilterValues(filterParams);
        else setFilterValues(null);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const refetchTable = useCallback(() => {
    if (filterValues) filter(filterValues);
    else getCompaniesList(companiesMeta?.pagination);
  }, [filterValues, filter, getCompaniesList]);

  useEffect(() => {
    getCompaniesList({ perPage: 20 });
  }, [getCompaniesList]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 3, pb: 4 }}>
      <Box sx={{ width: { xs: "90%" } }}>
        <CompanyActionsContextProvider
          value={{
            onEdit: () => null,
            onDelete: openDeleteModal,
            onAddBalance: openMainModalForAddBalance,
            refetchTable: () => {
              if (filterValues) filter(filterValues);
              else getCompaniesList(companiesMeta?.pagination);
            }
          }}
        >
          <WrapWithFilter filter_topic="companies" onFilter={filter} filtering={filtering} filter_height={100}>
            <TableWrapper>
              <WrapWithLoading loading={loading}>
                <WrapWithNoResult length={companies.length}>
                  <WrapWithPagination
                    pagination_meta={companiesMeta?.pagination}
                    onPageChange={page => getCompaniesList({ page, perPage: 20 })}
                  >
                    <Table>
                      <TableHead cellNames={tableHeadCellNames} />
                      <TableBody companies={companies} />
                    </Table>
                  </WrapWithPagination>
                </WrapWithNoResult>
              </WrapWithLoading>
            </TableWrapper>
          </WrapWithFilter>
        </CompanyActionsContextProvider>
      </Box>
      <BaseModal open={mainModal?.open} onClose={closeMainModal} disableBackdropClick>
        {(() => {
          if (mainModal?.id) {
            switch (mainModal?.for) {
              case "add_balance":
                return <AddBalance id={mainModal.id} refetch={refetchTable} closeModal={closeMainModal} />;
            }
          }
        })()}
      </BaseModal>
      <DeleteModal open={deleteModal?.open} onClose={closeDeleteModal} onDelete={handleDelete} loading={deleting} />
    </Box>
  );
}

export default withModals(Companies);
