export const genderOptions = [
  { value: "male", label: "male" },
  { value: "female", label: "female" }
];

export const positionsTableHeadConfig = [
  { id: 0, name_key: "type" },
  { id: 1, name_key: "expire_at" },
  { id: 2, name_key: "position" },
  { id: 3, name_key: "action" }
];
