import { memo } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
// components
import { TextField, TextFieldProps } from "@mui/material";

const languages = {
  0: "am",
  1: "en",
  2: "ru"
} as const;

export type LanguageValueTypes = keyof typeof languages;

interface FieldProps {
  field_key: string;
}

function Field({ field_key, ...props }: FieldProps & TextFieldProps) {
  const { t } = useTranslation();
  const { getFieldProps, errors } = useFormikContext<{ [key: string]: string[] }>();

  return (
    <TextField
      fullWidth
      label={t(field_key)}
      {...getFieldProps(field_key)}
      error={Boolean(errors[field_key])}
      helperText={t(errors[field_key] ?? "")}
      {...props}
    />
  );
}

export default memo(Field);
