import ApiSlice from "../slice";
import { generateQueryParamsForFilter } from "src/providers/lib";
// types
import { FilterCommons, FilterPathConfig } from "./types";

export default class Filter extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin`;
  static defaultAuth = true;

  static filter = (path: FilterPathConfig, filter_body?: object) =>
    this.request<FilterCommons[]>(`/${path}${generateQueryParamsForFilter(filter_body ?? null)}`);
}

export * from "./types";
