import { memo, useState } from "react";
// components
import { Box, IconButton, Snackbar, TableCell, Typography } from "@mui/material";
import ContactStatus from "./ContactStatus";
// icons
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

type Props = {
  email: string;
  showStatus?: boolean;
  isVerified?: boolean;
};

function EmailCell({ email, showStatus, isVerified }: Props) {
  const [open, setOpen] = useState(false);
  const handleCopy = () => {
    setOpen(true);
    navigator.clipboard.writeText(email);
  };

  return (
    <TableCell sx={{ position: "relative" }}>
      <Box sx={{ columnGap: 0.5 }} className="flex-center">
        <Typography onClick={handleCopy} sx={{ textAlign: "center", cursor: "copy" }}>
          {email}
        </Typography>
        <IconButton title="Copy email" onClick={handleCopy}>
          <ContentCopyIcon sx={{ fontSize: 14 }} />
        </IconButton>
        {showStatus && <ContactStatus isVerified={Boolean(isVerified)} />}
      </Box>
      <Snackbar open={open} onClose={() => setOpen(false)} autoHideDuration={1500} message="Copied to clipboard" />
    </TableCell>
  );
}

export default memo(EmailCell);
