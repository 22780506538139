// hooks
import { FastField, FastFieldProps, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
// components
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// styles
import { Box, FormHelperText } from "@mui/material";
import { UpdateCoupon } from "../source";

interface Props {
  field_key: keyof UpdateCoupon;
}

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color"
];

const modules = {
  toolbar: [
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ color: ["Black", "WhiteSmoke", "Blue ", "Green", "Red", "Orange", "Violet", "Indigo", "Yellow"] }],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["clean"]
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};

export default function FastEditorFormik({ field_key }: Props) {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext<{ [key: string]: unknown }>();

  return (
    <Box>
      <FastField name={field_key}>
        {({ field, form }: FastFieldProps) => (
          <>
            <ReactQuill
              style={{ minHeight: "100px" }}
              placeholder={t(field_key)}
              modules={modules}
              formats={formats}
              theme="snow"
              value={field.value}
              onChange={value => setFieldValue(field_key, value)}
              className={(form.errors[field_key] ? "custom-quill " : " ") + "draft-by-me"}
            />
            {form.errors[field_key] && <FormHelperText sx={{ color: "error.main" }}>{t("is_required")}</FormHelperText>}
          </>
        )}
      </FastField>
    </Box>
  );
}
