import ApiSlice from "src/api/slice";
// types
import { FinanceDealType } from "./types";

export default class Finances extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin/finance`;
  static defaultAuth = true;

  static getList = () => this.request<FinanceDealType[]>();
  static getSingle = (id: number) => this.request<FinanceDealType>(`/${id}`);
  static update = (id: number, body: FormData) => this.request<FinanceDealType>(`/${id}`, "POST", body);
  static create = (body: FormData) => this.request<FinanceDealType>(``, "POST", body);
  static delete = (id: number) => this.request(`/${id}`, "DELETE");
}

export * from "./types";
