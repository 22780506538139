import { useFormikContext } from "formik";
import { memo } from "react";

import moment from "moment";
// components
import { TextField, TextFieldProps } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTranslation } from "react-i18next";

type Props = {
  field_key: string;
};

function DatePickerC({ field_key, ...textFieldProps }: Props & TextFieldProps) {
  const { setFieldValue, values } = useFormikContext<{ [key: string]: string }>();
  const { t } = useTranslation();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        closeOnSelect
        inputFormat="dd/MM/yyyy"
        value={values[field_key]}
        label={t(field_key)}
        onChange={newValue => setFieldValue(field_key, newValue ? moment(newValue).format("YYYY/MM/DD") : "")}
        renderInput={props => <TextField {...props} fullWidth error={false} {...textFieldProps} />}
      />
    </LocalizationProvider>
  );
}

export default memo(DatePickerC);
