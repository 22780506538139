import { memo, useEffect, useState } from "react";
// components
import { Grid, Switch } from "@mui/material";
// source
import { changeLanguage } from "i18next";

export const LANGUAGE_KEY_FOR_STORAGE = "biglemon-admin-language";
export const RUSSIAN_KEY = "rs";
export const USA_KEY = "en";

function SwitchLanguages() {
  const [enable, setEnable] = useState(localStorage.getItem(LANGUAGE_KEY_FOR_STORAGE) === "rs");

  const handleChange = (isEnable: boolean) => {
    if (isEnable) localStorage.setItem(LANGUAGE_KEY_FOR_STORAGE, RUSSIAN_KEY);
    else localStorage.setItem(LANGUAGE_KEY_FOR_STORAGE, USA_KEY);
    setEnable(isEnable);
  };

  useEffect(() => {
    if (enable) changeLanguage(RUSSIAN_KEY);
    else changeLanguage(USA_KEY);
  }, [enable]);

  return (
    <div>
      <Grid container>
        <Grid item>
          <img width={40} height={40} src="/images/usa-flag.png" alt="russian-flag-icon" />
        </Grid>
        <Grid item>
          <Switch checked={enable} onChange={e => handleChange(e.target.checked)} />
        </Grid>
        <Grid item>
          <img width={40} height={40} src="/images/russian-flag.png" alt="usa-flag-icon" />
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(SwitchLanguages);
