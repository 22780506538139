// hooks
import { useTranslation } from "react-i18next";
import { FunctionComponent, memo } from "react";
// components
import { ListItemButton, ListItemText, ListItemTextProps, SvgIconProps } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
// icons
import ListItemIcon from "@mui/material/ListItemIcon";
import LogoutIcon from "@mui/icons-material/Logout";
// types
import { ListCommons } from ".";
// redux
import { useDispatch, useSelector } from "react-redux";
import { getUserAvailablePermissionGroups, signOut } from "src/providers/redux/slices";

interface ListItemProps extends ListCommons {
  to: string;
  Icon: FunctionComponent<SvgIconProps>;
}

export const ListItemTextC = memo(function ListItem({ title, ...props }: ListItemTextProps) {
  const { t } = useTranslation();

  return (
    <ListItemText
      primary={t(title ?? "")}
      primaryTypographyProps={{ sx: { fontSize: "15px", color: "whitesmoke" } }}
      {...props}
    />
  );
});

export const ListItem = memo(function ListItem({ title, to, Icon, permission_key }: ListItemProps) {
  const { pathname } = useLocation();
  const available_permissions = useSelector(getUserAvailablePermissionGroups);

  if (!available_permissions.includes(permission_key) && permission_key !== "common") return null;

  return (
    <Link to={to} style={{ textDecoration: "none", color: "unset" }}>
      <ListItemButton
        selected={pathname === to}
        sx={{
          "&.Mui-selected": {
            backgroundColor: "rgba(252, 252, 252, 0.09) !important"
          },
          "&:hover": {
            transition: "0.5s !important",
            backgroundColor: "rgba(252, 252, 252, 0.04) !important"
          }
        }}
      >
        <ListItemIcon>
          <Icon sx={{ color: "#FFF", fontSize: 20 }} />
        </ListItemIcon>
        <ListItemTextC title={title} />
      </ListItemButton>
    </Link>
  );
});

export const Logout = memo(function ListItem() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    // make destroyed requests
    dispatch(signOut());
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <ListItemButton onClick={handleLogout}>
      <ListItemIcon>
        <LogoutIcon sx={{ color: "whitesmoke", fontSize: 20 }} />
      </ListItemIcon>
      <ListItemTextC title={"exit"} />
    </ListItemButton>
  );
});
