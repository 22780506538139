//hooks/helpers
import { ChangeEvent, ComponentType, useCallback, useState } from "react";
// components
import { Box, Collapse, IconButton } from "@mui/material";
// icons
import BackupIcon from "@mui/icons-material/Backup";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export interface withUploadProps {
  clear(): void;
  image: { url: string; file: File | null; first_render?: boolean };
}

function withUpload<T>(Component: ComponentType<T>) {
  return function WrappedComponent(props: Omit<T, "image" | "clear">) {
    // !!!! image => url for view in client side and file for request
    const [image, setImage] = useState<{ url: string; file: File | null; first_render?: boolean }>({
      url: "/images/cloud-computing.png",
      file: null,
      first_render: true
    });
    const [hovered, setHovered] = useState(!image.file);

    const handleAvatarChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) setImage({ url: URL.createObjectURL(e.target.files[0]), file: e.target.files[0], first_render: false });
    }, []);

    const handleClear = useCallback(() => {
      setImage({
        url: "/images/cloud-computing.png",
        file: null,
        first_render: false
      });
    }, []);

    return (
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box
          sx={{ position: "relative", width: "100%" }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <Box>
            <Component {...(props as T)} image={image ? image : "/images/cloud-computing.png"} />
          </Box>
          <Box sx={{ position: "absolute", width: "100%", bottom: 0 }}>
            <Collapse in={hovered}>
              <Box sx={{ height: "100%" }}>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "rgba(0, 0, 0, 0.3)"
                  }}
                >
                  <IconButton aria-label="upload avatar" component="label">
                    <input hidden accept="image/*" multiple type="file" onChange={handleAvatarChange} />
                    <BackupIcon sx={{ color: "#FFF" }} />
                  </IconButton>
                  <IconButton onClick={handleClear}>
                    <DeleteForeverIcon sx={{ color: "#FFF" }} />
                  </IconButton>
                </Box>
              </Box>
            </Collapse>
          </Box>
        </Box>
      </Box>
    );
  };
}

export default withUpload;
