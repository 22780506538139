// hooks/helpers
import { useTranslation } from "react-i18next";
import { memo } from "react";
import { useFormikContext } from "formik";
// components
import { Grid, TextField } from "@mui/material";
import { Select } from "src/components/common-components";
// source
import { UserUpsertTypes } from "src/api/admin";
import { genderOptions } from "src/providers/source";
import { userRoleOptions } from "./source";

interface FieldProps {
  field_key: keyof UserUpsertTypes;
}

const Field = memo(function Field({ field_key }: FieldProps) {
  const { getFieldProps, errors } = useFormikContext<UserUpsertTypes>();
  const { t } = useTranslation();

  return (
    <TextField
      fullWidth
      label={t(field_key ?? "")}
      {...getFieldProps(field_key)}
      error={Boolean(errors[field_key])}
      helperText={errors[field_key]}
    />
  );
});

function User() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Field field_key="first_name" />
      </Grid>
      <Grid item xs={6}>
        <Field field_key="last_name" />
      </Grid>
      <Grid item xs={12}>
        <Field field_key="position" />
      </Grid>
      <Grid item xs={6}>
        <Field field_key="email" />
      </Grid>
      <Grid item xs={6}>
        <Field field_key="phone" />
      </Grid>
      <Grid item xs={12}>
        <Select field_key="role" options={userRoleOptions} />
      </Grid>
      <Grid item xs={12}>
        <Select field_key="gender" options={genderOptions} />
      </Grid>
    </Grid>
  );
}

export default User;
