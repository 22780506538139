import { Box, Paper } from "@mui/material";
import { Navigate, useParams } from "react-router-dom";
// components
import Add from "./Add";
import Update from "./Update";
// sources

export default function UpsertBlog() {
  const { id } = useParams<{ id: "add" | string }>();

  if (!id) return <Navigate to="/blog" />;

  return (
    <Box className="flex-center">
      <Box sx={{ width: "80%", my: 3 }}>
        <Paper elevation={8}>{id === "add" ? <Add /> : <Update id={id} />}</Paper>
      </Box>
    </Box>
  );
}
