import Api from "src/api";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { memo, SyntheticEvent, useCallback, useState } from "react";
// components
import { Box, Divider, Grid, TextField } from "@mui/material";
import { FormikErrors, FormikProvider, useFormik } from "formik";
import { FieldsWrapper, Submit } from "src/components/common-components";
import UpsertForm from "../upsert-form";
// source
import { AddModalProps } from "src/types/components";
import { SubscriberOnUpdate } from "src/api/subscribers";

interface Props {
  phoneFromFilter?: string;
}

function AddSubscriber({ refetch, closeModal, phoneFromFilter }: AddModalProps & Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [phoneError, setPhoneError] = useState<{ code: string } | null>(null);
  const [phone, setPhone] = useState(phoneFromFilter ?? "");
  const [confirming, setConfirming] = useState(false);
  const [addSubscriberAccess, setAddSubscriberAccess] = useState(false);

  const updateSubscriber = useCallback(async (body: SubscriberOnUpdate) => {
    try {
      setLoading(true);
      const response = await Api.subscribers.addSubscriber(body);
      setLoading(false);
      if (response.data) {
        enqueueSnackbar(t("subscriber_successfully_added", { id: response.data.id }), { variant: "success" });
        closeModal();
        refetch();
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        if (response.errors) setErrors(response.errors as FormikErrors<SubscriberOnUpdate>);
      }
    } catch (error) {
      new Error(error as string);
    }
  }, []);

  const confirmPhone = useCallback(async (phone: string) => {
    try {
      setConfirming(true);
      const response = await Api.subscribers.confirmPhone(phone);
      if (response.data) {
        enqueueSnackbar(t("code_sended"), { variant: "success" });
        setFieldValue("phone", phone);
        setAddSubscriberAccess(true);
      } else {
        setPhoneError({ code: response?.errors?.phone[0] as string });
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setConfirming(false);
    }
  }, []);

  const formik = useFormik<SubscriberOnUpdate>({
    initialValues: {
      first_name: "",
      last_name: "",
      phone,
      gender: "male",
      code: ""
    },
    onSubmit(values) {
      updateSubscriber(values);
    },
    enableReinitialize: true
  });

  const { handleSubmit, setErrors, setFieldValue } = formik;

  return (
    <FieldsWrapper title="add_subscriber">
      <Box
        component="form"
        onSubmit={(e: SyntheticEvent) => {
          e.preventDefault();
          confirmPhone(phone);
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              value={phone}
              onChange={e => setPhone(e.target.value)}
              required
              fullWidth
              label={t("phone")}
              disabled={addSubscriberAccess}
              error={Boolean(phoneError)}
              helperText={phoneError?.code ?? ""}
            />
          </Grid>
          <Grid item xs={4} sx={{ height: "auto" }}>
            <Submit sx={{ height: "100%" }} title="confirm" loading={confirming} disabled={addSubscriberAccess} />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <form onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Box sx={{ width: "35vw" }}>
            <UpsertForm disabled={!addSubscriberAccess} />
            <Grid container justifyContent="center" pt={4}>
              <Submit title="add" loading={loading} disabled={!addSubscriberAccess} />
            </Grid>
          </Box>
        </FormikProvider>
      </form>
    </FieldsWrapper>
  );
}

export default memo(AddSubscriber);
