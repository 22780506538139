import Api from "src/api";
// hooks
import { useCallback, useState } from "react";
import { BusinessType, CreateBusinessType } from "src/api/settings";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
// components
import { FieldsWrapper, Submit, WrapWithLoading } from "src/components/common-components";
import UpsertForm from "./upsert-form";
import { UpdateModalProps } from "src/types/components";
// source
import { FormikErrors, FormikProvider, useFormik } from "formik";
import { useRequest } from "src/providers/hooks";

export default function Update({ id, closeModal, refetch }: UpdateModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [updating, setUpdating] = useState(false);

  const { loading, data: businessType } = useRequest<BusinessType, { id: number }>(
    () => Api.settings.businessTypes.getSingle(id),
    {
      onError: () => {
        closeModal();
        refetch();
      }
    }
  );

  const updateBusinessType = useCallback(
    async (values: CreateBusinessType) => {
      try {
        setUpdating(true);
        const response = await Api.settings.businessTypes.update(id, values);
        if (response.data) {
          closeModal();
          refetch();
          enqueueSnackbar(t("business_type_updated", { id }), { variant: "success" });
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
          if (response.errors) setErrors(response.errors as FormikErrors<CreateBusinessType>);
        }
      } catch (error) {
        throw new Error(error as string);
      } finally {
        setUpdating(false);
      }
    },
    [id]
  );

  const formik = useFormik<CreateBusinessType>({
    initialValues: {
      title_am: businessType?.title_am ?? "",
      title_ru: businessType?.title_ru ?? "",
      title_en: businessType?.title_en ?? ""
    },
    onSubmit(values) {
      updateBusinessType(values);
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    enableReinitialize: true
  });

  const { handleSubmit, setErrors } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <FieldsWrapper>
          <WrapWithLoading loading={loading}>
            <UpsertForm />
            <Submit title="update" loading={updating} />
          </WrapWithLoading>
        </FieldsWrapper>
      </FormikProvider>
    </form>
  );
}
