import Api from "src/api";
import { memo, useCallback, useState } from "react";
// components
import { ChartWrapper, Filter, PieChart } from "../common";
// source
import { FilterParams } from "src/api/statistics";
import { format } from "date-fns";
import { WrapWithNoResult } from "src/components/common-components";

function PaymentMethods() {
  const [chartSeries, setChartSeries] = useState<ApexNonAxisChartSeries>([]);
  const [chartCategories, setChartCategories] = useState<string[]>([]);

  const getPaymentMethods = useCallback(async (values: FilterParams) => {
    try {
      const response = await Api.statistics.getPaymentMethods({
        ...values,
        from: format(new Date(values.from), "yyyy-MM-dd"),
        to: format(new Date(values.to), "yyyy-MM-dd")
      });
      if (response.data) {
        setChartCategories(response.data.map(coupon => coupon.name));
        setChartSeries(response.data.map(coupon => Number(coupon.value)));
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  return (
    <ChartWrapper>
      <Filter makeFilter={getPaymentMethods} chartType="pie" />
      <WrapWithNoResult length={chartSeries.length || chartCategories.length}>
        <PieChart title="payment_methods_statistics" data={chartSeries} labels={chartCategories} />
      </WrapWithNoResult>
    </ChartWrapper>
  );
}

export default memo(PaymentMethods);
