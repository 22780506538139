import { TableBody as MuiTableBody, TableCell, TableRow } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { PlanType } from "src/api/plans";
import { Actions } from "src/components/common-components";
// import { JsonView } from "src/components/modal";

interface Props {
  plans: PlanType[];
  onEdit(id: number): void;
  onDelete(id: number): void;
}

function TableBody({ plans, onEdit, onDelete }: Props) {
  // const [openJsonViewModal, setOpenJsonViewModal] = useState<{ open: boolean; positions: string[] }>({
  //   open: false,
  //   positions: []
  // });
  const { t } = useTranslation();

  // const handleOpenJsonViewModal = (positions: string[]) => setOpenJsonViewModal({ open: true, positions });
  // const handleCloseJsonViewModal = () => setOpenJsonViewModal({ open: false, positions: [] });

  return (
    <MuiTableBody>
      {plans.map(plan => (
        <TableRow key={plan.id}>
          <TableCell>{plan.name}</TableCell>
          <TableCell>{plan.price}</TableCell>
          <TableCell>{t(plan.visible ? "yes" : "no")}</TableCell>
          {/* <TableCell
            sx={{ ...(plan?.data && { cursor: "pointer" }) }}
            onClick={() => {
              if (plan?.data)
                if (typeof plan.data !== "string") {
                  handleOpenJsonViewModal(plan.data.positions);
                }
            }}
          >
            <Button sx={{ py: 0.5 }}>{plan?.data ? t("positions") : "-"}</Button>
          </TableCell> */}
          <TableCell>
            <Actions actions={["edit", "remove"]} onEdit={() => onEdit(plan.id)} onDelete={() => onDelete(plan.id)} />
          </TableCell>
        </TableRow>
      ))}
      {/* <JsonView data={{ data: openJsonViewModal.positions }} open={openJsonViewModal.open} onClose={handleCloseJsonViewModal} /> */}
    </MuiTableBody>
  );
}

export default memo(TableBody);
