export const tableHeadCellNames = [
  { id: 0, name_key: "title" },
  { id: 1, name_key: "description" },
  { id: 2, name_key: "target" },
  { id: 4, name_key: "image" },
  { id: 5, name_key: "position" },
  { id: 6, name_key: "active" },
  { id: 7, name_key: "clicks" },
  { id: 8, name_key: "action" }
];
