import ApiSlice from "src/api/slice";
import { CreateSection, Section, SectionGroups } from "./types";

export default class Sections extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin/sections`;
  static defaultAuth = true;

  static getList = () => this.request<Section[]>();
  static updateOrder = (body: { id: number }[]) => this.request("/order", "POST", { order: body });
  static getSingle = (id: number) => this.request<Section>(`/${id}`);
  static getGroups = () => this.request<SectionGroups[]>(`/groups`);
  static update = (id: number, body: CreateSection) => this.request<Section>(`/${id}`, "POST", { ...body, _method: "PUT" });
  static create = (body: CreateSection) => this.request<Section>(``, "POST", body);
  static delete = (id: number) => this.request(`/${id}`, "DELETE");
}
