// components
import Chart, { Props as ApexChartProps } from "react-apexcharts";
// source
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../../providers/lib";

export interface LineChartData {
  x: string;
  y: number;
}

interface Props {
  title: string;
  label: string;
  data: LineChartData[];
}

export default function LineChart({ title, data, label, ...props }: Props | ApexChartProps) {
  const { t } = useTranslation();

  return (
    <Chart
      series={[
        {
          name: t(label),
          data
        }
      ]}
      options={{
        chart: {
          type: "bar",
          zoom: {
            enabled: true
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return formatNumber(value);
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        title: {
          text: t(title)
        },
        tooltip: {
          y: {
            formatter: val => {
              return formatNumber(val);
            }
          }
        }
      }}
      height="250"
      {...props}
    />
  );
}
