import Api from "src/api";
import { memo, useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
// components
import { Grid, Stack, TextField, TextFieldProps } from "@mui/material";
// source
import { FormikErrors, FormikProvider, useFormik, useFormikContext } from "formik";
import { ChangePassword } from "src/api/user";
import { FieldsWrapper, Submit } from "src/components/common-components";
import { useDispatch } from "react-redux";
import { signOut } from "src/providers/redux/slices";
import { useNavigate } from "react-router-dom";

interface FieldProps {
  field_key: keyof ChangePassword;
}

const Field = memo(function Field({ field_key, ...props }: FieldProps & TextFieldProps) {
  const { t } = useTranslation();
  const { getFieldProps, errors } = useFormikContext<ChangePassword>();

  return (
    <TextField
      fullWidth
      {...getFieldProps(field_key)}
      label={t(field_key)}
      error={Boolean(errors[field_key])}
      helperText={errors[field_key]}
      {...props}
    />
  );
});

function ChangePersonalPassword() {
  const { enqueueSnackbar } = useSnackbar();
  const [updating, setUpdating] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLogout = () => {
    // make destroyed requests
    dispatch(signOut());
    localStorage.removeItem("token");
    navigate("/");
  };

  const changePassword = useCallback(async (values: ChangePassword) => {
    try {
      setUpdating(true);
      const response = await Api.user.changePassword(values);
      if (response.data) {
        enqueueSnackbar(t("success"), { variant: "success" });
        handleLogout();
      } else {
        setErrors(response.errors as FormikErrors<ChangePassword>);
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setUpdating(false);
    }
  }, []);

  const formik = useFormik<ChangePassword>({
    initialValues: {
      old_password: "",
      password: "",
      password_confirmation: ""
    },
    onSubmit: values => {
      changePassword(values);
    },
    enableReinitialize: true
  });

  const { handleSubmit, setErrors } = formik;

  return (
    <div>
      <FormikProvider value={formik}>
        <FieldsWrapper title="change_password">
          <div>
            <Grid container component="form" alignItems="space-between" onSubmit={handleSubmit} spacing={5}>
              <Grid item xs={12}>
                <Stack justifyContent="space-between" spacing={3.2}>
                  <Field field_key="old_password" type="password" />
                  <Field field_key="password" type="password" />
                  <Field field_key="password_confirmation" type="password" />
                  <Submit title="update" loading={updating} />
                </Stack>
              </Grid>
            </Grid>
          </div>
        </FieldsWrapper>
      </FormikProvider>
    </div>
  );
}

export default memo(ChangePersonalPassword);
