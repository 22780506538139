import { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
// components
import { Box, Button, Divider, FormHelperText, Grid, Paper, Popper, Typography } from "@mui/material";
import { AddPrice, EditPrice } from "./upsert";
import { WrapWithNoResult } from "src/components/common-components";
import { UpdateCoupon } from "../source";
import Card from "./card";
// source
import { BaseModal } from "src/components/modal";
import { withModals, withModalsProps } from "src/components/hoc";

function Prices({ mainModal, openMainModalForAdding, openMainModalForUpdating, closeMainModal }: withModalsProps) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const {
    errors,
    values: { prices }
  } = useFormikContext<UpdateCoupon>();

  const isError = useMemo(() => {
    return Object.keys(errors).some((field: string) => field.includes("price"));
  }, [errors]);

  const priceErrors = useMemo(() => {
    if (isError) return Object.entries(errors).filter(([key]) => key.includes("price"));
  }, [isError]);

  const handleHover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "popper-for-prices-box" : undefined;

  return (
    <Grid container rowGap={4}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography sx={{ ...(isError && { color: "error.main" }) }} variant="h5">
              {t("prices")}
            </Typography>
            {isError && (
              <Box onMouseEnter={handleHover} onMouseLeave={handleHover}>
                <FormHelperText sx={{ color: "error.main" }}>{t("you_have_error_in_this_field")}</FormHelperText>
                <Popper id={id} anchorEl={anchorEl} open={open}>
                  <Paper sx={{ overflow: "auto", color: "error.main", p: 2 }}>
                    {priceErrors?.map((element, index) => (
                      <Box key={`price_error_${index}`} sx={{ display: "flex", columnGap: 1 }}>
                        <Typography>{element[0]} :</Typography>
                        <Typography>{JSON.stringify(element[1])}</Typography>
                      </Box>
                    ))}
                  </Paper>
                </Popper>
              </Box>
            )}
          </Grid>
          <Grid item>
            <Button onClick={openMainModalForAdding}>{t("add")}</Button>
          </Grid>
        </Grid>
      </Grid>
      <WrapWithNoResult length={prices.length}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {prices.map((price, index) => (
              <Grid key={`price_${index}`} item xs={4} sx={{ minHeight: "100%" }}>
                <Card price={price} index={index} onEdit={openMainModalForUpdating} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </WrapWithNoResult>
      <BaseModal open={Boolean(mainModal?.open)} onClose={closeMainModal} disableBackdropClick>
        <Box sx={{ width: "70vw" }}>
          {(() => {
            if (mainModal?.for) {
              switch (mainModal.for) {
                case "add":
                  return <AddPrice onClose={closeMainModal} />;
                case "edit":
                  return mainModal?.id !== undefined && <EditPrice id={mainModal.id} onClose={closeMainModal} />;
                default:
                  return <p>unknown</p>;
              }
            }
          })()}
        </Box>
      </BaseModal>
    </Grid>
  );
}

export default memo(withModals(Prices));
