import ApiSlice from "src/api/slice";
import { CreateFAQ, FAQTypes } from "./types";

export default class FAQ extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin/faq`;
  static defaultAuth = true;

  static getList = () => this.request<FAQTypes[]>();
  static getSingle = (id: number) => this.request<FAQTypes>(`/${id}`);
  static update = (id: number, body: CreateFAQ) => this.request<FAQTypes>(`/${id}`, "POST", { ...body, _method: "PUT" });
  static create = (body: CreateFAQ) => this.request<FAQTypes>(``, "POST", body);
  static delete = (id: number) => this.request(`/${id}`, "DELETE");
}
