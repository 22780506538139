import { TextField, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
// components
import { FastField, FastFieldProps } from "formik";
// source
import { UpdateCoupon } from "../source";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";

interface Props {
  field_key: keyof UpdateCoupon;
}

export default function FastFieldFormik({ field_key, ...textFieldProps }: Props & TextFieldProps) {
  const { t } = useTranslation();

  return (
    <FastField name={field_key}>
      {({ form }: FastFieldProps<UpdateCoupon>) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              closeOnSelect
              inputFormat="MM/dd/yyyy"
              value={form.values[field_key] as string}
              label={t(field_key)}
              onChange={newValue => form.setFieldValue(field_key, newValue ? format(new Date(newValue), "yyyy-MM-dd") : "")}
              renderInput={props => (
                <TextField
                  fullWidth
                  {...props}
                  {...textFieldProps}
                  error={Boolean(form.errors[field_key])}
                  helperText={form.errors[field_key] as string}
                />
              )}
            />
          </LocalizationProvider>
        );
      }}
    </FastField>
  );
}
