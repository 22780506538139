import { createContext } from "react";
import { ActionCommons } from ".";

export interface SubscriberConfirmParams {
  id: number;
  email: string | null;
  phone: string | null;
}

export interface SubscriberActionsContextProps extends ActionCommons {
  onConfirm(params: SubscriberConfirmParams): void;
  refetchTable: () => void;
}

const onEdit = () => alert("onEdit fn is empty");
const onDelete = () => alert("onDelete fn is empty");
const onAddBalance = () => alert("onAddBalance fn is empty");
const onConfirm = () => alert("onConfirm fn is empty");
const refetchTable = () => alert("onConfirm fn is empty");

export const SubscriberActionsContext = createContext<SubscriberActionsContextProps>({
  onEdit,
  onDelete,
  onAddBalance,
  onConfirm,
  refetchTable
});

export const { Provider: SubscriberActionsContextProvider } = SubscriberActionsContext;
