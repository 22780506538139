import { memo, PropsWithChildren } from "react";
import LinearLoading from "./LinearLoading";

type Props = {
  loading: boolean;
};

function WrapWithLoading({ loading, children }: PropsWithChildren<Props>) {
  return <>{loading ? <LinearLoading /> : children}</>;
}

export default memo(WrapWithLoading);
