// hooks
import { useTranslation } from "react-i18next";
import { FunctionComponent, memo, PropsWithChildren, useState } from "react";
// components
import { Collapse, List, ListItemButton, ListItemText, SvgIconProps } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { ListItemTextC } from "./ListItems";
// icons
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
// types
import { ListCommons } from ".";
// redux
import { useSelector } from "react-redux";
import { getUserAvailablePermissionGroups } from "src/providers/redux/slices";
import { Permission } from "src/types/api";
interface NestedListProps {
  title: string;
  Icon: FunctionComponent<SvgIconProps>;
  permission_keys: Permission[];
}

interface NestedListItemProps extends ListCommons {
  to: string;
  Icon: FunctionComponent<SvgIconProps>;
}

export const NestedList = memo(function NestedList({
  title,
  children,
  Icon,
  permission_keys
}: PropsWithChildren<NestedListProps>) {
  const available_permissions = useSelector(getUserAvailablePermissionGroups);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(state => !state);
  };

  const { pathname } = useLocation();

  if (permission_keys.every(key => !available_permissions.includes(key) && key !== "common")) return null;

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        selected={!open && pathname.includes(title)}
        sx={{
          "&.Mui-selected": {
            backgroundColor: "rgba(252, 252, 252, 0.09) !important"
          },
          "&:hover": {
            transition: "0.5s !important",
            backgroundColor: "rgba(252, 252, 252, 0.04) !important"
          }
        }}
      >
        <ListItemIcon>
          <Icon sx={{ color: "whitesmoke", fontSize: 22 }} />
        </ListItemIcon>
        <ListItemTextC title={title} />
        {open ? <ArrowDropDownIcon sx={{ color: "whitesmoke" }} /> : <ArrowRightIcon sx={{ color: "whitesmoke" }} />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
});

export const NestedListItem = memo(function NestedListItem({ title, permission_key, to, Icon }: NestedListItemProps) {
  const available_permissions = useSelector(getUserAvailablePermissionGroups);
  const { t } = useTranslation();

  const { pathname } = useLocation();

  if (!available_permissions.includes(permission_key) && permission_key !== "common") return null;

  return (
    <Link to={to} style={{ textDecoration: "navy", color: "unset" }}>
      <ListItemButton
        selected={pathname === to}
        sx={{
          pl: 4,
          "&.Mui-selected": {
            backgroundColor: "rgba(252, 252, 252, 0.09) !important"
          },
          "&:hover": {
            transition: "0.5s !important",
            backgroundColor: "rgba(252, 252, 252, 0.04) !important"
          }
        }}
      >
        <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
          <Icon sx={{ color: "whitesmoke" }} />
        </ListItemIcon>
        <ListItemText primary={t(title)} primaryTypographyProps={{ sx: { fontSize: 12, color: "whitesmoke" } }} />
      </ListItemButton>
    </Link>
  );
});
