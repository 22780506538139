import { memo } from "react";
// components
import { Box, TableCell, Typography } from "@mui/material";

type Props = {
  image: string;
  text: string;
};

function CellWithImages({ image, text }: Props) {
  return (
    <TableCell sx={{ position: "relative" }}>
      <Box sx={{ columnGap: 1, textAlign: "left" }} className="flex-center">
        <img
          width={40}
          height={40}
          src={image ? image : "/images/no-photo.png"}
          alt={"cell_with_image"}
          style={{ objectFit: "cover" }}
        />
        <Typography title={text}>{text}</Typography>
      </Box>
    </TableCell>
  );
}

export default memo(CellWithImages);
