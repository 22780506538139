import { Box, TextField, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
// components
import { FastField, FastFieldProps } from "formik";
// source
import { UpdateCoupon } from "../source";

interface Props {
  field_key: keyof UpdateCoupon;
}

export default function FastFieldFormik({ field_key, ...props }: Props & TextFieldProps) {
  const { t } = useTranslation();
  return (
    <Box>
      <FastField name={field_key}>
        {({ field, form }: FastFieldProps) => (
          <TextField
            fullWidth
            label={t(field_key)}
            {...field}
            {...props}
            error={Boolean(form.errors[field_key])}
            helperText={Boolean(form.errors[field_key]) && String(form.errors[field_key])}
          />
        )}
      </FastField>
    </Box>
  );
}
