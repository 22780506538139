import { Avatar, Box, Rating, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { CompanyFeedback } from "src/api/comments";
import View from "src/components/common-components/review/view";
import { DependModeRedirector } from "src/components/common-components/wrappers";
import { BaseModal } from "src/components/modal";
import { Actions } from "../../../components/common-components/actions";

interface Props {
  comments: CompanyFeedback[];
  onDelete(id: number): void;
}

export default function TableBodyC({ comments, onDelete }: Props) {
  const [openView, setOpenView] = useState<{
    isOpen: boolean;
    currentMessage?: string;
    currentAnswer?: string;
  }>({
    isOpen: false
  });

  const handleOpenModalForView = (currentMessage: string, currentAnswer?: string) =>
    setOpenView({ isOpen: true, currentMessage, currentAnswer });

  const handleCloseModalForView = () => setOpenView({ isOpen: false });

  return (
    <TableBody>
      {comments.map(comment => (
        <TableRow key={comment.id}>
          <TableCell>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Avatar src={comment.user.avatar ?? ""} />
              <Typography sx={{ ml: 1 }}>{comment.user.first_name}</Typography>
            </Box>
          </TableCell>
          <TableCell>
            {
              <DependModeRedirector to={`company/${comment.company.id}/main`} target="_blank">
                {comment.company.name}
              </DependModeRedirector>
            }
          </TableCell>
          <TableCell>{<Rating value={comment.rating} disabled />}</TableCell>
          <TableCell>
            <Actions
              actions={["remove", "view"]}
              onDelete={() => onDelete(comment.id)}
              onView={() => handleOpenModalForView(comment.message, comment.answer)}
            />
          </TableCell>
        </TableRow>
      ))}
      <BaseModal open={openView.isOpen} onClose={handleCloseModalForView}>
        <View message={openView.currentMessage} answer={openView.currentAnswer} place="company" />
      </BaseModal>
    </TableBody>
  );
}
