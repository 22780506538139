import Api from "src/api";
import { FormikErrors, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { memo, useCallback, useEffect, useState } from "react";
// components
import UpsertForm from "../upsert-form";
import { WrapWithLoading } from "src/components/common-components";
// types
import { UserUpsertTypes } from "src/api/admin";
import { Admin } from "src/types/api";
import { UpdateModalProps } from "src/types/components";

function UpdateUser({ id, refetch, closeModal }: UpdateModalProps) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<Admin | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const getCurrentUser = useCallback(async () => {
    try {
      const response = await Api.users.getSingleUser(id);

      if (response.data) {
        setUser(response.data);
        setLoading(false);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const updateUser = useCallback(async (body: UserUpsertTypes) => {
    try {
      const response = await Api.users.updateUser(body, id);
      if (response.data) {
        enqueueSnackbar("User successfully added", { variant: "success" });
        refetch();
        closeModal();
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        if (response.errors) setErrors(response.errors as FormikErrors<UserUpsertTypes>);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const formik = useFormik<UserUpsertTypes>({
    initialValues: {
      first_name: user?.first_name ?? "",
      last_name: user?.last_name ?? "",
      email: user?.email ?? "",
      phone: user?.phone ?? "",
      position: user?.position ?? "",
      permissions: [],
      gender: user?.gender ?? "male",
      role: user?.role ?? "operator"
    },
    onSubmit(values) {
      updateUser(values);
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    enableReinitialize: true
  });

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  const { handleSubmit, setErrors } = formik;

  return (
    <WrapWithLoading loading={loading}>
      <form onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <UpsertForm permissions={user?.permissions} />
        </FormikProvider>
      </form>
    </WrapWithLoading>
  );
}

export default memo(UpdateUser);
