import { useTranslation } from "react-i18next";
// components
import { Chip, SvgIconProps, TableCell } from "@mui/material";
import { FunctionComponent, memo, useMemo } from "react";
// source
import { Status } from "src/api/coupons";
// icon
import WarningIcon from "@mui/icons-material/Warning";
import CancelIcon from "@mui/icons-material/Cancel";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

type Props = {
  status: Status;
};

function StatusCell({ status }: Props) {
  const { t } = useTranslation();

  const statusColor = useMemo(() => {
    switch (status) {
      case "active":
        return "#078a00 !important";
      case "expired":
        return "#2a3328 !important";
      case "admin_review":
        return "#d66400 !important";
      case "company_review":
        return "#cd61ff !important";
      case "canceled":
        return "#b50a04 !important";
    }
  }, [status]);

  const StatusIcon = useMemo((): FunctionComponent<SvgIconProps> => {
    switch (status) {
      case "active":
        return CheckCircleIcon;
      case "expired":
        return EventBusyIcon;
      case "admin_review":
        return WarningIcon;
      case "company_review":
        return PriorityHighIcon;
      case "cancelled":
        return CancelIcon;
      default:
        return CheckCircleIcon;
    }
  }, [status]);

  return (
    <TableCell>
      <Chip
        label={t(status ?? "")}
        icon={<StatusIcon sx={{ color: statusColor }} />}
        sx={{
          color: statusColor,
          minWidth: "80%"
        }}
      />
    </TableCell>
  );
}

export default memo(StatusCell);
