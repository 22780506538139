import { useCallback } from "react";
import { useFormikContext } from "formik";
// components
import { IconButton, Paper } from "@mui/material";
import { openUniversalImage, UniversalImageType } from "src/providers/lib";
// icons
import Cancel from "@mui/icons-material/Cancel";
// source
import { AddMedia } from "../..";

type Props = {
  index: number;
  src: UniversalImageType;
};

export default function VideoBox({ src, index }: Props) {
  const { setFieldValue, values } = useFormikContext<AddMedia>();

  const handleDelete = useCallback(() => {
    setFieldValue(
      "videos_preview",
      [...values.videos_preview].filter((_, i) => i !== index)
    );
  }, [index, values.videos_preview]);

  return (
    <Paper elevation={8} sx={{ overflow: "hidden", height: 150, position: "relative" }}>
      <video src={openUniversalImage(src)} width="100%" height="100%" style={{ objectFit: "cover" }} />
      <IconButton sx={{ position: "absolute", top: 0, right: 0 }} onClick={handleDelete}>
        <Cancel sx={{ color: "error.main" }} />
      </IconButton>
    </Paper>
  );
}
