import ApiSlice from "../slice";
import { CompanyAddress, CouponAddress, IndRegion, ParentCategory, PublicBusinessType } from "./types";

export default class Independent extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}`;
  static defaultAuth = true;

  static getCategories = () => this.request<ParentCategory[]>("/categories");
  static getBusinessTypes = () => this.request<PublicBusinessType[]>("/business-types");
  static getRegions = () => this.request<IndRegion[]>("/regions");
  static getCompanyAddresses = (id: number) => this.request<CompanyAddress[]>(`/public/company/${id}/addresses`);
  static getCouponAddresses = (id: string) => this.request<CouponAddress[]>(`/public/coupons/addresses/${id}`);
  static uploadFile = (form: FormData) => this.request<string>(`/files/upload`, "POST", form);
}

export * from "./types";
