import { TableBody as MuiTableBody, TableCell, TableRow } from "@mui/material";
import { ContactPerson } from "src/api/companies";
import { EmailCell, PhoneCell, UserCell } from "src/components/common-components";
import ActionsGroup from "src/components/common-components/actions/ActionsGroup";

interface Props {
  contactPersons: ContactPerson[];
  onEdit(id: number): void;
  onDelete(id: number): void;
}

export default function TableBody({ contactPersons, onEdit, onDelete }: Props) {
  return (
    <MuiTableBody>
      {contactPersons.map(contact => (
        <TableRow key={`contact_persons_${contact.id}`} sx={{ "& td, p, span": { fontSize: 11 }, "& td": { px: 1 } }}>
          <UserCell label={contact.name} />
          <TableCell>{contact.position}</TableCell>
          <EmailCell email={contact.email} />
          <PhoneCell label={contact.phone} />
          <TableCell>{contact.notes}</TableCell>
          <TableCell>
            <ActionsGroup actions={["edit", "remove"]} onEdit={() => onEdit(contact.id)} onDelete={() => onDelete(contact.id)} />
          </TableCell>
        </TableRow>
      ))}
    </MuiTableBody>
  );
}
