import { useState } from "react";
import { useTranslation } from "react-i18next";
// components
import { Button, Grid, Typography } from "@mui/material";
import MediaBox, { MediaResource } from "./media-box";
import UploadModal from "./upload-modal";
// source
import { BaseModal } from "src/components/modal";
import { CouponMediaContextProvider } from "src/providers/context";

interface Props {
  medias: MediaResource[];
}

export default function Photos({ medias }: Props) {
  const { t } = useTranslation();

  const [mediasCache, setMediasCache] = useState(medias);

  const [openUploadModal, setOpenUploadModal] = useState(false);

  const handleUploadModalOpen = () => setOpenUploadModal(true);
  const handleUploadModalClose = () => setOpenUploadModal(false);

  return (
    <Grid container rowGap={4}>
      <CouponMediaContextProvider value={{ setMediasCache }}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">{t("photos")}</Typography>
            </Grid>
            <Grid item>
              <Button onClick={handleUploadModalOpen}> {t("upload")}</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {mediasCache.map((media, index) => (
              <Grid item xs={3} key={`media_${index}`}>
                <MediaBox data={media} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <BaseModal open={openUploadModal} onClose={handleUploadModalClose}>
          <UploadModal />
        </BaseModal>
      </CouponMediaContextProvider>
    </Grid>
  );
}
