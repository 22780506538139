import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";
// icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UpdateForm from "./update-form";
// source
import { TextFromRequest } from "src/api/settings";
import Api from "src/api";
import { useSnackbar } from "notistack";
import { WrapWithLoading, WrapWithNoResult } from "src/components/common-components";

export default function Texts() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<TextFromRequest[]>([]);

  const getList = useCallback(async () => {
    try {
      const response = await Api.settings.texts.getList();
      if (response.data) setList(response.data);
      else enqueueSnackbar(response.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <Box className="flex-center" sx={{ py: 5 }}>
      <Box sx={{ width: "80%" }}>
        <WrapWithLoading loading={loading}>
          <WrapWithNoResult length={list.length}>
            {list.map(values => (
              <Accordion key={`texts_for_public_content:${values.key}`}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>{t(values.key)}</AccordionSummary>
                <AccordionDetails>
                  <UpdateForm derived_values={values} />
                </AccordionDetails>
              </Accordion>
            ))}
          </WrapWithNoResult>
        </WrapWithLoading>
      </Box>
    </Box>
  );
}
