// components
import { Box, Typography } from "@mui/material";
import { BaseModal } from ".";
import { JSONTree } from "react-json-tree";
// props
import { BaseModalProps } from "src/types/components";
import { useTranslation } from "react-i18next";

export type JSONValue = string | number | boolean | { [x: string]: JSONValue } | Array<JSONValue>;

interface Props extends BaseModalProps {
  data: { [key: string]: JSONValue };
}

export default function JsonView({ open, data, onClose }: Props) {
  const { t } = useTranslation();
  return (
    <BaseModal open={open} onClose={onClose} disablePaperPadding cancelButtonColor="#FFF">
      <Box sx={{ px: 5, py: 2, width: "40vw", backgroundColor: "rgb(0, 43, 54) !important" }}>
        <Typography variant="h4" sx={{ color: "#FFF" }}>
          {t("response")}
        </Typography>
        <Box sx={{ pl: 4 }}>
          <JSONTree data={data} />
        </Box>
      </Box>
    </BaseModal>
  );
}
