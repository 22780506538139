import { memo } from "react";
// components
import { TableBody as MuiTableBody, TableCell, TableRow } from "@mui/material";
import { Actions } from "src/components/common-components";
// types
import { Navigation } from "src/api/settings";

interface Props {
  navigation: Navigation[];
  onEdit(id: number): void;
  onDelete(id: number): void;
}

function TableBody({ navigation, onDelete, onEdit }: Props) {
  return (
    <MuiTableBody>
      {navigation.map(nav => (
        <TableRow key={nav.id}>
          <TableCell>{nav.title_am ?? "-"}</TableCell>
          <TableCell>{nav.title_ru ?? "-"}</TableCell>
          <TableCell>{nav.title_en ?? "-"}</TableCell>
          {/* handle images cell */}
          <TableCell>
            <img src={nav.image} alt="navigation_image" width={100} height={50} style={{ objectFit: "cover" }} />
          </TableCell>
          <TableCell>
            <Actions actions={["edit", "remove"]} onEdit={() => onEdit(nav.id)} onDelete={() => onDelete(nav.id)} />
          </TableCell>
        </TableRow>
      ))}
    </MuiTableBody>
  );
}

export default memo(TableBody);
