import ApiSlice from "../slice";
// types
import { Dashboard as DashboardType } from "./types";

export default class Dashboard extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin/dashboard`;
  static defaultAuth = true;

  static getDashboard = () => this.request<DashboardType>();
}

export * from "./types";
