import { TableHead, TableCell, TableRow, TableHeadProps, SxProps } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props extends TableHeadProps {
  cellNames: { id: number; name_key: string }[];
  tableCellSx?: SxProps;
  tableCellClassName?: string;
}

export default function CustomTableHead({ cellNames, tableCellClassName = "", tableCellSx, ...props }: Props) {
  const { t } = useTranslation();

  return (
    <TableHead sx={{ bgcolor: "primary.main" }} {...props}>
      <TableRow>
        {cellNames.map(({ id, name_key }) => (
          <TableCell
            className={tableCellClassName}
            sx={{ color: "#FFF", textAlign: "center", ...tableCellSx }}
            key={`${name_key}_${id}`}
          >
            {t(name_key)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
