import { generateQueryParamsForFilter, generateQueryParamsForPagination } from "src/providers/lib";
import ApiSlice from "../slice";
// types
import { Admin, Pagination, UserPermission } from "src/types/api";
import { UserUpsertResponseTypes, UserUpsertTypes } from "./types";

export default class Users extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin`;
  static defaultAuth = true;

  static getUsersList = (paginationParams?: Pagination) =>
    this.request<Admin[]>(`/users${generateQueryParamsForPagination(paginationParams)}`);
  static getManagers = () => this.request<Admin[]>(`/users${generateQueryParamsForFilter({ role: "manager" })}`);
  static createUser = (body: UserUpsertTypes) => this.request<UserUpsertResponseTypes>("/users", "POST", { ...body });
  static getSingleUser = (id: number) => this.request<Admin>(`/users/${id}`);
  static deleteUser = (id: number) => this.request(`/users/${id}`, "DELETE");
  static updateUser = (body: UserUpsertTypes, id: number) =>
    this.request<UserUpsertResponseTypes>(`/users/${id}`, "PUT", { ...body });

  static getAdminPermissions = () => this.request<UserPermission[]>("/permissions");
}

export * from "./types";
