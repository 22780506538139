import Api from "src/api";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
// components
import { Box, Table } from "@mui/material";
import { TableHead, TableWrapper, WrapWithAddButton, WrapWithLoading } from "src/components/common-components";
import { TableBody } from "./table";
import { BaseModal, DeleteModal } from "src/components/modal";
import { AddCategory, UpdateCategory } from "./upsert";
// source
import { Category } from "src/api/settings";
import { tableHeadCellNames } from "./source";
import { withModals, withModalsProps } from "src/components/hoc";

function Categories({
  mainModal,
  deleteModal,
  openDeleteModal,
  openMainModalForAdding,
  openMainModalForUpdating,
  closeDeleteModal,
  closeMainModal
}: withModalsProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<Category[]>([]);

  const getCategoriesList = useCallback(async () => {
    try {
      const response = await Api.settings.categories.getList();
      if (response.data) {
        setLoading(false);
        setCategories(response.data);
      } else enqueueSnackbar(response.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const handleDelete = useCallback(async () => {
    if (deleteModal?.id) {
      try {
        setDeleting(true);
        const response = await Api.settings.categories.delete(deleteModal.id);
        setDeleting(false);
        if (response.status === "success") {
          enqueueSnackbar(t("category_deleted", { id: deleteModal.id }), { variant: "success" });
        } else enqueueSnackbar(response.message, { variant: "error" });
      } catch (error) {
        throw new Error(error as string);
      } finally {
        closeDeleteModal();
        await getCategoriesList();
      }
    }
  }, [deleteModal]);

  useEffect(() => {
    getCategoriesList();
  }, [getCategoriesList]);

  return (
    <WrapWithAddButton sx={{ width: "80%" }} handleAdd={openMainModalForAdding}>
      <WrapWithLoading loading={loading}>
        <TableWrapper>
          <Table>
            <TableHead cellNames={tableHeadCellNames} />
            <TableBody categories={categories} onEdit={openMainModalForUpdating} onDelete={openDeleteModal} />
          </Table>
        </TableWrapper>
      </WrapWithLoading>
      <BaseModal open={mainModal?.open} onClose={closeMainModal} disableBackdropClick>
        <Box sx={{ width: "50vw" }}>
          {(() => {
            if (mainModal?.for) {
              switch (mainModal.for) {
                case "add":
                  return <AddCategory refetch={getCategoriesList} closeModal={closeMainModal} />;
                case "edit":
                  return (
                    mainModal?.id && <UpdateCategory refetch={getCategoriesList} closeModal={closeMainModal} id={mainModal.id} />
                  );
                default:
                  return <p>Unknown form</p>;
              }
            }
          })()}
        </Box>
      </BaseModal>
      <DeleteModal open={deleteModal?.open} onClose={closeDeleteModal} onDelete={handleDelete} loading={deleting} />
    </WrapWithAddButton>
  );
}

export default withModals(Categories);
