import { createContext, Dispatch, SetStateAction } from "react";
import { Status } from "src/api/coupons";
import { CompanyAddress, ParentCategory } from "src/api/independent";
import { MediaResource } from "src/pages/coupons/upsert/photos/media-box";
import { Pagination } from "src/types/api";

interface CouponsContextProps {
  refetchTable(paginationParams?: Pagination): void;
  statusList: Status[];
}

const refetchTable = () => alert("handleDelete fn is empty");

export const CouponsContext = createContext<CouponsContextProps>({
  statusList: [],
  refetchTable
});

interface CouponContextProps {
  categories: ParentCategory[];
  companyAddresses: CompanyAddress[];
}

export const { Provider: CouponsContextProvider } = CouponsContext;

export const CouponContext = createContext<CouponContextProps>({
  categories: [],
  companyAddresses: []
});

export const { Provider: CouponProvider } = CouponContext;

interface CouponMediaContextProps {
  setMediasCache: Dispatch<SetStateAction<MediaResource[]>> | null;
}

export const CouponMediaContext = createContext<CouponMediaContextProps>({
  setMediasCache: null
});

export const { Provider: CouponMediaContextProvider } = CouponMediaContext;
