import { memo, useCallback, useState } from "react";
// components
import { Box, Grid } from "@mui/material";
//source
import CurrentLanguage from "./current-language";
import { useFormikContext } from "formik";
import { UpdateCoupon } from "../source";
import CopyPastActions from "./current-language/copy-past-actions";
import { ContentLanguages } from "src/types/components";

function Content() {
  const { values, setFieldValue } = useFormikContext<UpdateCoupon>();
  const [contentCopied, setContentCopied] = useState<{ contentLanguage: ContentLanguages; copied: boolean }>({
    contentLanguage: "am",
    copied: false
  });
  const [activeLanguage, setActiveLanguage] = useState<ContentLanguages>("am");

  const handleCopy = useCallback((currentContentLanguage: ContentLanguages) => {
    setContentCopied({ contentLanguage: currentContentLanguage, copied: true });
  }, []);

  const handlePaste = useCallback(
    (pasteInto: ContentLanguages) => {
      if (contentCopied.copied) {
        setFieldValue(`title_${pasteInto}`, values[`title_${contentCopied.contentLanguage}`]);
        setFieldValue(`meta_keywords_${pasteInto}`, values[`meta_keywords_${contentCopied.contentLanguage}`]);
        setFieldValue(`excerpt_${pasteInto}`, values[`excerpt_${contentCopied.contentLanguage}`]);
        setFieldValue(`meta_description_${pasteInto}`, values[`meta_description_${contentCopied.contentLanguage}`]);
        setFieldValue(`description_${pasteInto}`, values[`description_${contentCopied.contentLanguage}`]);
        setFieldValue(`terms_${pasteInto}`, values[`terms_${contentCopied.contentLanguage}`]);
      }
      setContentCopied({ contentLanguage: "am", copied: false });
    },
    [contentCopied]
  );

  return (
    <Grid container spacing={2}>
      <Grid item sx={{ transition: "0.5s" }} xs={12} xl={activeLanguage === "am" ? 8 : 2} onClick={() => setActiveLanguage("am")}>
        <Box className="flex-center">
          <CopyPastActions
            onCopy={() => handleCopy("am")}
            onPaste={() => handlePaste("am")}
            disableCopy={contentCopied.copied && contentCopied.contentLanguage === "am"}
            copiedContentAvailable={contentCopied.copied}
          />
        </Box>
        <CurrentLanguage activeLanguage={activeLanguage} currentLanguage="am" />
      </Grid>
      <Grid item sx={{ transition: "0.5s" }} xs={12} xl={activeLanguage === "ru" ? 8 : 2} onClick={() => setActiveLanguage("ru")}>
        <Box className="flex-center">
          <CopyPastActions
            onCopy={() => handleCopy("ru")}
            onPaste={() => handlePaste("ru")}
            disableCopy={contentCopied.copied && contentCopied.contentLanguage === "ru"}
            copiedContentAvailable={contentCopied.copied}
          />
        </Box>
        <CurrentLanguage activeLanguage={activeLanguage} currentLanguage="ru" />
      </Grid>
      <Grid item sx={{ transition: "0.5s" }} xs={12} xl={activeLanguage === "en" ? 8 : 2} onClick={() => setActiveLanguage("en")}>
        <Box className="flex-center">
          <CopyPastActions
            onCopy={() => handleCopy("en")}
            onPaste={() => handlePaste("en")}
            disableCopy={contentCopied.copied && contentCopied.contentLanguage === "en"}
            copiedContentAvailable={contentCopied.copied}
          />
        </Box>
        <CurrentLanguage activeLanguage={activeLanguage} currentLanguage="en" />
      </Grid>
    </Grid>
  );
}

export default memo(Content);
