import { memo } from "react";
import { useTranslation } from "react-i18next";
// components
import { useFormikContext } from "formik";
// source
import { AddMedia } from "..";
import { Button, Grid, Stack } from "@mui/material";
import LinkField from "./link-field";

function AddLinks() {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<AddMedia>();

  const handleAdd = () => {
    setFieldValue("links_preview", [...values.links_preview, ""]);
  };
  return (
    <div>
      <Stack spacing={2} alignItems="flex-end">
        <div style={{ width: "100%" }}>
          <Grid container justifyContent="center" spacing={2} py={2}>
            {values.links_preview.map((value, index) => (
              <Grid item xs={12} key={`link-preview-${index}`}>
                <LinkField url={value} index={index} />
              </Grid>
            ))}
            <Grid item>
              <Button onClick={handleAdd}>{t("add_link")}</Button>
            </Grid>
          </Grid>
        </div>
      </Stack>
    </div>
  );
}

export default memo(AddLinks);
