import ApiSlice from "../slice";
// types
import { Transaction } from "./types";
// lib
import { generateQueryParamsForFilter, generateQueryParamsForPagination } from "src/providers/lib";
import { TransactionFilterProps } from "src/components/common-components/filter/transactions/Transactions";
import { Pagination } from "src/types/api";

export default class Transactions extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin/transactions`;
  static defaultAuth = true;

  static getTransactionsList = ({
    filter_body,
    paginationParams
  }: {
    filter_body?: TransactionFilterProps;
    paginationParams?: Pagination;
  }) =>
    this.request<Transaction[]>(
      `${generateQueryParamsForFilter(filter_body ?? null)}${generateQueryParamsForPagination(
        paginationParams,
        Boolean(filter_body)
      )}`
    );
}

export * from "./types";
