import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
// components
import { TableBody as MuiTableBody, TableCell, TableRow } from "@mui/material";
import { Actions, ImageCell } from "src/components/common-components";
// types
import { Banner } from "src/api/settings";

interface Props {
  banners: Banner[];
  onDelete(id: number): void;
  onEdit(id: number): void;
}

function TableBody({ banners, onDelete, onEdit }: Props) {
  const { t } = useTranslation();
  const sortedBanners = useMemo(
    () => banners.sort((banner1, banner2) => (banner1.position > banner2.position ? -1 : 1)),
    [banners]
  );

  return (
    <MuiTableBody>
      {sortedBanners.map(banner => (
        <TableRow key={banner.id}>
          <TableCell>{banner.name ?? "-"}</TableCell>
          <TableCell>{banner.description ?? "-"}</TableCell>
          <TableCell>{t(banner.target)}</TableCell>
          <ImageCell image={banner.url} alt="banner_image" />
          <TableCell>{banner.position ?? "-"}</TableCell>
          <TableCell sx={{ color: banner.visible ? "success.main" : "errors.main", fontWeight: 700 }}>
            {banner.visible ? t("visible_banner") : t("unvisible")}
          </TableCell>
          <TableCell>{banner.clicks ?? "0"}</TableCell>
          {/* handle images cell */}
          <TableCell>
            <Actions actions={["edit", "remove"]} onEdit={() => onEdit(banner.id)} onDelete={() => onDelete(banner.id)} />
          </TableCell>
        </TableRow>
      ))}
    </MuiTableBody>
  );
}

export default memo(TableBody);
