import ApiSlice from "../slice";
import { CategoryFollower, CompanyFollower } from "./types";

export default class Followers extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin/followers`;
  static defaultAuth = true;

  static getCompanyFollowers = () => this.request<CompanyFollower[]>(`/company`);
  static getCategoryFollowers = () => this.request<CategoryFollower[]>(`/category`);
}

export * from "./types";
