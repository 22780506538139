import ApiSlice from "../slice";
// types
import { ChangePassword, UserPersonalInformation } from "./types";

export default class User extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/user`;
  static defaultAuth = true;

  static getMe = () => this.request<UserPersonalInformation>();
  static updateMe = (body: FormData) => this.request<UserPersonalInformation>("", "POST", body);
  static changePassword = (body: ChangePassword) => this.request(`/password`, "POST", { ...body, _method: "PUT" });
}

export * from "./types";
