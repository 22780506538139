import Api from "src/api";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Company } from "src/api/companies";
import { Box, Grid, Link, Skeleton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

interface WithDescriptionProps {
  title: string;
}

const FieldWithDescription = ({ title, children }: PropsWithChildren<WithDescriptionProps>) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={1}>
      <Typography sx={{ fontSize: { xs: 16, xl: 18 }, fontWeight: 600 }}>{t(title)}</Typography>
      <Box sx={{ pl: 2 }}>{children}</Box>
    </Stack>
  );
};

export default function ViewCompany() {
  const [company, setCompany] = useState<Company | null>(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const getCompany = useCallback(async (id: string) => {
    try {
      const response = await Api.companies.getSingle(id);
      if (response.data) {
        setCompany(response.data);
      } else navigate(-1);
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  useEffect(() => {
    if (id !== undefined) getCompany(id);
  }, []);

  return (
    <Box sx={{ px: 10, py: 4 }}>
      {company ? (
        <Grid container rowGap={6} columnSpacing={2}>
          <Grid item xs={12} lg={3}>
            <Box>
              <img
                width="100%"
                height="300px"
                style={{ boxShadow: "0 0 10px 0", objectFit: "contain" }}
                src={company.logo ?? ""}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = "/images/no-photo.png";
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Stack spacing={2}>
              <FieldWithDescription title="company_name">
                <Typography>{company.name ?? "-"}</Typography>
              </FieldWithDescription>

              <FieldWithDescription title="category">
                {!!company.business_type &&
                  company.business_type.map((item: { title: string }, idx) => {
                    return <Typography key={`b_type_${idx}`}>{item.title},</Typography>;
                  })}
              </FieldWithDescription>
              <FieldWithDescription title="city">
                <Typography>{company.city ?? "-"}</Typography>
              </FieldWithDescription>
              <FieldWithDescription title="date">
                <Typography>{format(new Date(company.created_at), "dd-MM-yyyy") ?? "-"}</Typography>
              </FieldWithDescription>
              <FieldWithDescription title="url">
                <Link href={company.link} target="_blank">
                  <Typography>{company.link ?? "-"}</Typography>
                </Link>
              </FieldWithDescription>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <FieldWithDescription title="service_description">
                <Typography>{company.service_description ?? "-"}</Typography>
              </FieldWithDescription>
              <FieldWithDescription title="comments">
                <Typography>{company.comments ?? "-"}</Typography>
              </FieldWithDescription>
              <FieldWithDescription title="contact_person">
                <Typography>{company.contact_person ?? "-"}</Typography>
              </FieldWithDescription>
              <FieldWithDescription title="email">
                <Typography>{company.contact_email ?? "-"}</Typography>
              </FieldWithDescription>
              <FieldWithDescription title="phone">
                <Typography>{company.contact_phone ?? "-"}</Typography>
              </FieldWithDescription>
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <Grid container rowGap={6} columnSpacing={2}>
          <Grid item xs={12} lg={3}>
            <Skeleton height={300} />
          </Grid>
          <Grid item xs={12} lg={9}>
            <Stack spacing={2}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Stack>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
