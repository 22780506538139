// react
import { differenceInMilliseconds } from "date-fns";
import { ReactElement } from "react";
// components
import { Navigate } from "react-router-dom";
// source
import { TOKEN_EXPIRE_AT_KEY } from "src/pages/SignIn";

export default function ProtectedRoute({ children }: { children: ReactElement }) {
  const token = localStorage.getItem("token");
  const tokenExpired = differenceInMilliseconds(new Date(localStorage.getItem(TOKEN_EXPIRE_AT_KEY) as string), new Date()) <= 0;

  if (!token || tokenExpired) return <Navigate to="/" />;

  return children;
}
