import { createContext } from "react";
import { ParentCategory } from "src/api/independent";
import { Banner } from "src/api/settings";

interface DefaultValues {
  sectionGroups: string[];
  categories: ParentCategory[];
  banners: Banner[];
}

export const SectionsContext = createContext<DefaultValues>({ sectionGroups: [], categories: [], banners: [] });

export const { Provider: SectionsProvider } = SectionsContext;
