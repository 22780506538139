import { memo } from "react";
// components
import { TableBody as MuiTableBody, TableCell, TableRow } from "@mui/material";
import { Actions } from "src/components/common-components";
// types
import { FAQTypes } from "src/api/settings";

interface Props {
  faq: FAQTypes[];
  onEdit(id: number): void;
  onDelete(id: number): void;
}

function TableBody({ faq, onDelete, onEdit }: Props) {
  return (
    <MuiTableBody>
      {faq.map(element => (
        <TableRow key={element.id}>
          <TableCell>{element.title ?? "-"}</TableCell>
          {/* handle images cell */}
          <TableCell>
            <Actions actions={["edit", "remove"]} onEdit={() => onEdit(element.id)} onDelete={() => onDelete(element.id)} />
          </TableCell>
        </TableRow>
      ))}
    </MuiTableBody>
  );
}

export default memo(TableBody);
