import {
  Main,
  MemberPlans,
  Subscribers,
  Transactions,
  Users,
  CategoryFollowers,
  CompanyFollowers,
  Companies,
  ResetPassword,
  Navigation,
  Categories,
  Regions,
  BusinessTypes,
  CouponPlans,
  ContactForms,
  Banners,
  Sections,
  Coupons,
  UpsertCoupon,
  Statistics,
  Blog,
  UpsertBlog,
  FAQ,
  CompaniesSingleView,
  Finances,
  PersonalPage,
  CompanyFeedbacks,
  CouponFeedbacks,
  Texts
} from "./pages";
import companyQuestions from "./pages/company-questions";

const routes = [
  { path: "/main", Component: Main, permission: "protected" },
  { path: "/users", Component: Users, permission: "protected" },
  { path: "/blog/upsert/:id", Component: UpsertBlog, permission: "protected" },
  { path: "/transactions", Component: Transactions, permission: "protected" },
  { path: "/finances", Component: Finances, permission: "protected" },
  { path: "/subscribers", Component: Subscribers, permission: "protected" },
  { path: "/partners", Component: Companies, permission: "protected" },
  { path: "/partners/:id", Component: CompaniesSingleView, permission: "protected" },
  { path: "/coupons", Component: Coupons, permission: "protected" },
  { path: "/statistics", Component: Statistics, permission: "protected" },
  { path: "/coupons/upsert/:id", Component: UpsertCoupon, permission: "protected" },
  { path: "/company-feedbacks", Component: CompanyFeedbacks, permission: "protected" },
  { path: "/coupon-feedbacks", Component: CouponFeedbacks, permission: "protected" },
  { path: "/company-questions", Component: companyQuestions, permission: "protected" },
  { path: "/feedback-forms", Component: ContactForms, permission: "protected" },
  { path: "/followers/categories", Component: CategoryFollowers, permission: "protected" },
  { path: "/followers/companies", Component: CompanyFollowers, permission: "protected" },
  { path: "/plans/member", Component: MemberPlans, permission: "protected" },
  { path: "/plans/coupon", Component: CouponPlans, permission: "protected" },
  { path: "/settings/banners", Component: Banners, permission: "protected" },
  { path: "/settings/navigation", Component: Navigation, permission: "protected" },
  { path: "/settings/sections", Component: Sections, permission: "protected" },
  { path: "/settings/business_types", Component: BusinessTypes, permission: "protected" },
  { path: "/settings/categories", Component: Categories, permission: "protected" },
  { path: "/settings/regions", Component: Regions, permission: "protected" },
  { path: "/settings/blog", Component: Blog, permission: "protected" },
  { path: "/settings/texts", Component: Texts, permission: "protected" },
  { path: "/reset-password/:token", Component: ResetPassword, permission: "unprotected" },
  { path: "/settings/faq", Component: FAQ, permission: "protected" },
  { path: "personal", Component: PersonalPage, permission: "protected" }
];

export default routes;
