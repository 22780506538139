import { Box, FormHelperText, Paper } from "@mui/material";
import { useFormikContext } from "formik";
import { memo, useEffect } from "react";
// source
import { UpdateUserPersonalInformation } from "src/api/user";
import { withUpload } from "src/components/hoc/client-intervention";
import { withUploadProps } from "src/components/hoc/client-intervention/withUpload";
import { openUniversalImage } from "src/providers/lib";

interface Props extends withUploadProps {
  field_key: keyof UpdateUserPersonalInformation;
}

function UploadImage({ image, field_key }: Props) {
  const { values, errors, setFieldValue } = useFormikContext<UpdateUserPersonalInformation>();

  useEffect(() => {
    if (!image.first_render) {
      setFieldValue(field_key, image.file);
    }
  }, [image]);

  return (
    <Paper
      elevation={10}
      sx={{
        borderRadius: "0",
        ...(errors[field_key] && { border: "1.5px solid", borderColor: "error.main", p: 1, overflow: "hidden" })
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "300px"
        }}
      >
        <img
          src={values[field_key] ? openUniversalImage(values[field_key] as string | Blob) : image.url}
          width={"100%"}
          height={"100%"}
          style={{ objectFit: "cover" }}
        />
      </Box>
      {errors[field_key] && <FormHelperText sx={{ color: "error.main" }}>{errors[field_key]}</FormHelperText>}
    </Paper>
  );
}

export default withUpload(memo(UploadImage));
