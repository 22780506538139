import { ChangeEvent, memo } from "react";
import { useFormikContext } from "formik";
// components
import { Box, Grid, IconButton, SelectChangeEvent, Stack } from "@mui/material";
import Select from "./select";
// icons
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
// source
import { CreateSectionFormik } from "src/api/settings";
import Field from "./field";
import { useTranslation } from "react-i18next";

interface Props {
  innerBannersForSelect: { label: string; value: number }[];
}

function InnerBanners({ innerBannersForSelect }: Props) {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<CreateSectionFormik>();
  const inner_banners = values["inner_banner"] ?? [];

  const handleAdd = () => {
    setFieldValue("inner_banner", [...inner_banners, { inner_banner: "", banner_idx: "" }]);
  };

  const handleRemove = (index: number) => {
    setFieldValue(
      "inner_banner",
      inner_banners.filter((_, i) => i !== index)
    );
  };

  const handleBannerChange = (event: SelectChangeEvent<unknown>, index: number) => {
    setFieldValue(
      "inner_banner",
      inner_banners.map((banner, i) => (i === index ? { ...banner, inner_banner: event.target.value as number } : banner))
    );
  };

  const handleBannerIndexChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    setFieldValue(
      "inner_banner",
      inner_banners.map((banner, i) => (i === index ? { ...banner, banner_idx: event.target.value } : banner))
    );
  };

  return (
    <Grid container rowGap={1.5} columnSpacing={2}>
      <Grid item xs={12}>
        <Stack spacing={1.5}>
          {inner_banners?.map((banner, index) => (
            <Box key={`banner_section_${index}`}>
              <Grid container rowGap={1.5} columnSpacing={2} alignItems="center">
                <Grid item xs={5}>
                  <Select
                    options={innerBannersForSelect}
                    field_key="inner_banner"
                    value={banner.inner_banner}
                    onChange={e => handleBannerChange(e, index)}
                    MenuProps={{ sx: { maxHeight: "200px" } }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field
                    field_key="banner_idx"
                    type="number"
                    value={banner.banner_idx}
                    onChange={e => handleBannerIndexChange(e, index)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton title="Remove banner" onClick={() => handleRemove(index)}>
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        title={t(inner_banners.length === innerBannersForSelect.length ? "create_banner_initially" : "add_banner")}
      >
        <IconButton onClick={handleAdd} disabled={inner_banners.length === innerBannersForSelect.length}>
          <ControlPointIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
export default memo(InnerBanners);
