export const tableHeadCellNames = [
  { id: 1, name_key: "name" },
  { id: 2, name_key: "price" },
  { id: 3, name_key: "published" },
  { id: 5, name_key: "action" }
];

export type AddModalProps = {
  closeModal(): void;
  refetch(): void;
};

export type UpdateModalProps = {
  closeModal(): void;
  refetch(): void;
  planId?: number;
};
