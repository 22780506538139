import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  loading: boolean;
  onClick(): void;
};

function ResetForm({ loading, onClick }: Props) {
  const { t } = useTranslation();
  return (
    <LoadingButton
      type="submit"
      loading={loading}
      sx={{ fontSize: "16px" }}
      fullWidth
      loadingIndicator={<CircularProgress sx={{ color: "white" }} size={20} />}
      onClick={onClick}
    >
      {t("reset_form")}
    </LoadingButton>
  );
}

export default memo(ResetForm);
