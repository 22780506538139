import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
// components
import { Box, Chip, Collapse, IconButton, TableCell, TableRow as MuiTableRow } from "@mui/material";
import { Actions, CellWithImage, DateCell, PayCell } from "src/components/common-components";
import StatusCell from "./status-cell";
import ChangeStatus from "../change-status";
import PurchasedCoupons from "./purchased-coupons";
// types
import { Coupon } from "src/api/coupons";
import { BaseModal } from "src/components/modal";
import { withModals, withModalsProps } from "src/components/hoc";
// icons
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ChangePosition from "./change-position";

type Props = {
  coupon: Coupon;
};

const isDevelopment = process.env.NODE_ENV === "development";

function TableRow({ coupon, mainModal, closeMainModal, openMainModalForStatusChange }: Props & withModalsProps) {
  const { t } = useTranslation();

  const [purchasedCouponsOpen, setPurchasedCouponsOpen] = useState(false);
  const [openPositionChangeModal, setOpenPositionChangeModal] = useState(false);

  const handleCouponsView = () => setPurchasedCouponsOpen(open => !open);
  const closePurchasedCoupons = () => setPurchasedCouponsOpen(false);

  const handleOpenPositionChangeModal = () => setOpenPositionChangeModal(true);
  const handleClosePositionChangeModal = () => setOpenPositionChangeModal(false);

  return (
    <>
      <MuiTableRow key={coupon.id}>
        <TableCell>
          <IconButton onClick={handleCouponsView} title={t(purchasedCouponsOpen ? "hide_buyers" : "view_buyers")}>
            {purchasedCouponsOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </IconButton>
        </TableCell>
        <CellWithImage image={coupon.thumbnail} text={coupon.title} />
        <TableCell>{coupon.company?.name}</TableCell>
        <TableCell>
          {coupon.categories.map((c, index) => (
            <Chip
              size="small"
              label={c.name}
              key={`categories_coupon_${coupon.id}_category_${c.id}_n_${index}`}
              variant="outlined"
              sx={{ background: "#efefef", fontSize: 11, fontWeight: 600, mr: 0.5 }}
            />
          ))}
        </TableCell>
        <PayCell money={coupon.price} />
        <DateCell date={coupon.expire_at ?? "-"} />
        <StatusCell status={coupon.status} />
        <TableCell>
          <Actions
            position="flex-end"
            actions={["edit", "change_status", "view_purchased_coupons", "change_position", "view"]}
            onView={() =>
              window.open(
                isDevelopment ? `http://localhost:4000/${coupon.slug}` : `${process.env.REACT_APP_PUBLIC_URL}/${coupon.slug}`,
                "_blank"
              )
            }
            onChangeStatus={() => openMainModalForStatusChange(coupon.id)}
            onViewPurchasedCoupons={handleCouponsView}
            onEdit={() => window.open(`/coupons/upsert/${coupon.id}`, "_blank")}
            onPositionChange={handleOpenPositionChangeModal}
          />
        </TableCell>
        <BaseModal open={mainModal?.open} onClose={closeMainModal}>
          <Box sx={{ width: "25vw" }}>
            {(() => {
              if (mainModal?.for) {
                switch (mainModal?.for) {
                  case "change_status":
                    return (
                      mainModal.id && <ChangeStatus id={coupon.id} currentStatus={coupon.status} closeModal={closeMainModal} />
                    );
                  default:
                    return <p>unknown modal</p>;
                }
              }
            })()}
          </Box>
        </BaseModal>
      </MuiTableRow>
      <MuiTableRow>
        <TableCell sx={{ p: 0 }} colSpan={8}>
          <Collapse in={purchasedCouponsOpen} timeout="auto" unmountOnExit>
            <PurchasedCoupons id={coupon.id} onClose={closePurchasedCoupons} />
          </Collapse>
        </TableCell>
        <BaseModal open={openPositionChangeModal} onClose={handleClosePositionChangeModal}>
          <ChangePosition id={coupon.id} closeModal={handleClosePositionChangeModal} />
        </BaseModal>
      </MuiTableRow>
    </>
  );
}

export default withModals(memo(TableRow));
