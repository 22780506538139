import Api from "src/api";
import { useSnackbar } from "notistack";
import { useCallback, useContext, useEffect, useState } from "react";
// components
import { Box, Table, Typography } from "@mui/material";
import { TableHead, TableWrapper, WrapWithLoading, WrapWithNoResult } from "src/components/common-components";
import { SubscriberCouponsStoreContext } from "src/providers/context/SubscriberCouponsStoreContext";
import { TableBody } from "./table";
// source
import { Coupon } from "src/api/coupons";
import { tableHeadCellNames } from "./source";
import { BaseModal } from "src/components/modal";
import { useTranslation } from "react-i18next";
import { SubscriberActionsContext } from "src/providers/context";

interface Props {
  couponId: number;
}

export default function Prices({ couponId }: Props) {
  const { subscriberId } = useContext(SubscriberCouponsStoreContext);
  const { refetchTable } = useContext(SubscriberActionsContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [buying, setBuying] = useState(false);
  const [coupon, setCoupon] = useState<Coupon | null>(null);
  const [errorModal, setErrorModal] = useState("");

  const getCoupon = useCallback(async () => {
    if (couponId) {
      try {
        const coupon = await Api.coupons.getSingle(String(couponId));
        if (coupon.data) {
          setCoupon(coupon.data);
        } else {
          enqueueSnackbar(coupon.message, { variant: "error" });
          setCoupon(null);
        }
      } catch (error) {
        throw new Error(error as string);
      } finally {
        setLoading(false);
      }
    }
  }, [couponId]);

  const handleBuy = useCallback(async (priceId: number) => {
    try {
      setBuying(true);
      const response = await Api.subscribers.buyCoupon({
        coupon_id: couponId,
        price_id: priceId,
        subscriber_id: subscriberId as number
      });
      if (response.status === "success") {
        refetchTable();
        enqueueSnackbar(t("success"), { variant: "success" });
      } else {
        setErrorModal(response.message as string);
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setBuying(false);
    }
  }, []);

  useEffect(() => {
    getCoupon();
  }, [getCoupon]);

  return (
    <Box className="flex-center" sx={{ py: 1 }}>
      <Box sx={{ width: "90%" }}>
        <WrapWithLoading loading={loading}>
          {coupon && (
            <TableWrapper>
              <WrapWithLoading loading={loading}>
                <WrapWithNoResult length={coupon.prices.length}>
                  <Table>
                    <TableHead cellNames={tableHeadCellNames} />
                    <TableBody prices={coupon.prices} onBuy={priceId => handleBuy(priceId)} loading={buying} />
                  </Table>
                </WrapWithNoResult>
              </WrapWithLoading>
            </TableWrapper>
          )}
        </WrapWithLoading>
        <BaseModal open={Boolean(errorModal)} onClose={() => setErrorModal("")}>
          <Box sx={{ p: 3 }}>
            <Typography>{errorModal}</Typography>
          </Box>
        </BaseModal>
      </Box>
    </Box>
  );
}
