import Api from "src/api";
import { memo, useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
// components
import { FormikErrors, FormikProvider, useFormik } from "formik";
import UpsertForm from "../upsert-form";
import { Stack } from "@mui/material";
// types
import { ContactPersonForForm } from "src/api/companies";
import { FieldsWrapper, Submit } from "src/components/common-components";
import { AddModalProps } from "src/types/components";

interface Props extends AddModalProps {
  partnerId: number;
}

function AddContactPerson({ partnerId, closeModal, refetch }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const addContactPerson = useCallback(async (values: ContactPersonForForm) => {
    try {
      setLoading(true);
      const response = await Api.companies.addContactPerson(partnerId, values);
      if (response.data) {
        closeModal();
        refetch();
        enqueueSnackbar(t("contact_person_added", { id: response.data.id }), { variant: "success" });
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        if (response.errors) setErrors(response.errors as FormikErrors<ContactPersonForForm>);
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, []);

  const formik = useFormik<ContactPersonForForm>({
    initialValues: {
      name: "",
      position: "",
      email: "",
      phone: "",
      notes: ""
    },
    onSubmit(values) {
      addContactPerson(values);
    },
    validateOnMount: false,
    validateOnChange: false
  });

  const { handleSubmit, setErrors } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <FieldsWrapper title="add_contact_person">
          <Stack spacing={3}>
            <UpsertForm />
            <Submit loading={loading} title="add" />
          </Stack>
        </FieldsWrapper>
      </FormikProvider>
    </form>
  );
}

export default memo(AddContactPerson);
