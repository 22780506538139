import { memo, useContext, useMemo } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
// components
import { Box, Divider, Grid, Typography } from "@mui/material";
import { Select } from "src/components/common-components";
import Field from "./field";
// source
import { CreateSectionFormik } from "src/api/settings";
import { SectionsContext } from "src/providers/context/SectionsContext";
import { couponTypeOptions, showBannerOptions } from "../../source";
import InnerBanners from "./inner-banners";

interface GroupTitleProps {
  title: string;
}

const GroupTitle = ({ title }: GroupTitleProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="h6">{t(title)}</Typography>
      <Divider sx={{ mt: 0.5 }} />
    </Box>
  );
};

const SubGroupTitle = ({ title }: GroupTitleProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="subtitle1" sx={{ pl: 2 }}>
        {t(title)}
      </Typography>
      <Divider sx={{ mt: 0.5 }} />
    </Box>
  );
};

function UpsertForm() {
  const { values, setFieldValue } = useFormikContext<CreateSectionFormik>();

  const { sectionGroups, categories, banners } = useContext(SectionsContext);

  const sectionGroupsForSelect = useMemo(() => sectionGroups.map(type => ({ label: type, value: type })), [sectionGroups]);

  const categoriesForSelect = useMemo(
    () => categories.map(category => ({ label: category.title, value: category.id })),
    [categories]
  );

  const innerBannersForSelect = useMemo(
    () => banners.filter(banner => banner.position === "section_inner").map(banner => ({ label: banner.name, value: banner.id })),
    [values.inner_banner]
  );

  const sectionBannersForSelect = useMemo(
    () =>
      banners.filter(banner => banner.position === "section_divider").map(banner => ({ label: banner.name, value: banner.id })),
    [values.section_divider]
  );

  const sidesBannersForSelect = useMemo(
    () => banners.filter(banner => banner.position === "section_sides").map(banner => ({ label: banner.name, value: banner.id })),
    [values.banners]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container rowGap={1.5} columnSpacing={2}>
          <Grid item xs={12}>
            <GroupTitle title="decor" />
          </Grid>
          <Grid item xs={4}>
            <Field field_key="title_am" />
          </Grid>
          <Grid item xs={4}>
            <Field field_key="title_ru" />
          </Grid>
          <Grid item xs={4}>
            <Field field_key="title_en" />
          </Grid>
          <Grid item xs={12}>
            <SubGroupTitle title="grouping" />
          </Grid>
          <Grid item xs={6}>
            <Select field_key="group" options={sectionGroupsForSelect} />
          </Grid>
          <Grid item xs={6}>
            <Field field_key="per_page" type="number" />
          </Grid>
          <Grid item xs={6}>
            <Select
              field_key="categories"
              options={categoriesForSelect}
              onChange={e => {
                setFieldValue("categories", e.target.value);
                //if (values.coupon_type.length) setFieldValue("coupon_type", []);
              }}
              multiple
              MenuProps={{ sx: { maxHeight: "400px" } }}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              field_key="coupon_type"
              multiple
              options={couponTypeOptions}
              onChange={e => {
                setFieldValue("coupon_type", e.target.value);
                //if (values.categories.length) setFieldValue("categories", []);
              }}
              MenuProps={{ sx: { maxHeight: "250px" } }}
            />
          </Grid>
          <Grid item xs={12}>
            <GroupTitle title="section_divider_banner" />
          </Grid>
          <Grid item xs={4}>
            <Select field_key="section_divider" options={sectionBannersForSelect} MenuProps={{ sx: { maxHeight: "200px" } }} />
          </Grid>
          <Grid item xs={12}>
            <GroupTitle title="side_banner" />
          </Grid>
          <Grid item xs={4}>
            <Select field_key="banners" options={sidesBannersForSelect} multiple MenuProps={{ sx: { maxHeight: "200px" } }} />
          </Grid>
          <Grid item xs={4}>
            <Select field_key="show_banner" options={showBannerOptions} />
          </Grid>
          <Grid item xs={12}>
            <GroupTitle title="inner_banner" />
          </Grid>
          <Grid item xs={12}>
            <InnerBanners innerBannersForSelect={innerBannersForSelect} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(UpsertForm);
