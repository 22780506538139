import Api from "src/api";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
// components
import { Box, Table } from "@mui/material";
import {
  TableHead,
  TableWrapper,
  WrapWithFilter,
  WrapWithLoading,
  WrapWithNoResult,
  WrapWithPagination
} from "src/components/common-components";
import TableBody from "./table";
//source
import { tableHeadCellNames } from "./source";
import { Meta, Pagination } from "src/types/api";
import { Coupon } from "src/api/coupons";
import { CouponsFilterProps } from "src/components/common-components/filter/coupons";

function Coupons() {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [filtering, setFiltering] = useState(false);
  const [couponsList, setCouponsList] = useState<Coupon[]>([]);
  const [couponsMeta, setCouponsMeta] = useState<Meta | null>(null);

  const getCoupons = useCallback(async (paginationParams: Pagination = { perPage: 20 }) => {
    try {
      setLoading(true);
      const couponsList = await Api.coupons.getList({ filterParams: { status: "active" }, paginationParams });
      if (couponsList?.data) setCouponsList(couponsList.data);
      else enqueueSnackbar(couponsList.message, { variant: "error" });
      if (couponsList?.meta) setCouponsMeta(couponsList.meta);
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, []);

  const filter = useCallback(async (filterParams: CouponsFilterProps) => {
    try {
      setFiltering(true);
      const response = await Api.coupons.getList({
        filterParams: { status: "active", ...filterParams },
        paginationParams: { perPage: 20 }
      });
      if (response) {
        if (response.data) setCouponsList(response.data);
        if (response.meta) setCouponsMeta(response.meta);
      }
      setFiltering(false);
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  useEffect(() => {
    getCoupons();
  }, [getCoupons]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
      <Box sx={{ width: { xs: "95%" } }}>
        <WrapWithFilter filter_topic="coupons" onFilter={filter} filter_height={100} filtering={filtering}>
          <TableWrapper>
            <WrapWithPagination
              pagination_meta={couponsMeta?.pagination}
              onPageChange={page => getCoupons({ page, perPage: 20 })}
            >
              <WrapWithLoading loading={loading || filtering}>
                <WrapWithNoResult length={couponsList.length}>
                  <Box sx={{ height: "55vh", overflow: "auto" }}>
                    <Table>
                      <TableHead cellNames={tableHeadCellNames} />
                      <TableBody coupons={couponsList} />
                    </Table>
                  </Box>
                </WrapWithNoResult>
              </WrapWithLoading>
            </WrapWithPagination>
          </TableWrapper>
        </WrapWithFilter>
      </Box>
    </Box>
  );
}

export default Coupons;
