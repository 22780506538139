import { FunctionComponent, memo, useContext } from "react";
import { useTranslation } from "react-i18next";
// components
import { Typography, Stack, Paper, Grid, SvgIconProps, Skeleton } from "@mui/material";
import { DashboardContext } from "src/providers/context";
import CountUp from "react-countup";
// source
import { Dashboard } from "src/api/dashboard";
import { Link } from "react-router-dom";

interface Props {
  path: string;
  title: keyof Dashboard | "stocks";
  Icon: FunctionComponent<SvgIconProps>;
  count?: number;
  iconColor?: string;
}

function Card({ path, title, Icon, count, iconColor = "black" }: Props) {
  const { loading, data } = useContext(DashboardContext);
  const { t } = useTranslation();

  return (
    <Paper sx={{ p: 2 }} elevation={12}>
      {loading ? (
        <Skeleton height={70} />
      ) : (
        <Grid container alignItems="center">
          <Grid item xs={9}>
            <Stack spacing={1} alignItems="flex-start">
              <Typography variant="subtitle1" sx={{ color: "primary.main" }}>
                {t(title)}
              </Typography>
              <Link to={path} style={{ textDecoration: "none" }}>
                <Typography variant="h6" sx={{ color: "rgb(125, 135, 156)", display: "inline" }}>
                  {data && <CountUp end={count ?? 0} formattingFn={n => n.toLocaleString("en-US")} />}
                  {title === "sold_coupons_amount" && " ֏"}
                </Typography>
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={3} className="flex-center" sx={{ height: "100%" }}>
            <Icon sx={{ fontSize: 50, color: iconColor }} />
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}

export default memo(Card);
