import { memo } from "react";
// components
import { TableBody as MuiTableBody } from "@mui/material";
import TableRow from "./table-row";
// types
import { Coupon } from "src/api/coupons";

interface Props {
  coupons: Coupon[];
}

function TableBody({ coupons }: Props) {
  return (
    <MuiTableBody>
      {coupons.map(coupon => (
        <TableRow key={`coupon_${coupon.id}`} coupon={coupon} />
      ))}
    </MuiTableBody>
  );
}

export default memo(TableBody);
