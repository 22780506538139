import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";
import { useTranslation } from "react-i18next";
// components
import { FastField, FastFieldProps } from "formik";
// source
import { UpdateCoupon } from "../source";
import { CustomSelectProps } from "src/components/common-components";

interface Props {
  field_key: keyof UpdateCoupon;
  options: CustomSelectProps[];
}

export default function FastFieldFormik({ field_key, options, ...props }: Props & SelectProps) {
  const { t } = useTranslation();
  return (
    <Box>
      <FastField name={field_key}>
        {({ field, form }: FastFieldProps) => (
          <FormControl fullWidth>
            <InputLabel id={`create_user_${field_key}_label`}>{t(field_key)}</InputLabel>
            <Select
              MenuProps={{ sx: { maxHeight: "300px" } }}
              {...field}
              labelId={`create_user_${field_key}_label`}
              id={`create_user_${field_key}`}
              label={t(field_key)}
              {...props}
              error={Boolean(form.errors[field_key])}
            >
              {options.map(({ label, value, disabled, labelAdditionalValue }) => (
                <MenuItem disabled={Boolean(disabled)} key={`${field_key}_${value}`} value={value}>
                  {t(label, { additional: labelAdditionalValue ?? "" })}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText sx={{ color: "error.main" }}>{form.errors[field_key] as string}</FormHelperText>
          </FormControl>
        )}
      </FastField>
    </Box>
  );
}
