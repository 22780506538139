import { createContext } from "react";
// types
import { Dashboard } from "src/api/dashboard";

export const DashboardContext = createContext<{ data: Dashboard | null; loading: boolean }>({
  data: {
    coupons: [],
    partners: [],
    managers: 0,
    sold_coupons: 0,
    sold_coupons_amount: "",
    subscribers: 0
  },
  loading: true
});

export const { Provider: DashboardProvider } = DashboardContext;
