import Api from "src/api";
import { useSnackbar } from "notistack";
// hooks
import { FormikProvider, useFormik } from "formik";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
// components
import { Grid } from "@mui/material";
import { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Autocomplete, ResetForm, SubmitForm } from "../common";
// context
import { CouponsContext, FilterContext } from "src/providers/context";
// source
import { ParentCategory } from "src/api/independent";
import { Select } from "../../control";
import Field from "../common/Field";

export interface CouponsFilterProps {
  keyword?: string;
  company?: string;
  categories?: number[];
  status?: string;
}

function Coupons() {
  const { onFilter, filtering } = useContext(FilterContext);
  const { statusList } = useContext(CouponsContext);
  const [categories, setCategories] = useState<ParentCategory[]>([]);

  const { search } = useLocation();

  const status = queryString.parse(search)?.status as string;

  const { enqueueSnackbar } = useSnackbar();

  const getCategories = useCallback(async () => {
    try {
      const response = await Api.independent.getCategories();
      if (response.data) setCategories(response.data);
      else enqueueSnackbar(response.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const formik = useFormik<CouponsFilterProps>({
    initialValues: { keyword: "", company: "", categories: [], status: status ?? "" },
    onSubmit(values) {
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(value => Boolean(value[1] || value[1].length) && value)
      );

      onFilter(filteredValues);
    }
  });

  const { handleSubmit, resetForm } = formik;

  const categoriesForSelect = useMemo(
    () => categories.map(category => ({ label: category.title, value: category.id })),
    [categories]
  );

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <Grid container alignItems="center" justifyContent="space-between" rowGap={2} columnSpacing={2} pt={2}>
          <Grid item xs={8}>
            <Grid container columnSpacing={2}>
              <Grid item xs={3}>
                <Field field_key="keyword" />
              </Grid>
              <Grid item xs={3}>
                <Select field_key="categories" multiple options={categoriesForSelect} MenuProps={{ sx: { maxHeight: 300 } }} />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete field_key="company" alt="company_name" filterPathConfig="companies" />
              </Grid>
              <Grid item xs={3}>
                <Select
                  field_key="status"
                  options={statusList.map(status => ({ value: status, label: status }))}
                  MenuProps={{ sx: { maxHeight: 300 } }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container columnSpacing={2} justifyContent="flex-end">
              <Grid item>
                <SubmitForm loading={filtering} />
              </Grid>
              <Grid item>
                <ResetForm onClick={resetForm} loading={filtering} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormikProvider>
    </form>
  );
}

export default memo(Coupons);
