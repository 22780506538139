import { Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  children: ReactNode;
  title?: string;
};

export default function WrapForms({ children, title }: Props) {
  const { t } = useTranslation();

  return (
    <Stack spacing={2} sx={{ minWidth: "40vw", p: 2 }}>
      <Typography variant="h4" sx={{ textAlign: "center" }}>
        {t(title ?? "")}
      </Typography>
      <div>{children}</div>
    </Stack>
  );
}
