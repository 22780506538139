import Api from "src/api";
// hooks
import { useCallback, useEffect, useState } from "react";
import { CreateRegion, Region } from "src/api/settings";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
// components
import { FieldsWrapper, Submit, WrapWithLoading } from "src/components/common-components";
import UpsertForm from "./upsert-form";
import { UpdateModalProps } from "src/types/components";
// source
import { FormikErrors, FormikProvider, useFormik } from "formik";

export default function Update({ id, closeModal, refetch }: UpdateModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [region, setRegion] = useState<Region | null>(null);

  const getRegion = useCallback(async () => {
    try {
      const response = await Api.settings.regions.getSingle(id);
      if (response.data) {
        setRegion(response.data);
        setLoading(false);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        closeModal();
        refetch();
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, [id]);

  useEffect(() => {
    getRegion();
  }, [getRegion]);

  const createRegion = useCallback(
    async (values: CreateRegion) => {
      try {
        setUpdating(true);
        const response = await Api.settings.regions.update(id, values);
        if (response.data) {
          closeModal();
          refetch();
          enqueueSnackbar(t("region_updated", { id }), { variant: "success" });
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
          if (response.errors) setErrors(response.errors as FormikErrors<CreateRegion>);
        }
      } catch (error) {
        throw new Error(error as string);
      } finally {
        setUpdating(false);
      }
    },
    [id]
  );

  const formik = useFormik<CreateRegion>({
    initialValues: {
      title_am: region?.title_am ?? "",
      title_ru: region?.title_ru ?? "",
      title_en: region?.title_en ?? "",
      longitude: region?.longitude ?? "",
      latitude: region?.latitude ?? ""
    },
    onSubmit(values) {
      createRegion(values);
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    enableReinitialize: true
  });

  const { handleSubmit, setErrors } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <FieldsWrapper>
          <WrapWithLoading loading={loading}>
            <UpsertForm />
            <Submit title="update" loading={updating} />
          </WrapWithLoading>
        </FieldsWrapper>
      </FormikProvider>
    </form>
  );
}
