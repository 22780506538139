import Api from "src/api";
import { memo, useCallback, useContext, useEffect, useState } from "react";
// components
import { Paper } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { FieldsWrapper, Select, Submit, WrapWithLoading } from "src/components/common-components";
// source
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { CompanyActionsContext } from "src/providers/context";

type Props = {
  closeModal: () => void;
  companyId: number;
  managerId?: number;
};

export type AssignManagerForm = {
  manager_id: number | "";
};

function AssignManager({ closeModal, companyId, managerId }: Props) {
  const [managers, setManagers] = useState<{ label: string; value: number }[]>([]);
  const [loading, setLoading] = useState(false);
  const [assigning, setAssigning] = useState(false);

  const { refetchTable } = useContext(CompanyActionsContext);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const getManagers = useCallback(async () => {
    setLoading(true);

    const response = await Api.users.getManagers();
    if (response.data) {
      setManagers(response.data.map(user => ({ label: ` Id:${user.id} - ${user.full_name}`, value: user.id })));
    } else {
      closeModal();
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setLoading(false);
  }, []);

  const assignManager = useCallback(
    async (managerId: number) => {
      if (refetchTable) {
        setAssigning(true);
        try {
          const response = await Api.companies.assignManager(companyId, managerId);
          if (response.status === "success") {
            enqueueSnackbar(t("assigned_to_user"), { variant: "success" });
            closeModal();
            refetchTable();
          } else enqueueSnackbar(response.message, { variant: "error" });
        } catch (error) {
          throw new Error(error as string);
        } finally {
          setAssigning(false);
        }
      }
    },
    [companyId]
  );

  const formik = useFormik<AssignManagerForm>({
    initialValues: {
      manager_id: managerId ?? ""
    },
    onSubmit: values => {
      if (values.manager_id) assignManager(Number(values.manager_id));
    }
  });

  useEffect(() => {
    getManagers();
  }, [getManagers]);

  const { handleSubmit } = formik;

  return (
    <Paper sx={{ width: "30vw", p: 2 }} component="form" onSubmit={handleSubmit}>
      <WrapWithLoading loading={loading}>
        <FormikProvider value={formik}>
          <FieldsWrapper title="assign_manager_to_user">
            <Select field_key="manager_id" options={managers} />
            <Submit title="assign" loading={assigning} />
          </FieldsWrapper>
        </FormikProvider>
      </WrapWithLoading>
    </Paper>
  );
}

export default memo(AssignManager);
