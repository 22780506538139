import Api from "src/api";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikErrors, FormikProvider, useFormik, useFormikContext } from "formik";
// components
import { Button, Grid, TextField, TextFieldProps, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { Submit } from "src/components/common-components";
import { CompanyChangePassword } from "src/api/companies";

interface Props {
  id: number;
  username: string | null;
  closeModal(): void;
}

interface FieldProps {
  field_key: keyof CompanyChangePassword;
}

function Field({ field_key, ...props }: FieldProps & TextFieldProps) {
  const { errors, getFieldProps } = useFormikContext<CompanyChangePassword>();
  const { t } = useTranslation();

  return (
    <TextField
      fullWidth
      label={t(field_key)}
      {...getFieldProps(field_key)}
      {...props}
      error={Boolean(errors[field_key])}
      helperText={errors[field_key]}
    />
  );
}

export default function ChangePassword({ id, username, closeModal }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const handleAdd = useCallback(async (body: CompanyChangePassword) => {
    try {
      setLoading(true);
      const response = await Api.companies.changePassword(id, body);
      if (response.status === "success") {
        enqueueSnackbar(t("company_balance_updated", { id }), { variant: "success" });
        closeModal();
      } else {
        setErrors(response.errors as FormikErrors<CompanyChangePassword>);
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, []);

  const formik = useFormik<CompanyChangePassword>({
    initialValues: {
      password: "",
      username: username
    },
    onSubmit(values) {
      handleAdd(values);
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false
  });

  const { handleSubmit, setErrors } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <Grid container rowSpacing={4} p={2} sx={{ maxWidth: "30vw" }}>
          <Grid item xs={12}>
            <Typography variant="h5">{t("change_password")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Field fullWidth field_key="username" defaultValue={username} />
          </Grid>
          <Grid item xs={12}>
            <Field fullWidth field_key="password" defaultValue={null} />
          </Grid>
          <Grid item xs={12}>
            <Grid container columnSpacing={2}>
              <Grid item xs={6}>
                <Submit title={t("update")} loading={loading} />
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth onClick={closeModal}>
                  {t("cancel")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormikProvider>
    </form>
  );
}
