import { memo } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
// components
import { Grid, TextField, TextFieldProps } from "@mui/material";
import { Select } from "src/components/common-components";
// source
import { CreateFinanceDealType } from "src/api/finances";

interface FieldProps {
  field_key: keyof CreateFinanceDealType;
}

const Field = ({ field_key, ...props }: FieldProps & TextFieldProps) => {
  const { t } = useTranslation();
  const { getFieldProps, errors } = useFormikContext<CreateFinanceDealType>();
  return (
    <TextField
      fullWidth
      {...getFieldProps(field_key)}
      label={t(field_key)}
      error={Boolean(errors[field_key])}
      helperText={errors[field_key]}
      {...props}
    />
  );
};

function UpsertForm() {
  return (
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <Select
          options={[
            { label: "income", value: "income" },
            { label: "expense", value: "expense" }
          ]}
          field_key="type"
        />
      </Grid>
      <Grid item xs={12}>
        <Field field_key="description" />
      </Grid>
      <Grid item xs={12}>
        <Field field_key="operation" />
      </Grid>
      <Grid item xs={12}>
        <Field field_key="amount" type="number" />
      </Grid>
    </Grid>
  );
}

export default memo(UpsertForm);
