import { memo } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
// components
import { Grid, TextField, TextFieldProps } from "@mui/material";
//types
import { ContactPersonForForm } from "src/api/companies";

interface FieldProps {
  field_key: keyof ContactPersonForForm;
}

const Field = memo(function Field({ field_key, ...props }: FieldProps & TextFieldProps) {
  const { t } = useTranslation();
  const { getFieldProps, errors } = useFormikContext<ContactPersonForForm>();
  return (
    <TextField
      fullWidth
      {...getFieldProps(field_key)}
      label={t(field_key)}
      error={Boolean(errors[field_key])}
      helperText={errors[field_key]}
      {...props}
    />
  );
});

function ContactPersonUpsertForm() {
  return (
    <Grid container rowGap={2} columnSpacing={2}>
      <Grid item xs={12}>
        <Field field_key="name" />
      </Grid>
      <Grid item xs={12}>
        <Field field_key="position" />
      </Grid>
      <Grid item xs={6}>
        <Field field_key="email" />
      </Grid>
      <Grid item xs={6}>
        <Field field_key="phone" />
      </Grid>
      <Grid item xs={12}>
        <Field field_key="notes" multiline rows={3} />
      </Grid>
    </Grid>
  );
}

export default memo(ContactPersonUpsertForm);
