import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
// components
import { Box, Grid, Stack, TextField } from "@mui/material";
import UploadImage from "./upload-image";
import Content from "./content";
// source
import { CreateBlog } from "src/api/blog";
import { Checkbox } from "src/components/common-components";

interface FieldProps {
  field_key: keyof CreateBlog;
}

const Field = ({ field_key }: FieldProps) => {
  const { t } = useTranslation();
  const { getFieldProps, errors } = useFormikContext<CreateBlog>();

  return (
    <TextField
      fullWidth
      {...getFieldProps(field_key)}
      label={t(field_key)}
      error={Boolean(errors[field_key])}
      helperText={errors[field_key]}
    />
  );
};

function UpsertForm() {
  return (
    <Box>
      <Grid container rowGap={5} columnSpacing={2}>
        <Grid item xs={12}>
          <Grid container columnSpacing={4} rowGap={4}>
            <Grid item xs={12} xl={4}>
              <UploadImage field_key="thumbnail" />
            </Grid>
            <Grid item xs={12} xl={8} pt={{ xs: 0, xl: 4 }}>
              <Grid container rowGap={4}>
                <Grid item xs={12}>
                  <Field field_key="title_am" />
                </Grid>
                <Grid item xs={12}>
                  <Field field_key="title_ru" />
                </Grid>
                <Grid item xs={12}>
                  <Field field_key="title_en" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <Field field_key="excerpt_am" />
            <Field field_key="excerpt_ru" />
            <Field field_key="excerpt_en" />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Content />
        </Grid>
        <Grid item xs={12}>
          <Checkbox field_key="is_top" />
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(UpsertForm);
