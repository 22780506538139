import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
// components
import { Collapse, IconButton, TableCell, TableRow as MuiTableRow } from "@mui/material";
import { CellWithImage, DateCell } from "src/components/common-components";
import Prices from "./prices";
// types
import { Coupon } from "src/api/coupons";
import { withModals, withModalsProps } from "src/components/hoc";
// icons
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

type Props = {
  coupon: Coupon;
};

function TableRow({ coupon }: Props & withModalsProps) {
  const { t } = useTranslation();

  const [couponPricesOpen, setCouponPricesOpen] = useState(false);

  const handlePricesView = () => setCouponPricesOpen(open => !open);

  return (
    <>
      <MuiTableRow key={coupon.id}>
        <TableCell>
          <IconButton onClick={handlePricesView} title={t(couponPricesOpen ? "hide_prices" : "view_prices")}>
            {couponPricesOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </IconButton>
        </TableCell>
        <CellWithImage image={coupon.thumbnail} text={coupon.title} />
        <TableCell>{coupon.categories[0]?.name}</TableCell>
        <DateCell date={coupon.expire_at ?? "-"} />
        <TableCell>
          <IconButton onClick={handlePricesView} title={t(couponPricesOpen ? "hide_prices" : "view_prices")}>
            {couponPricesOpen ? <ArrowDropDownIcon /> : <ArrowLeftIcon />}
          </IconButton>
        </TableCell>
      </MuiTableRow>
      <MuiTableRow>
        <TableCell sx={{ p: 0 }} colSpan={7}>
          <Collapse in={couponPricesOpen} timeout="auto" unmountOnExit>
            <Prices couponId={coupon.id} />
          </Collapse>
        </TableCell>
      </MuiTableRow>
    </>
  );
}

export default withModals(memo(TableRow));
