import ApiSlice from "src/api/slice";
// types
import { Category as CategoryType } from "./types";

export default class Categories extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin/categories`;
  static defaultAuth = true;

  static getList = () => this.request<CategoryType[]>();
  static getSingle = (id: number) => this.request<CategoryType>(`/${id}`);
  static update = (id: number, body: FormData) => this.request<CategoryType>(`/${id}`, "POST", body);
  static create = (body: FormData) => this.request<CategoryType>(``, "POST", body);
  static delete = (id: number) => this.request(`/${id}`, "DELETE");
}

export * from "./types";
