import React from "react";
import ReactDOM from "react-dom/client";
// providers
import { ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { theme } from "./providers";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { persistor, store } from "./providers/redux";
// components
import App from "./App";
// css
import "./index.css";
// i18n
import "./i18n";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <PersistGate loading={null} persistor={persistor}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              autoHideDuration={1000}
              maxSnack={3}
              style={{ maxWidth: "40vw" }}
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
            >
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </Provider>
      </PersistGate>
    </BrowserRouter>
  </React.StrictMode>
);
