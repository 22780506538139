import { memo } from "react";
// components
import { Box } from "@mui/material";
// icons
import VerifiedIcon from "@mui/icons-material/Verified";
import ReportIcon from "@mui/icons-material/Report";
import { useTranslation } from "react-i18next";

type Props = {
  isVerified: boolean;
};

function ContactStatus({ isVerified }: Props) {
  const { t } = useTranslation();
  return (
    <Box className="flex-center">
      {isVerified ? (
        <VerifiedIcon titleAccess={t("verified")} sx={{ color: "#79e200", fontSize: 18 }} />
      ) : (
        <ReportIcon titleAccess={t("refused")} sx={{ color: "#ff4040", fontSize: 18 }} />
      )}
    </Box>
  );
}

export default memo(ContactStatus);
