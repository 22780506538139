import { memo } from "react";
// components
import { Chip, TableCell } from "@mui/material";

type Props = {
  label: string | number;
};

function BalanceCell({ label }: Props) {
  return (
    <TableCell>
      <Chip label={label + " ֏"} sx={{ minWidth: "70%" }} />
    </TableCell>
  );
}

export default memo(BalanceCell);
