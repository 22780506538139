import { Box, Divider, Paper, Stack, Typography, TypographyProps } from "@mui/material";
import { memo, ReactNode } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  title?: string;
  spacing?: number;
  children: ReactNode;
};

function ContentWrapper({ title, spacing = 3, children, ...props }: Props & TypographyProps) {
  const { t } = useTranslation();
  return (
    <Paper elevation={6} sx={{ p: 4 }}>
      <Stack spacing={spacing}>
        {title && (
          <>
            <Typography variant="h5" {...props}>
              {t(title)}
            </Typography>
            <Divider />
          </>
        )}
        <Box>{children}</Box>
      </Stack>
    </Paper>
  );
}

export default memo(ContentWrapper);
