import { memo, useContext, useMemo } from "react";
// components
import { Grid, Stack } from "@mui/material";
import { FastCheckbox, FastDatePicker, FastField, FastSelect } from "../fast-fields";
import UploadImage from "./upload-image";
// source
import { CouponContext } from "src/providers/context";
import { couponTypeOptions } from "../source";

function ConstantFields() {
  const { categories, companyAddresses } = useContext(CouponContext);

  const categoriesForSelect = useMemo(
    () => categories.map(category => ({ label: category.title, value: category.id })),
    [categories]
  );
  const companyAddressesForSelect = useMemo(
    () => companyAddresses.map(address => ({ label: address.address, value: address.id })),
    [companyAddresses]
  );

  return (
    <Grid container spacing={4} px={4}>
      <Grid item xs={4}>
        <Stack spacing={2}>
          <UploadImage />
          <FastField field_key="thumbnail_alt" />
        </Stack>
      </Grid>
      <Grid item xs={8} sx={{ height: "auto" }}>
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <FastSelect field_key="categories" options={categoriesForSelect} multiple />
          </Grid>
          <Grid item xs={4} className="flex-center">
            <FastCheckbox field_key="sold_count_enabled" />
          </Grid>
          <Grid item xs={6}>
            <FastField field_key="slug" />
          </Grid>
          <Grid item xs={6}>
            <FastSelect field_key="addresses" options={companyAddressesForSelect} multiple />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={3}>
                <FastField field_key="coupon_price" type="number" />
              </Grid>
              <Grid item xs={12} md={3}>
                <FastField field_key="price" type="number" />
              </Grid>
              <Grid item xs={12} md={3}>
                <FastField field_key="discount" type="number" />
              </Grid>
              <Grid item xs={12} md={3}>
                <FastCheckbox field_key="discount_equal" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <FastDatePicker field_key="expire_at" />
          </Grid>
          <Grid item xs={3}>
            <FastSelect field_key="type" options={couponTypeOptions} />
          </Grid>
          <Grid item xs={6}>
            <FastField field_key="page_title" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(ConstantFields);
