import { useTranslation } from "react-i18next";
import { useState } from "react";
// components
import { Button, Grid, IconButton, Slide, Stack, SxProps, Typography } from "@mui/material";
// icons
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CouponPlans from "./coupon-plans";
import MemberPlans from "./member-plans";

const buttonStyles: SxProps = {
  minHeight: 150,
  fontSize: 22
};

interface Props {
  company_id: number;
}

export default function AssignPlan({ company_id }: Props) {
  const [selectedPlanType, setSelectedPlanType] = useState<"coupon" | "member" | "">("");

  const handleSelectCouponPlan = () => setSelectedPlanType("coupon");
  const handleSelectMemberPlan = () => setSelectedPlanType("member");
  const handleCancelMemberPlan = () => setSelectedPlanType("");

  const { t } = useTranslation();

  return (
    <Stack sx={{ width: "40vw" }} spacing={5} py={5} px={3}>
      <div>
        <Grid container justifyContent="center">
          <Slide timeout={500} direction="left" in={Boolean(selectedPlanType)}>
            <Grid item xs={1} sx={{ display: selectedPlanType ? "block" : "none" }}>
              <IconButton onClick={handleCancelMemberPlan}>
                <ChevronLeftIcon sx={{ fontSize: 30 }} />
              </IconButton>
            </Grid>
          </Slide>
          <Grid item xs={selectedPlanType ? 11 : 12}>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              {selectedPlanType ? t("assign_plan") : t("select_plan_type")}
            </Typography>
          </Grid>
        </Grid>
      </div>
      {!selectedPlanType ? (
        <div>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={4}>
              <Button sx={buttonStyles} fullWidth onClick={handleSelectCouponPlan}>
                {t("coupon_plans")}
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button sx={buttonStyles} fullWidth onClick={handleSelectMemberPlan}>
                {t("member_plans")}
              </Button>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div>
          {
            {
              coupon: (
                <Slide direction="up" in={Boolean(selectedPlanType)}>
                  <div>
                    <CouponPlans company_id={company_id} />
                  </div>
                </Slide>
              ),
              member: (
                <Slide direction="up" in={Boolean(selectedPlanType)}>
                  <div>
                    <MemberPlans company_id={company_id} />
                  </div>
                </Slide>
              )
            }[selectedPlanType]
          }
        </div>
      )}
    </Stack>
  );
}
