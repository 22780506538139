import { memo, PropsWithChildren } from "react";
// components
import { Link, LinkProps } from "@mui/material";

const isDevelopment = process.env.NODE_ENV === "development";

interface Props {
  to: string;
}

function DependModeRedirector({ children, to, ...props }: PropsWithChildren<Omit<LinkProps, "href"> & Props>) {
  return (
    <Link href={isDevelopment ? `http://localhost:4000/${to}` : `${process.env.REACT_APP_PUBLIC_URL}/${to}`} {...props}>
      {children}
    </Link>
  );
}

export default memo(DependModeRedirector);
