import { MediaResource } from "src/pages/coupons/upsert/photos/media-box";
import { AddMedia, FileType } from "src/pages/coupons/upsert/photos/upload-modal";
import { UpdateCoupon } from "src/pages/coupons/upsert/source";

export function createFormDataForUpdate(values: UpdateCoupon, PUT?: boolean) {
  const formData = new FormData();
  Object.entries(values).forEach(([key, value]) => {
    if (typeof value === "number") formData.append(key, String(value));
    else if (key === "prices")
      value.forEach((price: string, i: number) => {
        Object.entries(price).forEach(([key, value]) => {
          formData.append(`prices[${i}][${key}]`, String(value));
        });
      });
    else if (key === "addresses") {
      value.forEach((addressId: string, i: number) => {
        formData.append(`${key}[${i}]`, String(addressId));
      });
    } else formData.append(key, value);
  });
  if (PUT) formData.append("_method", "PUT");
  return formData;
}

export function createFormDataForUpload(values: Omit<MediaResource, "id">[]) {
  const formData = new FormData();
  values.forEach((element, i: number) => {
    if (element?.file) formData.append(`resource[${i}][file]`, element?.file ?? "");
    if (element?.alt) formData.append(`resource[${i}][alt]`, element?.alt ?? "");
    if (element?.url) formData.append(`resource[${i}][url]`, element?.url ?? "");
    formData.append(`resource[${i}][type]`, element.type);
  });
  return formData;
}

export function generateMediaFilesForMainFormData(values: AddMedia) {
  const array: { url?: string; file?: File; type: FileType; alt: null }[] = [];
  Object.entries(values).forEach(([key, value]) => {
    if (key === "images_preview")
      value.forEach((element: File) => {
        array.push({ file: element, type: "image", alt: null });
      });
    else if (key === "videos_preview")
      value.forEach((element: File) => {
        array.push({ file: element, type: "video", alt: null });
      });
    else if (key === "links_preview")
      value.forEach((element: string) => {
        array.push({ url: element, type: "link", alt: null });
      });
  });
  return array;
}
