import { memo, useState } from "react";
// components
import { Box, IconButton, Snackbar, TableCell, Typography } from "@mui/material";
// icons
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContactStatus from "./ContactStatus";

type Props = {
  isVerified?: boolean;
  showStatus?: boolean;
  label: string | null;
};

function PhoneCell({ label, isVerified, showStatus }: Props) {
  const [open, setOpen] = useState(false);

  const handleCopy = () => {
    if (label) {
      setOpen(true);
      navigator.clipboard.writeText(label);
    }
  };

  return (
    <TableCell sx={{ position: "relative" }}>
      <Box sx={{ display: "flex", alignItems: "center", columnGap: 0.5 }}>
        <Typography onClick={handleCopy} sx={{ textDecoration: "navy", textAlign: "center", width: "100%", cursor: "copy" }}>
          {label ? `+ ${label}` : "-"}
        </Typography>
        {label && (
          <IconButton title="Copy phone" onClick={handleCopy}>
            <ContentCopyIcon sx={{ fontSize: 14 }} />
          </IconButton>
        )}
        {showStatus && <ContactStatus isVerified={Boolean(isVerified)} />}
      </Box>
      <Snackbar open={open} onClose={() => setOpen(false)} autoHideDuration={1500} message="Copied to clipboard" />
    </TableCell>
  );
}

export default memo(PhoneCell);
