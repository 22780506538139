// hooks
import { Grid } from "@mui/material";
import moment from "moment";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormikProvider, useFormik } from "formik";
// components
import { memo, useContext } from "react";
import { Autocomplete, ResetForm, SubmitForm } from "../common";
import { DatePicker } from "src/components/common-components";
// context
import { FilterContext } from "src/providers/context";

export interface TransactionFilterProps {
  company: string;
  from: string;
  to: string;
  user: string;
}

function Transactions() {
  const { onFilter, filtering } = useContext(FilterContext);

  const formik = useFormik<TransactionFilterProps>({
    initialValues: {
      company: "",
      user: "",
      from: moment().startOf("year").format("YYYY/MM/DD"),
      to: moment().endOf("month").format("YYYY/MM/DD")
    },
    onSubmit(values) {
      const filteredValues = Object.fromEntries(Object.entries(values).filter(value => Boolean(value[1]) && value));
      onFilter(filteredValues);
    }
  });

  const { handleSubmit, resetForm } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <Grid container alignItems="center" justifyContent="flex-end" columnSpacing={2} rowGap={2} pt={2}>
          <Grid item xs={3}>
            <Autocomplete field_key="company" filterPathConfig="companies" />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete field_key="user" filterPathConfig="subscribers" />
          </Grid>
          <Grid item xs={3}>
            <DatePicker field_key="from" />
          </Grid>
          <Grid item xs={3}>
            <DatePicker field_key="to" />
          </Grid>
          <Grid item>
            <Grid container columnGap={2}>
              <Grid>
                <SubmitForm loading={filtering} />
              </Grid>
              <Grid>
                <ResetForm onClick={resetForm} loading={filtering} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormikProvider>
    </form>
  );
}

export default memo(Transactions);
