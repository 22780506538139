// components
import { Box, Pagination } from "@mui/material";
// types
import { memo, PropsWithChildren } from "react";
import { Pagination as PaginationProps } from "src/types/api";

interface Props {
  pagination_meta?: PaginationProps;
  onPageChange(page: number): void;
}

function WrapWithPagination({ children, pagination_meta, onPageChange }: PropsWithChildren<Props>) {
  return (
    <Box>
      {children}
      <Box sx={{ width: "100%", py: 1 }} className="flex-center">
        <Pagination
          page={pagination_meta?.page ?? 1}
          count={pagination_meta?.totalPages}
          onChange={(_, page) => onPageChange(page)}
        />
      </Box>
    </Box>
  );
}

export default memo(WrapWithPagination);
