export const tableHeadCellNames = [
  { id: 0, name_key: "name" },
  { id: 1, name_key: "email" },
  { id: 2, name_key: "telephone" },
  { id: 3, name_key: "obligation" },
  { id: 5, name_key: "registration_date" },
  { id: 7, name_key: "action" }
];

export const permissionsTableHeadCellNames = [
  { id: 1, name_key: "name" },
  { id: 2, name_key: "view" },
  { id: 3, name_key: "add" },
  { id: 4, name_key: "edit" },
  { id: 5, name_key: "delete" }
];
