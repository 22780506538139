// components
import { TableBody as MuiTableBody } from "@mui/material";
// types
import TableRow from "./TableRow";
import { Company } from "src/api/companies";

interface Props {
  companies: Company[];
}

export default function TableBody({ companies }: Props) {
  return (
    <MuiTableBody>
      {companies.map(company => (
        <TableRow key={`Company_line_${company.id}`} company={company} />
      ))}
    </MuiTableBody>
  );
}
