import Api from "src/api";
// hooks
import { useCallback, useState } from "react";
import { FormikErrors, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
// components
import { FieldsWrapper, Submit } from "src/components/common-components";
import UpsertForm from "./upsert-form";
// source
import { CreateSection, CreateSectionFormik } from "src/api/settings";
import { AddModalProps } from "src/types/components";
import { createSectionRequestBody } from "src/providers/lib";

export default function Add({ refetch, closeModal }: AddModalProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const createSection = useCallback(async (values: CreateSection) => {
    try {
      setLoading(true);
      const response = await Api.settings.sections.create(values);
      if (response.data) {
        closeModal();
        refetch();
        enqueueSnackbar(t("banner_created", { id: response.data.id }), { variant: "success" });
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        if (response.errors) setErrors(response.errors as FormikErrors<CreateSection>);
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, []);

  const formik = useFormik<CreateSectionFormik>({
    initialValues: {
      title_am: "",
      title_ru: "",
      title_en: "",
      categories: [],
      banners: [],
      has_loadmore: false,
      inner_banner: [],
      per_page: "",
      show_banner: "",
      group: "coupons",
      section_divider: "",
      coupon_type: []
    },
    onSubmit(values) {
      createSection(createSectionRequestBody(values));
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false
  });

  const { handleSubmit, setErrors } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <FieldsWrapper>
          <UpsertForm />
          <Submit title="add" loading={loading} />
        </FieldsWrapper>
      </FormikProvider>
    </form>
  );
}
