import { createContext } from "react";

export interface FilterContextProps {
  onFilter(params: object): void;
  filtering: boolean;
}

const onFilter = () => alert("onFilter fn is empty");

export const FilterContext = createContext<FilterContextProps>({ onFilter, filtering: false });

export const { Provider: FilterContextProvider } = FilterContext;
