import Api from "src/api";
import { memo, useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
// components
import { Box } from "@mui/material";

import { CompanyPositionInRequestBody } from "src/types/api/companies";
import { WrapWithAddButton, WrapWithLoading, WrapWithNoResult, WrapWithPagination } from "src/components/common-components";
import { PositionsTable } from "src/components/common-components/positions";
import { Meta, Pagination } from "src/types/api";
import { BaseModal } from "src/components/modal";
import AddPosition from "src/components/common-components/positions/upsert/add";

interface Props {
  id: number;
  closeModal: () => void;
}

function ChangePosition({ id }: Props) {
  const [loading, setLoading] = useState(false);
  const [positions, setPositions] = useState<CompanyPositionInRequestBody[]>([]);
  const [meta, setMeta] = useState<Meta | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const [openAddModal, setOpenAddModal] = useState(false);

  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => setOpenAddModal(false);

  const getPositions = useCallback(async (paginationParams?: Pagination) => {
    try {
      setLoading(true);
      const response = await Api.companies.getPosition(id, paginationParams);
      if (response.data) {
        setPositions(response.data);
        if (response.meta) setMeta(response.meta);
      } else enqueueSnackbar(response.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getPositions();
  }, [getPositions]);

  return (
    <Box sx={{ width: "auto", p: 4, minWidth: "70vw" }}>
      <WrapWithAddButton sx={{ width: "100%" }} handleAdd={handleOpenAddModal}>
        <WrapWithPagination pagination_meta={meta?.pagination} onPageChange={page => getPositions({ page })}>
          <WrapWithLoading loading={loading}>
            <WrapWithNoResult length={positions.length}>
              <PositionsTable positions={positions} place="company" companyId={id} refetch={getPositions} />
            </WrapWithNoResult>
          </WrapWithLoading>
        </WrapWithPagination>
      </WrapWithAddButton>
      <BaseModal open={openAddModal} onClose={handleCloseAddModal}>
        <AddPosition closeAddModal={handleCloseAddModal} place="company" id={id} refetch={getPositions} />
      </BaseModal>
    </Box>
  );
}

export default memo(ChangePosition);
