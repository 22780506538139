import { memo } from "react";
// components
import { TableBody as MuiTableBody, TableCell, TableRow } from "@mui/material";
import { Actions, EmailCell, PhoneCell, UserCell } from "src/components/common-components";
import { format } from "date-fns";
// types
import { ContactForm } from "src/api/contact-form";

interface Props {
  contactFormsList: ContactForm[];
  onDelete(id: number): void;
}

function TableBody({ contactFormsList, onDelete }: Props) {
  return (
    <MuiTableBody>
      {contactFormsList.map(contactForm => (
        <TableRow key={contactForm.id}>
          <UserCell label={`${contactForm.first_name} ${contactForm.last_name}`} />
          <EmailCell email={contactForm.email} />
          <PhoneCell label={contactForm.phone} />
          <TableCell>{contactForm.subject}</TableCell>
          <TableCell>{contactForm.message}</TableCell>
          <TableCell sx={{ whiteSpace: "nowrap" }}>{format(new Date(contactForm.created_at), "yyyy-MM-dd H:s")}</TableCell>
          <TableCell>
            <Actions actions={["remove"]} onDelete={() => onDelete(contactForm.id)} />
          </TableCell>
        </TableRow>
      ))}
    </MuiTableBody>
  );
}

export default memo(TableBody);
