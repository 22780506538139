import { memo, useMemo } from "react";
import { FormikProvider, useFormik, useFormikContext } from "formik";
// components
import { FieldsWrapper, Submit } from "src/components/common-components";
// source
import { UpdateCoupon } from "../../source";
import UpsertForm, { CreatePrice } from "./upsert-form";

interface Props {
  id: number;
  onClose(): void;
}

function EditPrice({ id, onClose }: Props) {
  const {
    setFieldValue,
    values: { prices }
  } = useFormikContext<UpdateCoupon>();

  const price = useMemo(() => prices.filter((_, index) => index === id), [id])[0];

  const formik = useFormik<CreatePrice>({
    initialValues: {
      id: price?.id ?? null,
      description_am: price?.description_am ?? "",
      description_ru: price?.description_ru ?? "",
      description_en: price?.description_en ?? "",
      title_am: price?.title_am ?? "",
      title_en: price?.title_en ?? "",
      title_ru: price?.title_ru ?? "",
      price: price?.price ?? "",
      discounted_price: price?.discounted_price ?? "",
      coupon_price: price?.coupon_price ?? "",
      discount: price?.discount ?? "",
      available: price?.available ?? ""
    },
    onSubmit(values) {
      setFieldValue(
        "prices",
        prices.map((price, index) => {
          if (index === id) return values;
          else return price;
        })
      );
      onClose();
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false
  });

  const { handleSubmit } = formik;

  return (
    <FieldsWrapper>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <UpsertForm />
          <Submit title="edit" loading={false} />
        </form>
      </FormikProvider>
    </FieldsWrapper>
  );
}

export default memo(EditPrice);
