import Api from "src/api";
import { useTranslation } from "react-i18next";
import { memo, useCallback, useState } from "react";
// components
import { ChartWrapper, Filter } from "../common";
// source
import { FilterParams } from "src/api/statistics";
import { format } from "date-fns";
import { WrapWithNoResult } from "src/components/common-components";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { formatNumber } from "../../../providers/lib";

function OffersSales() {
  const { t } = useTranslation();

  const [data, setData] = useState<{ name: string; slug: string; count: string; sum: string }[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalSum, setTotalSum] = useState<number>(0);
  const getData = useCallback(async (values: FilterParams) => {
    try {
      const response = await Api.statistics.getOfferSales({
        ...values,
        from: format(new Date(values.from), "yyyy-MM-dd"),
        to: format(new Date(values.to), "yyyy-MM-dd")
      });
      if (response.data) {
        setData(response.data.data);
        setTotalCount(response.data.count);
        setTotalSum(response.data.sum);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const tdStyles = {
    padding: "3px",
    fontSize: "13px"
  };

  return (
    <ChartWrapper>
      <Filter makeFilter={getData} chartType="coupons_sales" />
      <WrapWithNoResult length={data.length}>
        <Typography sx={{ fontWeight: 700, px: 2 }}>Статистика продаж по акциям</Typography>
        <Box sx={{ py: 2 }}>
          <Box sx={{ p: 2, height: "350px", overflowY: "auto" }}>
            <table border={1} style={{ width: "100%", border: "1px solid #ccc", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th style={tdStyles}>{t("offer")}</th>
                  <th style={tdStyles}>{t("count")}</th>
                  <th style={tdStyles}>{t("sum")}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={`offer_sale_${index}`}>
                    <td style={tdStyles}>
                      <a href={`https://biglemon.am/${row.slug}`} target="_blank" rel="noreferrer">
                        {row.name}
                      </a>
                    </td>
                    <td style={{ ...tdStyles, textAlign: "center" }}>
                      {row.count} {t("stat_coupons")}
                    </td>
                    <td style={{ ...tdStyles, textAlign: "center" }}>{formatNumber(row.sum)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
          <hr />
          <Typography sx={{ textAlign: "right", fontWeight: 700, px: 2 }}>
            {t("stat_coupons")}: {formatNumber(totalCount)} | {t("sum")}: {formatNumber(totalSum)}
          </Typography>
        </Box>
      </WrapWithNoResult>
    </ChartWrapper>
  );
}

export default memo(OffersSales);
