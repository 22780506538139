// components
import Chart, { Props as ApexChartProps } from "react-apexcharts";
// source
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../../providers/lib";

interface Props {
  title: string;
  data: ApexNonAxisChartSeries;
  labels: string[];
}

export default function PieChart({ title, data, labels, ...props }: Props | ApexChartProps) {
  const { t } = useTranslation();

  return (
    <Chart
      type="pie"
      series={data}
      options={{
        chart: {
          type: "bar",
          zoom: {
            enabled: false
          }
        },
        labels: labels,
        colors: [
          "#0f350c",
          "#084566",
          "#ff3bfc",
          "#009688",
          "#ff9800",
          "#0025ff",
          "#898181",
          "#140202",
          "#030214",
          "#bb0d09",
          "#bc0d01",
          "#04b71f"
        ],
        title: {
          text: t(title)
        },
        tooltip: {
          y: {
            formatter: val => {
              return formatNumber(val);
            }
          }
        }
      }}
      height="250"
      {...props}
    />
  );
}
