import { memo } from "react";
// components
import { Box, TableCell, Typography } from "@mui/material";

type Props = {
  money: string | number;
};

function PayCell({ money }: Props) {
  return (
    <TableCell sx={{ position: "relative" }}>
      <Box className="flex-center">
        <Typography>{money} ֏</Typography>
      </Box>
    </TableCell>
  );
}

export default memo(PayCell);
