import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
// components
import { Grid, TextFieldProps } from "@mui/material";
import { Select } from "src/components/common-components";
import { TextField } from "@mui/material";
// source
import { genderOptions } from "src/providers/source";
import { SubscriberOnUpdate } from "src/api/subscribers";

interface FieldProps {
  field_key: keyof SubscriberOnUpdate;
}

const Field = memo(function Field({ field_key, ...props }: FieldProps & TextFieldProps) {
  const { getFieldProps, errors } = useFormikContext<SubscriberOnUpdate>();
  const { t } = useTranslation();
  return (
    <TextField
      fullWidth
      {...getFieldProps(field_key)}
      label={t(field_key)}
      {...props}
      error={Boolean(errors[field_key])}
      helperText={errors[field_key]}
    />
  );
});

interface Props {
  disabled: boolean;
}

function UpsertForm({ disabled }: Props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field field_key="code" type="number" disabled={disabled} />
      </Grid>
      <Grid item xs={6}>
        <Field field_key="first_name" disabled={disabled} />
      </Grid>
      <Grid item xs={6}>
        <Field field_key="last_name" disabled={disabled} />
      </Grid>
      <Grid item xs={12}>
        <Select field_key="gender" options={genderOptions} disabled={disabled} />
      </Grid>
    </Grid>
  );
}

export default memo(UpsertForm);
