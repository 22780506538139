import { Pagination } from "src/types/api";

export function generateQueryParamsForFilter<T>(params?: { [key in keyof T]: unknown } | null, asContinuation = false) {
  if (params) {
    return (
      (asContinuation ? "&" : "?") +
      Object.entries(params)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, value]) => {
          if (value instanceof Array && value.length) return true;
          else if (typeof value === "number") return true;
          else if (typeof value === "string") return true;
        })
        .map(([key, value]) => {
          if (!(value instanceof Array)) return `filter[${key}]=${value}`;
          else return value.map(category => `filter[${key}][]=${category}`).join("&");
        })
        .join("&")
    );
  }
  return "";
}

export function generateQueryParamsForPagination(params?: Pagination, asQueryParamsContinuation = false) {
  if (params) {
    return `${asQueryParamsContinuation ? "&" : "?"}${Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join("&")}`;
  }
  return "";
}
