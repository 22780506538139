import { Grid } from "@mui/material";
import { Submit } from "src/components/common-components";
// source
import { PlanType } from "src/api/plans";
import PlanField from "./plan-field";
import { useMemo } from "react";

interface Props {
  plans: PlanType[];
  loading: boolean;
}

export default function UpsertForm({ plans, loading }: Props) {
  const plans_for_select = useMemo(() => plans.map(plan => ({ label: plan.name, value: plan.id, price: plan.price })), [plans]);

  return (
    <Grid container rowGap={4}>
      <Grid item xs={12}>
        <PlanField field_key="plan_id" options={plans_for_select} />
      </Grid>
      <Grid item xs={12}>
        <Submit title="assign" loading={loading} />
      </Grid>
    </Grid>
  );
}
