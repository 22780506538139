import Api from "src/api";
// hooks
import { useCallback, useState } from "react";
import { FormikErrors, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// components
import { FieldsWrapper, Submit } from "src/components/common-components";
import UpsertForm from "./upsert-form";
// source
import { CreateBlog } from "src/api/blog";
import { createFormDataWithImage } from "src/providers/lib";

export default function Add() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const createBlog = useCallback(async (values: FormData) => {
    try {
      setLoading(true);
      const response = await Api.blog.create(values);
      if (response.data) {
        enqueueSnackbar(t("blog_created", { id: response.data.id }), { variant: "success" });
        navigate(-1);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        if (response.errors) setErrors(response.errors as FormikErrors<CreateBlog>);
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, []);

  const formik = useFormik<CreateBlog>({
    initialValues: {
      title_am: "",
      title_ru: "",
      title_en: "",
      content_am: "",
      content_ru: "",
      content_en: "",
      excerpt_am: "",
      excerpt_ru: "",
      excerpt_en: "",
      thumbnail: "",
      is_top: 0
    },
    onSubmit(values) {
      const formData = createFormDataWithImage(values);
      createBlog(formData);
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false
  });

  const { handleSubmit, setErrors } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <FieldsWrapper>
          <UpsertForm />
          <Submit title="add" loading={loading} />
        </FieldsWrapper>
      </FormikProvider>
    </form>
  );
}
