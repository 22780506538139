// hooks
import { memo, useMemo, useRef } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
// components
import ReactQuill, { ReactQuillProps } from "react-quill";
import "react-quill/dist/quill.snow.css";
// styles
import { Box, FormHelperText } from "@mui/material";
import Api from "src/api";
interface Props {
  field_key: string;
}

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color"
];

function CustomDraft({ field_key, ...props }: Props & ReactQuillProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const quill: any = useRef();
  const { setFieldValue, errors, values } = useFormikContext</**temp*/ { [key: string]: string }>();
  const isError = errors[field_key];

  const { t } = useTranslation();

  const imageHandler = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      if (input?.files) {
        const file = input.files[0];

        if (/^image\//.test(file.type)) {
          saveToServer(file);
        } else {
          alert("You could only upload images.");
        }
      }
    };
  };

  async function saveToServer(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    const response = await Api.independent.uploadFile(formData);
    if (response.data) insertToEditor(response.data);
  }

  function insertToEditor(url: string) {
    quill.current.getEditor().insertEmbed(null, "image", url);
  }

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ color: ["Black", "WhiteSmoke", "Blue ", "Green", "Red", "Orange", "Violet", "Indigo", "Yellow"] }],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          ["image", "video", "link"],
          ["clean"]
        ],
        handlers: { image: imageHandler }
      },

      clipboard: {
        matchVisual: false
      }
    }),
    []
  );

  return (
    <Box sx={{ zIndex: 1 }}>
      <ReactQuill
        ref={quill}
        style={{ minHeight: "300px" }}
        placeholder={t(field_key)}
        modules={modules}
        formats={formats}
        theme="snow"
        value={(values[field_key] as string) ?? ""}
        onChange={e => setFieldValue(field_key, e)}
        className={(isError ? "custom-quill " : " ") + props.className}
        {...props}
      />
      {isError && <FormHelperText sx={{ color: "error.main" }}>{t("is_required")}</FormHelperText>}
    </Box>
  );
}

export default memo(CustomDraft);
