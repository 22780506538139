import { Box } from "@mui/material";
import { memo } from "react";
import Coupons from "./coupons";

function CouponsStore() {
  return (
    <Box sx={{ width: "80vw" }}>
      <Coupons />
    </Box>
  );
}

export default memo(CouponsStore);
