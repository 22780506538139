import { ReviewFilterProps } from "src/components/common-components/filter/company-reviews/Reviews";
import { generateQueryParamsForFilter, generateQueryParamsForPagination } from "src/providers/lib";
import { Pagination } from "src/types/api";
import ApiSlice from "../slice";
//types
import { CompanyFeedback, CompanyQuestion, CouponFeedback } from "./types";

export default class CompanyFollowers extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin`;
  static defaultAuth = true;

  static getList = (params: { paginationParams?: Pagination; filterParams?: ReviewFilterProps }) =>
    this.request<CouponFeedback[]>(
      `/coupon/comments${generateQueryParamsForFilter<ReviewFilterProps>(
        params?.filterParams ?? null
      )}${generateQueryParamsForPagination(params.paginationParams, Boolean(params?.filterParams))}`
    );
  static getSingle = (id: number) => this.request<CouponFeedback[]>(`/coupon/comments/${id}`);
  static delete = (id: number) => this.request(`/coupon/comments/${id}`, "DELETE");

  static getCompanyReviewList = (params: { paginationParams?: Pagination; filterParams?: ReviewFilterProps }) =>
    this.request<CompanyFeedback[]>(
      `/company/reviews${generateQueryParamsForFilter<ReviewFilterProps>(
        params?.filterParams ?? null
      )}${generateQueryParamsForPagination(params.paginationParams, Boolean(params?.filterParams))}`
    );
  static getCompanyReviewSingle = (id: number) => this.request<CompanyFeedback[]>(`/company/reviews/${id}`);
  static companyReviewDelete = (id: number) => this.request(`/company/reviews/${id}`, "DELETE");

  static getCompanyQuestionList = (params: { paginationParams?: Pagination; filterParams?: ReviewFilterProps }) =>
    this.request<CompanyQuestion[]>(
      `/company/questions${generateQueryParamsForFilter<ReviewFilterProps>(
        params?.filterParams ?? null
      )}${generateQueryParamsForPagination(params.paginationParams, Boolean(params?.filterParams))}`
    );
  static getCompanyQuestionSingle = (id: number) => this.request<CompanyQuestion[]>(`/company/questions/${id}`);
  static companyQuestionDelete = (id: number) => this.request(`/company/questions/${id}`, "DELETE");
}

export * from "./types";
