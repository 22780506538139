import Api from "src/api";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
// components
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { Submit } from "src/components/common-components";

interface Props {
  id: number;
  closeModal(): void;
  refetch(): void;
}

export default function AddBalance({ id, closeModal, refetch }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const handleAdd = useCallback(async (amount: number | null) => {
    if (amount) {
      try {
        setLoading(true);
        const response = await Api.subscribers.addBalance(id, amount);
        if (response.data) {
          enqueueSnackbar(t("subscriber_balance_updated", { id }), { variant: "success" });
          closeModal();
          refetch();
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
      } catch (error) {
        throw new Error(error as string);
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const formik = useFormik<{ amount: number | null }>({
    initialValues: {
      amount: null
    },
    onSubmit(values) {
      handleAdd(values.amount);
    }
  });

  const { handleSubmit, getFieldProps } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container rowSpacing={4} p={2} sx={{ maxWidth: "30vw" }}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("add_balance")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth type="number" label={t("balance")} {...getFieldProps("amount")} />
        </Grid>
        <Grid item xs={12}>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <Submit title={t("update")} loading={loading} />
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth onClick={closeModal}>
                {t("cancel")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
