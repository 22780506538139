import ApiSlice from "../slice";
// types
import { SubscribersFilterProps } from "src/components/common-components/filter/subscribers";
import { BuyCoupon, SignIn, Subscriber, SubscriberOnUpdate } from "./types";
import { ContactTypes, Pagination } from "src/types/api";
// helpers
import { generateQueryParamsForFilter, generateQueryParamsForPagination } from "src/providers/lib";

export default class Subscribers extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin/subscribers`;
  static defaultAuth = true;

  static getSubscribersList = ({
    filter_body,
    paginationParams
  }: {
    filter_body?: SubscribersFilterProps | null;
    paginationParams?: Pagination;
  }) =>
    this.request<Subscriber[]>(
      `${generateQueryParamsForFilter(filter_body ?? null)}${generateQueryParamsForPagination(
        paginationParams,
        Boolean(filter_body)
      )}`
    );

  static removeSubscriber = (id: number) => this.request(`/${id}`, "DELETE");

  static getSubscriber = (id: number) => this.request<Subscriber>(`/${id}`);

  static signIn = (id: number) => this.request<SignIn>(`/${id}/login`, "POST");

  static addBalance = (id: number, amount: number) => this.request<Subscriber>(`/balance/${id}`, "POST", { amount });

  static confirmContact = (id: number, type: ContactTypes) => this.request<Subscriber>(`/confirm/${id}`, "POST", { type });

  static updateSubscriber = (id: number, body: SubscriberOnUpdate) => this.request<Subscriber>(`/${id}`, "PUT", { ...body });

  static confirmPhone = (phone: string) => this.request<Subscriber>(`/code`, "POST", { phone });
  static addSubscriber = (body: SubscriberOnUpdate) => this.request<Subscriber>(`/`, "POST", { ...body });

  static buyCoupon = (body: BuyCoupon) => this.request<Subscriber>(`/buy/coupon`, "POST", body);
}

export * from "./types";
