export const companyFollowersTableHeadCellNames = [
  { id: 0, name_key: "subscriber" },
  { id: 1, name_key: "company" },
  { id: 2, name_key: "date" }
];

export const categoryFollowersTableHeadCellNames = [
  { id: 0, name_key: "subscriber" },
  { id: 1, name_key: "category" },
  { id: 2, name_key: "date" }
];
