import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
// components
import { Button, Collapse, IconButton, TableCell, TableRow as MuiTableRow } from "@mui/material";
import { Actions, BalanceCell, EmailCell, PhoneCell, UserCell, DateCell } from "src/components/common-components";
import Coupons from "../coupons";
// types
import { Subscriber } from "src/api/subscribers";
import { SubscriberActionsContext } from "src/providers/context";
// icons
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Api from "src/api";
import { useSnackbar } from "notistack";
import { BaseModal } from "src/components/modal";
import CouponsStore from "../actions/coupons-store";
import { SubscriberCouponsStoreContextProvider } from "src/providers/context/SubscriberCouponsStoreContext";
import MoreInfo from "../actions/more-info";

interface Props {
  subscriber: Subscriber;
}

const isDevelopment = process.env.NODE_ENV === "development";

export default function TableRow({ subscriber }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { onDelete, onAddBalance, onConfirm } = useContext(SubscriberActionsContext);

  const [coupons, setCoupons] = useState({ open: false });
  const [openCouponsStore, setOpenCouponsStore] = useState(false);
  const [openMoreInformation, setOpenMoreInformation] = useState(false);

  const handleCouponsView = () => {
    setCoupons(({ open }) => ({ open: !open }));
  };

  const closeCoupons = () => setCoupons({ open: false });

  const handleCouponsStoreOpen = () => setOpenCouponsStore(true);
  const handleCouponsStoreClose = () => setOpenCouponsStore(false);

  const handleSubscriberMoreInfoOpen = () => setOpenMoreInformation(true);
  const handleSubscriberMoreInfoClose = () => setOpenMoreInformation(false);

  const handleForceSignIn = useCallback(async () => {
    try {
      const response = await Api.subscribers.signIn(subscriber.id);
      if (response.data) {
        window.open(
          isDevelopment
            ? `http://localhost:4000/auth/force-login?token=${response.data.auth.token}`
            : `${process.env.REACT_APP_PUBLIC_URL}/auth/force-login?token=${response.data.auth.token}`,
          "_blank"
        );
        enqueueSnackbar(t("success"), { variant: "success" });
      } else enqueueSnackbar(response.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    }
  }, [subscriber]);

  return (
    <>
      <MuiTableRow key={subscriber.id}>
        <TableCell>
          <IconButton onClick={handleCouponsView} title={t(coupons.open ? "hide_contact_persons" : "view_contact_persons")}>
            {coupons.open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </IconButton>
        </TableCell>
        <UserCell label={subscriber.full_name} />
        <EmailCell email={subscriber.email} isVerified={Boolean(subscriber.email_verified_at)} showStatus />
        <PhoneCell label={subscriber.phone} isVerified={Boolean(subscriber.phone_verified_at)} showStatus />
        <BalanceCell label={subscriber.balance} />
        <TableCell>
          <Button onClick={handleSubscriberMoreInfoOpen} sx={{ py: 0.5 }}>
            {t("see")}
          </Button>
        </TableCell>
        <DateCell date={subscriber.created_at} />
        <TableCell>
          <Actions
            position="flex-end"
            actions={[
              "sign_in",
              "view_coupons",
              "add_balance",
              "remove",
              subscriber.confirmed ? null : "confirm",
              "coupons_store"
            ]}
            onDelete={() => onDelete(subscriber.id)}
            onAddBalance={() => onAddBalance(subscriber.id)}
            onConfirm={() => onConfirm({ id: subscriber.id, email: subscriber.email, phone: subscriber.phone })}
            onViewCoupons={handleCouponsView}
            onSignIn={handleForceSignIn}
            onCouponsStore={handleCouponsStoreOpen}
          />
        </TableCell>
      </MuiTableRow>
      <MuiTableRow>
        <TableCell sx={{ p: 0 }} colSpan={9}>
          <Collapse in={coupons.open} timeout="auto" unmountOnExit>
            <Coupons id={subscriber.id} onClose={closeCoupons} />
          </Collapse>
        </TableCell>
        <BaseModal open={openCouponsStore} onClose={handleCouponsStoreClose}>
          <SubscriberCouponsStoreContextProvider value={{ subscriberId: subscriber.id, closeModal: handleCouponsStoreClose }}>
            <CouponsStore />
          </SubscriberCouponsStoreContextProvider>
        </BaseModal>
        <BaseModal open={openMoreInformation} onClose={handleSubscriberMoreInfoClose}>
          <MoreInfo subscriber={subscriber} />
        </BaseModal>
      </MuiTableRow>
    </>
  );
}
