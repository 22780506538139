import { useTranslation } from "react-i18next";
import { memo, useMemo } from "react";
// components
import { CircularProgress, Grid, IconButton } from "@mui/material";
// icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LoginIcon from "@mui/icons-material/Login";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import AddCardIcon from "@mui/icons-material/AddCard";
import CheckIcon from "@mui/icons-material/Check";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import PreviewIcon from "@mui/icons-material/Preview";
import BlockIcon from "@mui/icons-material/Block";
import ContactsIcon from "@mui/icons-material/Contacts";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StoreIcon from "@mui/icons-material/Store";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PictureInPictureIcon from "@mui/icons-material/PictureInPicture";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import AttachmentIcon from "@mui/icons-material/Attachment";
import PasswordIcon from "@mui/icons-material/Password";
import DownloadIcon from "@mui/icons-material/Download";

export type ActionTypes =
  | "sign_in"
  | "view"
  | "download"
  | "view_coupons"
  | "add_balance"
  | "change_password"
  | "confirm"
  | "send_email"
  | "view"
  | "edit"
  | "remove"
  | "cancel"
  | "activate"
  | "view_contact_persons"
  | "change_status"
  | "make_top"
  | "make_hot"
  | "cancel_top"
  | "cancel_hot"
  | "view_purchased_coupons"
  | "coupons_store"
  | "block"
  | "buy"
  | "assign_manager"
  | "change_position"
  | "view_company_coupons"
  | "send_sms"
  | "assign_plan"
  | null;

export type ActionsGroupProps = {
  actions: ActionTypes[];
  position?: "center" | "flex-end" | "flex-start";
  onSignIn?(): void;
  onViewCoupons?(): void;
  onAddBalance?(): void;
  onChangePassword?(): void;
  onConfirm?(): void;
  onSendEmail?(): void;
  onView?(): void;
  onEdit?(): void;
  onDownload?(): void;
  onDelete?(): void;
  onActivate?(): void;
  onCancel?(): void;
  onChangeStatus?(): void;
  onViewContactPersons?(): void;
  onMakeTop?(): void;
  onMakeHot?(): void;
  onCancelTop?(): void;
  onCancelHot?(): void;
  onViewPurchasedCoupons?(): void;
  onBlock?(): void;
  onCouponsStore?(): void;
  onBuy?(): void;
  onAssignManager?(): void;
  onCompanyCouponsView?(): void;
  onPositionChange?(): void;
  onSmsSend?(): void;
  onAssignPlan?(): void;
  loading?: boolean;
};

function ActionsGroup({
  actions,
  onSignIn,
  onViewCoupons,
  onViewContactPersons,
  onAddBalance,
  onChangePassword,
  onConfirm,
  onSendEmail,
  onView,
  onDownload,
  onEdit,
  onDelete,
  onCancel,
  onChangeStatus,
  onActivate,
  onMakeHot,
  onMakeTop,
  onCancelTop,
  onCancelHot,
  onViewPurchasedCoupons,
  onBlock,
  onCouponsStore,
  onBuy,
  onAssignManager,
  onCompanyCouponsView,
  onPositionChange,
  onSmsSend,
  onAssignPlan,
  position = "center",
  loading
}: ActionsGroupProps) {
  const { t } = useTranslation();

  const allActions = useMemo(
    (): { field_key: ActionTypes; Component: () => JSX.Element }[] => [
      {
        field_key: "coupons_store",
        Component: () => (
          <IconButton title={t("coupons_store")} onClick={onCouponsStore}>
            <StoreIcon />
          </IconButton>
        )
      },
      {
        field_key: "sign_in",
        Component: () => (
          <IconButton title={t("sign_in")} onClick={onSignIn}>
            <LoginIcon sx={{ color: "primary.main" }} />
          </IconButton>
        )
      },
      {
        field_key: "view_coupons",
        Component: () => (
          <IconButton title={t("view_coupons")} onClick={onViewCoupons}>
            <ConfirmationNumberIcon sx={{ color: "#ff4040" }} />
          </IconButton>
        )
      },
      {
        field_key: "view_contact_persons",
        Component: () => (
          <IconButton title={t("view_contact_persons")} onClick={onViewContactPersons}>
            <ContactsIcon sx={{ color: "#3a862e" }} />
          </IconButton>
        )
      },
      {
        field_key: "assign_plan",
        Component: () => (
          <IconButton title={t("assign_plan")} onClick={onAssignPlan}>
            <AttachmentIcon />
          </IconButton>
        )
      },
      {
        field_key: "assign_manager",
        Component: () => (
          <IconButton title={t("assign_manager")} onClick={onAssignManager}>
            <AssignmentIndIcon sx={{ color: "primary.main" }} />
          </IconButton>
        )
      },
      {
        field_key: "view_company_coupons",
        Component: () => (
          <IconButton title={t("view_company_coupons")} onClick={onCompanyCouponsView}>
            <ManageSearchIcon sx={{ color: "rgb(5, 73, 181)" }} />
          </IconButton>
        )
      },
      {
        field_key: "add_balance",
        Component: () => (
          <IconButton title={t("add_balance")} onClick={onAddBalance}>
            <AddCardIcon sx={{ color: "#ffb100" }} />
          </IconButton>
        )
      },
      {
        field_key: "change_password",
        Component: () => (
          <IconButton title={t("change_password")} onClick={onChangePassword}>
            <PasswordIcon sx={{ color: "#8f6c67" }} />
          </IconButton>
        )
      },
      {
        field_key: "confirm",
        Component: () => (
          <IconButton title={t("confirm")} onClick={onConfirm}>
            <TaskAltIcon sx={{ color: "#79e200" }} />
          </IconButton>
        )
      },
      {
        field_key: "block",
        Component: () => (
          <IconButton title={t("block")} onClick={onBlock}>
            <BlockIcon sx={{ color: "error.main" }} />
          </IconButton>
        )
      },
      {
        field_key: "send_email",
        Component: () => (
          <IconButton title={t("send_email")} onClick={onSendEmail}>
            <ScheduleSendIcon />
          </IconButton>
        )
      },
      {
        field_key: "send_sms",
        Component: () => (
          <IconButton title={t("send_sms")} onClick={onSmsSend}>
            <ForwardToInboxIcon sx={{ color: "#9a02d1" }} />
          </IconButton>
        )
      },
      {
        field_key: "view",
        Component: () => (
          <IconButton title={t("view")} onClick={onView}>
            <PreviewIcon sx={{ color: "rgb(5, 73, 181)" }} />
          </IconButton>
        )
      },
      {
        field_key: "make_hot",
        Component: () => (
          <IconButton title={t("make_hot")} onClick={onMakeHot}>
            <LocalFireDepartmentIcon sx={{ color: "rgb(245, 85, 32)" }} />
          </IconButton>
        )
      },
      {
        field_key: "make_top",
        Component: () => (
          <IconButton title={t("make_top")} onClick={onMakeTop}>
            <DoubleArrowIcon sx={{ color: "error.main", transform: "rotate(-90deg)" }} />
          </IconButton>
        )
      },
      {
        field_key: "cancel_hot",
        Component: () => (
          <IconButton title={t("cancel_hot")} onClick={onCancelHot}>
            <AcUnitIcon sx={{ color: "rgb(113, 209, 209)" }} />
          </IconButton>
        )
      },
      {
        field_key: "cancel_top",
        Component: () => (
          <IconButton title={t("cancel_top")} onClick={onCancelTop}>
            <DoubleArrowIcon sx={{ color: "rgb(25, 135, 84)", transform: "rotate(90deg)" }} />
          </IconButton>
        )
      },
      {
        field_key: "change_status",
        Component: () => (
          <IconButton title={t("change_status")} onClick={onChangeStatus}>
            <PublishedWithChangesIcon sx={{ color: "rgb(245, 213, 32)" }} />
          </IconButton>
        )
      },
      {
        field_key: "view_purchased_coupons",
        Component: () => (
          <IconButton title={t("view_purchased_coupons")} onClick={onViewPurchasedCoupons}>
            <ShoppingCartIcon sx={{ color: "rgb(104, 110, 106)" }} />
          </IconButton>
        )
      },
      {
        field_key: "change_position",
        Component: () => (
          <IconButton title={t("change_position")} onClick={onPositionChange}>
            <PictureInPictureIcon sx={{ color: "primary.main" }} />
          </IconButton>
        )
      },
      {
        field_key: "edit",
        Component: () => (
          <IconButton title={t("edit")} onClick={onEdit}>
            <EditIcon sx={{ color: "primary.main" }} />
          </IconButton>
        )
      },
      {
        field_key: "remove",
        Component: () => (
          <IconButton title={t("remove")} onClick={onDelete}>
            <DeleteIcon sx={{ color: "error.main" }} />
          </IconButton>
        )
      },
      {
        field_key: "activate",
        Component: () => (
          <IconButton title={t("activate")} onClick={onActivate}>
            <CheckIcon />
          </IconButton>
        )
      },
      {
        field_key: "download",
        Component: () => (
          <IconButton title={t("download")} onClick={onDownload}>
            <DownloadIcon sx={{ color: "#ff4040" }} />
          </IconButton>
        )
      },
      {
        field_key: "cancel",
        Component: () => (
          <IconButton title={t("cancel")} onClick={onCancel}>
            <BlockIcon />
          </IconButton>
        )
      },
      {
        field_key: "buy",
        Component: () => (
          <IconButton title={t("buy")} onClick={onBuy}>
            <ShoppingBagIcon sx={{ color: "#8f6c67" }} />
          </IconButton>
        )
      }
    ],
    []
  );

  const filteredActionsGroup = allActions.filter(({ field_key }) => actions.includes(field_key));

  return (
    <Grid container justifyContent={position} flexWrap="nowrap">
      {filteredActionsGroup.map(({ field_key, Component }, key) => (
        <Grid item key={`${field_key}_${key}`}>
          {loading ? <CircularProgress sx={{ maxWidth: 24, maxHeight: 24 }} /> : <Component />}
        </Grid>
      ))}
    </Grid>
  );
}

export default memo(ActionsGroup);
