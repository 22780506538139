import Api from "src/api";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
// hooks
import { FormikErrors, FormikProvider, useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
// components
import Fields from "./Fields";
import { FieldsWrapper, Submit, WrapWithLoading } from "src/components/common-components";
// types
import { CompaniesPlanFieldTypes, CompaniesPlanType } from "src/api/plans";
import { UpdateModalProps } from "../source";
import { CompaniesPositionType } from "src/types/api/companies";

function UpdateMemberPlan({ planId, refetch, closeModal }: UpdateModalProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [currentPlanInformation, setCurrentPlanInformation] = useState<CompaniesPlanType | null>(null);
  const [planLoading, setPlanLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const updatePlan = useCallback(
    async (body: CompaniesPlanFieldTypes) => {
      if (planId) {
        try {
          setLoading(true);
          const response = await Api.plans.updateMembershipPlan(planId, body);
          setLoading(false);
          if (!response.error) {
            enqueueSnackbar(t("membership_plan_updated", { name: response.data?.name }), { variant: "success" });
            refetch();
            closeModal();
          } else {
            enqueueSnackbar(response.message, { variant: "error" });
            if (response.errors) setErrors(response.errors as FormikErrors<CompaniesPlanFieldTypes>);
          }
        } catch (error) {
          throw new Error(error as string);
        }
      }
    },
    [refetch, closeModal, planId]
  );

  const getCurrentPlanInformation = useCallback(async () => {
    if (planId) {
      try {
        const response = await Api.plans.getSingleMembershipPlan(planId);
        setPlanLoading(false);
        if (response?.data) {
          const data: { positions: CompaniesPositionType[] } = JSON.parse(response.data.data as string);
          setCurrentPlanInformation({ ...response.data, data });
        }
      } catch (error) {
        throw new Error(error as string);
      }
    }
  }, [planId]);

  const formik = useFormik<CompaniesPlanFieldTypes>({
    initialValues: {
      name_am: currentPlanInformation?.name_am ?? "",
      name_ru: currentPlanInformation?.name_ru ?? "",
      name_en: currentPlanInformation?.name_en ?? "",
      description_am: currentPlanInformation?.description_am ?? "",
      description_ru: currentPlanInformation?.description_ru ?? "",
      description_en: currentPlanInformation?.description_en ?? "",
      price: currentPlanInformation?.price ?? "",
      period: currentPlanInformation?.period ?? "",
      visible: Boolean(currentPlanInformation?.visible) ?? false,
      contact_us: Boolean(currentPlanInformation?.contact_us) ?? false,
      positions:
        typeof currentPlanInformation?.data !== "string"
          ? (currentPlanInformation?.data?.positions as CompaniesPositionType[]) ?? []
          : [],
      full_content: currentPlanInformation?.full_content || 0,
      discount: currentPlanInformation?.discount || ""
    },
    onSubmit(values) {
      updatePlan(values);
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false
  });

  useEffect(() => {
    getCurrentPlanInformation();
  }, []);

  const { handleSubmit, setErrors } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <FieldsWrapper title="update_member_plan" additional={planId}>
          <WrapWithLoading loading={planLoading}>
            <Fields />
          </WrapWithLoading>
          <Submit loading={loading} title="update" />
        </FieldsWrapper>
      </FormikProvider>
    </form>
  );
}

export default UpdateMemberPlan;
