import { Box, Paper } from "@mui/material";
import { ReactNode } from "react";

type Props = { withGutter?: boolean; children: ReactNode };

export default function TableWrapper({ children, withGutter }: Props) {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", width: "100%", my: withGutter ? 4 : 0 }}>
      <Paper elevation={15} sx={{ width: { xs: "100%" }, overflowX: "auto" }}>
        {children}
      </Paper>
    </Box>
  );
}
