import ApiSlice from "src/api/slice";
// types
import { Navigation as NavigationType } from "./types";

export default class Navigation extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin/navigation`;
  static defaultAuth = true;

  static getList = () => this.request<NavigationType[]>();
  static getSingle = (id: number) => this.request<NavigationType>(`/${id}`);
  static update = (id: number, body: FormData) => this.request<NavigationType>(`/${id}`, "POST", body);
  static create = (body: FormData) => this.request<NavigationType>(``, "POST", body);
  static delete = (id: number) => this.request(`/${id}`, "DELETE");
}

export * from "./types";
