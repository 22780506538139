import { CreateSection, CreateSectionFormik } from "src/api/settings";

export function createSectionRequestBody({
  title_am,
  title_ru,
  title_en,
  group,
  inner_banner,
  categories,
  has_loadmore,
  per_page,
  coupon_type,
  show_banner,
  banners,
  section_divider
}: CreateSectionFormik): CreateSection {
  return {
    title_am,
    title_ru,
    title_en,
    group,
    filters: JSON.stringify({
      banners,
      inner_banner,
      categories,
      has_loadmore,
      per_page: per_page ? per_page : 8,
      show_banner,
      section_divider,
      coupon_type
    })
  };
}
