import Api from "src/api";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
// components
import { Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { Editor, Select, WrapWithLoading } from "src/components/common-components";
import UploadImage from "./upload-image";
// source
import { CreateNavigation } from "src/api/settings";
import { ParentCategory } from "src/api/independent";

interface FieldProps {
  field_key: keyof CreateNavigation;
}

const Field = ({ field_key }: FieldProps) => {
  const { t } = useTranslation();
  const { getFieldProps, errors } = useFormikContext<CreateNavigation>();
  return (
    <TextField
      fullWidth
      {...getFieldProps(field_key)}
      label={t(field_key)}
      error={Boolean(errors[field_key])}
      helperText={errors[field_key]}
    />
  );
};

function UpsertForm() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<ParentCategory[]>([]);

  const getCategories = useCallback(async () => {
    try {
      const response = await Api.independent.getCategories();
      if (response.data) {
        setLoading(false);
        setCategories(response.data);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const categoryOptions = useMemo(
    () => categories.map(category => ({ label: category.title, value: category.id })),
    [categories]
  );

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <div>
      <Grid container rowGap={2} columnSpacing={2}>
        <Grid item xs={12}>
          <Grid container columnSpacing={2} rowGap={2}>
            <Grid item xs={12} xl={12} className="flex-center">
              <Paper elevation={10} sx={{ height: "100%", width: "100%", pt: 1, overflow: "hidden" }}>
                <Stack spacing={2} sx={{ height: "100%" }} justifyContent="space-between">
                  <Typography variant="subtitle2" sx={{ fontSize: 12, px: 0.5 }}>
                    {t("icon")}
                  </Typography>
                  <UploadImage field_key="icon" />
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Field field_key="title_am" />
        </Grid>
        <Grid item xs={4}>
          <Field field_key="title_ru" />
        </Grid>
        <Grid item xs={4}>
          <Field field_key="title_en" />
        </Grid>
        <Grid item xs={12}>
          <Editor field_key="seo_text_am" />
        </Grid>
        <Grid item xs={12}>
          <Editor field_key="seo_text_ru" />
        </Grid>
        <Grid item xs={12}>
          <Editor field_key="seo_text_en" />
        </Grid>

        <Grid item xs={12}>
          <WrapWithLoading loading={loading}>
            <Select options={categoryOptions} field_key="parent_id" />
          </WrapWithLoading>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(UpsertForm);
