import Api from "src/api";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
// components
import { Box, Table } from "@mui/material";
import { TableHead, TableWrapper, WrapWithAddButton, WrapWithLoading } from "src/components/common-components";
import { BaseModal, DeleteModal } from "src/components/modal";
import { AddSection, UpdateSection } from "./upsert";
import { TableBody } from "./table";
// source
import { Banner, Section, SectionGroups } from "src/api/settings";
import { tableHeadCellNames } from "./source";
import { withModals, withModalsProps } from "src/components/hoc";
import { SectionsProvider } from "src/providers/context/SectionsContext";
import { ParentCategory } from "src/api/independent";

function Sections({
  mainModal,
  deleteModal,
  openDeleteModal,
  openMainModalForAdding,
  openMainModalForUpdating,
  closeDeleteModal,
  closeMainModal
}: withModalsProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState<Section[]>([]);
  const [categories, setCategories] = useState<ParentCategory[]>([]);
  const [banners, setBanners] = useState<Banner[]>([]);
  const [sectionGroups, setSectionGroups] = useState<SectionGroups[]>([]);

  const getSections = useCallback(async () => {
    try {
      const [sections, sectionGroups, categories, banners] = await Promise.all([
        Api.settings.sections.getList(),
        Api.settings.sections.getGroups(),
        Api.independent.getCategories(),
        Api.settings.banners.getList()
      ]);
      if (sections.data) setSections(sections.data);
      else enqueueSnackbar(sections.message, { variant: "error" });
      if (sectionGroups.data) setSectionGroups(sectionGroups.data);
      else enqueueSnackbar(sectionGroups.message, { variant: "error" });
      if (categories.data) setCategories(categories.data);
      else enqueueSnackbar(categories.message, { variant: "error" });
      if (banners.data) setBanners(banners.data);
      else enqueueSnackbar(banners.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDelete = useCallback(async () => {
    if (deleteModal?.id) {
      try {
        setDeleting(true);
        const response = await Api.settings.sections.delete(deleteModal.id);
        setDeleting(false);
        if (response.status === "success") {
          enqueueSnackbar(t("section_deleted", { id: deleteModal.id }), { variant: "success" });
        } else enqueueSnackbar(response.message, { variant: "error" });
      } catch (error) {
        throw new Error(error as string);
      } finally {
        closeDeleteModal();
        await getSections();
      }
    }
  }, [deleteModal]);

  const handleOrderToUp = useCallback(async (index: number, array: Section[]) => {
    const temp_array = array;
    const temp = temp_array[index];
    temp_array[index] = temp_array[index - 1];
    temp_array[index - 1] = temp;

    try {
      setLoading(true);
      const response = await Api.settings.sections.updateOrder(
        temp_array.map(element => ({
          id: element.id
        }))
      );
      if (response.status === "success") enqueueSnackbar("Success", { variant: "success" });
      else enqueueSnackbar(response.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    } finally {
      await getSections();
      setLoading(false);
    }
  }, []);

  const handleOrderToDown = useCallback(async (index: number, array: Section[]) => {
    const temp_array = array;
    const temp = temp_array[index];
    temp_array[index] = temp_array[index + 1];
    temp_array[index + 1] = temp;

    try {
      setLoading(true);
      const response = await Api.settings.sections.updateOrder(
        temp_array.map(element => ({
          id: element.id
        }))
      );
      if (response.status === "success") enqueueSnackbar("Success", { variant: "success" });
      else enqueueSnackbar(response.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    } finally {
      await getSections();
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getSections();
  }, [getSections]);

  return (
    <SectionsProvider value={{ sectionGroups, categories, banners }}>
      <WrapWithAddButton title="sections" sx={{ width: "80%" }} handleAdd={openMainModalForAdding}>
        <WrapWithLoading loading={loading}>
          <TableWrapper>
            <Table>
              <TableHead cellNames={tableHeadCellNames} />
              <TableBody
                onOrderToDown={handleOrderToDown}
                onOrderToUp={handleOrderToUp}
                sections={sections}
                onDelete={openDeleteModal}
                onEdit={openMainModalForUpdating}
              />
            </Table>
          </TableWrapper>
        </WrapWithLoading>
        <BaseModal open={mainModal?.open} onClose={closeMainModal} disableBackdropClick>
          <Box sx={{ width: "60vw" }}>
            {(() => {
              if (mainModal?.for) {
                switch (mainModal.for) {
                  case "add":
                    return <AddSection refetch={getSections} closeModal={closeMainModal} />;
                  case "edit":
                    return mainModal?.id && <UpdateSection refetch={getSections} closeModal={closeMainModal} id={mainModal.id} />;
                  default:
                    return <p>Unknown form</p>;
                }
              }
            })()}
          </Box>
        </BaseModal>
        <DeleteModal open={deleteModal?.open} onClose={closeDeleteModal} onDelete={handleDelete} loading={deleting} />
      </WrapWithAddButton>
    </SectionsProvider>
  );
}

export default withModals(Sections);
