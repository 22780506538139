import ApiSlice from "src/api/slice";
import { CreateRegion, Region } from "./types";

export default class Regions extends ApiSlice {
  static baseUrl = `${ApiSlice.baseUrl}/admin/regions`;
  static defaultAuth = true;

  static getList = () => this.request<Region[]>();
  static getSingle = (id: number) => this.request<Region>(`/${id}`);
  static update = (id: number, body: CreateRegion) => this.request<Region>(`/${id}`, "POST", { ...body, _method: "PUT" });
  static create = (body: CreateRegion) => this.request<Region>(``, "POST", body);
  static delete = (id: number) => this.request(`/${id}`, "DELETE");
}
