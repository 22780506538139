import { memo } from "react";
// components
import { Chip, TableCell } from "@mui/material";
// icons
import FaceIcon from "@mui/icons-material/Face";

type Props = {
  label: string;
};

function UserCell({ label }: Props) {
  return (
    <TableCell title={label}>
      <Chip icon={<FaceIcon />} label={label} sx={{ bgcolor: "transparent", width: "100%", justifyContent: "flex-start" }} />
    </TableCell>
  );
}

export default memo(UserCell);
