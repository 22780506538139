import { Divider, Grid, TextField, TextFieldProps, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { memo } from "react";
import { useTranslation } from "react-i18next";
// components
import { CouponPrice } from "src/api/coupons";

export type CreatePrice = Omit<CouponPrice, "coupon_id" | "title" | "description" | "sold">;

interface FieldProps {
  field_key: keyof CreatePrice;
}

const Field = ({ field_key, ...props }: FieldProps & TextFieldProps) => {
  const { t } = useTranslation();
  const { getFieldProps, errors } = useFormikContext<CreatePrice>();
  return (
    <TextField
      fullWidth
      {...getFieldProps(field_key)}
      label={t(field_key)}
      error={Boolean(errors[field_key])}
      helperText={errors[field_key]}
      {...props}
    />
  );
};

function UpsertForm() {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<CreatePrice>();

  return (
    <Grid container columnSpacing={2} rowGap={3} pb={3}>
      <Grid item xs={12}>
        <Typography variant="h6">{t("content")}</Typography>
        <Divider sx={{ mt: 2 }} />
      </Grid>
      <Grid item xs={4}>
        <Field field_key="title_am" />
      </Grid>
      <Grid item xs={4}>
        <Field field_key="title_ru" />
      </Grid>
      <Grid item xs={4}>
        <Field field_key="title_en" />
      </Grid>
      <Grid item xs={4}>
        <Field field_key="description_am" rows={4} multiline />
      </Grid>
      <Grid item xs={4}>
        <Field field_key="description_ru" rows={4} multiline />
      </Grid>
      <Grid item xs={4}>
        <Field field_key="description_en" rows={4} multiline />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">{t("prices")}</Typography>
        <Divider sx={{ mt: 2 }} />
      </Grid>
      <Grid item xs={12}>
        <Field field_key="coupon_price" type="number" size="small" />
      </Grid>
      <Grid item xs={6}>
        <Field
          field_key="price"
          onChange={e => {
            setFieldValue("price", e.target.value);
            if (values.discount) setFieldValue("discount", "");
            if (values.discounted_price) setFieldValue("discounted_price", "");
          }}
          type="number"
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          field_key="discount"
          onChange={e => {
            setFieldValue("discount", e.target.value);
            if (values.price)
              setFieldValue(
                "discounted_price",
                (Number(values.price) - (Number(values.price) * Number(e.target.value)) / 100).toFixed(0)
              );
          }}
          type="number"
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          field_key="discounted_price"
          onChange={e => {
            setFieldValue("discounted_price", e.target.value);
            if (values.price)
              setFieldValue(
                "discount",
                (((Number(values.price) - Number(e.target.value)) * 100) / Number(values.price)).toFixed(0)
              );
          }}
          type="number"
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <Field field_key="available" type="number" size="small" />
      </Grid>
    </Grid>
  );
}

export default memo(UpsertForm);
