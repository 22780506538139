import Api from "src/api";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
// components
import { Box, Table } from "@mui/material";
import { TableHead, TableWrapper, WrapWithAddButton, WrapWithLoading } from "src/components/common-components";
import { TableBody } from "./table";
import { BaseModal, DeleteModal } from "src/components/modal";
import { AddNavigation, UpdateNavigation } from "./upsert";
// source
import { Navigation as NavType } from "src/api/settings";
import { tableHeadCellNames } from "./source";
import { withModals, withModalsProps } from "src/components/hoc";

function Navigation({
  mainModal,
  deleteModal,
  openDeleteModal,
  openMainModalForAdding,
  openMainModalForUpdating,
  closeDeleteModal,
  closeMainModal
}: withModalsProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [navigation, setNavigation] = useState<NavType[]>([]);

  const getNavigationList = useCallback(async () => {
    try {
      const response = await Api.settings.navigation.getList();
      if (response.data) {
        setLoading(false);
        setNavigation(response.data);
      } else enqueueSnackbar(response.message, { variant: "error" });
    } catch (error) {
      throw new Error(error as string);
    }
  }, []);

  const handleDelete = useCallback(async () => {
    if (deleteModal?.id) {
      try {
        setDeleting(true);
        const response = await Api.settings.navigation.delete(deleteModal.id);
        setDeleting(false);
        if (response.status === "success") {
          enqueueSnackbar(t("navigation_deleted", { id: deleteModal.id }), { variant: "success" });
        } else enqueueSnackbar(response.message, { variant: "error" });
      } catch (error) {
        throw new Error(error as string);
      } finally {
        closeDeleteModal();
        await getNavigationList();
      }
    }
  }, [deleteModal]);

  useEffect(() => {
    getNavigationList();
  }, [getNavigationList]);

  return (
    <WrapWithAddButton sx={{ width: "80%" }} handleAdd={openMainModalForAdding}>
      <WrapWithLoading loading={loading}>
        <TableWrapper>
          <Table>
            <TableHead cellNames={tableHeadCellNames} />
            <TableBody navigation={navigation} onEdit={openMainModalForUpdating} onDelete={openDeleteModal} />
          </Table>
        </TableWrapper>
      </WrapWithLoading>
      <BaseModal open={mainModal?.open} onClose={closeMainModal} disableBackdropClick>
        <Box sx={{ width: "30vw" }}>
          {(() => {
            if (mainModal?.for) {
              switch (mainModal.for) {
                case "add":
                  return <AddNavigation refetch={getNavigationList} closeModal={closeMainModal} />;
                case "edit":
                  return (
                    mainModal?.id && (
                      <UpdateNavigation refetch={getNavigationList} closeModal={closeMainModal} id={mainModal.id} />
                    )
                  );
                default:
                  return <p>Unknown form</p>;
              }
            }
          })()}
        </Box>
      </BaseModal>
      <DeleteModal open={deleteModal?.open} onClose={closeDeleteModal} onDelete={handleDelete} loading={deleting} />
    </WrapWithAddButton>
  );
}

export default withModals(Navigation);
